import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import session from '../api/session'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import FilledInput from '@mui/material/FilledInput';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    flexGrow: 1
};




function SuperVisorModal(props) {
    const [open, setOpen] = React.useState(false)
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [benchMeters, setBenchMeters] = React.useState(props.tetragwnika ? props.tetragwnika : 0)
    const [benchPosition, setBenchPosition] = React.useState(props.benchPosition ? props.benchPosition : "")
    const [isPresent, setIsPresent] = React.useState(true)
    const [IllegalTextOne, setIllegalTextOne] = React.useState('')
    const [IllegalTextTwo, setIllegalTextTwo] = React.useState('')
    const [isIllegal, setIsIllegal] = React.useState(false)

    const [selectedViolation, setSelectedViolation] = React.useState(props.violations ? props.violations : [])
    const [benchViolations, setBenchViolations] = React.useState()

    const [cashList, setCashList] = React.useState(0)
    const [proofList, setProofList] = React.useState(0)

    const getBenchDue = async () => {
        await session.get(`api/bench/${props.benchAfm}/dept/`).then(resp => {
            setProofList(resp.data.proof_list)
            setCashList(resp.data.cash_list)
        })

    }

    const handleOpen = () => {
        getBenchDue()

        setOpen(true)
    }
    const handleChange = (e) => {
        setSelectedViolation(e.target.value)

    }


    const handleBenchPosition = (e) => {

        const benchMeters = e.target.value
            .split("-")
            .map((e) => e != "")
            .filter((o) => o);



        if (e.target.value.split("-").length > 2) {
            return;
        } else if (benchMeters.length == 1) {
            setBenchMeters(1)

        } else if (benchMeters.length == 0) {
            setBenchMeters(0)
        }

        if (benchMeters.length == 2) {
            setBenchMeters(Math.abs(eval(e.target.value)) + 1)
        }


        setBenchPosition(e.target.value)


    }

    const updateBenchPosition = () => {
        session.put(`api/Pagkoi/${props.pagkosID}/`, {
            bench_position: benchPosition,
            tetragwnika: benchMeters
        })

    }
    const parousiaPagkouPost = () => {
        const user = localStorage.getItem('userselectedd')
        const date = new Date().toISOString().split('T')[0]
        session.post('api/ParousiaPagkou/', {
            parousia: isPresent,
            date: date,
            pagkos: props.pagkosID,
            user: user
        })
    }
    const illegalPagkosPost = () => {
        const date = new Date().toISOString().split('T').join(' ')
        const user = localStorage.getItem('userselectedd')
        session.post('api/ParanomosPagkou/', {
            paratiriseis: IllegalTextOne,
            paratiriseis2: IllegalTextTwo,
            date: date,
            pagkos: props.pagkosID,
            user: user,
            violations: props.benchViolations.filter(e => selectedViolation.includes(e.title)).map(e => e.id)
        }).then((resp) => {
            console.log('ntaksei', resp.data)
            console.log(props.laikiAgora)
            let data = Object.assign({}, { laikiAgora: props.laikiAgora }, resp.data)
            session.post('api/dimotiki-astynomia/email/', data).then((r) => {

                console.log('ntaksei email')
            }).catch(e => {
                console.log(e)
                return
            })
        }).catch(e => {
            console.log(e)
            return
        })
    }
    const inspectedPagkos = () => {
        const date = new Date().toISOString().split('T').join(' ')
        const user = localStorage.getItem('userselectedd')
        session.post('api/ElegxosPagkou/', {
            date: date,
            user: user,
            pagkos: props.pagkosID
        })
    }
    const saveCard = () => {
        updateBenchPosition()
        parousiaPagkouPost()
        inspectedPagkos()
        if (isIllegal) {
            illegalPagkosPost()
        }
        props.endBenchInspection()
        setOpen(false)


    }
    return (
        <div>
            <Button onClick={handleOpen}>Έλεγχος</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Ελεγχος Παγκου
                    </Typography>
                    <hr />
                    <Stack spacing={5}>

                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>

                                    <CardContent sx={{ borderStyle: "solid", borderRadius: "20px", borderColor: "darkcyan", boxShadow: "0 0 6px cyan" }}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Στοιχεία κατόχου
                                        </Typography>

                                        <TextField disabled id="standard-basic" label="Αρ. Πάγκου" variant="standard" value={props.numberP} />
                                        <TextField disabled id="standard-basic" label="Oνοματεπώνυμο" variant="standard" value={props.name} />
                                        <TextField
                                            disabled
                                            label="Έναρξη Άδειας πάγκου"
                                            id="standard-basic"
                                            variant="standard"
                                            value={props.licenseStart}
                                        />

                                        <TextField
                                            disabled
                                            label="Λήξη Άδειας πάγκου"
                                            id="standard-basic"
                                            variant="standard"
                                            value={props.licenceEnd}
                                        />
                                        <TextField

                                            label="Θέση Πάγκου"
                                            id="standard-basic"
                                            variant="standard"
                                            disabled={props.benchPosition ? true : false}
                                            value={benchPosition}
                                            onChange={(e) => handleBenchPosition(e)}
                                        />
                                        <TextField
                                            disabled
                                            label="Μέτρα"
                                            id="standard-basic"
                                            variant="standard"
                                            value={benchMeters}
                                        />


                                        <TextField
                                            disabled
                                            label="Barcode"
                                            id="standard-basic"
                                            variant="standard"
                                            value={props.barcode}
                                        />


                                        <TextField
                                            disabled
                                            label="Qr Code"
                                            id="standard-basic"
                                            variant="standard"
                                            value={props.qrcode}
                                        />



                                        <FormControlLabel control={
                                            <Checkbox
                                                disabled
                                                checked={props.anastoli}
                                                // onChange={(e) => this.checkBoxHandle(e)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label='Αναστολή' />


                                        <FormControlLabel control={
                                            <Checkbox
                                                disabled
                                                checked={props.adeiaAllouDimou}
                                                // onChange={(e) => this.checkBoxHandle(e)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label="Άδεια Άλλου Δήμου" />



                                        <FormControlLabel control={
                                            <Checkbox
                                                disabled
                                                checked={props.diakopi}

                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label="Διακοπή" />


                                    </CardContent>
                                </Grid>
                                {/* from here */}
                                <Grid item xs={4}>
                                    <CardContent sx={{ borderStyle: "solid", borderRadius: "20px", borderColor: "darkcyan", display: "flex", flexDirection: "column", gap: '20px' }}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            ΥΠΟΛΟΙΠΟ ΟΦΕΙΛΗΣ
                                        </Typography>

                                        <TextField
                                            label="ΟΦΕΙΛΗ"
                                            id="standard-basic"
                                            variant="standard"
                                            disabled
                                            value={cashList}

                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                            }}
                                        />

                                        {/* <TextField
                                            style={{ marginTop: '20px' }}
                                            disabled
                                            label="ΑΠΟ ΒΕΒΑΙΩΤΙΚΟ ΣΗΜΕΙΩΜΑ"
                                            id="standard-basic"
                                            variant="standard"
                                            value={proofList}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                            }}

                                        // onChange={(e) => this.handleCategoryText(e)}
                                        /> */}


                                    </CardContent>
                                </Grid>
                                {/* to here */}
                                <Grid item xs={6}>
                                    <CardContent sx={{ borderStyle: "solid", borderRadius: "20px", borderColor: "darkcyan", boxShadow: "0 0 6px cyan" }}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Παρουσία Πάγκου
                                        </Typography>


                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={isPresent}
                                                onChange={() => setIsPresent(!isPresent)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label='Παρών' />

                                    </CardContent>
                                </Grid>
                                <Grid item xs={6}>
                                    <CardContent sx={{ borderStyle: "solid", borderRadius: "20px", borderColor: "darkcyan", display: "flex", flexDirection: "column", boxShadow: "0 0 6px cyan" }}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Παράβαση Πάγκου
                                        </Typography>

                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={isIllegal}
                                                onChange={() => setIsIllegal(!isIllegal)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } label='Ναι' />



                                        <FormControl sx={{ m: 1, width: 300, backgroundColor: !isIllegal ? "#cecece" : '' }}>
                                            <InputLabel id="demo-multiple-chip-label">Κατηγορία Παράβασης</InputLabel>
                                            <Select
                                                disabled={!isIllegal}
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                multiple

                                                value={selectedViolation}
                                                onChange={handleChange}
                                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} />
                                                        ))}
                                                    </Box>
                                                )}

                                            >
                                                {props.benchViolations.map((e) => (
                                                    <MenuItem
                                                        key={e.id}
                                                        value={e.title}

                                                    >
                                                        {e.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>


                                        <TextField
                                            style={{ backgroundColor: !isIllegal ? "#cecece" : '' }}
                                            label="Παρατηρήσεις "
                                            id="standard-basic"
                                            variant="standard"
                                            multiline
                                            disabled={!isIllegal}
                                            maxRows={4}
                                            onChange={(e) => setIllegalTextOne(e.target.value)}
                                        />
                                        <br />
                                        <br />
                                        <br />

                                        {/* <TextField
                                            label="Παρατηρήσεις 2"
                                            id="standard-basic"
                                            variant="standard"
                                            multiline
                                            maxRows={4}
                                            onChange={(e) => setIllegalTextTwo(e.target.value)}
                                        /> */}

                                    </CardContent>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {/* TODO: alert message when the form is saved */}
                        <Button variant="contained" color="success" onClick={saveCard}>
                            Αποθηκευση
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Ακυρο
                        </Button>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

export { SuperVisorModal }
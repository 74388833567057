import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import session from "../api/session";

import userParser from "components/userGroups.js";
import {
  BenchViolation,
  BusinessCategoryDeleteSwal,
  BusinessCategoryModal,
  CitySectionDeleteSwal,
  CitySectionModal,
  PenaltyDeleteSwal,
  PenaltyModal,
  EidosParabasisModal,
  EidosParabasisDeleteSwal,
  StadiaParabaseisModal,
  StadioParabasisDeleteSwal,
  EidosAdeiasModal,
  EidosAdeiasDeleteSwal,
  AttestationCreateModal,
  AttestationDeleteSwal,
  ViolationStageCreateModal,
  ViolationStageDeleteSwal,
  ViolationDescriptionCreateModal,
  ViolationDescriptionDeleteSwal,
  ViolationMappingFields,
  ViolationMappingFieldDelete,
  ParabasiMappingModalCreateUpdate,
  ParabasiMappingDelete
} from "./modals.jsx";
import { TripOriginSharp } from "@mui/icons-material";

const parseDate = (dt) => {
  return new Date(dt).toLocaleDateString("el", { hour12: false });
};

class Parameters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newValue: "1",
      eidiParabasis: [],
      stadiaParabasis: [],
      openUpdateEidosModal: false,
      openUpdateStadioModal: false,
      openCreateEidosModal: false,
      openCreateStadioModal: false,
      title: "",
      createValue: "",
      createValue2: "",
      eidiAdeias: [],
      penalties: [],
      citySections: [],
      businessCategories: [],
      benchViolations: [],
      attestations: [],
      violationStages: [],
      violationDescriptions: [],
      violationFields: [],
      violationMappingFields: [],
      parabasiMappingList: []
    };
  }
  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }

    if (!userParser("Admin") && !userParser("SuperUsers")) {
      window.location.href = "/";
    }

    this.fetchEidiParabasis();
    this.fetchStadiaParabasis();
    this.fetchEidiAdeias();

    this.fetchPenalty();
    this.fetchCitySections();
    this.fetchBusinessCategories();
    this.fetchBenchViolations();
    this.fetchAttestations()
    this.fetchViolationStages()
    this.fetchViolationDescriptions()
    this.fetchViolationFields()
    this.fetchMappingViolationFields()
    this.fetchParabasiMapping()
  }

  fetchParabasiMapping() {
    session.get('api/parabasi-mapping/').then((resp) => {
      this.setState({ parabasiMappingList: resp.data })
    })
  }

  fetchBenchViolations() {
    session.get("api/bench-violations/").then((resp) => {
      this.setState({ benchViolations: resp.data });
    });
  }

  fetchBusinessCategories() {
    session.get("api/EpixeirisiCategory/").then((resp) => {
      this.setState({ businessCategories: resp.data });
    });
  }

  fetchCitySections() {
    session
      .get("api/city-section/list/")
      .then((resp) => {
        this.setState({ citySections: resp.data });
      })
      .catch((e) => {
        if (
          e.response.data &&
          e.response.data.detail === "Signature has expired."
        ) {
          window.location.href = "/yp";
        }
      });
  }

  fetchEidiParabasis() {
    session
      .get("api/eidos-parabasis/all/")
      .then((resp) => {
        this.setState({ eidiParabasis: resp.data });
      })
      .catch((e) => {
        if (
          e.response.data &&
          e.response.data.detail === "Signature has expired."
        ) {
          window.location.href = "/yp";
        }
      });
  }

  fetchPenalty() {
    session
      .get("api/penalty/list/")
      .then((resp) => {
        this.setState({ penalties: resp.data });
      })
      .catch((e) => {
        if (
          e.response.data &&
          e.response.data.detail === "Signature has expired."
        ) {
          window.location.href = "/yp";
        }
      });
  }

  fetchViolationFields() {
    session.get('api/violation-fields/').then((resp) => {
      this.setState({ violationFields: resp.data })

    }).catch(e => {
      console.log(e)
    })
  }

  fetchMappingViolationFields() {
    session.get('api/eidos-mapping-violation-list/').then((resp) => {
      this.setState({ violationMappingFields: resp.data })
    }).catch(e => {
      console.log(e)
      return

    })
  }

  fetchStadiaParabasis() {
    session.get("api/stadio-parabasis/all/").then((resp) => {
      this.setState({ stadiaParabasis: resp.data });
    });
  }

  fetchEidiAdeias() {
    session.get("api/eidos-adeias/all/").then((resp) => {
      this.setState({ eidiAdeias: resp.data });
    });
  }
  fetchAttestations() {
    session.get('api/violation-attestations/').then((resp) => {
      this.setState({ attestations: resp.data })
    })
  }
  fetchViolationStages() {
    session.get('api/bussiness-violations-statuses/').then((resp) => {
      this.setState({ violationStages: resp.data })
    })
  }
  fetchViolationDescriptions() {
    session.get('api/violation-descriptions/').then((resp) => {
      this.setState({ violationDescriptions: resp.data })
    })
  }
  handleChange = (_, newValue) => {
    this.setState({
      newValue,
    });
  };

  businessCategoryEnd(e) {
    setTimeout(() => {
      this.fetchBusinessCategories();
    }, 2000);
  }

  citySectionEnd(e) {
    setTimeout(() => {
      this.fetchCitySections();
    }, 2000);
  }

  handlePenaltyEnd(e) {
    setTimeout(() => {
      this.fetchPenalty();
    }, 2000);
  }

  eidosAdeiasEnd(e) {
    setTimeout(() => {
      this.fetchEidiAdeias();
    }, 2000);
  }
  handleEndEidosParabasis(e) {
    setTimeout(() => {
      this.fetchEidiParabasis();
    }, 2000);
  }

  handleStadioEnd(e) {
    setTimeout(() => {
      this.fetchStadiaParabasis();
    }, 2000);
  }

  handleBenchViolationChange(e) {
    setTimeout(() => {
      this.fetchBenchViolations();
    }, 2000);
  }
  handleAttestationEnd(e) {
    setTimeout(() => {
      this.fetchAttestations()
    }, 2000);
  }
  handleViolationStageEnd(e) {
    setTimeout(() => {
      this.fetchViolationStages()
    }, 2000);
  }
  handleViolationDescriptionEnd(e) {
    setTimeout(() => {
      this.fetchViolationDescriptions()
    }, 2000);
  }

  handleViolationMapping(e) {
    setTimeout(() => {
      this.fetchMappingViolationFields()
    }, 1000);
  }

  handleParabsiMapping(e) {
    setTimeout(() => {
      this.fetchParabasiMapping()
    }, 1000);
  }

  parseDate(dt) {
    return new Date(dt).toLocaleDateString("el", { hour12: false });
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax small filter bgImage={require("assets/img/profile-bg2.jpg")}>
          <div style={{ height: "200px" }} />
        </Parallax>

        <Box
          sx={{
            width: "fit-content",
            borderBottom: 1,
            borderColor: "divider",
            mx: "auto",
            textAlign: "center",
          }}
        >
          <h3>ΠΑΡΑΜΕΤΡΙΚΑ</h3>
        </Box>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            backgroundColor: "white",
          }}
        >
          <TabContext value={this.state.newValue} >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TabList
                variant="scrollable"
                onChange={this.handleChange}
                aria-label="lab API tabs example"

              >
                <Tab label="ΕΙΔH ΠΑΡΑΒΑΣΗΣ" value="1" />
                <Tab label="ΣΤΑΔΙA ΠΑΡΑΒΑΣΗΣ" value="2" />
                <Tab label="ΠΑΡΑΜΕΤΡΙΚΑ ΑΔΕΙΑΣ" value="3" />
                {/* NEW */}
                <Tab label="ΠΟΙΝΕΣ" value="4" />
                <Tab label="ΔΗΜΟΤΙΚΗ ΕΝΟΤΗΤΑ" value="5" />
                <Tab label="ΚΑΤΗΓΟΡΙΕΣ ΕΠΙΧΕΙΡΗΣΕΩΝ" value="6" />
                <Tab label="ΠΑΡΑΒΑΣΕΙΣ ΠΑΓΚΟΥ" value="7" />
                <Tab label="ΑΡΧΗ ΒΕΒΑΙΩΣΗΣ ΠΑΡΑΒΑΣΗΣ" value="8" />
                <Tab label="ΚΑΤΑΣΤΑΣΗ ΠΑΡΑΒΑΣΗΣ" value="9" />
                <Tab label="ΠΕΡΙΓΡΑΦΗ ΠΑΡΑΒΑΣΗΣ" value="10" />
                <Tab label="ΛΕΠΤΟΜΕΡΕΙΕΣ ΠΑΡΑΒΑΣΗΣ" value="11" />
                <Tab label="ΣΥΣΧΕΤΙΣΗ ΕΙΔΟΣ-ΣΤΑΔΙΟ ΠΑΡΑΒΑΣΗΣ" value="12" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Τίτλος</TableCell>
                      <TableCell align="right">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.eidiParabasis &&
                      this.state.eidiParabasis.length > 0 &&
                      this.state.eidiParabasis.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{e.title}</TableCell>
                          <TableCell align="right">
                            {parseDate(e.created_at)}
                          </TableCell>
                          <TableCell align="right">
                            <EidosParabasisModal
                              action="Update"
                              handleEnd={(e) => this.handleEndEidosParabasis(e)}
                              eidosId={e.id}
                              title={e.title}
                            ></EidosParabasisModal>
                            <EidosParabasisDeleteSwal
                              EidosID={e.id}
                              handleEnd={(e) => this.handleEndEidosParabasis(e)}
                            ></EidosParabasisDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <EidosParabasisModal
                action="Create"
                handleEnd={(e) => this.handleEndEidosParabasis(e)}
              ></EidosParabasisModal>
            </TabPanel>
            {/* TAB STADIA PARABASEIS */}
            <TabPanel value="2">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Τίτλος</TableCell>
                      <TableCell align="right">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.stadiaParabasis &&
                      this.state.stadiaParabasis.length > 0 &&
                      this.state.stadiaParabasis.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{e.title}</TableCell>
                          <TableCell align="right">
                            {parseDate(e.created_at)}
                          </TableCell>
                          <TableCell align="right">
                            <StadiaParabaseisModal
                              action="Update"
                              title={e.title}
                              handleStadioEnd={(e) => this.handleStadioEnd(e)}
                              stadioID={e.id}
                            ></StadiaParabaseisModal>

                            <StadioParabasisDeleteSwal
                              handleStadioEnd={(e) => this.handleStadioEnd(e)}
                              stadioID={e.id}
                            ></StadioParabasisDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <StadiaParabaseisModal
                action="Create"
                handleStadioEnd={(e) => this.handleStadioEnd(e)}
              ></StadiaParabaseisModal>
            </TabPanel>
            <TabPanel value="3">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Τίτλος</TableCell>
                      <TableCell align="right">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.eidiAdeias &&
                      this.state.eidiAdeias.length > 0 &&
                      this.state.eidiAdeias.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{e.title}</TableCell>
                          <TableCell align="right">
                            {parseDate(e.created_at)}
                          </TableCell>
                          <TableCell align="right">
                            <EidosAdeiasModal
                              action="Update"
                              title={e.title}
                              eidosAdeiasID={e.id}
                              eidosAdeiasEnd={(e) => this.eidosAdeiasEnd(e)}
                            ></EidosAdeiasModal>
                            <EidosAdeiasDeleteSwal
                              eidosAdeiasEnd={(e) => this.eidosAdeiasEnd(e)}
                              eidosAdeiasID={e.id}
                            ></EidosAdeiasDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <EidosAdeiasModal
                action="Create"
                eidosAdeiasEnd={(e) => this.eidosAdeiasEnd(e)}
              ></EidosAdeiasModal>
            </TabPanel>
            <TabPanel value="4">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Τίτλος</TableCell>
                      <TableCell align="right">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.penalties &&
                      this.state.penalties.length > 0 &&
                      this.state.penalties.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{e.title}</TableCell>
                          <TableCell align="right">
                            {parseDate(e.created_at)}
                          </TableCell>
                          <TableCell align="right">
                            <PenaltyModal
                              action="Update"
                              title={e.title}
                              penaltyId={e.id}
                              penaltyEnd={(e) => this.handlePenaltyEnd(e)}
                            ></PenaltyModal>
                            <PenaltyDeleteSwal
                              penaltyEnd={(e) => this.handlePenaltyEnd(e)}
                              penaltyId={e.id}
                            ></PenaltyDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <PenaltyModal
                action="Create"
                penaltyEnd={(e) => this.handlePenaltyEnd(e)}
              ></PenaltyModal>
            </TabPanel>

            <TabPanel value="5">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Τίτλος</TableCell>
                      <TableCell align="right">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.citySections &&
                      this.state.citySections.length > 0 &&
                      this.state.citySections.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{e.title}</TableCell>
                          <TableCell align="right">
                            {parseDate(e.created_at)}
                          </TableCell>
                          <TableCell align="right">
                            <CitySectionModal
                              action="Update"
                              title={e.title}
                              citySectionId={e.id}
                              citySectionEnd={(e) => this.citySectionEnd(e)}
                            ></CitySectionModal>
                            <CitySectionDeleteSwal
                              citySectionEnd={(e) => this.citySectionEnd(e)}
                              citySectionId={e.id}
                            ></CitySectionDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <CitySectionModal
                action="Create"
                citySectionEnd={(e) => this.citySectionEnd(e)}
              ></CitySectionModal>
            </TabPanel>

            <TabPanel value="6">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="center">Τίτλος</TableCell>
                      <TableCell align="center">Logo</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.businessCategories &&
                      this.state.businessCategories.length > 0 &&
                      this.state.businessCategories.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">{e.nameEC}</TableCell>
                          <TableCell align="center">
                            <img
                              src={e.logo}
                              style={{ width: "3%" }}
                              alt="Logo"
                            ></img>
                          </TableCell>
                          <TableCell align="right">
                            <BusinessCategoryModal
                              action="Update"
                              nameEC={e.nameEC}
                              businessCatetoryId={e.id}
                              businessCategoryEnd={(e) =>
                                this.businessCategoryEnd(e)
                              }
                            ></BusinessCategoryModal>
                            <BusinessCategoryDeleteSwal
                              businessCategoryEnd={(e) =>
                                this.businessCategoryEnd(e)
                              }
                              businessCatetoryId={e.id}
                            ></BusinessCategoryDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <BusinessCategoryModal
                action="Create"
                businessCategoryEnd={(e) => this.businessCategoryEnd(e)}
              ></BusinessCategoryModal>
            </TabPanel>

            <TabPanel value="7">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="center">Τίτλος</TableCell>
                      <TableCell align="center">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.benchViolations &&
                      this.state.benchViolations.length > 0 &&
                      this.state.benchViolations.map((e, idx) => (
                        <TableRow
                          key={idx}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell align="center">
                            {e.title}
                          </TableCell>
                          <TableCell align="center">
                            {parseDate(e.created_at)}
                          </TableCell>
                          <TableCell align="right">
                            <BenchViolation
                              title={e.title}
                              action="Update"
                              benchViolationId={e.id}
                              BenchViolationChange={(e) =>
                                this.handleBenchViolationChange(e)
                              }
                            ></BenchViolation>
                          </TableCell>
                        </TableRow>
                      ))}


                  </TableBody>
                </Table>
              </TableContainer>
              <BenchViolation
                action="Create"
                BenchViolationChange={(e) =>
                  this.handleBenchViolationChange(e)
                }
              ></BenchViolation>
            </TabPanel>
            <TabPanel value="8">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Τίτλος</TableCell>
                      <TableCell align="right">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.attestations &&
                      this.state.attestations.length > 0 &&
                      this.state.attestations.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{e.name}</TableCell>
                          <TableCell align="right">
                            {parseDate(e.created_at)}
                          </TableCell>
                          <TableCell align="right">
                            <AttestationCreateModal
                              action="Update"
                              title={e.name}
                              handleAttestationEnd={(e) => this.handleAttestationEnd(e)}
                              attestationId={e.id}
                            ></AttestationCreateModal>

                            <AttestationDeleteSwal
                              handleAttestationEnd={(e) => this.handleAttestationEnd(e)}
                              attestationId={e.id}
                            ></AttestationDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <AttestationCreateModal
                action="Create"
                handleAttestationEnd={(e) => this.handleAttestationEnd(e)}
              ></AttestationCreateModal>
            </TabPanel>
            <TabPanel value="9">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Τίτλος</TableCell>
                      <TableCell align="right">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.violationStages &&
                      this.state.violationStages.length > 0 &&
                      this.state.violationStages.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{e.name}</TableCell>
                          <TableCell align="right">
                            {parseDate(e.created_at)}
                          </TableCell>
                          <TableCell align="right">
                            <ViolationStageCreateModal
                              action="Update"
                              title={e.name}
                              handleViolationStageEnd={(e) => this.handleViolationStageEnd(e)}
                              violationId={e.id}
                            ></ViolationStageCreateModal>

                            <ViolationStageDeleteSwal
                              handleViolationStageEnd={(e) => this.handleViolationStageEnd(e)}
                              violationId={e.id}
                            ></ViolationStageDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ViolationStageCreateModal
                action="Create"
                handleViolationStageEnd={(e) => this.handleViolationStageEnd(e)}
              ></ViolationStageCreateModal>
            </TabPanel>




            <TabPanel value="10">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Τίτλος</TableCell>
                      <TableCell align="right">Δημιουργήθηκε</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.violationDescriptions &&
                      this.state.violationDescriptions.length > 0 &&
                      this.state.violationDescriptions.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{e.name}</TableCell>
                          <TableCell align="right">
                            {parseDate(e.created_at)}
                          </TableCell>
                          <TableCell align="right">
                            <ViolationDescriptionCreateModal
                              action="Update"
                              title={e.name}
                              handleViolationDescriptionEnd={(e) => this.handleViolationDescriptionEnd(e)}
                              violationId={e.id}
                            ></ViolationDescriptionCreateModal>

                            <ViolationDescriptionDeleteSwal
                              handleViolationDescriptionEnd={(e) => this.handleViolationDescriptionEnd(e)}
                              violationId={e.id}
                            ></ViolationDescriptionDeleteSwal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ViolationDescriptionCreateModal
                action="Create"
                handleViolationDescriptionEnd={(e) => this.handleViolationDescriptionEnd(e)}
              ></ViolationDescriptionCreateModal>
            </TabPanel>
            <TabPanel value="11">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Κατηγορία</TableCell>
                      <TableCell align="center">Πεδία</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.violationMappingFields &&
                      this.state.violationMappingFields.length > 0 &&
                      this.state.violationMappingFields.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">
                            {e.eidos_field}
                          </TableCell>
                          <TableCell align="center">
                            <div style={{ whiteSpace: 'pre-line' }}>
                              {JSON.parse(e.fields_to_exclude).map((field) => field.verbose_name).join('\n')}
                            </div>
                          </TableCell>

                          <TableCell align="right">
                            <ViolationMappingFields
                              action="Update"
                              violationFields={this.state.violationFields}
                              eidosParabasis={this.state.eidiParabasis}
                              handleViolationMapping={(e) => this.handleViolationMapping(e)}
                              fieldName={JSON.parse(e.fields_to_exclude).map((field) => field.verbose_name)}
                              EidosMappingId={e.id}
                              eidosCategory={e.eidos_choice}
                            ></ViolationMappingFields>

                            <ViolationMappingFieldDelete
                              EidosMappingId={e.id}
                              handleViolationMapping={(e) => this.handleViolationMapping(e)}
                            ></ViolationMappingFieldDelete>
                          </TableCell>

                        </TableRow>

                      ))}

                  </TableBody>

                </Table>
              </TableContainer>

              <ViolationMappingFields action="Create" violationFields={this.state.violationFields} eidosParabasis={this.state.eidiParabasis} handleViolationMapping={(e) => this.handleViolationMapping(e)}></ViolationMappingFields>

            </TabPanel>


            <TabPanel value="12">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>A/A</TableCell>
                      <TableCell align="right">Είδος παράβασης</TableCell>
                      <TableCell align="center">Στάδια Παράβασης</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.parabasiMappingList &&
                      this.state.parabasiMappingList.length > 0 &&
                      this.state.parabasiMappingList.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">
                            {e.eidos_parabasis_title}
                          </TableCell>
                          <TableCell align="center">
                            <div style={{ whiteSpace: 'pre-line' }}>
                              {e.stadio_parabasis.map((field) => field.title).join('\n')}
                            </div>
                          </TableCell>

                          <TableCell align="right">
                            <ParabasiMappingModalCreateUpdate
                              action='Update'
                              eidosParabasis={this.state.eidiParabasis}
                              stadiaParabasis={this.state.stadiaParabasis}
                              fieldName={e.stadio_parabasis.map((field) => field.title)}
                              handleParabasiMappingModal={(e) => this.handleParabsiMapping(e)}
                              parabasiMappingId={e.id}
                              eidosCategory={e.eidos_parabasis}

                            ></ParabasiMappingModalCreateUpdate>
                            <ParabasiMappingDelete
                              parabasiMappingId={e.id}
                              handleParabasiMappingModal={(e) => this.handleParabsiMapping(e)}

                            ></ParabasiMappingDelete>

                            {/* <ViolationMappingFields
                              action="Update"
                              violationFields={this.state.violationFields}
                              eidosParabasis={this.state.eidiParabasis}
                              handleViolationMapping={(e) => this.handleViolationMapping(e)}
                              fieldName={JSON.parse(e.fields_to_exclude).map((field) => field.verbose_name)}
                              EidosMappingId={e.id}
                              eidosCategory={e.eidos_choice}
                            ></ViolationMappingFields>

                            <ViolationMappingFieldDelete
                              EidosMappingId={e.id}
                              handleViolationMapping={(e) => this.handleViolationMapping(e)}
                            ></ViolationMappingFieldDelete> */}
                          </TableCell>

                        </TableRow>

                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ParabasiMappingModalCreateUpdate
                action='Create'
                eidosParabasis={this.state.eidiParabasis}
                stadiaParabasis={this.state.stadiaParabasis}
                handleParabasiMappingModal={(e) => this.handleParabsiMapping(e)}
              ></ParabasiMappingModalCreateUpdate>
            </TabPanel>




          </TabContext>
        </Box>

        <div style={{ marginTop: "200px" }}>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Parameters;

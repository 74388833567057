import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  Tooltip,
  useMapEvents,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import "leaflet/dist/leaflet.css";
import { isConstructorDeclaration } from "typescript";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CustomInput from "components/CustomInput/CustomInput.js";
import Date from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Close from "@material-ui/icons/Close";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Badge from "components/Badge/Badge.js";
import DateFnsUtils from "@date-io/date-fns";
import TimeInput from "react-input-time";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MultiSelect from "react-multi-select-component";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// core components

import modalStyle from "assets/jss/material-kit-react/modalStyle.js";

import {
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Progress,
  Container,
  Row,
  Input,
  Nav,
  TabPane,
  TabContent,
  // Button,
  NavLink,
  NavItem,
  Col,
  // UncontrolledTooltip,
} from "reactstrap";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import { number } from "prop-types";
// import leaflet from 'https://unpkg.com/leaflet@1.5.1/dist/leaflet.css';
import ReactTable from "components/ReactTable/ReactTable.js";
import Geocode from "react-geocode";
import lastDayOfISOWeek from "date-fns/fp/lastDayOfISOWeek/index";
import session from "../api/session";
import userParser from "components/userGroups.js";
Geocode.setApiKey("AIzaSyBSF6RaFqZGzFJgPZv1drw7PEjYZ8qZd_0");
Geocode.setLanguage("el");
Geocode.setRegion("gr");
let UdataTable = [];
// styles = {
//   label: {
//     cursor: "pointer",
//     paddingLeft: "0",
//     color: "rgba(0, 0, 0, 0.26)",
//     fontSize: "14px",
//     lineHeight: "1.428571429",
//     fontWeight: "400",
//     display: "inline-flex",
//   },
// };

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let posseleted = [];
let adressel = "";
let newE = false;
let editE = false;
let center = [39.365, 22.951];
let zoom = 14;

async function setlogo(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/logoupload";

  // console.log(formData);
  console.log(item);
  console.log(item.logo);
  console.log(item.nameEC);

  let formData = new FormData();

  formData.append("logo", item.logo);
  formData.append("nameEC", item.nameEC);
  formData.append("iconEC", "-");
  console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/logoupload", formData);

  // let response = await axios.post(url, formData);
  console.log(response);
  // console.log(response.data.logo);
  // var logoname = response.data.logo;
  // window.location.reload();
}
// function MyComponent() {
//   const map = useMapEvents({
//     click: () => {
//       map.locate();
//     },
//     locationfound: (location) => {
//    console.log("location found:", location);
//     },
//   });
//   return null;
// }

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      style={{ borderRadius: "15px" }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Όλα</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

async function setDocE(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/docuploadEpixirisi";
  console.log(item);

  let formData = new FormData();
  formData.append("epixeirisi", item.epixeirisi);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  console.log(formData);

  // let response = await axios.post(url, formData);
  await session.post("api/docuploadEpixirisi", formData).then(() => {
    this.refreshList();
  });

  // let response = await axios.post(url, formData);
  // console.log(response);
}

function LocationMarker() {
  const [position, setPosition] = useState(null);
  // const newItem = useState([]);

  const map = useMapEvents({
    click(e) {
      var coord = e.latlng;
      var lat = coord.lat;
      var lng = coord.lng;
      // posseleted = lat;
      setPosition(e.latlng);
      var tmpPos = [];
      tmpPos.push(lat);
      tmpPos.push(lng);
      posseleted.push(tmpPos);
      console.log(
        "You clicked the map at latitude: " + lat + " and longitude: " + lng
      );

      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);
          adressel = address;
        },
        (error) => {
          console.error(error);
        }
      );

      // map.locate();
    },
    // locationfound(e) {
    //   setPosition(e.latlng);
    //   map.flyTo(e.latlng, map.getZoom());
    // },
  });

  return position === null ? null : (
    <Marker position={position}>
      {" "}
      <Tooltip>{adressel}</Tooltip>{" "}
    </Marker>
  );
}

// function handleChangeEp(e) {
//console.log(e.target);
//console.log(e.target.value);
//console.log(e.target.id);

// const { name, value } = e.target;
// this.state.newItem = { ...this.state.newItem, [name]:  value };

// console.log(this.state.newItem);
// }

export default function MapEAdmin() {
  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  let newItem = React.useState();
  let newItemC = React.useState();

  React.useEffect(() => {
    console.log("e");
    console.log(editE);
  }, [editE]);

  React.useEffect(() => {
    // console.log("e");
    // console.log(editE);
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  // const setModalOpen = () => {
  //   modal = true;
  //console.log("ok");
  // };

  // function handleChangeEp(e) {
  //   // console.log(e.target);
  //   // console.log(e.target.value);
  //   // console.log(e.target.id);

  //   // if (e.target.id == "nameE") {
  //   //   newItem.nameE = e.target.value;
  //   // } else if (e.target.id == "afmE") {
  //   //   newItem.afmE = e.target.value;
  //   // }

  //   const name = e.target.id;
  //   const value = e.target.value;
  //   newItem = { ...newItem, [name]: value };

  //   // console.log(newItem);
  // }

  // function handleChangeC(e) {
  //   // console.log(e.target);
  //   // console.log(e.target.value);
  //   // console.log(e.target.id);

  //   // if (e.target.id == "nameE") {
  //   //   newItem.nameE = e.target.value;
  //   // } else if (e.target.id == "afmE") {
  //   //   newItem.afmE = e.target.value;
  //   // }

  //   const name = e.target.id;
  //   const value = e.target.value;
  //   newItemC = { ...newItemC, [name]: value };

  //   // console.log(newItemC);
  // }

  // function handlePostEp() {
  //   // console.log(newItem);
  //   // console.log(posseleted);

  //   newItem.coordsE = posseleted[0];
  //   newItem.userIdE = 1;
  //   newItem.enarxiE = "2021-04-26";
  //   newItem.lixiE = "2021-04-26";

  //   // console.log(newItem);
  //   // console.log(posseleted);

  //   axios
  //     .post("https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/", newItem)
  //     .then(setModal(false));

  //   newE = true;
  // }
  // const position = [51.505, -0.09];
  // const polygon = [
  //   [51.515, -0.09],
  //   [51.52, -0.1],
  //   [51.52, -0.12],
  // ];
  // const purpleOptions = { color: "purple" };

  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
      <br />
      <br />
      <br />
      <br />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            {/* <Button color="rose" size="sm" round onClick={() => setModal(true)}>
              Προσθήκη Επιχείρησης
            </Button>
            <Button
              color="rose"
              size="sm"
              round
              onClick={() => setModal2(true)}
            >
              Προσθήκη Κατηγορίας
            </Button> */}
            <MapPanel />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

class MapPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      EpixeiriseisList: [],
      EpixeiriseisCategoryList: [],
      docsListE: [],
      open: false,
      markers: [[0, 0]],
      activeItemE: {},
      activeItemEC: {},
      modalE: false,
      modalEC: false,
      map: null,
      mapModal: null,
      epixirisiselected: "",
      arxdocitemE: {},
      selectedDocsE: [],
      swalAlertOpen: false,
      businessToDelete: "",
    };
  }
  handleSwalAlertOpen() {
    this.setState({ swalAlertOpen: true });
  }
  handleSwalAlertClose() {
    this.setState({ swalAlertOpen: false });
  }
  deleteBusinessAlert(businessToDelete) {
    console.log("nai", businessToDelete);
    this.setState({ swalAlertOpen: false });
    session.delete(`api/Epixeiriseis/${businessToDelete.id}`).then(() => {
      this.refreshList();
    });

    this.setState({ state: this.state });
    this.render();
  }

  //ΚΛΕΙΝΕΙ MODAL
  handleCloseE = () => {
    this.state.activeItemE = [];
    this.setState({ activeItemE: [] });
    this.setState({ modalE: false });
  };

  handleCloseEC = () => {
    this.state.activeItemEC = [];
    this.setState({ activeItemEC: [] });
    this.setState({ modalEC: false });
  };

  handleEpixirisiSelect(e, latlng) {
    const value = e.target.value;

    this.state.epixirisiselected = value;

    const { map } = this.state;
    if (map) map.flyTo(latlng, 18);

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  checkaddress = () => {
    let address = this.state.activeItemE.dieuthinsi;

    let latS = "";
    let lngS = "";

    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        latS = lat;
        lngS = lng;
        console.log(lat, lng);
        var tmpPos = [];
        tmpPos.push(latS);
        tmpPos.push(lngS);
        posseleted.push(tmpPos);

        if (this.state.mapModal) {
          this.state.mapModal.flyTo(tmpPos, 18);
          var marker = L.marker([latS, lngS]);

          this.state.mapModal.addLayer(marker);
        }

        // if (this.state.mapModal != null) {
        //   var marker = L.marker([latS, lngS]);
        //   var markers = L.markerClusterGroup();
        //   markers.addLayer(marker);
        //   this.state.mapModal.addLayer(markers);

        //   const { map } = this.state;
        //   if (map) map.flyTo(latlng, 18);

        // }
      },
      (error) => {
        console.error(error);
      }
    );

    // Geocode.fromAddress("Eiffel Tower").then(
    //   (response) => {
    //     const { lat, lng } = response.results[0].geometry.location;
    //     console.log(lat, lng);
    //   },
    //   (error) => {
    //     console.error(error);
    //   }
    // );
  };

  handleChangeEC = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemEC = { ...this.state.activeItemEC, [name]: value };
    // formData.append([name], value);
    console.log("ok");
    this.setState({ activeItemEC });
  };

  handleChangeSelectIcon = (e) => {
    const name = "iconeE";
    const value = e.target.value;
    const activeItemE = { ...this.state.activeItemE, [name]: value };

    console.log(activeItemE);
    this.setState({ activeItemE });

    this.setState({ state: this.state });
  };
  handleChangeLogo = (e) => {
    console.log("ok");
    var logo = e.target.files[0];
    // formData.append("logo", logo);

    this.state.activeItemEC.logo = e.target.files[0];
  };

  handleLogoSet = (item) => {
    if (item.logo === "") {
      alert("Προσθέστε αρχείο");
    } else if (item.nameEc == "") {
      alert("Προσθέστε όνομα κατηγορίας");
    } else {
      setlogo(item);

      this.handleCloseEC();
    }
  };

  handleChangeBools = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });
  };

  handleChangeEp = (e) => {
    // console.log(e);
    // console.log(e.target.id);

    // console.log(e.target.value);

    const name = e.target.id;
    const value = e.target.value;
    const activeItemE = { ...this.state.activeItemE, [name]: value };

    // console.log(activeItemE);
    this.setState({ activeItemE });
  };

  handleChangeArxDocE = (e) => {
    var files = e.target.files[0];
    console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // console.log(name)
    // const arxdocitemE = { ...this.state.arxdocitemE, [name]: files };

    // this.setState({ arxdocitemE });

    console.log(this.state.activeItemE);

    this.state.arxdocitemE.file_field = e.target.files[0];
    this.state.arxdocitemE.epixeirisi = this.state.activeItemE.nameE;
    this.state.arxdocitemE.description = fname[0];
  };

  handleChangeEnarxiWrariou2 = (e) => {
    console.log(e);
    console.log(e.target);
    console.log(e.timestamp);

    // var hour = e.getHours();
    // var minutes = e.getMinutes();

    // if (minutes.length == 1) {
    //   minutes = "0" + minutes;
    // }
    // var fulltime = hour + ":" + minutes + ":00";

    // this.state.activeItemE.wrarioE = fulltime;
    // this.setState({ state: this.state });
    // console.log(fulltime);
  };

  handleChangeEnarxiWrariou = (e) => {
    console.log(e);

    var hour = e.getHours();
    var minutes = e.getMinutes();

    if (minutes.length == 1) {
      minutes = "0" + minutes;
    }
    var fulltime = hour + ":" + minutes + ":00";

    this.state.activeItemE.wrarioE = fulltime;
    this.setState({ state: this.state });
    console.log(fulltime);
  };

  handleChangeEnarxiE = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItemE.enarxiE = fulldate;

    console.log(this.state.activeItemE);
    this.setState({ state: this.state });
    // this.setState({ activeItemE });
  };

  handleChangeLixiE = (e) => {
    // console.log(e);

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    this.state.activeItemE.lixiE = fulldate;

    this.setState({ state: this.state });
  };

  handleChangeEnarksiAdeias = (e) => {
    // console.log(e);

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    this.state.activeItemE.enarksi_adeias = fulldate;

    this.setState({ state: this.state });
  };
  handleChangeLiksiAdeias = (e) => {
    // console.log(e);

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    this.state.activeItemE.enarksi_adeias = fulldate;

    this.setState({ state: this.state });
  };

  handlePostEp = () => {
    // console.log(newItem);
    // console.log(posseleted);
    const newItem = this.state.activeItemE;

    let lenghtps = posseleted.length;

    if (lenghtps != 0) {
      newItem.coordsE = posseleted[lenghtps - 1];
    }

    if (adressel != "") {
      newItem.dieuthinsi = adressel;
    }

    newItem.centerE = newItem.coordsE;
    // newItem.userIdE = 1;
    // newItem.enarxiE = "2021-04-21";
    // newItem.lixiE = "2021-04-26";
    // newItem.iconeE = "test";

    if (newItem.wrarioE === "") {
      newItem.wrarioE = "08:00";
    }
    if (newItem.wrarioL === "") {
      newItem.wrarioL = "16:00";
    }

    console.log(newItem);
    // console.log(posseleted);

    if (newItem.centerE[0] == 0) {
      alert("Παρακαλούμε επιλέξτε σημείο στον χάρτη");
    } else if (
      newItem.userIdE == "" ||
      newItem.nameE == "" ||
      newItem.iconeE == ""
    ) {
      alert("Παρακαλούμε προσθέστε τα υποχρεωτικά πεδία");
    } else {
      if (newItem.id) {
        // axios
        //   .put(
        //     `https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/${newItem.id}/`,
        //     newItem
        //   )
        session.put(`api/Epixeiriseis/${newItem.id}/`, newItem).then(() => {
          this.refreshList();
        });
        if (this.state.arxdocitemE.file_field != undefined) {
          setDocE(this.state.arxdocitemE);
          this.refreshListDocs();
        }
      } else {
        this.state.arxdocitemE.epixeirisi = newItem.nameE;

        // axios
        //   .post("https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/", newItem)
        session.post("api/Epixeiriseis/", newItem).then(() => {
          this.refreshList();
        });

        if (this.state.arxdocitemE.file_field != undefined) {
          setDocE(this.state.arxdocitemE);
          this.refreshListDocs();
        }
      }

      this.refreshListDocs();
      this.state.activeItemE = [];
      this.refreshList();
      this.handleCloseE();
      document.getElementById("file_field").value = null;
      posseleted = [];
      posseleted.splice();
    }
    // newE = true;
  };
  // c

  handleNewE = () => {
    // console.log(item);
    // this.state.activeItemE. = "";
    this.state.activeItemE.userIdE = "";
    this.state.activeItemE.nameE = "";
    this.state.activeItemE.afmE = "";

    this.state.activeItemE.arfak = "";
    this.state.activeItemE.antikatastasi = false;
    this.state.activeItemE.paratiriseis = "";
    this.state.activeItemE.dieuthinsi = "";

    this.state.activeItemE.siteE = "";
    this.state.activeItemE.posoE = "";
    this.state.activeItemE.covidStatus = "";
    this.state.activeItemE.wrarioE = "";
    this.state.activeItemE.wrarioL = "";
    this.state.activeItemE.iconeE = "";
    // this.state.activeItemE.enarksi_adeias = "";
    // this.state.activeItemE.liksi_adeias = "";
    document.getElementById("file_field").value = null;
    // this.state.activeItemE = item;
    this.setState({ modalE: true });
    // console.log(this.state.modalE);
    console.log(this.state.activeItemE);
    // this.render();
  };
  handleNewEC = () => {
    // console.log(item);
    this.state.activeItemEC.nameEC = "";
    this.state.activeItemEC.logo = "";
    // this.state.activeItemE = item;
    this.setState({ modalEC: true });
    // console.log(this.state.modalE);
    console.log(this.state.activeItemEC);
    // this.render();
  };

  handleEditE = (item) => {
    console.log(item);
    console.log(this.state.docsListE);

    this.refreshListDocs();

    this.state.selectedDocsE = this.state.docsListE.filter(
      (itemS) => String(itemS.epixeirisi) === String(item.nameE)
    );

    console.log(this.state.selectedDocsE);

    this.state.activeItemE = item;
    // this.state.activeItemE = item;
    this.setState({ modalE: true });
    // console.log(this.state.modalE);
    console.log(this.state.activeItemE);
    // this.render();
  };

  handleDeleteDocE = (id) => {
    this.setState({ modalE: false });
    // axios
    //   .delete(
    //     `https://epixirin-volos.govcloud.gr/be/api/FileFieldFormEpixeirisi/${id}`
    //   )
    session.delete(`api/FileFieldFormEpixeirisi/${id}`).then(() => {
      this.refreshList();
      this.refreshListDocs();
    });

    this.state.selectedDocsE = this.state.docsListE.filter(
      (itemS) =>
        String(itemS.epixeirisi) === String(this.state.activeItemE.nameE)
    );

    this.setState({ state: this.state });

    this.render();
    this.refreshListDocs();
    document.getElementById("file_field").value = null;
    // window.location.reload();

    this.setState({ modalE: false });

    // this.setState({ modalE: true });
    this.yourFunction();

    // window.location.reload(false);
    // this.setState({ modalP: true });
  };

  async yourFunction() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    await delay(1000);
    this.handleEditE(this.state.activeItemE);
  }

  handleDeleteE = (item) => {
    this.setState({ swalAlertOpen: true, businessToDelete: item });
    // axios
    //   .delete(`https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/${item.id}`)
    // session.delete(`api/Epixeiriseis/${item.id}`).then(() => {
    //   this.refreshList();
    //   this.setState({ state: this.state });
    //   this.render();
    // });
    // this.refreshList();

    // this.setState({ state: this.state });
    // this.render();
  };
  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
    if (!userParser("Admin") && !userParser("SuperUsers")) {
      window.location.href = "/";
    }
    this.refreshList();
    this.refreshListDocs();
    this.refreshListCategory();
  }

  refreshList() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/")
    session
      .get("api/Epixeiriseis/")
      .then((res) => this.setState({ EpixeiriseisList: res.data }))
      .catch((err) => console.log(err));
  }

  refreshListCategory() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/EpixeirisiCategory/")
    session
      .get("api/EpixeirisiCategory/")
      .then((res) => this.setState({ EpixeiriseisCategoryList: res.data }))
      .catch((err) => console.log(err));
  }

  refreshListDocs() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/FileFieldFormEpixeirisi/")
    session
      .get("api/FileFieldFormEpixeirisi/")
      .then((res) => this.setState({ docsListE: res.data }))
      .catch((err) => console.log(err));
  }

  render() {
    let EpixeiriseisItems = this.state.EpixeiriseisList;
    const { markers } = this.state;
    const classes = this.state.useStyles;
    const Epcategories = this.state.EpixeiriseisCategoryList;

    // console.log(Epcategories);
    // console.log(EpixeiriseisItems);

    const myIcon = L.icon({
      iconUrl: require("../../assets/img/food.png"),
      iconSize: [25, 30],
      // iconAnchor: [32, 64],
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
    });

    if (Epcategories != undefined) {
      for (var i = 0; i < EpixeiriseisItems.length; i++) {
        // console.log(EpixeiriseisItems[0].iconeE);
        // console.log(Epcategories);
        let categorytmp = Epcategories.filter(
          (item) => String(item.nameEC) === String(EpixeiriseisItems[i].iconeE)
        );

        if (categorytmp[0] != undefined) {
          // console.log(categorytmp);

          EpixeiriseisItems[i].logo = categorytmp[0].logo;

          const myIcon = L.icon({
            iconUrl: categorytmp[0].logo,
            iconSize: [25, 25],
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
          });

          EpixeiriseisItems[i].logo2 = myIcon;

          // const iconPerson = new L.Icon({
          //   iconUrl: require(categorytmp[0].logo),
          //   iconRetinaUrl: require(categorytmp[0].logo),
          //   iconAnchor: null,
          //   popupAnchor: null,
          //   shadowUrl: null,
          //   shadowSize: null,
          //   shadowAnchor: null,
          //   iconSize: new L.Point(60, 75),
          //   className: "leaflet-div-icon",
          // });

          // EpixeiriseisItems[i].logo = iconPerson;
        }
      }
    } else {
      Epcategories = undefined;
      this.setState({ state: this.state });
    }

    // console.log(EpixeiriseisItems);

    // console.log(newE);

    // if (newE == true) {
    //   this.refreshList();
    //   this.refreshList();
    //console.log(EpixeiriseisItems.length);
    //console.log(this.state.EpixeiriseisList);
    //   newE = false;
    // }

    // for (var i = 0; i < EpixeiriseisItems.length; i++) {
    //   var tmpr = [];

    //   var tmpstr = "";
    //   tmpstr = EpixeiriseisItems[i].coordsE.split(",");
    //   var tmpstrlat = parseFloat(tmpstr[0]);
    //   var tmpstrlong = parseFloat(tmpstr[1]);
    //   tmpr.push(tmpstrlat);
    //   tmpr.push(tmpstrlong);
    //   // markers.push(tmpr);

    //   EpixeiriseisItems.coordsE = tmpr;
    // }

    // const { markers } = this.state;
    // markers.push(e.latlng)
    // this.setState({markers})
    const positionC = [39.365, 22.951];
    const open = this.state.open;
    // console.log(open);
    // console.log(EpixeiriseisItems);

    // console.log(this.state.modalE);

    // const tmpItemsEdit = this.state.activeItemE;

    // if (tmpItemsEdit == undefined) {
    //   tmpItemsEdit = [];
    // }
    if (this.state.activeItemE.coordsE == undefined) {
      this.state.activeItemE.coordsE = [0, 0];
    }

    UdataTable = [];

    for (var i = 0; i < EpixeiriseisItems.length; i++) {
      const dataRow = {};
      const coords = EpixeiriseisItems[i].coordsE;
      const name = EpixeiriseisItems[i].nameE;
      const logo = EpixeiriseisItems[i].logo;
      const item = EpixeiriseisItems[i];
      dataRow.name = name;
      dataRow.selectLa = (
        <Radio
          checked={String(this.state.epixirisiselected) === String(name)}
          onChange={(e) => this.handleEpixirisiSelect(e, coords)}
          value={name}
          name="radio-button-demo"
        />
      );
      // tmpItemsLaikes[i].nameLA;
      dataRow.media = <img style={{ width: "25px" }} src={logo} />;
      dataRow.eidos = EpixeiriseisItems[i].iconeE;
      dataRow.wrario =
        EpixeiriseisItems[i].wrarioE + "-" + EpixeiriseisItems[i].wrarioL;

      dataRow.user = EpixeiriseisItems[i].userIdE;

      let tmp = EpixeiriseisItems[i].enarxiE;
      let tmp2 = tmp.split("-");
      dataRow.wrarioE = tmp2[2] + "-" + tmp2[1] + "-" + tmp2[0];

      tmp = EpixeiriseisItems[i].lixiE;
      tmp2 = tmp.split("-");
      dataRow.wrarioL = tmp2[2] + "-" + tmp2[1] + "-" + tmp2[0];

      // dataRow.wrarioE = EpixeiriseisItems[i].enarxiE;
      // dataRow.wrarioL = EpixeiriseisItems[i].lixiE;

      dataRow.e = (
        <>
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEditE(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>{" "}
          <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDeleteE(item)}
          >
            <i className="fa fa-times"></i>
          </Button>
        </>
      );

      UdataTable.push(dataRow);
    }

    return (
      <>
        <GridContainer spacing={0}>
          <GridItem xs={12} sm={12} md={12} lg={5}>
            <Card style={{ width: "100%" }}>
              <CardHeader color="success">Χάρτης</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    {Epcategories.length == 0 && (
                      <MapContainer
                        center={center}
                        zoom={zoom}
                        scrollWheelZoom={false}
                        style={{ height: "60vh", width: "200wh" }}
                        whenCreated={(map) => this.setState({ map })}
                      >
                        <LayersControl position="topright">
                          <LayersControl.BaseLayer checked name="Street View">
                            <TileLayer
                              maxNativeZoom={19}
                              minZoom={0}
                              maxZoom={22}
                              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                          </LayersControl.BaseLayer>
                          <LayersControl.BaseLayer name="Earth view">
                            <TileLayer
                              maxNativeZoom={17}
                              minZoom={0}
                              maxZoom={17}
                              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                              attribution='&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
                            />
                          </LayersControl.BaseLayer>
                        </LayersControl>
                      </MapContainer>
                    )}

                    {Epcategories.length != 0 && (
                      <MapContainer
                        center={center}
                        zoom={zoom}
                        scrollWheelZoom={false}
                        style={{ height: "80vh", width: "200wh" }}
                        whenCreated={(map) => this.setState({ map })}
                      >
                        <LayersControl position="topright">
                          <LayersControl.BaseLayer checked name="Street View">
                            <TileLayer
                              maxNativeZoom={19}
                              minZoom={0}
                              maxZoom={22}
                              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                          </LayersControl.BaseLayer>
                          <LayersControl.BaseLayer name="Earth view">
                            <TileLayer
                              maxNativeZoom={19}
                              minZoom={0}
                              maxZoom={22}
                              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                              attribution='&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
                            />
                          </LayersControl.BaseLayer>
                        </LayersControl>
                        {/* <MyComponent /> */}
                        {/* <LocationMarker /> */}

                        {/* {EpixeiriseisItems.map(
                          ({ _id, nameE, coordsE, logo, iconeE }) => (
                            <Marker
                              key={`marker-${_id}`}
                              position={coordsE}
                              // icon={item.logo}
                              icon={L.icon({
                                iconUrl: logo,
                                iconSize: [25, 35],
                                popupAnchor: null,
                                shadowUrl: null,
                                shadowSize: null,
                                shadowAnchor: null,
                              })}
                            >
                              <Tooltip>
                                {nameE}
                                <br></br>
                                {iconeE}
                              </Tooltip>
                            </Marker>
                          )
                        )} */}
                      </MapContainer>
                    )}
                  </Col>
                </Row>
              </CardBody>{" "}
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={7} spacing={1}>
            <Card>
              <CardHeader color="success">
                Επιχειρήσεις{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewE()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
                <br />
                Προσθήκη Κατηγορίας
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewEC()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>
              <CardBody>
                {/* <GridContainer
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <h3>
                    {" "}
                    {"Επιχειρήσεις"}
                    <Button
                      round
                      color="info"
                      size="sm"
                      onClick={() => this.handleNewE()}
                    >
                      <i className="fa fa-plus"></i>
                    </Button>{" "}
                  </h3>
                  <h3>
                    {" "}
                    {"Προσθήκη Κατηγορίας"}
                    <Button
                      round
                      color="info"
                      size="sm"
                      onClick={() => this.handleNewEC()}
                    >
                      <i className="fa fa-plus"></i>
                    </Button>{" "}
                  </h3>
                </GridContainer> */}

                <ReactTable
                  data={UdataTable}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: " ",
                      accessor: "selectLa",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Επωνυμία",
                      accessor: "name",
                    },
                    {
                      Header: " ",
                      accessor: "media",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Κατηγορία",
                      accessor: "eidos",
                      Filter: SelectColumnFilter,
                      filter: "includes",
                    },
                    {
                      Header: "Ιδιοκτήτης",
                      accessor: "user",
                    },
                    {
                      Header: "Έναρξη",
                      accessor: "wrarioE",
                    },
                    {
                      Header: "Λήξη",
                      accessor: "wrarioL",
                    },
                    {
                      Header: "",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    // {
                    //   Header: " ",
                    //   accessor: "",
                    // },
                  ]}
                  defaultPageSize={6}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />

                {/* <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Όνομα</TableCell>
                        <TableCell>Κατηγορία</TableCell>
                        <TableCell>Ιδιοκτήτης</TableCell>
                        <TableCell>Έναρξη</TableCell>
                        <TableCell>Λήξη</TableCell>
                        <TableCell>Ενέργειες</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {EpixeiriseisItems.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell component="th" scope="row">
                            <Radio
                              checked={
                                String(this.state.epixirisiselected) ===
                                String(item.nameE)
                              }
                              onChange={(e) =>
                                this.handleEpixirisiSelect(e, item.coordsE)
                              }
                              value={item.nameE}
                              name="radio-button-demo"
                            />
                            {item.nameE}
                          </TableCell>

                          <TableCell>
                            <img style={{ width: "25px" }} src={item.logo} />{" "}
                            {item.iconeE}
                          </TableCell>
                          <TableCell>{item.userIdE}</TableCell>
                          <TableCell>{item.enarxiE}</TableCell>
                          <TableCell>{item.lixiE}</TableCell>
                          <TableCell>
                            <Button
                              round
                              color="info"
                              size="sm"
                              onClick={() => this.handleEditE(item)}
                            >
                              <i className="fa fa-edit"></i>
                            </Button>{" "}
                            <Button
                              round
                              color="danger"
                              size="sm"
                              onClick={() => this.handleDeleteE(item)}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}
              </CardBody>{" "}
            </Card>
          </GridItem>
        </GridContainer>
        {/* strart swal */}
        <Dialog
          open={this.state.swalAlertOpen}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Διαγραφή Επιχείρησης"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Η επιχείρηση {this.state.businessToDelete.nameE} θα διαγραφεί
              οριστικά! Είστε Σίγουροι;
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="error"
              onClick={() => this.handleSwalAlertClose()}
            >
              Ακυρο
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() =>
                this.deleteBusinessAlert(this.state.businessToDelete)
              }
              autoFocus
            >
              Διαγραφη
            </Button>
          </DialogActions>
        </Dialog>
        {/* endSwal */}

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalE}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="xlg"
          onClose={() => this.handleCloseE()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            Στοιχεία Επιχείρησης
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseE()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <Card>
                  <CardHeader color="success"> Χάρτης</CardHeader>
                  <CardBody>
                    {" "}
                    <label>
                      Παρακαλούμε εισάγετε την διεύθυνση παρακάτω και πατήστε
                      για αποθήκευση ή επιλέξτε την στον χάρτη
                    </label>
                    <Row>
                      <CustomInput
                        id="dieuthinsi"
                        inputProps={{
                          value: this.state.activeItemE.dieuthinsi,
                          placeholder: "Διεύθυνση",
                          onChange: (e) => this.handleChangeEp(e),
                          type: "text",
                        }}
                      />
                      <Button
                        size="sm"
                        round
                        onClick={() => this.checkaddress()}
                        color="success"
                      >
                        Αποθήκευση διέυθυνσης
                      </Button>
                    </Row>
                    <MapContainer
                      center={center}
                      zoom={zoom}
                      scrollWheelZoom={false}
                      style={{ height: "50vh", width: "180wh" }}
                      whenCreated={(mapModal) => this.setState({ mapModal })}
                    >
                      <TileLayer
                        maxNativeZoom={19}
                        minZoom={0}
                        maxZoom={22}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />

                      {/* <Marker position={this.state.activeItemE.coordsE}>
                        <Tooltip>{this.state.activeItemE.nameE}</Tooltip>
                      </Marker> */}

                      {/* <MyComponent /> */}
                      <LocationMarker />
                    </MapContainer>
                  </CardBody>
                </Card>
              </GridItem>
              {/* <GridItem xs={1}></GridItem> */}
              <GridItem xs={6} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="success"> Στοιχεία Επιχείρησης</CardHeader>
                  <CardBody>
                    {" "}
                    <br />
                    <label style={{ color: "#47A34B" }}>Επωνυμία*</label> <br />
                    <CustomInput
                      id="nameE"
                      inputProps={{
                        value: this.state.activeItemE.nameE,
                        placeholder: "Επωνυμία*",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <label style={{ color: "#47A34B" }}>Ιδιοκτήτης*</label>{" "}
                    <br />
                    <CustomInput
                      id="userIdE"
                      inputProps={{
                        value: this.state.activeItemE.userIdE,
                        placeholder: "Ιδιοκτήτης*",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />
                    {/* <CustomInput
                  id="dieuthinsi"
                  inputProps={{
                    value: this.state.activeItemE.dieuthinsi,
                    placeholder: "Διεύθυνση",
                    onChange: (e) => this.handleChangeEp(e),
                    type: "text",
                  }}
                /> */}
                    {/* <CustomInput
                  id="tk"
                  inputProps={{
                    value: this.state.activeItemE.tk,
                    placeholder: "ΤΚ",
                    onChange: (e) => this.handleChangeAdd(e),
                    type: "text",
                  }}
                /> */}
                    <br />
                    <label>
                      <b>ΑΦΜ</b>
                    </label>
                    <br />
                    <CustomInput
                      id="afmE"
                      inputProps={{
                        value: this.state.activeItemE.afmE,
                        placeholder: "ΑΦΜ",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "number",
                      }}
                    />
                    <br />
                    <label>
                      <b>Αριθμός Φακέλου</b>
                    </label>
                    <br />
                    <CustomInput
                      id="arfak"
                      inputProps={{
                        value: this.state.activeItemE.arfak,
                        placeholder: "Αριθμός Φακέλου",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "number",
                      }}
                    />
                    <br />
                    <label>
                      <b>Site</b>
                    </label>
                    <br />
                    <CustomInput
                      id="siteE"
                      inputProps={{
                        value: this.state.activeItemE.siteE,
                        placeholder: "Site",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />
                    {/* <Input
                  type="select"
                  name="iconeE"
                  id="iconeE"
                  value={String(this.state.activeItemE.iconeE)}
                  onChange={(e) => this.handleChangeEp(e)}
                >
                  <option style={{ color: "black" }}>{"-"}</option>
                  {Epcategories.map((item) => (
                    <option value={item.nameEC}>{item.nameEC}</option>
                  ))}
                </Input> */}
                    <br />
                    <label style={{ color: "#47A34B" }}>
                      Επιλογή κατηγορίας*
                    </label>{" "}
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="iconeE"
                      id="iconeE"
                      value={String(this.state.activeItemE.iconeE)}
                      onChange={(e) => this.handleChangeSelectIcon(e)}
                    >
                      {Epcategories.map((item) => (
                        <MenuItem value={item.nameEC}>
                          <img style={{ width: "20px" }} src={item.logo} />{" "}
                          {item.nameEC}
                        </MenuItem>
                      ))}
                    </Select>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="success"> Παρατηρήσεις</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="paratiriseis"
                      inputProps={{
                        value: this.state.activeItemE.paratiriseis,
                        placeholder: "Παρατηρήσεις",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="success"> Οικονομικά στοιχεία</CardHeader>
                  <CardBody>
                    {" "}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="enarxiE"
                        name="enarxiE"
                        showTodayButton
                        label="Έναρξη"
                        value={this.state.activeItemE.enarxiE}
                        onChange={(e) => this.handleChangeEnarxiE(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <br />
                    <br />
                    <br />
                    {/* <InputLabel id="lixiE" className={classes.label}>
                  Λήξη
                </InputLabel> */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="lixeE"
                        name="lixeE"
                        label="Λήξη"
                        value={this.state.activeItemE.lixiE}
                        onChange={(e) => this.handleChangeLixiE(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <br />
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="enarksiAdeias"
                        name="enarksiAdeias"
                        showTodayButton
                        label="Έναρξη Άδειας"
                        value={this.state.activeItemE.enarksi_adeias}
                        onChange={(e) => this.handleChangeEnarksiAdeias(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="liksiAdeias"
                        name="liksiAdeias"
                        showTodayButton
                        label="Λήξη Άδειας"
                        value={this.state.activeItemE.liksi_adeias}
                        onChange={(e) => this.handleChangeLiksiAdeias(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider> */}
                    {/* <CustomInput
                  id="posoE"
                  inputProps={{
                    value: this.state.activeItemE.posoE,
                    placeholder: "Ποσό",
                    onChange: (e) => this.handleChangeEp(e),
                    type: "text",
                  }}
                /> */}
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="success"> Επιπλέον στοιχεία</CardHeader>
                  <CardBody>
                    {" "}
                    <CustomInput
                      id="wrarioE"
                      inputProps={{
                        value: this.state.activeItemE.wrarioE,
                        placeholder: "Έναρξη ωραρίου (ΩΩ:ΛΛ)",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />
                    {/* <h3> Λήξη ωραρίου</h3>*/}
                    <CustomInput
                      id="wrarioL"
                      inputProps={{
                        value: this.state.activeItemE.wrarioL,
                        placeholder: "Λήξη ωραρίου (ΩΩ:ΛΛ)",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    />
                    {this.state.activeItemE.antikatastasi && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) => this.handleChangeBools(e)}
                            name="antikatastasi"
                            id="antikatastasi"
                            color="primary"
                          />
                        }
                        label="Αντικατάσταση;"
                      />
                    )}
                    {!this.state.activeItemE.antikatastasi && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => this.handleChangeBools(e)}
                            name="antikatastasi"
                            id="antikatastasi"
                            color="primary"
                          />
                        }
                        label="Αντικατάσταση;"
                      />
                    )}
                    <br />
                    {/* <label>
                      <b>Παρατηρήσεις</b>
                    </label>
                    <br />
                    <CustomInput
                      id="paratiriseis"
                      inputProps={{
                        value: this.state.activeItemE.paratiriseis,
                        placeholder: "Παρατηρήσεις",
                        onChange: (e) => this.handleChangeEp(e),
                        type: "text",
                      }}
                    /> */}
                    {/* <CustomInput
                  id="covidStatus"
                  inputProps={{
                    value: this.state.activeItemE.covidStatus,
                    placeholder: "Covid Status",
                    onChange: (e) => this.handleChangeEp(e),
                    type: "text",
                  }}
                /> */}
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="file_field"
                      >
                        <b>Αρχείο</b>
                      </label>
                      <Input
                        //  multiple
                        id="file_field"
                        onChange={(e) => this.handleChangeArxDocE(e)}
                        name="file_field"
                        type="file"
                      />
                    </FormGroup>
                    <br />
                    {this.state.activeItemE.id && (
                      <>
                        {" "}
                        <b> Διαθέσιμα αρχεία</b>
                        {this.state.selectedDocsE.map(
                          ({ id, file_field, description }) => (
                            <Badge>
                              <Button
                                size="sm"
                                round
                                onClick={() =>
                                  window.open(file_field, "_blank")
                                }
                              >
                                {" "}
                                {description}{" "}
                              </Button>

                              <Button
                                round
                                color="danger"
                                size="sm"
                                onClick={() => this.handleDeleteDocE(id)}
                              >
                                <i className="fa fa-times"></i>
                              </Button>
                            </Badge>
                          )
                        )}{" "}
                      </>
                    )}
                  </CardBody>
                </Card>
              </GridItem>

              {/* <TextField
                  id="time"
                  label="Alarm clock"
                  type="time"
                  defaultValue={this.state.activeItemE.wrarioE}
                  className={classes.textField}
                  onChange={(e) => this.handleChangeEnarxiWrariou2(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 100, // 5 min
                  }}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    value={this.state.activeItemE.wrarioE}
                    onChange={(e) => this.handleChangeEnarxiWrariou(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider> */}
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseE()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handlePostEp()}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalEC}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleCloseEC()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            Κατηγορία Επιχείρησης
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseEC()}
            >
              <Close className={classes.modalCloseEC} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem>
                <Card>
                  <CardHeader color="success">Στοιχεία</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="nameEC"
                      inputProps={{
                        value: this.state.activeItemEC.nameEC,
                        placeholder: "Όνομα",
                        onChange: (e) => this.handleChangeEC(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <Input
                      // style={{
                      //   color: "black",
                      //   borderRadius: "100px",
                      //   height: "25px",
                      // }}
                      accept="image/*"
                      // className={classes.input}
                      id="post-image"
                      onChange={(e) => this.handleChangeLogo(e)}
                      name="image"
                      type="file"
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseEC()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handleLogoSet(this.state.activeItemEC)}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}


import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import session from "../api/session";
import Html5QrcodePlugin from "./Html5QrcodePlugin.jsx";
import ResultContainerPlugin from "./ResultContainerPlugin.jsx";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SuperVisorModal } from './modal-supervisor.jsx'

export default class SupervisorIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: "",
            decodedResults: [],
            result: "",
            responseData: [],
            msg: "",
            laikes: [],
            open: false,
            selectedFleaMarket: "",
            benchViolations: []
        };
        this.onNewScanResult = this.onNewScanResult.bind(this);
    }


    componentDidMount() {
        this.fetchCurrentDayFleaMarket()
        this.fetchBenchViolations()

    }

    fetchCurrentDayFleaMarket() {
        const currentDateName = new Date().toLocaleString('en-us', { weekday: 'long' })

        const getObjectDays = {
            'Monday': 'deytera',
            'Tuesday': 'triti',
            'Wednesday': 'tetarti',
            'Thursday': 'pempti',
            'Friday': 'paraskeui',
            'Saturday': 'savvato',
            'Sunday': 'kyriaki'
        }
        session.get(`/api/laikes/?${getObjectDays[currentDateName]}=true&is_active=true`).then((resp) => {
            this.setState({ laikes: resp.data })
        }).catch(e => {

            if (e.response.data && e.response.data.detail === 'Signature has expired.') {
                window.location.href = "/yp";
            }
        })
    }

    fetchBenchViolations() {
        session.get('api/bench-violations/').then(resp => {
            this.setState({ benchViolations: resp.data })
        })
    }


    changeInputValue(e) {
        e.persist();

        this.setState({ inputValue: e.target.value });

    }
    // Select States
    handleClose() {
        this.setState({ open: false });
    }
    handleOpen() {
        this.setState({ open: true });

    }
    handleChange(e) {
        this.setState({ selectedFleaMarket: e.target.value })
    }

    searchForPagko() {
        const number = this.state.inputValue
        if (this.state.selectedFleaMarket === "") {
            alert('Παρακαλώ επιλέξτε λαϊκή αγορά')
            return
        }

        if (!number || isNaN(number)) {
            return;
        } else {
            session
                .get(`api/pagkoi-all/?barcode=${number}&qr_code=${number}&laikiAgoraId=${this.state.selectedFleaMarket}`)
                // .get(`api/Pagkoi/?barcode=${number}&qr_code=${number}`)
                .then((resp) => {
                    this.setState({ responseData: resp.data });
                    if (resp.data.results.length === 0) {
                        this.setState({ msg: "Δεν βρέθηκε πάγκος" })
                        return
                    } else {
                        this.setState({ msg: " " })
                        return
                    }
                })
                .catch((e) => {

                    console.log(e);
                    return
                });
        }
    }

    endBenchInspectionModal(e) {
        console.log('done')
    }

    onNewScanResult(decodedText, decodedResult) {
        console.log("App [result]", decodedResult);

        // let decodedResults = this.state.decodedResults;
        // decodedResults.push(decodedResult);
        this.setState((state, props) => {
            state.decodedResults.push(decodedResult);
            state.result = decodedResult.result.text;
            state.inputValue = decodedResult.result.text;
            return state;
        });
    }
    render() {
        return (
            <div>


                <React.Fragment>
                    <CssBaseline />
                    <Box
                        sx={{
                            mt: "5vh",
                            height: "fit-content",
                            ml: "5vh",
                        }}
                    >
                        <h3>Αναζήτηση πάγκου με barcode </h3>
                    </Box>

                    <Box
                        sx={{
                            display: "grid",
                            gap: 2,
                            gridTemplateColumns: "repeat(2,1fr)",
                            collumnGap: 2,
                            height: "fit-content",
                            mt: "5vh",
                            ml: "5vh",
                        }}
                    >
                        <ul>
                            <li>
                                Πατήστε το κουμπί κάμερα και κάντε σάρωση το barcode ή QR code
                            </li>
                            <li>
                                {" "}
                                Στη συνέχεια πατήστε <strong>αναζήτηση</strong> για αναζήτηση
                                πάγκου.
                            </li>
                        </ul>
                    </Box>

                    <Html5QrcodePlugin
                        fps={10}
                        qrbox={250}
                        disableFlip={false}
                        qrCodeSuccessCallback={this.onNewScanResult}
                    />
                    <ResultContainerPlugin results={this.state.decodedResults} />
                    <Box
                        sx={{
                            display: "grid",
                            width: "50vh",
                            height: "5vh",
                            mt: "20vh",
                            mx: "auto",
                            mb: "15vh",
                            gap: 2,
                            gridTemplateColumns: "repeat(2,1fr)",
                            collumnGap: 2,
                        }}
                    >

                        <FormControl>

                            <InputLabel id="demo-controlled-open-select-label">
                                Λαϊκή Αγορά
                            </InputLabel>
                            <Select
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                open={this.state.open}
                                onClose={() => this.handleClose()}
                                onOpen={() => this.handleOpen()}
                                value={this.state.selectedFleaMarket}
                                onChange={(e) => this.handleChange(e)}
                            >
                                <MenuItem value="">
                                    <em>-------------------</em>
                                </MenuItem>
                                {this.state.laikes && this.state.laikes.length > 0 && this.state.laikes.map((e) => (
                                    <MenuItem key={e.id} value={e.id}>
                                        {e.nameLA} - {e.perioxiA}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br />

                        <TextField
                            id="outlined-basic"
                            label="Barcode"
                            variant="outlined"
                            value={this.state.inputValue}
                            onChange={(e) => this.changeInputValue(e)}
                        />
                        <Button
                            variant="contained"
                            onClick={() => this.searchForPagko()}
                            sx={{
                                textTransform: "none",
                                bgcolor: "#388e3c",
                            }}
                        >
                            <i className="fas fa-search"></i>Αναζήτηση
                        </Button>

                        <Box component="span" sx={{
                            p: 2, display: "grid"
                        }}>


                            <p color="inherit">
                                {this.state.responseData && this.state.responseData.results && this.state.responseData.results.length > 0
                                    ? this.state.responseData.results.map((e) => e.anagnwristikoP).join("")
                                    : " "}
                            </p>
                            {this.state.responseData && this.state.responseData.results && this.state.responseData.results.length > 0 && this.state.responseData.results.map((row) => (
                                <SuperVisorModal
                                    key={row.id}
                                    benchAfm={row.afmP}
                                    name={row.userIdp}
                                    numberP={row.nameP}
                                    licenseStart={row.enarxiAP}
                                    licenceEnd={row.lixiAP}
                                    squares={row.tetragwnika}
                                    barcode={row.barcode}
                                    qrcode={row.qr_code}
                                    anastoli={row.anastoli}
                                    adeiaAllouDimou={row.adeiaAllouDimou}
                                    diakopi={row.diakopi}
                                    pagkosID={row.id}
                                    anagnwristikoPagkou={row.anagnwristikoP}
                                    benchPosition={row.bench_position}
                                    tetragwnika={row.tetragwnika}
                                    endBenchInspection={(e) => this.endBenchInspectionModal(e)}
                                    // benchViolations={this.state.benchViolations}
                                    benchViolations={this.state.benchViolations}
                                    selectedBenchViolations={row.violations}

                                    laikiAgora={this.state.selectedFleaMarket}

                                ></SuperVisorModal>))}

                        </Box>
                        <div>{this.state.msg}</div>

                    </Box>

                    {/* Results */}
                </React.Fragment>

            </div >
        );
    }
}





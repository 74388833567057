import * as React from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import Box from "@mui/material/Box";
// import Checkmarks from "./checkMarks";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ListView from "./listView";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import session from "../api/session";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";

import enGB from "date-fns/locale/en-GB";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import grLocale from "date-fns/locale/el";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { CreateBusinessModal } from "./create-business-modal.jsx";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import "moment/locale/el";
moment.locale("el");

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const customTextField = {
  "& input::placeholder": {
    fontSize: "20px",
  },
};
class BusinessCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      responseData: [],
      page: 1,
      categoryBusiness: "",
      businessName: "",
      businessCategories: [],
      selectedCategory: "",
      open: false,
      searchField: "",
      eidiAdeias: [],
      lisenceOptions: false,
      selecteLicenseOption: "",
      citySections: [],
      startNotificationDate: "",
      endNotificationDate: "",
      fromNotificationDate: null,
      toNotificationDate: null,
      isSuperUser: false,
      mayorSpinner: false
    };
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
    const groups = localStorage.getItem("usergroups");
    this.setState({ isSuperUser: groups.includes("SuperUser") });

    this.fetchAllBuisness();
    this.fetchBusinessCategories();
    this.fetchEidiAdeias();
    this.fetchCitySections();
  }

  fetchBusinessCategories() {
    session.get("api/EpixeirisiCategory/").then((response) => {
      this.setState({ businessCategories: response.data.map((e) => e.nameEC) });
    });
  }
  fetchAllBuisness() {
    session
      .get("api/business/")
      .then((resp) => {
        this.setState({ responseData: resp.data });
      })
      .catch((e) => {
        console.log(e.response.data);
        if (
          e.response.data &&
          e.response.data.detail === "Signature has expired."
        ) {
          window.location.href = "/yp";
        }
      });
  }
  fetchEidiAdeias() {
    session.get("api/eidos-adeias/all/").then((resp) => {
      this.setState({ eidiAdeias: resp.data });
    });
  }
  fetchCitySections() {
    session.get("api/city-section/list/").then((resp) => {
      this.setState({ citySections: resp.data.map((e) => e.title) });
    });
  }

  fetchBusiness(
    category,
    businessName,
    outOfDate,
    startNotificationDate,
    endNotificationDate,
    page
  ) {
    const parse_out_of_date = outOfDate.id !== undefined ? outOfDate.id : "";
    session
      .get(
        `api/business/?iconeE__nameEC=${category}&business_name=${businessName}&out_of_date=${parse_out_of_date}&start_notification_date=${startNotificationDate}&end_notification_date=${endNotificationDate}&page=${page}`
      )
      .then((resp) => {
        this.setState({ responseData: resp.data });
      });
  }
  searchData() {
    this.setState({ page: 1 });

    this.fetchBusiness(
      this.state.selectedCategory,
      this.state.searchField,
      this.state.selecteLicenseOption,
      this.state.startNotificationDate,
      this.state.endNotificationDate,
      1
    );
  }

  pageChange(_, p) {
    this.setState({ page: p });

    this.fetchBusiness(
      this.state.selectedCategory,
      this.state.searchField,
      this.state.selecteLicenseOption,
      this.state.startNotificationDate,
      this.state.endNotificationDate,
      p
    );
  }

  handleChange(e) {
    this.setState({ selectedCategory: e.target.value });
    this.fetchBusiness(
      e.target.value,
      this.state.searchField,
      this.state.selecteLicenseOption,
      this.state.startNotificationDate,
      this.state.endNotificationDate,
      1
    );
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleCloseLicense() {
    this.setState({ lisenceOptions: false });
  }
  handleOpenmLicense() {
    this.setState({ lisenceOptions: true });
  }
  handleOpen() {
    this.setState({ open: true });
  }
  searchTextChange(e) {
    this.setState({ searchField: e.target.value });
  }
  handleChangeLisence(e) {
    this.setState({
      selecteLicenseOption: e.target.value,
    });
    this.fetchBusiness(
      this.state.selectedCategory,
      this.state.searchField,
      e.target.value,
      this.state.startNotificationDate,
      this.state.endNotificationDate,
      1
    );
  }
  handleBusinessDeleteEnd1(e) {
    console.log("girisa sto index");
    this.fetchAllBuisness();
    this.fetchBusinessCategories();
    this.fetchEidiAdeias();
  }
  createBusinessEndModal(e) {
    setTimeout(() => {
      this.fetchAllBuisness();
      this.fetchBusinessCategories();
      this.fetchEidiAdeias();
    }, 2000);
  }
  handleStartNotificationDate(value) {
    if (value == null) {
      this.setState({
        startNotificationDate: "",
        fromNotificationDate: null,
        page: 1,
      });
      this.fetchBusiness(
        this.state.selectedCategory,
        this.state.searchField,
        this.state.selecteLicenseOption,
        "",
        this.state.endNotificationDate,
        1
      );
      return;
    }

    if (!moment(value, "DD/MM/YYYY", true).isValid()) {
      return;
    }

    this.setState({
      startNotificationDate: moment(value).format("YYYY-MM-DD"),
      fromNotificationDate: value,
      page: 1,
    });

    this.fetchBusiness(
      this.state.selectedCategory,
      this.state.searchField,
      this.state.selecteLicenseOption,
      moment(value).format("YYYY-MM-DD"),
      this.state.endNotificationDate,
      1
    );
  }
  handleEndNotificationDate(value) {
    if (value == null) {
      this.setState({
        endNotificationDate: "",
        toNotificationDate: null,
        page: 1,
      });
      this.fetchBusiness(
        this.state.selectedCategory,
        this.state.searchField,
        this.state.selecteLicenseOption,
        this.state.startNotificationDate,
        "",
        1
      );
      return;
    }
    if (!moment(value, "DD-MM-YYYY", true).isValid()) {
      return;
    }

    this.setState({
      endNotificationDate: moment(value).format("YYYY-MM-DD"),
      toNotificationDate: value,
      page: 1,
    });
    this.fetchBusiness(
      this.state.selectedCategory,
      this.state.searchField,
      this.state.selecteLicenseOption,
      this.state.startNotificationDate,
      moment(value).format("YYYY-MM-DD"),
      1
    );
  }
  printBusinessesForMayor() {
    console.log(
      this.state.startNotificationDate,
      this.state.endNotificationDate
    );
    if (this.state.startNotificationDate === "") {
      alert("Παρακαλώ εισάγεται Ημν/ια Γνωστοποίησης Απο");
      return;
    }
    this.setState({ mayorSpinner: true })
    session
      .get(
        `api/business-printer/?start_date=${this.state.startNotificationDate}&end_date=${this.state.endNotificationDate}`,
        { responseType: "blob" }
      )
      .then((resp) => {
        const blobData = resp.data;
        const blob = new Blob([blobData], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        this.setState({ mayorSpinner: false })
        window.open(objectUrl, "_blank");
        URL.revokeObjectURL(objectUrl);
        // tha stelnei email
      })
      .catch((e) => {
        console.log(e.response.data);
        this.setState({ mayorSpinner: false })
        return
      });
    return;
    // window.open(`api/business-printer/?start_date=${this.state.startNotificationDate}&end_date=${this.state.endNotificationDate}`)
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax small filter bgImage={require("assets/img/profile-bg2.jpg")}>
          <div style={{ height: "200px" }} />
        </Parallax>

        <Box
          sx={{
            width: "fit-content",
            borderBottom: 1,
            borderColor: "divider",
            mx: "auto",
            textAlign: "center",
          }}
        >
          <h3>Πίνακας Επιχειρήσεων</h3>
        </Box>

        <Box display="flex" sx={{ backgroundColor: "white" }}>
          <Box
            sx={{ mx: "auto" }}
            display="flex"
            style={{ alignItems: "center" }}
          >
            {this.state.businessCategories &&
              this.state.businessCategories.length > 0 && (
                <FormControl sx={{ m: 1, width: 200 }} variant="standard">
                  <InputLabel id="demo-controlled-open-select-label">
                    Κατηγορίες
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={this.state.open}
                    onClose={() => this.handleClose()}
                    onOpen={() => this.handleOpen()}
                    value={this.state.selectedCategory}
                    onChange={(e) => this.handleChange(e)}
                  >
                    <MenuItem value="">
                      <em>-------------------</em>
                    </MenuItem>
                    {this.state.businessCategories.map((e) => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

            <FormControl
              sx={{ m: 1, width: 200, margin: "0px 26px 0px 20px" }}
              variant="standard"
            >
              <InputLabel id="demo-simple-select-standard-label">
                Άδειες που έληξαν
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                open={this.state.lisenceOptions}
                onClose={() => this.handleCloseLicense()}
                onOpen={() => this.handleOpenmLicense()}
                value={this.state.selecteLicenseOption}
                onChange={(e) => this.handleChangeLisence(e)}
              >
                <MenuItem value="">
                  <em>-------------------</em>
                </MenuItem>
                {this.state.eidiAdeias.map((e) => (
                  <MenuItem key={e} value={e}>
                    {e.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Tooltip title="Αναζήτηση με επωνυνία, ιδιοκτήτη, αρ.φακέλου, διακ.τίτλου, αρ.αδείας, δημ. ενότητα, σχόλια">
              <TextField
                id="standard-basic"
                label="Αναζήτηση..."
                variant="standard"
                value={this.state.searchField}
                onChange={(e) => this.searchTextChange(e)}
              />
            </Tooltip>

            <Button
              variant="contained"
              sx={{
                width: "fit-content",
                height: "78%",
                textTransform: "none",
                bgcolor: "#388e3c",
              }}
              onClick={() => this.searchData()}
            >
              <i className="fas fa-search"></i>Αναζήτηση
            </Button>

            <div className="Provider" style={{ margin: "0px 20px 0px 20px" }}>

              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enGB}
              >
                <Stack spacing={4}>
                  <DesktopDatePicker
                    label="Ημ/νια Γνωστοποίησης Από"
                    inputFormat="dd/MM/yyyy"
                    value={this.state.fromNotificationDate}
                    onChange={(newValue) =>
                      this.handleStartNotificationDate(newValue)
                    }
                    renderInput={(params) => (
                     <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </div>

            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={enGB}
            >

              <Stack spacing={4}>

                <DesktopDatePicker
                  label="Ημ/νια Γνωστοποίησης Έως"
                  inputFormat="dd/MM/yyyy"
                  value={this.state.toNotificationDate}
                  onChange={(newValue) =>
                    this.handleEndNotificationDate(newValue)
                  }
                  renderInput={(params) => (
                   <TextField {...params} sx={{ width: "100%" }} />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Box>

          <Box
            sx={{
              "& > :not(style)": { m: 1 },
              mx: "auto",
            }}
          >
            {this.state.mayorSpinner ? <CircularProgress /> : (<Button
              hidden={true}
              variant="contained"
              sx={{
                width: "fit-content",
                height: "78%",
                textTransform: "none",
                bgcolor: "#3b388e",
              }}
              onClick={() => this.printBusinessesForMayor()}
            >
              <i className="fas fa-print"></i>Εκτύπωση
            </Button>)}

          </Box>


          <Box
            sx={{
              "& > :not(style)": { m: 1 },
              mx: "auto",
            }}
          >
            <CreateBusinessModal
              categories={this.state.businessCategories}
              citySections={this.state.citySections}
              createBusinessEnd={(e) => this.createBusinessEndModal(e)}
            ></CreateBusinessModal>
          </Box>
        </Box>
        <br></br>
        {this.state.responseData &&
          this.state.responseData.results &&
          this.state.responseData.results.length > 0 && (
            <ListView
              data={this.state.responseData.results}
              handleBusinessDeleteEnd1={(e) => this.handleBusinessDeleteEnd1(e)}
            ></ListView>
          )}
        {this.state.responseData &&
          this.state.responseData.results &&
          this.state.responseData.results.length === 0 && (
            <p>Δεν Βρέθηκαν Αποτελέσματα</p>
          )}

        <Stack spacing={2} direction={{ xs: "column", sm: "row" }} mt={5}>
          <Pagination
            color="primary"
            count={this.state.responseData.total_pages}
            page={this.state.page}
            onChange={(e, p) => this.pageChange(e, p)}
          />
        </Stack>

        <div style={{ marginTop: "200px" }}>
          <Footer />
        </div>
      </div>
    );
  }
}
export default BusinessCards;

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import session from '../api/session'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import el from "date-fns/locale/el";
// registerLocale("el", el);



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    flexGrow: 1
};
export default class BenchModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            benchDetail: [],
            valueStartDate: new Date(),
            valueEndDate: new Date(),
        }
    }



    fetchBenchDetails() {
        session.get(`api/Pagkoi/${this.props.benchID}/`).then(res => {
            this.setState({ benchDetail: res.data, valueStartDate: res.data.enarxiAP, valueEndDate: res.data.lixiAP })
        })

    }

    handleOpenModal() {

        this.setState({ open: true })
        this.fetchBenchDetails()
    }
    handleCloseModal() {
        this.setState({ open: false })
    }

    changeDateValue(v) {

        this.setState({ valueStartDate: v })

    }

    changeEndDatevalue(v) {
        this.setState({ valueEndDate: v })
    }

    parseDate(date) {
        if (date instanceof Date) {
            console.log(date)
            return date.toISOString().split('T')[0]
        }
        else {
            return date
        }
    }


    submitForm() {
        this.state.benchDetail['enarxiAP'] = this.parseDate(this.state.valueStartDate)
        this.state.benchDetail['lixiAP'] = this.parseDate(this.state.valueEndDate)
        session.put(`api/Pagkoi/${this.props.benchID}/`, {


            elegxos: this.state.benchDetail['elegxos'],
            xrewsi: this.state.benchDetail['xrewsi'],
            parousia: this.state.benchDetail['parousia'],
            nameP: this.state.benchDetail['nameP'],
            anagnwristikoP: this.state.benchDetail['anagnwristikoP'],
            coordsP1: this.state.benchDetail['coordsP1'],
            coordsP2: this.state.benchDetail['coordsP2'],
            coordsP3: this.state.benchDetail['coordsP3'],
            coordsP4: this.state.benchDetail['coordsP4'],
            adeioP: this.state.benchDetail['adeioP'],
            afmP: this.state.benchDetail['afmP'],
            siteP: this.state.benchDetail['siteP'],
            userIdp: this.state.benchDetail['userIdp'],
            enarxiP: this.state.benchDetail['enarxiP'],
            lixiP: this.state.benchDetail['lixiP'],
            posoP: this.state.benchDetail['posoP'],
            plirwse: this.state.benchDetail['plirwse'],
            isinteam: this.state.benchDetail['isinteam'],
            adeiaDimou: this.state.benchDetail['adeiaDimou'],
            adeiaApokentrwmenis: this.state.benchDetail['adeiaApokentrwmenis'],
            tetragwnika: this.state.benchDetail['tetragwnika'],
            timimonadas: this.state.benchDetail['timimonadas'],
            poso: this.state.benchDetail['poso'],
            anastoli: this.state.benchDetail['anastoli'],
            adeiaAllouDimou: this.state.benchDetail['adeiaAllouDimou'],
            enarxiAP: this.state.benchDetail['enarxiAP'],
            lixiAP: this.state.benchDetail['lixiAP'],
            patronymo: this.state.benchDetail['patronymo'],
            tilefwno: this.state.benchDetail['tilefwno'],
            dieuthinsi: this.state.benchDetail['dieuthinsi'],
            kinito: this.state.benchDetail['kinito'],
            typos: this.state.benchDetail['typos'],
            kathgoria: this.state.benchDetail['kathgoria'],
            paratiriseis: this.state.benchDetail['paratiriseis'],
            arfak: this.state.benchDetail['arfak'],
            diakopi: this.state.benchDetail['diakopi'],
            barcode: this.state.benchDetail['barcode'],
            qr_code: this.state.benchDetail['qr_code'],
            laikiAgoraId: this.state.benchDetail['laikiAgoraId'],
            categoryP: this.state.benchDetail['categoryP']

        }).then(() => {
            console.log('done')
            this.handleCloseModal()
        })
    }



    render() {
        return (
            <div>
                <Button onClick={() => this.handleOpenModal()}>Ανανεωση Αδειας Παγκου</Button>
                <Modal
                    open={this.state.open}
                    onClose={() => this.handleCloseModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Ανανέωση Άδειας Πάγκου- {this.state.benchDetail.userIdp}
                        </Typography>
                        <hr />
                        <Stack spacing={5}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={el}>
                                            <DesktopDatePicker
                                                label="Έναρξη Άδειας"
                                                value={this.state.valueStartDate}
                                                mask="__/__/____"
                                                inputFormat="dd/MM/yyyy"
                                                onChange={(v) => this.changeDateValue(v)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                        <hr />
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={el}>
                                            <DesktopDatePicker
                                                label="Λήξη Άδειας"
                                                mask="__/__/____"
                                                inputFormat="dd/MM/yyyy"
                                                value={this.state.valueEndDate}
                                                onChange={(v) => this.changeEndDatevalue(v)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>


                                    </Grid>

                                </Grid>

                            </Box>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" color="success" onClick={() => this.submitForm()}>
                                    Αποθηκευση
                                </Button>

                                <Button variant="contained" color="error" onClick={() => this.handleCloseModal()}>
                                    Ακυρωση
                                </Button>
                            </Stack>

                        </Stack>

                    </Box>
                </Modal>

            </div >
        )
    }
}
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Work from "@material-ui/icons/Work";
import Map from "@material-ui/icons/Map";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            ΤΟ ΝΕΟ PORTAL ΕΠΙΧΕΙΡΗΣΕΩΝ {""}
            ΤΟΥ ΔΗΜΟΥ ΒΟΛΟΥ
          </h2>
          <h5 className={classes.description}>
            Μέσα από το νέο μας portal μπορείτε να ενημερωθείτε για τα
            απαιτούμενα έγγραφα, τις νέες νομοθεσίες και τα διαδικαστικά για την
            έναρξη μιας νέας, τοπικής επιχείρησης, αποφεύγοντας έτσι τον χρόνο
            συγκέντρωσης των προ απαιτούμενων εγγράφων και διαδικασιών. Επίσης,
            μπορείτε να περιηγηθείτε στους δύο διαδραστικούς χάρτες του portal
            και να ενημερωθείτε για τις τοπικές επιχειρήσεις, καθώς επίσης και
            την τοπική λαϊκή αγορά του Δήμου. Διαβάστε τα τελευταία νέα του
            Δήμου, ενημερωθείτε άμεσα για τις τελευταίες αλλαγές σχετικά με τις
            διαδικασίες έναρξης επιχειρήσεων και ελάτε σε επικοινωνία μαζί μας.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <InfoArea
              title="
              ΕΓΓΡΑΦΑ & ΔΙΑΔΙΚΑΣΙΕΣ"
              description="Ενημερωθείτε άμεσα και εύκολα για τις διαδικασίες έναρξης επιχείρησης."
              icon={Work}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <InfoArea
              title="ΔΙΑΔΡΑΣΤΙΚΟΙ ΧΑΡΤΕΣ"
              description="Λίστα τοπικών επιχειρήσεων και λαϊκής αγοράς του δήμου."
              icon={Map}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import session from "../api/session";
import BenchModal from "./benchModal.jsx";



export default class CollapsibleTable extends React.Component {
  constructor(props) {
    super(props);
    // this.BasicModal = React.createRef()
    this.state = {
      benches: [],
      page: 1,

    };
  }

  componentDidMount() {

    this.fetchBenches(1)

  }


  fetchBenches(page) {
    let date = new Date().toLocaleDateString('el', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-')
    session.get(`api/pagkoi-all/?liksi=${date}&page=${page}`).then(res => {
      this.setState({ benches: res.data })
    })
  }


  pageClick(_, v) {
    console.log(v);
    this.setState({ page: v });
    console.log(this.state.page);
    this.fetchBenches(v)
  }




  render() {
    return (
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Αριθμός Πάγκου</TableCell>
                <TableCell align="center">Ονοματεπώνυμο</TableCell>
                <TableCell align="center">Κατηγορία</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.benches &&
                this.state.benches.results &&
                this.state.benches.results.length > 0 &&
                this.state.benches.results.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.nameP}
                    </TableCell>
                    <TableCell align="center">{row.anagnwristikoP}</TableCell>
                    <TableCell align="center">{row.kathgoria != null ? row.kathgoria : '-'}</TableCell>
                    <TableCell align="center">
                      <BenchModal benchID={row.id}></BenchModal>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>



        <Stack spacing={2} direction={{ xs: "column", sm: "row" }} mt={5}>
          <Pagination
            color="primary"
            count={this.state.benches.total_pages}
            page={this.state.page}
            onChange={(e, v) => this.pageClick(e, v)}
          />
        </Stack>
      </div>
    );
  }
}

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";



export default function BasicTable({ data }) {
  const parseDateTime = (dt) => {
    return new Date(dt).toLocaleString('el', { hour12: false })
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Επώνυμο</TableCell>
            <TableCell align="left">Όνομα</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Τηλέφωνο</TableCell>
            <TableCell align="left">Μήνυμα</TableCell>
            <TableCell align="left">Ημερομηνία αποστολής</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{row.last_name}</TableCell>
              <TableCell align="left">{row.first_name}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row.telephone}</TableCell>
              <TableCell align="left" style={{ textAlign: "justify" }}>{row.message}</TableCell>
              <TableCell align="left">{parseDateTime(row.created_at)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
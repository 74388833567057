import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import axios from "axios";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import session from "../api/session";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h2>Σύνδεση</h2>
                  </CardHeader>
                  <Login></Login>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      username: "",
      password: "",
      GroupList: [],
      repsonseGroupList: [],
      responseData: [],
    };
  }

  componentDidMount() {
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("usergroups");
    this.refreshList();
  }

  refreshList() {
    session
      .get("api/groups/")
      .then((res) => this.setState({ GroupList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get(`${api_url}groups/`)
    //   .then((res) => this.setState({ GroupList: res.data }))
    //   .catch((err) => console.log(err));
  }

  handle_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevstate) => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
  };

  handle_login = async (data) => {
    // e.preventDefault();
    // console.log(data)

    console.log(data);
    await session
      .post("api/token-auth/", {
        username: data.username,
        password: data.password,
      })
      .then((response) => {
        this.setState({
          repsonseGroupList: response.data.user.groups,
          responseData: response.data,
        });
      })
      .catch((e) => {
        console.log(e.response.data);
        if (e.response.data.non_field_errors) {
          alert(e.response.data.non_field_errors[0]);
          return;
        } else {
          alert(e.response.data);
        }
      });
    // const userGroups = this.state.GroupList.filter((e) =>
    //   this.state.repsonseGroupList.includes(e.id)
    // ).map((o) => o.name);
    // console.log(userGroups)
    const userGroups = this.state.responseData.user.group.map(e => e.name)
    // return

    localStorage.setItem("token", this.state.responseData.token);
    localStorage.setItem(
      "userselectedname",
      this.state.responseData.user.username
    );
    localStorage.setItem("isSuper", this.state.responseData.user.is_superuser);
    localStorage.setItem("userselectedd", this.state.responseData.user.id);
    localStorage.setItem("usergroups", userGroups);
    setTimeout(() => {
      window.location.href = "../";
    }, 1000);
  };
  render() {
    const classes = this.state.useStyles;

    return (
      <>
        <CardBody>
          <label>Username </label>
          <CustomInput
            // labelText="Username..."
            id="username"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "username",
              id: "username",
              onChange: (e) => this.handle_change(e),
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.inputIconsColor} />
                </InputAdornment>
              ),
            }}
          />
          <label>Password </label>
          <CustomInput
            id="pass"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "password",
              name: "password",
              id: "password",
              onChange: (e) => this.handle_change(e),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputIconsColor}>lock_outline</Icon>
                </InputAdornment>
              ),
              autoComplete: "off",
            }}
          />
        </CardBody>
        <CardFooter className={classes.cardFooter}>
          <Button
            onClick={() => this.handle_login(this.state)}
            simple
            color="primary"
            size="lg"
          >
            ΣΥΝΔΕΣΗ
          </Button>
        </CardFooter>
      </>
    );
  }
}

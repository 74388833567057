import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import session from "../../api/session";
import Collapse from '@mui/material/Collapse';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom'
import {
  ParabaseisModal,
  MetabibaseisModal,
  PoinesParabaseisModal,
} from "./modal-create.jsx";
import {
  BusinessLisenceModal,
  UpdateBusinessLicenseFile,
  BusinessEdit,
  DeleteBusinessFile,
} from "./modals.jsx";

import { BusinessViolationFile } from './violation-file-modal.jsx'

import CircularProgress from "@mui/material/CircularProgress";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

class BusinessDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newValue: "1",
      detailData: {},

      businessParabaseis: [],
      businessTranfer: [],
      stadiaParabasis: [],
      eidosParabasis: [],
      currentUser: "",
      businessLicense: [],
      businessLicenseParameters: [],
      businessFilePage: [],
      businessCategories: [],
      iconIds: [],
      citySections: [],
      penalties: [],
      poinesParabaseis: [],
      eggrafaParabasis: [],
      spinner: {
        businessSpinner: false,
        licenseSpinner: false,
        parabasiSpinner: false,
        metabibasiSpinner: false,
      },
      openCollapse: [],
      collapedIdxes: [],
      attestationList: [],
      violationStatusList: [],
      violationDescriptionList: [],
      parabaseisDetailsExist: {},

      attestationOpen: false,
      selectedAttestation: "",
      eidosOpen: false,
      selectedEidos: "",
      descriptionOpen: false,
      selectedDescription: "",
      stadioOpen: false,
      selectedStadio: "",
      penaltyOpen: false,
      selectedPenalty: "",
      sortOpen: false,
      selectedSort: "-violation_date",
      currentTab: '1'
    };
  }
  handleChange = (_, newValue) => {

    this.setState({
      newValue,
    });
  };

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }

    session
      .get(`api/Epixeiriseis/${this.props.match.params.id}/`)
      .then((resp) => {
        this.setState({ detailData: resp.data });
      })
      .catch((e) => {
        console.log(e.response.data);
        if (
          e.response.data &&
          e.response.data.detail === "Signature has expired."
        ) {
          window.location.href = "/yp";
        }
      });
    session
      .get(`api/parabaseis/${this.props.match.params.id}/per-year/`)
      .then((resp) => {
        this.setState({ poinesParabaseis: resp.data });
      });



    session.get("api/penalty/list/").then((resp) => {
      this.setState({ penalties: resp.data });
    });
    // console.log(this.props.match.params.id)
    session.get("api/city-section/list/").then((resp) => {
      this.setState({ citySections: resp.data.map((e) => e.title) });
    });

    session.get("api/users/").then((resp) => {
      const localUser = localStorage.getItem("userselectedname");

      this.setState({
        currentUser: resp.data.find((e) => e.username === localUser).id,
      });
    });

    // session
    //   .get(`api/business-parabaseis/${this.props.match.params.id}/parabaseis/`)
    //   .then((resp) => {
    //     this.setState({ businessParabaseis: resp.data });
    //   });
    session.get(`api/business-parabaseis/${this.props.match.params.id}/parabaseis/`).then((resp) => {
      this.setState({ businessParabaseis: resp.data });
      let collapsedIdx = {}
      for (let i = 0; i <= resp.data.length; i++) {
        collapsedIdx[i] = false
      }
      this.setState({ collapedIdxes: collapsedIdx })
      // this.checkViolationDetailsExists()
    });
    session
      .get(`api/business-transfer/${this.props.match.params.id}/transfer/`)
      .then((resp) => {
        this.setState({ businessTranfer: resp.data });
      });

    session.get("api/stadio-parabasis/all/").then((resp) => {
      this.setState({ stadiaParabasis: resp.data });
    });
    session.get("api/eidos-parabasis/all/").then((resp) => {
      this.setState({ eidosParabasis: resp.data });
    });
    session
      .get(`api/business-license/${this.props.match.params.id}/`)
      .then((resp) => {
        this.setState({ businessLicense: resp.data });
      });

    session.get("api/eidos-adeias/all/").then((resp) => {
      this.setState({ businessLicenseParameters: resp.data });
    });
    session
      .get(`api/epixirisi/${this.props.match.params.id}/stoixeia/`)
      .then((resp) => {
        this.setState({ businessFilePage: resp.data });
      });
    session.get('api/egrafa-parabasis/').then(resp => {
      this.setState({ eggrafaParabasis: resp.data })

    })

    // session.get("api/FileFieldFormEpixeirisi/").then((resp) => {
    //   this.setState({
    //     businessFilePage: resp.data.filter(
    //       (e) => e.epixeirisi === this.state.detailData.id
    //     ),
    //   });
    // });
    session.get("api/EpixeirisiCategory/").then((response) => {
      this.setState({
        businessCategories: response.data.map((e) => e.nameEC),
        iconID: response.data.map((e) => e.id),
      });
    });


    session.get('api/violation-attestations/').then((resp) => {
      this.setState({
        attestationList: resp.data
      })

    })

    session.get('api/bussiness-violations-statuses/').then((resp) => {
      this.setState({
        violationStatusList: resp.data
      })
    })


    session.get('api/violation-descriptions/').then((resp) => {
      this.setState({
        violationDescriptionList: resp.data
      })
    })
    if (this.props.location.state && this.props.location.state.newValue) {

      this.setState({
        newValue: this.props.location.state.newValue
      })
    }
  }

  handleModalBuisnessEdit(e) {
    setTimeout(() => {
      session
        .get(`api/Epixeiriseis/${this.props.match.params.id}/`)
        .then((resp) => {
          this.setState({ detailData: resp.data });
        });
      session
        .get(`api/epixirisi/${this.props.match.params.id}/stoixeia/`)
        .then((resp) => {
          this.setState({
            businessFilePage: resp.data,
            spinner: { ...this.state.spinner, businessSpinner: false },
          });
        });
      // session.get("api/FileFieldFormEpixeirisi/").then((resp) => {
      //   this.setState({
      //     businessFilePage: resp.data.filter(
      //       (e) => e.epixeirisi === this.state.detailData.id
      //     ),
      //   });
      // });
    }, 1500);
  }

  async checkViolationDetailsExists() {
    for (const parabasi of this.state.businessParabaseis) {
      session
        .get(`api/violation-details/${parabasi.id}/`)
        .then((resp) => {
          this.setState(prevState => ({
            parabaseisDetailsExist: {
              ...prevState.parabaseisDetailsExist,
              [parabasi.id]: resp.data.length !== 0,
            },
          }));
        })
        .catch((e) => {
          console.log(e);
        })
    }
  }

  handleSpinner(e) {
    console.log(e, "spinner");

    this.setState({ spinner: { ...this.state.spinner, [e]: true } });
  }

  handleModalLisenceClosed(e) {
    setTimeout(() => {
      session
        .get(`api/business-license/${this.props.match.params.id}/`)
        .then((resp) => {
          this.setState({
            businessLicense: resp.data,
            spinner: { ...this.state.spinner, licenseSpinner: false },
          });
        });
    }, 1500);
  }

  handleModalTranferClosed(e) {
    setTimeout(() => {
      session
        .get(`api/business-transfer/${this.props.match.params.id}/transfer/`)
        .then((resp) => {
          this.setState({
            businessTranfer: resp.data,
            spinner: { ...this.state.spinner, metabibasiSpinner: false },
          });
        });
    }, 1500);
  }

  handleModalParabasiClosed(e) {
    setTimeout(() => {
      session.get(`api/business-parabaseis/${this.props.match.params.id}/parabaseis/`).then((resp) => {
        // this.setState({ businessParabaseis: resp.data });
        this.setState({
          businessParabaseis: resp.data,
          spinner: { ...this.state.spinner, parabasiSpinner: false },
        });
      })
      // session
      //   .get(`api/business-parabaseis/${this.props.match.params.id}/parabaseis/`)
      //   .then((resp) => {
      //     this.setState({
      //       businessParabaseis: resp.data,
      //       spinner: { ...this.state.spinner, parabasiSpinner: false },
      //     });
      //   });
      session
        .get(`api/parabaseis/${this.props.match.params.id}/per-year/`)
        .then((resp) => {
          this.setState({ poinesParabaseis: resp.data });
        });
    }, 1500);
  }
  parseTimer(dt) {
    if (dt !== undefined) {
      const dtsplit = dt.split(":");

      if (dtsplit.length === 3) {
        return dtsplit.splice(0, 2).join(":");
      }
      return dt;
    }
  }

  myfunc(dt) {
    const [date, time] = dt.split(" ");
    return date.split("-").reverse().join("-"), time;
  }

  openCollapseState(idx) {

    this.setState(
      { collapedIdxes: Object.assign({}, this.state.collapedIdxes, { [idx]: !this.state.collapedIdxes[idx] }), })
  }

  fetchParabaseis(
    attestation,
    eidos,
    stadio,
    penalty,
    description,
    sort
  ) {
    session.get(`api/business-parabaseis/${this.props.match.params.id}/parabaseis`, {
      params: {
        attestation_authority: attestation,
        eidos: eidos,
        stadio: stadio,
        penalty: penalty,
        description: description,
        sort: sort
      }
    })
      .then((resp) => {
        this.setState({ businessParabaseis: resp.data });
        // this.checkViolationDetailsExists();
      })
      .catch((e) => {
        console.log(e);
      })
  }

  handleAttestationChange(e) {
    this.setState({ selectedAttestation: e.target.value });
    this.fetchParabaseis(
      e.target.value,
      this.state.selectedEidos,
      this.state.selectedStadio,
      this.state.selectedPenalty,
      this.state.selectedDescription,
      this.state.selectedSort
    );
  }

  handleEidosChange(e) {
    this.setState({ selectedEidos: e.target.value });
    this.fetchParabaseis(
      this.state.selectedAttestation,
      e.target.value,
      this.state.selectedStadio,
      this.state.selectedPenalty,
      this.state.selectedDescription,
      this.state.selectedSort
    );
  }

  handleDescriptionChange(e) {
    this.setState({ selectedDescription: e.target.value });
    this.fetchParabaseis(
      this.state.selectedAttestation,
      this.state.selectedEidos,
      this.state.selectedStadio,
      this.state.selectedPenalty,
      e.target.value,
      this.state.selectedSort
    );
  }

  handleStadioChange(e) {
    this.setState({ selectedStadio: e.target.value });
    this.fetchParabaseis(
      this.state.selectedAttestation,
      this.state.selectedEidos,
      e.target.value,
      this.state.selectedPenalty,
      this.state.selectedDescription,
      this.state.selectedSort
    );
  }

  handlePenaltyChange(e) {
    this.setState({ selectedPenalty: e.target.value });
    this.fetchParabaseis(
      this.state.selectedAttestation,
      this.state.selectedEidos,
      this.state.selectedStadio,
      e.target.value,
      this.state.selectedDescription,
      this.state.selectedSort
    );
  }

  handleSortChange(e) {
    this.setState({ selectedSort: e.target.value });
    this.fetchParabaseis(
      this.state.selectedAttestation,
      this.state.selectedEidos,
      this.state.selectedStadio,
      this.state.selectedPenalty,
      this.state.selectedDescription,
      e.target.value
    );
  }
  deleteBusinessViolationFile(id) {
    session.get(`api/parabasi/${id}/eggrafo/delete/`).then(() => {

      setTimeout(() => {
        session.get(`api/business-parabaseis/${this.props.match.params.id}/parabaseis/`).then((resp) => {
          // this.setState({ businessParabaseis: resp.data });
          this.setState({
            businessParabaseis: resp.data,
            spinner: { ...this.state.spinner, parabasiSpinner: false },
          });
        })
      }, 1500);

    }).catch(e => {
      console.log(e)
      return
    })
  }


  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax small filter bgImage={require("assets/img/profile-bg2.jpg")}>
          <div style={{ height: "200px" }} />
        </Parallax>

        <Box
          sx={{
            width: "fit-content",
            borderBottom: 1,
            borderColor: "divider",
            mx: "auto",
            textAlign: "center",
          }}
        >
          <h3>Καρτέλα επιχείρησης - {this.state.detailData.nameE}</h3>
        </Box>

        <Box
          sx={{
            width: "100%",
            typography: "body1",
            backgroundColor: "white",
          }}
        >
          <TabContext value={this.state.newValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TabList
                onChange={this.handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="ΣΤΟΙΧΕΙΑ ΕΠΙΧΕΙΡΗΣΗΣ" value="1" />
                <Tab label="ΑΔΕΙΕΣ" value="2" />
                <Tab label="ΠΑΡΑΒΑΣΕΙΣ" value="3" />
                <Tab label="ΜΕΤΑΒΙΒΑΣΕΙΣ" value="4" />
                {/* <Tab label="ΑΙΤΗΣΕΙΣ" value="5" /> */}
              </TabList>
            </Box>
            {/* // TODO Make the tab views */}
            <TabPanel value="2">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Εναρξη Αδειας</TableCell>
                      <TableCell align="right">Λήξη</TableCell>
                      <TableCell align="right">Είδος Άδειας</TableCell>
                      <TableCell align="center">Αρχείο</TableCell>
                      <TableCell align="right">Παρατηρήσεις</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.businessLicense &&
                      this.state.businessLicense.length > 0 &&
                      this.state.businessLicense.map((e, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {e.start_date.split("-").reverse().join("-")}
                          </TableCell>
                          <TableCell align="right">
                            {e.end_date
                              ? e.end_date.split("-").reverse().join("-")
                              : "-"}
                          </TableCell>
                          <TableCell align="right">
                            {e.license_parameter_name}
                          </TableCell>
                          <TableCell align="right">
                            {this.state.spinner.licenseSpinner && (
                              <CircularProgress />
                            )}
                            {e.files.length > 0 &&
                              e.files.map((row, index) => (
                                <div key={index}>
                                  <a
                                    href={row.file}
                                    key={index}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {/* Αρχείο {index + 1} */}
                                    {decodeURI(row.file.split("/").pop())}
                                  </a>
                                  <UpdateBusinessLicenseFile
                                    handleSpinner={(e) => this.handleSpinner(e)}
                                    file={row.file}
                                    fileId={row.id}
                                    businessID={this.state.detailData.id}
                                    handleBusinessFileEnd={(e) =>
                                      this.handleModalLisenceClosed(e)
                                    }
                                  ></UpdateBusinessLicenseFile>
                                  <DeleteBusinessFile
                                    filenameUrl={row.file}
                                    filename={decodeURI(
                                      row.file.split("/").pop()
                                    )}
                                    fileId={row.id}
                                    action={"license"}
                                    handleChangeUpdate={(e) =>
                                      this.handleModalLisenceClosed(e)
                                    }
                                  ></DeleteBusinessFile>
                                </div>
                              ))}
                          </TableCell>

                          <TableCell align="right">{e.comments}</TableCell>
                          <TableCell align="right">
                            <BusinessLisenceModal
                              handleSpinner={(e) => this.handleSpinner(e)}
                              handleBusinessChange={(e) =>
                                this.handleModalLisenceClosed(e)
                              }
                              action="Update"
                              actionID={e.id}
                              businessID={this.state.detailData.id}
                              licenseParameters={
                                this.state.businessLicenseParameters
                              }
                              created_by={this.state.currentUser}
                              comment={e.comments}
                              start_date={e.start_date}
                              end_date={e.end_date}
                              licenseParam={e.license_parameter}
                            ></BusinessLisenceModal>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <BusinessLisenceModal
                handleSpinner={(e) => this.handleSpinner(e)}
                handleBusinessChange={(e) => this.handleModalLisenceClosed(e)}
                action="Create"
                businessID={this.state.detailData.id}
                licenseParameters={this.state.businessLicenseParameters}
                created_by={this.state.currentUser}
              ></BusinessLisenceModal>
            </TabPanel>

            <TabPanel value="3">
              {/* start collapse */}


              <Box display="flex" sx={{ backgroundColor: "white" }}>
                <Box
                  sx={{ mx: "auto" }}
                  display="flex"
                  style={{ alignItems: "center" }}
                >
                  <FormControl sx={{ m: 1, width: 200 }} variant="standard">
                    <InputLabel id="demo-controlled-open-select-label">
                      Αρχή βεβαίωσης
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      open={this.state.attestationOpen}
                      onClose={() => this.setState({ attestationOpen: false })}
                      onOpen={() => this.setState({ attestationOpen: true })}
                      value={this.state.selectedAttestation}
                      onChange={(e) => this.handleAttestationChange(e)}
                    >
                      <MenuItem value="">
                        <em>-------------------</em>
                      </MenuItem>
                      {this.state.attestationList.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{ m: 1, width: 200, margin: "0px 26px 0px 20px" }}
                    variant="standard"
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Είδος παράβασης
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      open={this.state.eidosOpen}
                      onClose={() => this.setState({ eidosOpen: false })}
                      onOpen={() => this.setState({ eidosOpen: true })}
                      value={this.state.selectedEidos}
                      onChange={(e) => this.handleEidosChange(e)}
                    >
                      <MenuItem value="">
                        <em>-------------------</em>
                      </MenuItem>
                      {this.state.eidosParabasis.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{ m: 1, width: 200, margin: "0px 26px 0px 20px" }}
                    variant="standard"
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Περιγραφή παράβασης
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      open={this.state.descriptionOpen}
                      onClose={() => this.setState({ descriptionOpen: false })}
                      onOpen={() => this.setState({ descriptionOpen: true })}
                      value={this.state.selectedDescription}
                      onChange={(e) => this.handleDescriptionChange(e)}
                    >
                      <MenuItem value="">
                        <em>-------------------</em>
                      </MenuItem>
                      {this.state.violationDescriptionList.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{ m: 1, width: 200, margin: "0px 26px 0px 20px" }}
                    variant="standard"
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Στάδιο παράβασης
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      open={this.state.stadioOpen}
                      onClose={() => this.setState({ stadioOpen: false })}
                      onOpen={() => this.setState({ stadioOpen: true })}
                      value={this.state.selectedStadio}
                      onChange={(e) => this.handleStadioChange(e)}
                    >
                      <MenuItem value="">
                        <em>-------------------</em>
                      </MenuItem>
                      {this.state.stadiaParabasis.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{ m: 1, width: 200, margin: "0px 26px 0px 20px" }}
                    variant="standard"
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Ποινή παράβασης
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      open={this.state.penaltyOpen}
                      onClose={() => this.setState({ penaltyOpen: false })}
                      onOpen={() => this.setState({ penaltyOpen: true })}
                      value={this.state.selectedPenalty}
                      onChange={(e) => this.handlePenaltyChange(e)}
                    >
                      <MenuItem value="">
                        <em>-------------------</em>
                      </MenuItem>
                      {this.state.penalties.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>


                  <FormControl
                    sx={{ m: 1, width: 200, margin: "0px 26px 0px 20px" }}
                    variant="standard"
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Ταξινόμηση
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      open={this.state.sortOpen}
                      onClose={() => this.setState({ sortOpen: false })}
                      onOpen={() => this.setState({ sortOpen: true })}
                      value={this.state.selectedSort}
                      onChange={(e) => this.handleSortChange(e)}
                    >
                      <MenuItem value="-violation_date">
                        Ημερομηνία(Πιο πρόσφατη πρώτα)
                      </MenuItem>
                      <MenuItem value="violation_date">
                        Ημερομηνία(Παλαιότερη πρώτα)
                      </MenuItem>
                      <MenuItem value="-created_at">
                        Δημιουργία παράβασης(Πιο πρόσφατη πρώτα)
                      </MenuItem>
                      <MenuItem value="created_at">
                        Δημιουργία παράβασης(Παλαιότερη πρώτα)
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* <Button
                    variant="contained"
                    sx={{
                      width: "fit-content",
                      height: "78%",
                      textTransform: "none",
                      bgcolor: "#388e3c",
                    }}
                    onClick={() => this.searchData()}
                  >
                    <i className="fas fa-search"></i>Αναζήτηση
                  </Button> */}
                </Box>
              </Box>
              <br></br>



              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>

                      <TableCell>Ημ/νια Δημιουργίας</TableCell>
                      <TableCell align="center"> Ημ/νια παράβασης</TableCell>
                      <TableCell align="center">Αρ. Πρωτοκόλλου</TableCell>
                      <TableCell align="center">Στάδιο</TableCell>
                      <TableCell align="center">Είδος Παράβασης</TableCell>
                      <TableCell align="center">Ποινή</TableCell>
                      {/* <TableCell align="right">Ανάκληση Άδειας</TableCell> */}

                      <TableCell align="center">Αρχείο</TableCell>
                      <TableCell align="center">Ενέργειες</TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>


                  <TableBody >
                    {this.state.businessParabaseis.length > 0 &&
                      this.state.businessParabaseis.map((row, idx) => (

                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={idx}>
                          {/* <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => this.openCollapseState(idx)}
                            >
                              {this.state.collapedIdxes[idx] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell> */}

                          <TableCell component="th" scope="row">
                            {row.created}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {row.violation_date.split("-").reverse().join("-")}
                          </TableCell>




                          {/* <TableCell align="center">
                            {row.eidos_title != null ? row.eidos_title : "-"}
                          </TableCell> */}
                          <TableCell align="center">
                            {row.violation_protocol_number != null ? row.violation_protocol_number : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row.stadio_title != null ? row.stadio_title : "-"}
                          </TableCell>

                          <TableCell align="center">
                            {row.eidos_title != null ? row.eidos_title : "-"}
                          </TableCell>

                          <TableCell align="center">
                            {row.penalty_title ? row.penalty_title : "-"}
                          </TableCell>
                          {/* <TableCell align="right">
                            {row.anaklisi_adeias ? "Ναι" : "Οχι"}
                          </TableCell> */}
                          <TableCell align="right">
                            {this.state.spinner.parabasiSpinner && (
                              <CircularProgress />
                            )}

                            {/* {row.file != null ? (
                              <div>
                                <a href={row.file} target="_blank">
                                  {decodeURI(row.file.split("/").pop())}
                                </a>
                                <button
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => this.deleteBusinessViolationFile(row.id)}

                                >

                                  Διαγραφή
                                </button>
                              </div>
                            ) : (
                              "-"
                            )} */}


                            <div>
                              {row.file && (
                                <div>
                                  <a href={row.file} target="_blank" rel="noopener noreferrer">
                                    {decodeURI(row.file.split("/").pop())}
                                  </a>
                                  <button
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => this.deleteBusinessViolationFile(row.id)}
                                  >
                                    Διαγραφή
                                  </button>
                                </div>
                              )}
                              {row.files && row.files.length > 0 && row.files.map((file, index) => (
                                <div key={index}>
                                  <a href={file.file} target="_blank" rel="noopener noreferrer">
                                    {decodeURI(file.file.split("/").pop())}
                                  </a>

                                </div>
                              ))}
                              {row.detail_files && row.detail_files.length > 0 && row.detail_files.map((detail, index) => (
                                <div key={index}>
                                  {/* You need to adapt this part depending on your data structure */}
                                  <a href={detail.file} target="_blank" rel="noopener noreferrer">
                                    {decodeURI(detail.file.split("/").pop())}
                                  </a>

                                </div>
                              ))}
                            </div>


                            {/* Να φαίνονται εδώ όλα τα αρχεία της παράβασης. */}
                          </TableCell>
                          <TableCell align="right">
                            <Button component={Link} to={{ pathname: `/business/${this.props.match.params.id}/violation/${row.id}/update/`, state: { businessId: this.props.match.params.id, violationId: row.id } }} variant="outlined">Επεξεργασια</Button>
                            {/* <ParabaseisModal
                              handleSpinner={(e) => this.handleSpinner(e)}
                              handleChange={(e) =>
                                this.handleModalParabasiClosed()
                              }
                              action="Update"
                              date={row.violation_date}
                              file={row.file}
                              comments={row.comments}
                              title="Επεξεργασία"
                              buttonTitle="Επεξεργασια"
                              id={row.id}
                              stadia={this.state.stadiaParabasis}
                              // updateStadia={row.stadio}
                              eidos={this.state.eidosParabasis}
                              personID={this.state.detailData.id}
                              checkAnaklisi={row.anaklisi_adeias}
                              created_by={this.state.currentUser}
                              selectedStadio={row.stadio}
                              selectedEidos={row.eidos}
                              penalties={this.state.penalties}
                              selectedPenalty={row.penalty}
                            /> */}
                          </TableCell>
                          <TableCell align="right">
                            {/* {this.state.parabaseisDetailsExist[row.id] ? (
                              <Button component={Link} to={{ pathname: `/business/${this.props.match.params.id}/violation/${row.id}/details/update/`, state: { businessId: this.props.match.params.id, violationId: row.id } }} variant="outlined">ΛΕΠΤΟΜΕΡΕΙΕΣ</Button>
                            ) : (
                              <Button component={Link} to={{ pathname: `/business/${this.props.match.params.id}/violation/${row.id}/details/create/`, state: { businessId: this.props.match.params.id, violationId: row.id } }} variant="outlined">ΛΕΠΤΟΜΕΡΕΙΕΣ</Button>
                            )} */}
                            {row.has_violations_details ? (
                              <Button component={Link} to={{ pathname: `/business/${this.props.match.params.id}/violation/${row.id}/details/update/`, state: { businessId: this.props.match.params.id, violationId: row.id } }} variant="outlined">ΛΕΠΤΟΜΕΡΕΙΕΣ</Button>
                            ) : (
                              <Button component={Link} to={{ pathname: `/business/${this.props.match.params.id}/violation/${row.id}/details/create/`, state: { businessId: this.props.match.params.id, violationId: row.id } }} variant="outlined">ΛΕΠΤΟΜΕΡΕΙΕΣ</Button>
                            )}
                          </TableCell>
                          <TableCell>


                            <BusinessViolationFile
                              eggrafa={this.state.eggrafaParabasis}
                              id={row.id}
                              handleChange={(e) =>
                                this.handleModalParabasiClosed()}
                              handleSpinner={(e) => this.handleSpinner(e)}
                            ></BusinessViolationFile>

                          </TableCell>
                        </TableRow>
                      ))}




                  </TableBody>



                </Table>
              </TableContainer>
              {/* end collapse */}


              <Button component={Link} to={{ pathname: `/business/${this.props.match.params.id}/violation/create/`, state: { businessId: this.props.match.params.id, newValue: '3' } }} variant="outlined">ΔΗΜΙΟΥΡΓΙΑ</Button>
              <PoinesParabaseisModal poines={this.state.poinesParabaseis} />
            </TabPanel>
            <TabPanel value="4">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Από</TableCell>
                      <TableCell align="center">Πρός</TableCell>
                      <TableCell align="right">Ημερομηνία</TableCell>
                      <TableCell align="center">Σχόλια</TableCell>
                      <TableCell align="right">Αρχείο</TableCell>
                      <TableCell align="right">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.businessTranfer.length > 0 &&
                      this.state.businessTranfer.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.from_person}
                          </TableCell>
                          <TableCell align="center">{row.to_person}</TableCell>
                          <TableCell align="right">
                            {row.date.split("-").reverse().join("-")}
                          </TableCell>
                          <TableCell align="center">{row.comments}</TableCell>
                          <TableCell align="right">
                            {this.state.spinner.metabibasiSpinner && (
                              <CircularProgress />
                            )}
                            {row.file != null ? (
                              <a href={row.file} target="_blank" rel="noopener noreferrer">
                                {decodeURI(row.file.split("/").pop())}
                              </a>
                            ) : (
                              "-"
                            )}
                          </TableCell>

                          <TableCell align="right">
                            <MetabibaseisModal
                              handleSpinner={(e) => this.handleSpinner(e)}
                              handleChange={(e) =>
                                this.handleModalTranferClosed(e)
                              }
                              action="Update"
                              fromPerson={row.from_person}
                              toPerson={row.to_person}
                              date={row.date}
                              file={row.file}
                              comments={row.comments}
                              title="Επεξεργασία"
                              buttonTitle="Επεξεργασια"
                              id={row.id}
                              personID={this.state.detailData.id}
                              created_by={this.state.currentUser}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <MetabibaseisModal
                handleSpinner={(e) => this.handleSpinner(e)}
                handleChange={(e) => this.handleModalTranferClosed(e)}
                action="Create"
                personID={this.state.detailData.id}
                fromPerson={this.state.detailData.userIdE}
                created_by={this.state.currentUser}
              />
            </TabPanel>

            <TabPanel value="1">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Ιδιοκτήτης</TableCell>
                      <TableCell align="center">Διακ. Τιτλος</TableCell>
                      <TableCell align="center">ΑΦΜ</TableCell>
                      <TableCell align="center">Αρ. Φακέλου</TableCell>
                      <TableCell align="center">Αρ. Αδείας</TableCell>

                      <TableCell align="right">Είδος Επιχείρησης</TableCell>
                      <TableCell align="right">Διεύθυνση</TableCell>
                      <TableCell align="center">Ωράριο</TableCell>
                      <TableCell align="center">Τηλέφωνα Επικοινωνίας</TableCell>
                      {/* <TableCell align="right">Site</TableCell> */}
                      <TableCell align="right">Δημοτική Ενότητα</TableCell>
                      <TableCell align="right">Ημν/ια Γνωστοποίησης</TableCell>
                      <TableCell align="center">Παρατηρήσεις</TableCell>
                      <TableCell align="center">Αρχείο</TableCell>
                      <TableCell align="center">Ενέργειες</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {this.state.detailData.userIdE}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {this.state.detailData.distinctive_title}
                      </TableCell>
                      <TableCell align="center">
                        {this.state.detailData.afmE}
                      </TableCell>
                      <TableCell align="center">
                        {this.state.detailData.arfak}
                      </TableCell>
                      <TableCell align="center">
                        {this.state.detailData.license_number}
                      </TableCell>
                      <TableCell align="center">
                        {this.state.detailData.icon_name}
                      </TableCell>
                      <TableCell align="right">
                        {this.state.detailData.dieuthinsi}
                      </TableCell>
                      <TableCell align="center">
                        {this.parseTimer(this.state.detailData.wrarioE)} -{" "}
                        {this.parseTimer(this.state.detailData.wrarioL)}
                      </TableCell>
                      <TableCell align="center">
                        {this.state.detailData.mobile_phone}
                        <br />
                        {this.state.detailData.home_phone}
                      </TableCell>
                      {/* <TableCell align="center">
                        {this.state.detailData.siteE}
                      </TableCell> */}
                      <TableCell align="center">
                        {this.state.detailData.city_section_title}
                      </TableCell>

                      <TableCell align="center">
                        {this.state.detailData.notification_date
                          ? this.state.detailData.notification_date
                            .split("-")
                            .reverse()
                            .join("-")
                          : "-"}
                      </TableCell>

                      <TableCell align="center">
                        {this.state.detailData.paratiriseis}
                      </TableCell>
                      <TableCell align="center">
                        {this.state.spinner.businessSpinner && (
                          <CircularProgress />
                        )}
                        {this.state.businessFilePage &&
                          this.state.businessFilePage.length > 0 ? (
                          this.state.businessFilePage.map((s) => (
                            <div
                              key={s.id}
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                paddingBottom: "20px",
                              }}
                            >
                              <a href={s.file_field} target="_blank" rel="noopener noreferrer">
                                {decodeURI(s.file_field.split("/").pop())}
                              </a>

                              <DeleteBusinessFile
                                filenameUrl={s.file_field}
                                filename={decodeURI(
                                  s.file_field.split("/").pop()
                                )}
                                fileId={s.id}
                                action={"stoixeia"}
                                handleChangeUpdate={(e) =>
                                  this.handleModalBuisnessEdit(e)
                                }
                              ></DeleteBusinessFile>
                            </div>
                          ))
                        ) : (
                          <TableCell align="center">-</TableCell>
                        )}
                      </TableCell>

                      <TableCell>
                        {this.state.detailData &&
                          this.state.detailData.nameE && (
                            <BusinessEdit
                              handleChangeUpdate={(e) =>
                                this.handleModalBuisnessEdit(e)
                              }
                              handleSpinner={(e) => this.handleSpinner(e)}
                              bussinessId={this.props.match.params.id}
                              distinctiveTitle={
                                this.state.detailData.distinctive_title
                              }
                              bussinessName={this.state.detailData.nameE}
                              owner={this.state.detailData.userIdE}
                              afm={this.state.detailData.afmE}
                              folderNumber={this.state.detailData.arfak}
                              address={this.state.detailData.dieuthinsi}
                              mobilePhone={this.state.detailData.mobile_phone}
                              homePhone={this.state.detailData.home_phone}
                              isClosed={this.state.detailData.is_closed}
                              notes={this.state.detailData.paratiriseis}
                              categories={this.state.businessCategories}
                              site={this.state.detailData.siteE}
                              startTime={this.state.detailData.wrarioE}
                              endTime={this.state.detailData.wrarioL}
                              file={this.state.businessFilePage}
                              bussinessCategory={
                                this.state.detailData.icon_name
                              }
                              licenseNumber={
                                this.state.detailData.license_number
                              }
                              notificationDate={
                                this.state.detailData.notification_date
                              }
                              citySection={
                                this.state.detailData.city_section_title
                              }
                              citySections={this.state.citySections}
                              citySectionId={this.state.detailData.city_section}
                              businessCatId={this.state.detailData.iconeE}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                    {
                      this.state.detailData.is_closed ?
                        <TableRow>
                          <Typography
                            color="error"
                          >
                            Η επιχείρηση έχει κλείσει
                          </Typography>
                        </TableRow> : null
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabContext>
        </Box >

        <div style={{ marginTop: "200px" }}>
          <Footer />
        </div>
      </div >
    );
  }
}
export default BusinessDetails;

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import session from '../api/session'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import el from "date-fns/locale/el";
// registerLocale("el", el);




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    flexGrow: 1
};

export default class TableModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            businessDetail: [],
            valueStartDate: new Date(),
            valueEndDate: new Date(),
        }
    }



    handleOpenModal() {

        this.setState({ open: true })
        this.fetchBusinnessDetails()
    }
    handleCloseModal() {
        this.setState({ open: false })
    }

    fetchBusinnessDetails() {
        session.get(`api/Epixeiriseis/${this.props.name}`).then(res => {
            this.setState({ businessDetail: res.data, valueStartDate: res.data.enarxiE, valueEndDate: res.data.lixiE })
        })
    }
    changeDateValue(v) {

        this.setState({ valueStartDate: v })

    }

    changeEndDatevalue(v) {
        this.setState({ valueEndDate: v })
    }

    parseDate(date) {
        if (date instanceof Date) {
            console.log(date)
            return date.toISOString().split('T')[0]
        }
        else {
            return date
        }
    }

    submitForm() {
        console.log('submited button')
        console.log(this.parseDate(this.state.valueEndDate))

        console.log(this.parseDate(this.state.valueStartDate))
        this.state.businessDetail['enarxiE'] = this.parseDate(this.state.valueStartDate)
        this.state.businessDetail['lixiE'] = this.parseDate(this.state.valueEndDate)
        console.log(this.state.businessDetail)
        session.put(`api/Epixeiriseis/${this.props.name}/`, {
            nameE: this.state.businessDetail["nameE"],
            coordsE: this.state.businessDetail['coordsE'],
            afmE: this.state.businessDetail["afmE"],
            siteE: this.state.businessDetail["siteE"],
            userIdE: this.state.businessDetail["userIdE"],
            enarxiE: this.state.businessDetail["enarxiE"],
            lixiE: this.state.businessDetail["lixiE"],
            posoE: this.state.businessDetail["posoE"],
            adeiaDimou: this.state.businessDetail["adeiaDimou"],
            adeiaApokentrwmenis: this.state.businessDetail["adeiaApokentrwmenis"],
            covidStatus: this.state.businessDetail["covidStatus"],
            wrarioE: this.state.businessDetail["wrarioE"],
            wrarioL: this.state.businessDetail["wrarioL"],
            arfak: this.state.businessDetail["arfak"],
            paratiriseis: this.state.businessDetail["paratiriseis"],
            dieuthinsi: this.state.businessDetail["dieuthinsi"],
            tk: this.state.businessDetail["tk"],
            antikatastasi: this.state.businessDetail["antikatastasi"],
            katid: this.state.businessDetail["katid"],
            enarksi_adeias: this.state.businessDetail["enarksi_adeias"],
            liksi_adeias: this.state.businessDetail["liksi_adeias"],
            iconeE: this.state.businessDetail["iconeE"],

        }).then(() => {
            this.handleCloseModal()

        })
    }

    render() {
        return (
            <div>
                <Button onClick={() => this.handleOpenModal()}>Ανανεωση Αδειας</Button>
                <Modal
                    open={this.state.open}
                    onClose={() => this.handleCloseModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Ανανέωση Άδειας - {this.state.businessDetail.nameE}
                        </Typography>
                        <hr />
                        <Stack spacing={5}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={el}>
                                            <DesktopDatePicker
                                                label="Έναρξη Άδειας"
                                                value={this.state.valueStartDate}
                                                mask="__/__/____"
                                                inputFormat="dd/MM/yyyy"
                                                onChange={(v) => this.changeDateValue(v)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                        <hr />
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={el}>
                                            <DesktopDatePicker
                                                label="Λήξη Άδειας"
                                                mask="__/__/____"
                                                inputFormat="dd/MM/yyyy"
                                                value={this.state.valueEndDate}
                                                onChange={(v) => this.changeEndDatevalue(v)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>


                                    </Grid>

                                </Grid>

                            </Box>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" color="success" onClick={() => this.submitForm()}>
                                    Αποθηκευση
                                </Button>

                                <Button variant="contained" color="error" onClick={() => this.handleCloseModal()}>
                                    Ακυρωση
                                </Button>
                            </Stack>

                        </Stack>

                    </Box>
                </Modal>

            </div >
        )
    }
}
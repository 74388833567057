import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import axios from "axios";
// import PDFViewer from "pdf-viewer-reactjs";
import DownloadLink from "react-download-link";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-kit-react.js";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  Polygon,
  useMapEvents,
} from "react-leaflet";
import {
  Badge,
  // Card,
  // CardHeader,
  // CardFooter,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Progress,
  Container,
  Row,
  Input,
  Nav,
  TabPane,
  TabContent,
  // Button,
  NavLink,
  // CardBody,
  NavItem,
  Col,
  // UncontrolledTooltip,
} from "reactstrap";
import L from "leaflet";
import Parallax from "components/Parallax/Parallax.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { PDFDownloadLink, Document, Page } from "react-pdf";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import ReactTable from "components/ReactTable/ReactTable.js";
import session from "../api/session";

let UdataTablePcat = [];

let UdataTableP = [];
const useStyles = makeStyles(styles);

export default function Post(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
      <br />
      <br />
      <br />
      <br />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <PostPanel />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
class PostPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PostList: [],
      markers: [[0, 0]],
      map: null,
      postselected: "-",
      numPages: 0,
      pageNumber: 1,
      postdocsList: [],
      selectedPostDocs: [],
      PostCatList: [],
      postcategoryselected: "covid",
    };
  }

  componentDidMount() {
    this.refreshList();
    this.refreshListPostDocs();
  }

  refreshListPostDocs() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/FileFieldFormPost/")
    session
      .get("api/FileFieldFormPost/")
      .then((res) => this.setState({ postdocsList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/PostCategory/")
    session
      .get("api/PostCategory/")
      .then((res) => this.setState({ PostCatList: res.data }))
      .catch((err) => console.log(err));
  }

  handlePostSelect(e) {
    const value = e.target.value;

    this.state.postselected = value;

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  handlePostCategorySelect(e) {
    const value = e.target.value;

    this.state.postcategoryselected = value;
    this.setState({ state: this.state });
    this.refreshList();
  }

  ClearPostCategorySelect() {
    this.state.postcategoryselected = "-";
    this.setState({ state: this.state });
    this.refreshList();
  }

  ClearPostSelect() {
    this.state.postselected = "-";
    this.setState({ state: this.state });
    this.refreshList();
  }

  refreshList() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/Post/")
    session
      .get("api/Post/")
      .then((res) => this.setState({ PostList: res.data }))
      .catch((err) => console.log(err));
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    // setNumPages(numPages);
    this.state.numPages = numPages;
  };

  // getDataFromURL = (url) =>
  //   new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       fetch(url)
  //         .then((response) => response.text())
  //         .then((data) => {
  //           resolve(data);
  //         });
  //     });
  //   }, 2000);

  render() {
    let posts = this.state.PostList.filter((item) => item.status === 1);

    console.log(this.state.postcategoryselected);

    if (
      this.state.postcategoryselected != undefined &&
      this.state.postcategoryselected != "-"
    ) {
      posts = posts.filter(
        (item) => item.category == this.state.postcategoryselected
      );
    }

    if (
      this.state.postselected != undefined &&
      this.state.postselected != "-"
    ) {
      posts = posts.filter((item) => item.id == this.state.postselected);
    }

    const postsCat = this.state.PostCatList;

    let tmpItemstoshow = [];

    tmpItemstoshow = this.state.PostList.filter(
      (item) => item.id === parseInt(this.state.postselected)
    );

    for (var i = 0; i < tmpItemstoshow.length; i++) {
      const tmpdocs = this.state.postdocsList.filter(
        (itemS) => itemS.postId === tmpItemstoshow[i].title
      );

      if (tmpdocs[0] != undefined) {
        console.log(tmpdocs);
        tmpItemstoshow[i].image = String(tmpdocs[0].file_field);
      }
    }

    const styles = StyleSheet.create({
      page: { backgroundColor: "tomato" },
      section: { textAlign: "center", margin: 30 },
    });

    console.log(tmpItemstoshow);

    UdataTablePcat = [];

    for (var i = 0; i < postsCat.length; i++) {
      const dataRow = {};
      // const coords = tmpItemsLaikes[i].coordsLA;
      const name = postsCat[i].namePoC;

      dataRow.name = (
        <a onChange={(e) => this.handlePostCategorySelect(e)}>{name}</a>
      );
      dataRow.selectLa = (
        <Radio
          checked={String(this.state.postcategoryselected) === name}
          onChange={(e) => this.handlePostCategorySelect(e)}
          value={name}
          name="radio-button-demo"
        />
      );
      // tmpItemsLaikes[i].nameLA;
      // dataRow.perioxi = tmpItemsLaikes[i].perioxiA;

      UdataTablePcat.push(dataRow);
    }

    UdataTableP = [];

    for (var i = 0; i < posts.length; i++) {
      const dataRow = {};
      const id = posts[i].id;
      const nameP = posts[i].title;

      dataRow.name = nameP;
      // dataRow.name = <a onClick={(e) => this.handlePostSelect(e)}>{nameP}</a>;

      dataRow.selectLa = (
        <Radio
          checked={parseInt(this.state.postselected) === parseInt(id)}
          onChange={(e) => this.handlePostSelect(e)}
          value={id}
          name="radio-button-demo"
        />
      );
      // tmpItemsLaikes[i].nameLA;
      dataRow.category = posts[i].category;

      let tmp = posts[i].created_on.split("T")[0];

      let tmp2 = tmp.split("-");
      dataRow.date = tmp2[2] + "-" + tmp2[1] + "-" + tmp2[0];

      UdataTableP.push(dataRow);
    }

    return (
      <GridContainer>
        <GridItem>
          {/* <Card>
            <CardBody>
              <CardHeader color="success">Κατηγορίες</CardHeader>

              <ReactTable
                data={UdataTablePcat}
                filterable
                resizable={false}
                columns={[
                  {
                    Header: (
                      <Button
                        round
                        color="danger"
                        size="sm"
                        onClick={() => this.ClearPostCategorySelect()}
                      >
                        {" "}
                        <i className="fa fa-times"></i>{" "}
                      </Button>
                    ),
                    accessor: "selectLa",
                    disableFilters: true,
                    disableSortBy: true,
                  },
                  {
                    Header: "Όνομα",
                    accessor: "name",
                    disableFilters: false,
                    disableSortBy: false,
                  },
                ]}
                defaultPageSize={5}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              /> */}

          {/* <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Button
                          round
                          color="danger"
                          size="sm"
                          onClick={() => this.ClearPostCategorySelect()}
                        >
                          <i className="fa fa-times"></i>
                        </Button>
                        Όνομα
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {postsCat.map((item) => (
                      <TableRow key={item.id}> */}
          {/* <FormControlLabel
                      control={
                        <Checkbox
                          // onChange={(e) => this.handleChangePostCategory(e)}
                          name="namePoC"
                          id="namePoC"
                          color="primary"
                        />
                      }
                      label={item.namePoC}
                    /> */}

          {/* <Radio
                          checked={
                            String(this.state.postcategoryselected) ===
                            item.namePoC
                          }
                          onChange={(e) => this.handlePostCategorySelect(e)}
                          value={item.namePoC}
                          name="radio-button-demo"
                        />
                        {item.namePoC}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> */}
          {/* </CardBody>
          </Card> */}

          <Card>
            <CardBody>
              <CardHeader color="success">Ανακοινώσεις/Νέα</CardHeader>

              <ReactTable
                data={UdataTableP}
                filterable
                resizable={false}
                columns={[
                  {
                    Header: (
                      <Button
                        round
                        color="danger"
                        size="sm"
                        onClick={() => this.ClearPostSelect()}
                      >
                        {" "}
                        <i className="fa fa-times"></i>{" "}
                      </Button>
                    ),
                    accessor: "selectLa",
                    disableFilters: true,
                    disableSortBy: true,
                  },
                  {
                    Header: "Τίτλος",
                    accessor: "name",
                    disableFilters: false,
                    disableSortBy: false,
                  },
                  {
                    Header: "Κατηγορία",
                    accessor: "category",
                    disableFilters: true,
                    disableSortBy: false,
                  },
                  {
                    Header: "Ημερομηνία",
                    accessor: "date",
                    disableFilters: false,
                    disableSortBy: false,
                  },
                ]}
                defaultPageSize={5}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />

              {/* <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Όνομα</TableCell>
                      <TableCell>Κατηγορία</TableCell> */}
              {/* <TableCell>Συγγραφέας</TableCell> */}
              {/* <TableCell>Ημερομηνία</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {posts.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell component="th" scope="row">
                          <Radio
                            checked={
                              parseInt(this.state.postselected) ===
                              parseInt(item.id)
                            }
                            onChange={(e) => this.handlePostSelect(e)}
                            value={item.id}
                            name="radio-button-demo"
                          />
                          {item.title}
                        </TableCell>
                        <TableCell>{item.category}</TableCell> */}
              {/* <TableCell>{item.author}</TableCell> */}
              {/* <TableCell>{item.created_on.split("T")[0]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> */}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem>
          <GridContainer direction="row" justify="center" alignItems="center">
            {/* <h3> {"Posts"}</h3> */}
          </GridContainer>
          {tmpItemstoshow[0] != undefined && (
            <div>
              <Card>
                <CardHeader color="success">
                  Τίτλος: {tmpItemstoshow[0].title}
                </CardHeader>
                <CardBody>
                  {/* <Row>
                {"Τίτλος: "}
                <h2>{tmpItemstoshow[0].title}</h2>
              </Row> */}
                  {/* <Row>{tmpItemstoshow[0].slug}</Row> */}

                  <Row>{tmpItemstoshow[0].content}</Row>

                  {tmpItemstoshow[0].image != null && (
                    <div>
                      {String(tmpItemstoshow[0].image).includes("pdf") && (
                        // <PDFViewer
                        //   document={{
                        //     url: tmpItemstoshow[0].image,
                        //   }}
                        // />

                        <div>
                          {/* <Link to={tmpItemstoshow[0].image} target="_blank" download>Download</Link> */}

                          {/* <DownloadLink
                        label="Save"
                        filename={tmpItemstoshow[0].title + ".pdf"}
                        exportFile={() =>
                          Promise.resolve(
                            this.getDataFromURL(tmpItemstoshow[0].image)
                          )
                        }
                      /> */}

                          <Button
                            size="sm"
                            round
                            onClick={() =>
                              window.open(tmpItemstoshow[0].image, "_blank")
                            }
                          >
                            {"Λήψη"}
                          </Button>
                          <Card>
                            <CardBody>
                              <Document
                                file={tmpItemstoshow[0].image}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                              >
                                {Array.apply(null, Array(this.state.numPages))
                                  .map((x, i) => i + 1)
                                  .map((page) => (
                                    <Page pageNumber={page} />
                                  ))}
                              </Document>
                            </CardBody>
                          </Card>
                          {/* <div>
                        <p>
                          Page{" "}
                          {this.state.pageNumber ||
                            (this.state.numPages ? 1 : "--")}{" "}
                          of {this.state.numPages || "--"}
                        </p>
                        <button
                          type="button"
                          disabled={this.state.pageNumber <= 1}
                          onClick={this.state.previousPage}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          disabled={
                            this.state.pageNumber >= this.state.numPages
                          }
                          onClick={this.state.nextPage}
                        >
                          Next
                        </button>
                      </div> */}

                          {/* <PDFDownloadLink
                        document={tmpItemstoshow[0].image}
                        fileName={tmpItemstoshow[0].title}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download now!"
                        }
                      </PDFDownloadLink> */}
                        </div>
                      )}

                      {!String(tmpItemstoshow[0].image).includes("pdf") && (
                        <img
                          style={{ width: "50%" }}
                          src={tmpItemstoshow[0].image}
                        />
                      )}
                    </div>

                    // <Row>{tmpItemstoshow[0].image}</Row>
                  )}

                  {/* <Row>{tmpItemstoshow[0].slug}</Row> */}

                  <Row>
                    {/* <Col> Συντάκτης: {tmpItemstoshow[0].author} </Col> */}
                    <Col>
                      {" "}
                      Ημερομηνία:{" "}
                      {String(tmpItemstoshow[0].created_on).split("T")[0]}{" "}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

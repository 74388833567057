import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import DataTable from "./dataTable.jsx";
import BusinessTable from "./businessTable";

class Tabbing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newValue: "1",
    };
  }
  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
  }
  handleChange = (_, newValue) => {
    this.setState({
      newValue,
    });
  };
  render() {
    return (
      <div>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax small filter bgImage={require("assets/img/profile-bg2.jpg")}>
          <div style={{ height: "200px" }} />
        </Parallax>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
          }}
        >
          <DataTable></DataTable>
        </Box>
        <div style={{ marginTop: "200px" }}>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Tabbing;

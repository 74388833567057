import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import BarcodeRecognition from "./barcodeRecognition.jsx";
import LaikesTable from "./dataTable.jsx";
import userParser from "components/userGroups.js";

class Tabbing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newValue: "1",
    };
  }
  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
    if (!userParser("Ελεγκτής") && !userParser("SuperUsers")) {
      window.location.href = "/";
    }
  }
  handleChange = (_, newValue) => {
    this.setState({
      newValue,
    });
  };
  render() {
    return (
      <div>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax small filter bgImage={require("assets/img/profile-bg2.jpg")}>
          <div style={{ height: "200px" }} />
        </Parallax>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
          }}
        >
          <TabContext value={this.state.newValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TabList
                onChange={this.handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Παρουσιες Παγκου" value="1" />
                <Tab label="Αναζητηση με Barcore Qr code" value="2" />
                {/* <Tab label="Item Three" value="3" /> */}
              </TabList>
            </Box>
            <TabPanel value="1">
              <LaikesTable></LaikesTable>
            </TabPanel>
            <TabPanel value="2">
              <BarcodeRecognition></BarcodeRecognition>
            </TabPanel>
            {/* <TabPanel value="3">Item Three</TabPanel> */}
          </TabContext>
        </Box>
        <div style={{ marginTop: "200px" }}>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Tabbing;

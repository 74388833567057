import React, { Component } from "react";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Header from "components/Header/Header.js";
import session from "../../api/session";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
  Input,
} from "@material-ui/core";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { isConstructorDeclaration } from "typescript";

class AlertDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      progress: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDeleteFile = (fileId) => {
    this.setState({ progress: true });
    session
      .delete(`api/violation-file/${fileId}/delete/`)
      .then(() => {
        setTimeout(() => {
          // this.props.handleViolationUpdateFiles();
          this.setState({ progress: false });
          this.props.SuccessDeletedFile();
        }, 800);
      })
      .catch((e) => {
        this.setState({ progress: false });
        console.log(e);
      });
  };

  render() {
    const { file } = this.props;
    const { open } = this.state;

    return (
      <div>
        <button onClick={this.handleClickOpen} style={{ marginLeft: "10px" }}>
          Διαγραφή {file.file.split("/").pop()}
        </button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Διαγραφή αρχείου;"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Είστε σίγουροι ότι θέλετε να διαγράψετε το αρχείο{" "}
              <b>{file.file.split("/").pop()}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.state.progress ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={() => this.handleDeleteFile(file.id)}
                variant="contained"
                color="primary"
              >
                Ναι
              </Button>
            )}

            <Button variant="contained" onClick={this.handleClose}>
              Οχι
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

class ViolationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stadiaParabasis: [],
      eidosParabasis: [],
      eggrafaParabasis: [],
      attestations: [],
      violationStages: [],
      violationDescriptions: [],
      penalties: [],
      violationData: {},
      violation_date: "",
      violation_protocol_number: "",
      document_protocol_number: "",
      alternate_document_protocol_number: "",
      stadioParabasis: "",
      eidos: "",
      attestation_authority: "",
      violation_status: "",
      violation_description: "",
      penalty: "",
      files: [],
      selectedFiles: [],
      isDialogOpen: false,
      alertMsg: "",
      alertSeverity: "success",
      alertShow: false,
      parabasiMappingList: [],
      stadiaParabasisPerEidos: [],
      stadiaParabasisUpdated: false,
    };
  }

  openDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  handleAutocompleteChange = (event, value) => {
    this.setState({ violation_description: value || { id: null, name: "" } });
  };

  handleFileChange = (event) => {
    const selected_files = Array.from(event.target.files);
    this.setState({ selectedFiles: selected_files });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const pathname = window.location.pathname;
    const businessId = pathname.split("/")[2];
    const violationId = pathname.split("/")[4];

    const userId = localStorage.getItem("userselectedd");
    console.log(userId);

    if (this.props.mode === "update") {
      const formData = new FormData();
      // if ((this.state.selectedFile) && (this.state.selectedFile != this.state.file)) {
      //   formData.append("file", this.state.selectedFile);
      // }
      console.log(this.state.attestation_authority, "attestation EDW");

      formData.append("business", this.props.violationData.business);
      formData.append(
        "violation_protocol_number",
        this.state.violation_protocol_number
      );
      formData.append(
        "document_protocol_number",
        this.state.document_protocol_number
      );
      formData.append(
        "alternate_document_protocol_number",
        this.state.alternate_document_protocol_number
      );
      formData.append("violation_date", this.state.violation_date);
      formData.append("stadio", this.state.stadioParabasis || "");
      formData.append("eidos", this.state.eidos || "");
      formData.append(
        "attestation_authority",
        this.state.attestation_authority || ""
      );
      formData.append(
        "penalty",
        this.state.penalty != null ? this.state.penalty : ""
      );
      formData.append(
        "violation_description",
        this.state.violation_description || ""
      );
      session
        .put(`api/business-parabaseis/${violationId}/update/`, formData)
        .then((resp) => {
          if (this.state.selectedFiles.length > 0) {
            this.createFiles(this.state.selectedFiles, resp.data.id);
          } else {
            // this.props.handleViolationCreate();
            this.handleAlert("success", "Η παράβαση ανανεώθηκε");
          }
          // window.location.href = `/business-details/${businessId}`
        })
        .catch((e) => {
          console.log(e);
          this.handleAlert(
            "error",
            "Πηγε κάτι στραβά, παρακαλώ επικοινωνήστε με τους διαχειριστές"
          );
          return;
        });
    } else {
      const formData = new FormData();

      // if ((this.state.selectedFile) && (this.state.selectedFile != this.state.file)) {
      //   formData.append("file", this.state.selectedFile);
      // }
      formData.append(
        "violation_protocol_number",
        this.state.violation_protocol_number
      );
      formData.append(
        "document_protocol_number",
        this.state.document_protocol_number
      );
      formData.append(
        "alternate_document_protocol_number",
        this.state.alternate_document_protocol_number
      );
      formData.append("violation_date", this.state.violation_date);
      formData.append("stadio", this.state.stadioParabasis);
      formData.append("eidos", this.state.eidos);
      formData.append(
        "attestation_authority",
        this.state.attestation_authority
      );
      formData.append("penalty", this.state.penalty);
      formData.append(
        "violation_description",
        this.state.violation_description
      );
      formData.append("business", businessId);
      formData.append("created_by", userId);

      // this.props.handleViolationCreate();
      session
        .post(`api/business-parabaseis/${businessId}/create/`, formData)
        .then((resp) => {
          console.log(resp.data);

          if (this.state.selectedFiles.length > 0) {
            this.createFiles(this.state.selectedFiles, resp.data.id);
            // console.log("exei files", this.state.selectedFiles);
          } else {
            this.handleAlert("success", "Η παράβαση δημιουργήθηκε");
            this.props.handleViolationCreate();
          }
        })
        .catch((e) => {
          console.log(e);
          this.handleAlert(
            "error",
            "Πηγε κάτι στραβά, παρακαλώ επικοινωνήστε με τους διαχειριστές"
          );
        });
    }
  };

  createFiles = (selectedFiles, businessViolationId) => {
    const FileFormData = new FormData();
    selectedFiles.forEach((file) => {
      FileFormData.append("file", file);
    });
    FileFormData.append("business_violation", businessViolationId);
    session
      .post(`api/violation/files/create/`, FileFormData)
      .then((response) => {
        console.log(response.data);
        this.handleAlert("success", "Η παράβαση ανανεώθηκε");
        this.setState({ selectedFiles: [] });
        this.props.handleViolationCreate();
      })
      .catch((e) => {
        this.handleAlert("error", "Ουπς! Κατι πήγε στραβά");
        console.log(e);
        return;
      });
  };

  handleAlert = (severity, msg) => {
    this.setState({
      alertShow: true,
      alertMsg: msg,
      alertSeverity: severity,
    });
    setTimeout(() => {
      this.setState({
        alertShow: false,
      });
    }, 2000);
  };

  handleDeleteFile = (fileId) => {
    session
      .delete(`api/violation-file/${fileId}/delete/`)
      .then(() => {
        setTimeout(() => {
          this.props.handleViolationUpdateFiles();
        }, 800);
      })
      .catch((e) => {
        console.log(e);
      });

    // this.setState((prevState) => ({
    //   files: prevState.files.filter((file) => file.id !== fileId),
    // }));
  };

  handleChangeEidosParabasis = (e) => {
    this.setState({ eidos: e.target.value });
    const matchingObject = this.props.parabasiMapping.find(
      (item) => item.eidos_parabasis === e.target.value
    );
    const stadia = matchingObject ? matchingObject.stadio_parabasis : [];
    this.setState({ stadiaParabasisPerEidos: stadia });
  };

  // handleChangeEidosParabasis = (e) => {
  //   this.setState({ eidos: e.target.value }, () => {
  //     // Now that eidos has been updated, you can access its value
  //     const matchingObject = this.props.parabasiMapping.find(
  //       (item) => item.eidos_parabasis === this.state.eidos
  //     );
  //     const stadia = matchingObject ? matchingObject.stadio_parabasis : [];
  //     this.setState({ stadiaParabasisPerEidos: stadia });
  //   });
  // };

  componentDidUpdate(prevProps) {
    if (this.props.stadiaParabasis !== prevProps.stadiaParabasis) {
      this.setState({ stadiaParabasis: this.props.stadiaParabasis });
    }

    if (this.props.eidosParabasis !== prevProps.eidosParabasis) {
      this.setState({ eidosParabasis: this.props.eidosParabasis });
    }

    if (this.props.eggrafaParabasis !== prevProps.eggrafaParabasis) {
      this.setState({ eggrafaParabasis: this.props.eggrafaParabasis });
    }
    if (this.props.attestations !== prevProps.attestations) {
      this.setState({ attestations: this.props.attestations });
    }

    if (this.props.violationStages !== prevProps.violationStages) {
      this.setState({ violationStages: this.props.violationStages });
    }

    if (this.props.violationDescriptions !== prevProps.violationDescriptions) {
      this.setState({
        violationDescriptions: this.props.violationDescriptions,
      });
    }

    if (this.props.penalties !== prevProps.penalties) {
      this.setState({ penalties: this.props.penalties });
    }

    if (this.props.mode === "update") {
      if (this.state.stadiaParabasisPerEidos.length == 0) {
        if (!this.state.stadiaParabasisUpdated) {
          const matchingObject = this.props.parabasiMapping.find(
            (item) => item.eidos_parabasis === this.props.violationData.eidos
          );

          const stadia = matchingObject ? matchingObject.stadio_parabasis : [];

          if (stadia.length > 0) {
            this.setState({
              stadiaParabasisUpdated: true,
              stadiaParabasisPerEidos: stadia,
            });
          }
          // ;
          // this.setState({ stadiaParabasisPerEidos: stadia });
        }
      }
      if (this.props.violationData !== prevProps.violationData) {
        this.setState({
          violationData: this.props.violationData,
          violation_date: this.props.violationData.violation_date,
          violation_protocol_number: this.props.violationData
            .violation_protocol_number,
          alternate_document_protocol_number: this.props.violationData
            .alternate_document_protocol_number,
          document_protocol_number: this.props.violationData
            .document_protocol_number,
          stadioParabasis: this.props.violationData.stadio,
          eidos: this.props.violationData.eidos,
          attestation_authority: this.props.violationData.attestation_authority,
          violation_status: this.props.violationData.violation_status,
          penalty: this.props.violationData.penalty,
          // file: this.props.violationData.file || '',
          // selectedFile: this.props.violationData.file || '',
          violation_description: this.props.violationData.violation_description,
        });
      }

      // this.setState({ stadiaParabasisPerEidos: stadia });

      if (this.props.files != prevProps.files) {
        this.setState({
          files: this.props.files,
          // selectedFiles: this.props.files,
        });
      }
    }
  }

  render() {
    return (
      <Box p={4} m={2} bgcolor="white">
        <Card raised>
          <CardContent>
            {/* <form onSubmit={this.handleSubmit}> */}
            {/* {this.state.violation_description.id} */}
            {this.state.alertShow ? (
              <Alert severity={this.state.alertSeverity}>
                {this.state.alertMsg}
              </Alert>
            ) : null}

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom align="center">
                  {this.props.mode === "update" ? "Ενημέρωση" : "Δημιουργία"}{" "}
                  Παράβασης
                  {/* {this.props.violationData.violation_protocol_number} */}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="date"
                  label="Ημερομηνία"
                  type="date"
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  variant="standard"
                  fullWidth
                  value={this.state.violation_date}
                  onChange={(event) =>
                    this.setState({ violation_date: event.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="arparabasis"
                  label="Αρ. πρωτ παράβασης"
                  margin="normal"
                  size="small"
                  variant="standard"
                  fullWidth
                  value={this.state.violation_protocol_number}
                  onChange={(event) =>
                    this.setState({
                      violation_protocol_number: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="areggrafoy"
                  label="Αρ. πρωτ εγγράφου"
                  margin="normal"
                  size="small"
                  variant="standard"
                  fullWidth
                  value={this.state.document_protocol_number}
                  onChange={(event) =>
                    this.setState({
                      document_protocol_number: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Δεύτερο έγγραφο"
                  margin="normal"
                  size="small"
                  variant="standard"
                  fullWidth
                  value={this.state.alternate_document_protocol_number}
                  onChange={(event) =>
                    this.setState({
                      alternate_document_protocol_number: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  margin="normal"
                  size="small"
                  variant="standard"
                >
                  <InputLabel>Είδος παράβασης</InputLabel>
                  <Select
                    value={this.state.eidos}
                    onChange={(e) => this.handleChangeEidosParabasis(e)}
                    // onChange={(event) =>
                    //   this.setState({ eidos: event.target.value })
                    // }
                  >
                    <MenuItem value="">--------------</MenuItem>

                    {this.state.eidosParabasis.map((e) => {
                      return (
                        <MenuItem value={e.id} key={e.id}>
                          {e.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  margin="normal"
                  size="small"
                  variant="standard"
                >
                  <InputLabel>Στάδιο Παράβασης</InputLabel>
                  <Select
                    value={this.state.stadioParabasis}
                    onChange={(event) =>
                      this.setState({ stadioParabasis: event.target.value })
                    }
                  >
                    <MenuItem value="">--------------</MenuItem>
                    {this.state.stadiaParabasisPerEidos.map((e) => {
                      return (
                        <MenuItem value={e.id} key={e.id}>
                          {e.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  margin="normal"
                  size="small"
                  variant="standard"
                >
                  <InputLabel>Αρχή βεβαίωσης Παράβασης</InputLabel>
                  <Select
                    value={this.state.attestation_authority}
                    onChange={(event) =>
                      this.setState({
                        attestation_authority: event.target.value,
                      })
                    }
                  >
                    <MenuItem value="">--------------</MenuItem>
                    {this.state.attestations.map((e) => {
                      return (
                        <MenuItem value={e.id} key={e.id}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  size="small"
                  variant="standard"
                >
                  <InputLabel>Ποινή</InputLabel>
                  <Select
                    value={this.state.penalty}
                    onChange={(event) =>
                      this.setState({ penalty: event.target.value })
                    }
                  >
                    <MenuItem value="">--------------</MenuItem>
                    {this.state.penalties.map((e) => {
                      return (
                        <MenuItem value={e.id} key={e.id}>
                          {e.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  size="small"
                  variant="standard"
                >
                  <InputLabel>Περιγραφή παράβασης</InputLabel>
                  <Select
                    value={this.state.violation_description}
                    onChange={(event) =>
                      this.setState({
                        violation_description: event.target.value,
                      })
                    }
                  >
                    <MenuItem value="">--------------</MenuItem>
                    {this.state.violationDescriptions.map((e) => {
                      return (
                        <MenuItem value={e.id} key={e.id}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={7}>
                  <Autocomplete
                    value={this.state.violation_description}
                    options={this.state.violationDescriptions || { id: null, name: "" }}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, v) => this.handleAutocompleteChange(e, v)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="autocomplete"
                        label="Περιγραφή παράβασης"
                        margin="normal"
                        size="small"
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                </Grid> */}
              <Grid item xs={7} sm={7}>
                <FormControl fullWidth margin="normal">
                  <p>Λοιπή αλληλογραφία</p>
                  <Input
                    id="file-input"
                    type="file"
                    //   multiple
                    inputProps={{ multiple: true }}
                    onChange={this.handleFileChange}
                  />
                </FormControl>
                {this.state.files.length > 0 && (
                  <ul>
                    <p>Επιλεγμένα αρχεία</p>
                    {this.state.files.map((file) => (
                      <li key={file.id}>
                        <a
                          href={file.file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {file.file.split("/").pop()}
                        </a>
                        <AlertDialogComponent
                          openDialog={this.openDialog}
                          file={file}
                          SuccessDeletedFile={() =>
                            this.props.handleViolationUpdateFiles()
                          }
                        />
                        {/* <button
                          onClick={() => this.handleDeleteFile(file.id)}
                       
                          style={{ marginLeft: "10px" }}
                        >
                          Διαγραφή {file.file.split("/").pop()}
                        </button> */}
                      </li>
                    ))}
                  </ul>
                )}
              </Grid>
              {/* <Grid item xs={12} sm={10}>
                  <FormControl fullWidth margin="normal">
                    <p>Λοιπή αλληλογραφία</p>
                    <Input
                      id="file-input"
                      type="file"
                      // multiple
                      onChange={this.handleFileChange}
                    />
                  </FormControl>
                  {this.state.file ? (
                    <p>Επιλεγμένο αρχείο: <a href={this.state.file} target="_blank"
                    >{this.state.file.split("/").pop()}</a></p>
                  ) : null}
                </Grid> */}
              <Grid>
                {/* <a href={this.state.selectedFile} target="_blank">{this.state.selectedFile.split('/')[-1]}</a> */}
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={this.handleSubmit}
            >
              Αποθηκευση
            </Button>
            {/* </form> */}
          </CardContent>
        </Card>
      </Box>
    );
  }
}

class BusinessViolationDetails extends Component {
  constructor(props) {
    super(props);
    this.handleViolationCreate = this.handleViolationCreate.bind(this);
    this.state = {
      isUpdate: props.match.path.includes("update"),
      stadiaParabasis: [],
      eidosParabasis: [],
      eggrafaParabasis: [],
      attestations: [],
      violationStages: [],
      violationDescriptions: [],
      penalties: [],
      detailData: {},
      violationData: {},
      files: [],
      parabasiMappingList: [],
    };
  }
  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }

    // console.log(this.state.isUpdate);
    // console.log(this.props.match.params.bussinessId);

    this.fetchViolationUpdates();

    if (this.state.isUpdate) {
      const violationId = this.props.match.params.violationId;

      session
        .get(`api/business-parabaseis/${violationId}/update/`)
        .then((resp) => {
          this.setState({
            violationData: resp.data,
          });
        })
        .catch((e) => {
          console.log(e);
          return;
        });
      session
        .get(`api/violation/${violationId}/files/`)
        .then((resp) => {
          this.setState({
            files: resp.data,
          });
        })
        .catch((e) => {
          console.log(e);
          return;
        });
    }
  }
  handleViolationCreate() {
    session
      .get(`api/violation/${this.props.match.params.violationId}/files/`)
      .then((resp) => {
        this.setState({
          files: resp.data,
        });
      })
      .catch((e) => {
        console.log(e);
        return;
      });

    this.props.history.push({
      pathname: `/business-details/${this.props.match.params.bussinessId}`,

      state: { businessId: this.props.match.params.bussinessId, newValue: "3" },
    });
  }

  handleViolationUpdateFiles() {
    session
      .get(`api/violation/${this.props.match.params.violationId}/files/`)
      .then((resp) => {
        this.setState({
          files: resp.data,
        });
      })
      .catch((e) => {
        console.log(e);
        return;
      });
  }

  async fetchViolationUpdates() {
    const urls = [
      "api/stadio-parabasis/all/",
      "api/eidos-parabasis/all/",
      "api/egrafa-parabasis/",
      "api/violation-attestations/",
      "api/bussiness-violations-statuses/",
      "api/violation-descriptions/",
      "api/penalty/list/",
      `api/Epixeiriseis/${this.props.match.params.bussinessId}/`,
      "api/parabasi-mapping/",
    ];
    try {
      const [
        response1,
        response2,
        response3,
        response4,
        response5,
        response6,
        response7,
        response8,
        response9,
      ] = await Promise.all(urls.map((url) => session.get(url)));
      this.setState({
        stadiaParabasis: response1.data,
        eidosParabasis: response2.data,
        eggrafaParabasis: response3.data,
        attestations: response4.data,
        violationStages: response5.data,
        violationDescriptions: response6.data,
        penalties: response7.data,
        detailData: response8.data,
        parabasiMappingList: response9.data,
      });
    } catch (error) {
      console.log(error);
      return;
    }
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax small filter bgImage={require("assets/img/profile-bg2.jpg")}>
          <div style={{ height: "200px" }} />
        </Parallax>
        <Box
          sx={{
            width: "fit-content",
            borderBottom: 1,
            borderColor: "divider",
            mx: "auto",
            textAlign: "center",
          }}
        >
          <h3>Παραβάσεις επιχείρησης - {this.state.detailData.nameE}</h3>
        </Box>
        {this.state.isUpdate ? (
          <ViolationForm
            stadiaParabasis={this.state.stadiaParabasis}
            eidosParabasis={this.state.eidosParabasis}
            eggrafaParabasis={this.state.eggrafaParabasis}
            attestations={this.state.attestations}
            violationStages={this.state.violationStages}
            violationDescriptions={this.state.violationDescriptions}
            penalties={this.state.penalties}
            violationData={this.state.violationData}
            files={this.state.files}
            handleViolationUpdateFiles={(e) =>
              this.handleViolationUpdateFiles(e)
            }
            parabasiMapping={this.state.parabasiMappingList}
            handleViolationCreate={(e) => this.handleViolationCreate(e)}
            mode="update"
          />
        ) : (
          <ViolationForm
            stadiaParabasis={this.state.stadiaParabasis}
            eidosParabasis={this.state.eidosParabasis}
            eggrafaParabasis={this.state.eggrafaParabasis}
            attestations={this.state.attestations}
            violationStages={this.state.violationStages}
            violationDescriptions={this.state.violationDescriptions}
            penalties={this.state.penalties}
            parabasiMapping={this.state.parabasiMappingList}
            mode="create"
            handleViolationCreate={(e) => this.handleViolationCreate(e)}
          />
        )}
      </div>
    );
  }
}
export default BusinessViolationDetails;

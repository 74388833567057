import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  useMapEvents,
  FeatureGroup,
  Tooltip,
  // Circle,
} from "react-leaflet";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";
import classNames from "classnames";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import "leaflet/dist/leaflet.css";
// import { isConstructorDeclaration } from "typescript";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CustomInput from "components/CustomInput/CustomInput.js";
// import Date from "react-datetime";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import Close from "@material-ui/icons/Close";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import badgeStyle from "assets/jss/material-kit-react/components/badgeStyle.js";
import Badge from "components/Badge/Badge.js";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import MultiSelect from "react-multi-select-component";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// core components

import modalStyle from "assets/jss/material-kit-react/modalStyle.js";

import {
  CardFooter,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Progress,
  // Table,
  Container,
  Row,
  Input,
  Nav,
  TabPane,
  TabContent,
  // Button,
  NavLink,
  NavItem,
  Col,
  Modal,
  ModalHeader,
  // Button,
  ModalBody,
  // UncontrolledTooltip,
} from "reactstrap";
import Button from "components/CustomButtons/Button.js";
import { number } from "prop-types";
import ReactTable from "components/ReactTable/ReactTable.js";
import ReactExport from "react-data-export";
import Geocode from "react-geocode";
import lastDayOfISOWeek from "date-fns/fp/lastDayOfISOWeek/index";
import { TrendingFlatOutlined } from "@material-ui/icons";
import session from '../api/session'
Geocode.setApiKey("AIzaSyBSF6RaFqZGzFJgPZv1drw7PEjYZ8qZd_0");
Geocode.setLanguage("el");
Geocode.setRegion("gr");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
let UdataTable = [];
let UdataTableL = [];
let UdataTableP = [];
let UdataTablePP = [];
let UdataTablePX = [];
let UdataTableE = [];
let UdataTablePids = [];
async function setDoc(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/docuploadPagkos";
  console.log(item);

  let formData = new FormData();
  formData.append("pagkos", item.pagkos);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  console.log(formData);

  // let response = await axios.post(url, formData);
  let response= await axios.post("api/docuploadPagkos",formData)

  // let response = await axios.post(url, formData);
  console.log(response);
}

function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Ελ. (${min})`}
        style={{
          width: "100px",
          marginRight: "0.5rem",
          borderRadius: "15px",
        }}
      />
      με
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Μεγ. (${max})`}
        style={{
          width: "100px",
          marginLeft: "0.5rem",
          borderRadius: "15px",
        }}
      />
    </div>
  );
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      style={{ borderRadius: "15px" }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Όλα</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

function filterLessThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue <= filterValue;
  });
}

async function setPostDoc(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/docuploadPost";
  console.log(item);

  let formData = new FormData();
  formData.append("postId", item.postId);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/docuploadPost",formData)
  

  // let response = await axios.post(url, formData);
  console.log(response);
}

async function setDocE(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/docuploadEpixirisi";
  console.log(item);

  let formData = new FormData();
  formData.append("epixeirisi", item.epixeirisi);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/docuploadEpixirisi",formData)
  // let response = await axios.post(url, formData);
  console.log(response);
}

async function setlogo(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/logoupload";

  // console.log(formData);
  console.log(item);
  console.log(item.logo);
  console.log(item.nameEC);

  let formData = new FormData();

  formData.append("logo", item.logo);
  formData.append("nameEC", item.nameEC);
  formData.append("iconEC", "-");
  console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/logoupload",formData)

  // let response = await axios.post(url, formData);
  console.log(response);
  // console.log(response.data.logo);
  // var logoname = response.data.logo;
  // window.location.reload();
}
// import leaflet from 'https://unpkg.com/leaflet@1.5.1/dist/leaflet.css';

// styles = {
//   label: {
//     cursor: "pointer",
//     paddingLeft: "0",
//     color: "rgba(0, 0, 0, 0.26)",
//     fontSize: "14px",
//     lineHeight: "1.428571429",
//     fontWeight: "400",
//     display: "inline-flex",
//   },
// };

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let posseleted = [];
let adressel = "";
let newE = false;
let editE = false;
let center = [39.365, 22.951];
let zoom = 14;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function MyComponent() {
//   const map = useMapEvents({
//     click: () => {
//       map.locate();
//     },
//     locationfound: (location) => {
//       console.log("location found:", location);
//     },
//   });
//   return null;
// }

function LocationMarker() {
  const [position, setPosition] = useState(null);
  // const newItem = useState([]);

  const map = useMapEvents({
    click(e) {
      var coord = e.latlng;
      var lat = coord.lat;
      var lng = coord.lng;
      // posseleted = lat;
      setPosition(e.latlng);
      var tmpPos = [];
      tmpPos.push(lat);
      tmpPos.push(lng);
      posseleted.push(tmpPos);
      console.log(
        "You clicked the map at latitude: " + lat + " and longitude: " + lng
      );
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);
          adressel = address;
        },
        (error) => {
          console.error(error);
        }
      );

      // map.locate();
    },
    // locationfound(e) {
    //   setPosition(e.latlng);
    //   map.flyTo(e.latlng, map.getZoom());
    // },
  });

  return position === null ? null : (
    <Marker position={position}>
      {" "}
      <Tooltip>{adressel}</Tooltip>{" "}
    </Marker>
  );
}

function LocationMarkerLaiki() {
  const [position, setPosition] = useState(null);
  // const newItem = useState([]);

  const map = useMapEvents({
    click(e) {
      var coord = e.latlng;
      var lat = coord.lat;
      var lng = coord.lng;
      // posseleted = lat;
      setPosition(e.latlng);
      var tmpPos = [];
      tmpPos.push(lat);
      tmpPos.push(lng);
      posseleted.push(tmpPos);
      console.log(
        "You clicked the map at latitude: " + lat + " and longitude: " + lng
      );

      // map.locate();
    },
    // locationfound(e) {
    //   setPosition(e.latlng);
    //   map.flyTo(e.latlng, map.getZoom());
    // },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
}

// function handleChange(e) {
//   console.log(e.target);
//   console.log(e.target.value);
//   console.log(e.target.id);

// const { name, value } = e.target;
// this.state.newItem = { ...this.state.newItem, [name]:  value };

// console.log(this.state.newItem);
// }

export default function SuperuserAdmin() {
  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  let newItem = React.useState();
  let newItemC = React.useState();

  React.useEffect(() => {
    console.log("e");
    console.log(editE);
  }, [editE]);

  React.useEffect(() => {
    // console.log("e");
    // console.log(editE);
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
      <br />
       <br />
      <br />
      <br />
      <br />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <MapPanel></MapPanel>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

class MapPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      // useTheme: useTheme(),
      PagkoiList: [],
      PagkoiXrewsiList: [],
      LaikesList: [],
      docsList: [],
      LaikesSelectList: [],
      markers: [[0, 0]],
      activeItem: {},
      activeItemXrewsi: {},
      activeItemLaiki: {},
      modalP: false,
      modalPX: false,
      modalL: false,
      laikiselected: "-",
      pagkosselected: "",
      laikiselectedcenter: "",
      EpixeiriseisList: [],
      EpixeiriseisCategoryList: [],
      activeItemEC: {},
      activeItemE: {},
      modalE: false,
      modalEC: false,
      arxdocitem: {},
      selectedDocs: [],
      arxdocitemE: {},
      selectedDocsE: [],
      ParousiaList: [],
      ParousiesList: [],
      dateprint: [],
      datefrom: "",
      dateto: "",
      searchItem: {
        all: true,
        isAnastoli: false,
        exeiplirwsei: false,
      },
    };
  }
  handleCloseX = () => {
    this.state.activeItemXrewsi = [{}];
    this.setState({ activeItemXrewsi: [{}] });

    this.setState({ modalPX: false });
    this.setState({ state: this.state });
  };

  handleChangeX = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemXrewsi = { ...this.state.activeItemXrewsi, [name]: value };

    this.setState({ activeItemXrewsi });

    this.setState({ state: this.state });
  };

  handleChangeFrom = (e) => {
    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;
    this.state.datefrom = fulldate;

    console.log(this.state.datefrom);
    this.setState({ state: this.state });
  };

  handleChangeTo = (e) => {
    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;
    this.state.dateto = fulldate;

    this.setState({ state: this.state });
  };

  handledateclear = () => {
    this.state.datefrom = "";
    this.state.dateto = "";

    this.setState({ state: this.state });
  };

  handleChangeEnarxiXrewsi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItemXrewsi.enarxiP = fulldate;

    console.log(this.state.activeItemXrewsi);
    // this.setState({ activeItem });
  };

  handleChangeLixiXrewsi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItemXrewsi.lixiP = fulldate;

    console.log(this.state.activeItemXrewsi);

    this.setState({ state: this.state });
    // this.setState({ activeItem });
  };

  handleChangePagkosBoolsXrewsi = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    const activeItemXrewsi = { ...this.state.activeItemXrewsi, [name]: value };

    console.log(activeItemXrewsi);
    this.setState({ activeItemXrewsi });
  };

  handlePostXrewsi = () => {
    const newItem = this.state.activeItemXrewsi;

    if (newItem.tetragwnika != "" && newItem.timimonadas != "") {
      if (newItem.kathgoria === "Επαγγελματίες") {
        newItem.poso =
          parseInt(newItem.tetragwnika) * parseInt(newItem.timimonadas);
      } else {
        const parousies = this.state.ParousiesList.filter(
          (itemP) => itemP.pagkos === newItem.pagkos && itemP.parousia == true
        );

        let num = 0;

        var dateE = new Date(newItem.enarxiP);
        var dateL = new Date(newItem.lixiP);

        for (var i = 0; i < parousies.length; i++) {
          var date = new Date(parousies[i].date);

          console.log(parousies[i]);

          if (date >= dateE && date <= dateL) {
            num = num + 1;
          }
        }
        newItem.poso =
          parseInt(newItem.tetragwnika) *
          parseInt(newItem.timimonadas) *
          parseInt(num);
      }

      if (newItem.id) {
        // formData.append("id", newItem.id);
        // axios
        //   .put(
        //     `https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/${newItem.id}/`,
        //     newItem
        //   )
        session.put(`api/PagkoiXrewsi/${newItem.id}/`,newItem)
          .then(this.refreshList())
          .catch((error) => {
            alert(error.response.data);
          });
      } else {
        // axios
        //   .post("https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/", newItem)
        session.post("api/PagkoiXrewsi/",newItem)
          .then(this.refreshList());
      }

      // this.state.activeItemXrewsi = [];
      this.refreshList();
      this.handleCloseX();
      this.setState({ state: this.state });
    } else {
      alert("προσθέστε τα απαραίτητα στοιχεία");
    }
  };
  handleEditXrewsi = (item) => {
    this.state.activeItemXrewsi = item;

    console.log(this.state.activeItemXrewsi);
    this.setState({ modalPX: true });
  };

  handleNewXrewsi = () => {
    this.state.activeItemXrewsi = {};

    this.state.activeItemXrewsi.id = "";
    this.state.activeItemXrewsi.poso = "";
    this.state.activeItemXrewsi.plirwse = false;

    this.state.activeItemXrewsi.tetragwnika = this.state.activeItem.tetragwnika;
    this.state.activeItemXrewsi.timimonadas = this.state.activeItem.timimonadas;
    this.state.activeItemXrewsi.kathgoria = this.state.activeItem.kathgoria;
    this.state.activeItemXrewsi.pagkos = this.state.activeItem.nameP;

    console.log(this.state.activeItemXrewsi);
    this.setState({ modalPX: true });
  };

  //ΚΛΕΙΝΕΙ MODAL
  handleClose = () => {
    this.state.activeItem = [];
    this.setState({ activeItem: [] });
    this.setState({ modalP: false });
  };

  handleCloseX = () => {
    this.state.activeItemXrewsi = [{}];
    this.setState({ activeItemXrewsi: [{}] });

    this.setState({ modalPX: false });
    this.setState({ state: this.state });
  };
  handleCloseE = () => {
    this.state.activeItemE = [];
    this.setState({ activeItemE: [] });
    this.setState({ modalE: false });
  };

  handleCloseEC = () => {
    this.state.activeItemEC = [];
    this.setState({ activeItemEC: [] });
    this.setState({ modalEC: false });
  };

  handleCloseLaiki = () => {
    this.state.activeItemLaiki = [];
    this.setState({ activeItemLaiki: [] });
    this.setState({ modalL: false });
  };
  handleChangeEC = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemEC = { ...this.state.activeItemEC, [name]: value };
    // formData.append([name], value);
    console.log("ok");
    this.setState({ activeItemEC });
  };
  ClearLaikiSelect() {
    this.state.laikiselected = "-";
    this.state.laikiselectedcenter = center;
    this.setState({ state: this.state });
    this.refreshList();
  }

  handleLaikiSelect(e, latlng) {
    const value = e.target.value;

    console.log(value);

    this.state.laikiselected = value;
    this.state.laikiselectedcenter = latlng;

    const { map } = this.state;
    if (map) map.flyTo(latlng, 18);

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  handleChangeSelectIcon = (e) => {
    const name = "iconeE";
    const value = e.target.value;
    const activeItemE = { ...this.state.activeItemE, [name]: value };

    console.log(activeItemE);
    this.setState({ activeItemE });

    this.setState({ state: this.state });
  };
  handleChangeLogo = (e) => {
    console.log("ok");
    var logo = e.target.files[0];
    // formData.append("logo", logo);

    this.state.activeItemEC.logo = e.target.files[0];
  };

  handleMultiSelectChange = (e) => {
    const name = "laikiAgoraId";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleMultiSelectChangeΚ = (e) => {
    const name = "kathgoria";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleChangeEp = (e) => {
    console.log(e);
    console.log(e.target.id);
    console.log(e.target.name);
    console.log(e.target.value);

    const name = e.target.id;
    const value = e.target.value;
    const activeItemE = { ...this.state.activeItemE, [name]: value };

    // console.log(activeItemE);
    this.setState({ activeItemE });
  };

  handleChangeEnarxiE = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItemE.enarxiE = fulldate;

    console.log(this.state.activeItemE);
    this.setState({ state: this.state });
    // this.setState({ activeItemE });
  };

  handleChangeLixiE = (e) => {
    // console.log(e);

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    this.state.activeItemE.lixiE = fulldate;

    this.setState({ state: this.state });
  };

  handleChange = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleChangeArxDoc = (e) => {
    var files = e.target.files[0];
    console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // console.log(name)
    // const arxdocitem = { ...this.state.arxdocitem, [name]: files };

    // this.setState({ arxdocitem });

    this.state.arxdocitem.file_field = e.target.files[0];
    this.state.arxdocitem.pagkos = this.state.activeItem.anagnwristikoP;
    this.state.arxdocitem.description = fname[0];
  };

  handleChangePagkosBools = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });
  };

  handleChangePagkosBoolsPrint = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    let searchItem = { ...this.state.searchItem, [name]: value };

    if (name == "all" && value == true) {
      searchItem.isAnastoli = false;
      searchItem.exeiplirwsei = false;
    }

    console.log(searchItem);

    this.setState({ searchItem });

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  };

  handleChangeSelect = (e) => {
    const name = "categoryP";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleChangeLaikiMeres = (e) => {
    console.log(e);
    console.log(e.target.id);
    console.log(e.target.checked);

    const name = e.target.id;
    const value = e.target.checked;

    const activeItemLaiki = { ...this.state.activeItemLaiki, [name]: value };

    console.log(activeItemLaiki);
    this.setState({ activeItemLaiki });
  };

  handleChangeLaiki = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemLaiki = { ...this.state.activeItemLaiki, [name]: value };

    console.log(activeItemLaiki);
    this.setState({ activeItemLaiki });

    this.setState({ state: this.state });
  };

  checkaddress = () => {
    let address = this.state.activeItemE.dieuthinsi;

    let latS = "";
    let lngS = "";

    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        latS = lat;
        lngS = lng;
        console.log(lat, lng);
        var tmpPos = [];
        tmpPos.push(latS);
        tmpPos.push(lngS);
        posseleted.push(tmpPos);

        if (this.state.mapModal) {
          this.state.mapModal.flyTo(tmpPos, 18);
          var marker = L.marker([latS, lngS]);

          this.state.mapModal.addLayer(marker);
        }

        // if (this.state.mapModal != null) {
        //   var marker = L.marker([latS, lngS]);
        //   var markers = L.markerClusterGroup();
        //   markers.addLayer(marker);
        //   this.state.mapModal.addLayer(markers);

        //   const { map } = this.state;
        //   if (map) map.flyTo(latlng, 18);

        // }
      },
      (error) => {
        console.error(error);
      }
    );

    // Geocode.fromAddress("Eiffel Tower").then(
    //   (response) => {
    //     const { lat, lng } = response.results[0].geometry.location;
    //     console.log(lat, lng);
    //   },
    //   (error) => {
    //     console.error(error);
    //   }
    // );
  };

  handlePostEp = () => {
    // console.log(newItem);
    // console.log(posseleted);
    const newItem = this.state.activeItemE;

    let lenghtps = posseleted.length;

    if (lenghtps != 0) {
      newItem.coordsE = posseleted[lenghtps - 1];
    }

    if (adressel != "") {
      newItem.dieuthinsi = adressel;
    }
    newItem.centerE = newItem.coordsE;

    // newItem.enarxiE = "2021-04-21";
    // newItem.lixiE = "2021-04-26";
    // newItem.iconeE = "test";

    if (newItem.tetragwnika != "" && newItem.timimonadas != "") {
      newItem.poso =
        parseInt(newItem.tetragwnika) * parseInt(newItem.timimonadas);
    }

    console.log(newItem);
    // console.log(posseleted);

    if (newItem.centerE[0] == 0) {
      alert("Παρακαλούμε επιλέξτε σημείο στον χάρτη");
    } else {
      if (newItem.id) {
        // axios
        //   .put(
        //     `https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/${newItem.id}/`,
        //     newItem
        //   )
        session.put(`api/Epixeiriseis/${newItem.id}/`,newItem)
          .then(this.refreshList());
        if (this.state.arxdocitemE.file_field != undefined) {
          setDocE(this.state.arxdocitemE);
          this.refreshListDocs();
        }
      } else {
        // axios
        //   .post("https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/", newItem)
        session.post("api/Epixeiriseis/",newItem)
          .then(this.refreshList());
        if (this.state.arxdocitemE.file_field != undefined) {
          setDocE(this.state.arxdocitemE);
          this.refreshListDocs();
        }
      }

      this.state.activeItemE = [];
      this.refreshList();
      this.handleCloseE();
      document.getElementById("file_field").value = null;
    }
    // newE = true;
  };

  handleNewE = () => {
    // console.log(item);
    this.state.activeItemE.userIdE = "";
    this.state.activeItemE.nameE = "";
    this.state.activeItemE.afmE = "";

    this.state.activeItemE.arfak = "";
    this.state.activeItemE.antikatastasi = false;
    this.state.activeItemE.paratiriseis = "";
    this.state.activeItemE.dieuthinsi = "";

    this.state.activeItemE.siteE = "";
    this.state.activeItemE.posoE = "";
    this.state.activeItemE.covidStatus = "";
    this.state.activeItemE.wrarioE = "";
    this.state.activeItemE.wrarioL = "";
    this.state.activeItemE.iconeE = "";

    // this.state.activeItemE = item;
    this.setState({ modalE: true });
    // console.log(this.state.modalE);
    console.log(this.state.activeItemE);
    // this.render();
  };
  handleNewEC = () => {
    // console.log(item);
    this.state.activeItemEC.nameEC = "";
    this.state.activeItemEC.logo = "";
    // this.state.activeItemE = item;
    this.setState({ modalEC: true });
    // console.log(this.state.modalE);
    console.log(this.state.activeItemEC);
    // this.render();
  };

  handleEditE = (item) => {
    console.log(item);
    this.state.activeItemE = item;

    this.refreshListDocs();

    this.state.selectedDocsE = this.state.docsListE.filter(
      (itemS) => String(itemS.epixeirisi) === String(item.nameE)
    );

    console.log(this.state.selectedDocsE);

    // this.state.activeItemE = item;
    this.setState({ modalE: true });
    // console.log(this.state.modalE);
    console.log(this.state.activeItemE);
    // this.render();
  };

  handleDeleteE = (item) => {
    // axios
    //   .delete(`https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/${item.id}`)
    session.delete(`api/Epixeiriseis/${item.id}`)
      .then(this.refreshList());
    this.refreshList();
  };

  handleChangeArxDocE = (e) => {
    var files = e.target.files[0];
    console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // console.log(name)
    // const arxdocitemE = { ...this.state.arxdocitemE, [name]: files };

    // this.setState({ arxdocitemE });

    console.log(this.state.activeItemE);

    this.state.arxdocitemE.file_field = e.target.files[0];
    this.state.arxdocitemE.epixeirisi = this.state.activeItemE.nameE;
    this.state.arxdocitemE.description = fname[0];
  };

  handleChangeLixi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItem.lixiP = fulldate;

    console.log(this.state.activeItem);

    this.setState({ state: this.state });
    // this.setState({ activeItem });
  };

  handleChangeLixiAdeias = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItem.lixiAP = fulldate;

    console.log(this.state.activeItem);

    this.setState({ state: this.state });
    // this.setState({ activeItem });
  };

  handleChangeEnarxi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItem.enarxiP = fulldate;

    console.log(this.state.activeItem);
    // this.setState({ activeItem });
  };
  handleChangeEnarxiAdeias = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItem.enarxiAP = fulldate;

    console.log(this.state.activeItem);
    // this.setState({ activeItem });
  };

  handlePostLaiki = (e) => {
    console.log(posseleted);
    const newItem = this.state.activeItemLaiki;

    var length = posseleted.length;

    if (posseleted[length - 1] != undefined) {
      newItem.centerLA = posseleted[length - 1];
      newItem.coordsLA = posseleted[length - 1];
    }

    console.log(newItem);
    // console.log(posseleted);

    if (newItem.id) {
      // axios
      //   .put(
      //     `https://epixirin-volos.govcloud.gr/be/api/LaikiAgora/${newItem.id}/`,
      //     newItem
      //   )
      session.put(`api/LaikiAgora/${newItem.id}/`,newItem)
        .then(this.refreshList());
    } else {
      // axios
      //   .post("https://epixirin-volos.govcloud.gr/be/api/LaikiAgora/", newItem)
      session.post("api/LaikiAgora/",newItem)
        .then(this.refreshList());
    }

    this.state.activeItem = [];
    this.refreshList();
    this.handleCloseLaiki();
  };

  handlePost = () => {
    // console.log(newItem);
    // console.log(posseleted);
    const newItem = this.state.activeItem;

    var length = posseleted.length;

    if (posseleted[length - 1] != undefined || posseleted[length - 1] != 0) {
      newItem.coordsP1 = posseleted[length - 1];
    }
    if (posseleted[length - 2] != undefined || posseleted[length - 2] != 0) {
      newItem.coordsP2 = posseleted[length - 2];
    }
    if (posseleted[length - 3] != undefined || posseleted[length - 3] != 0) {
      newItem.coordsP3 = posseleted[length - 3];
    }
    if (posseleted[length - 4] != undefined || posseleted[length - 4] != 0) {
      newItem.coordsP4 = posseleted[length - 4];
    }

    console.log(newItem);

    if (
      newItem.coordsP1 != undefined &&
      newItem.coordsP2 != undefined &&
      newItem.coordsP3 != undefined &&
      newItem.coordsP4 != undefined
    ) {
      newItem.centerP = [
        (newItem.coordsP1[0] + newItem.coordsP3[0]) / 2,
        (newItem.coordsP1[1] + newItem.coordsP3[1]) / 2,
      ];

      if (
        newItem.coordsP2[0] == 0 &&
        newItem.coordsP3[0] == 0 &&
        newItem.coordsP4[0] == 0
      ) {
        newItem.coordsP1 = undefined;
        newItem.coordsP2 = undefined;
        newItem.coordsP3 = undefined;
        newItem.coordsP4 = undefined;
      }

      if (newItem.coordsP3[0] == 0 && newItem.coordsP4[0] == 0) {
        newItem.coordsP1 = undefined;
        newItem.coordsP2 = undefined;
        newItem.coordsP3 = undefined;
        newItem.coordsP4 = undefined;
      }
    }

    if (newItem.coordsP1 == 0 && newItem.coordsP2 == 0) {
      newItem.coordsP1 = undefined;
      newItem.coordsP2 = undefined;
      newItem.coordsP3 = undefined;
      newItem.coordsP4 = undefined;
    }
    // newItem.userIdp = 2;

    console.log(newItem);
    // console.log(posseleted);

    if (newItem.id) {
      // formData.append("id", newItem.id);
      // axios
      //   .put(
      //     `https://epixirin-volos.govcloud.gr/be/api/Pagkoi/${newItem.id}/`,
      //     newItem
      //   )
      session.put(`api/Pagkoi/${newItem.id}/`,newItem)
        .then(this.refreshList());

      if (this.state.arxdocitem.file_field != undefined) {
        setDoc(this.state.arxdocitem);
        this.refreshListDocs();
      }
    } else {
      if (UdataTablePids.includes(newItem.nameP)) {
        alert("Υπάρχει ήδη πάγκος με το ίδιο Αριθμό θέσης");
      } else {
        this.state.arxdocitem.pagkos = newItem.anagnwristikoP;

        // axios
        //   .post("https://epixirin-volos.govcloud.gr/be/api/Pagkoi/", newItem)
        session.post("api/Pagkoi/",newItem)
          .then(this.refreshList());

        if (this.state.arxdocitem.file_field != undefined) {
          setDoc(this.state.arxdocitem);
          this.refreshListDocs();
        }
      }
    }

    this.state.activeItem = [];
    this.refreshList();
    this.refreshListDocs();
    this.handleClose();
    this.setState({ state: this.state });
  };
  // c

  handleNewL = () => {
    // console.log(item);
    // this.state.activeItemLaiki = [];

    this.state.activeItemLaiki.nameLA = "";
    this.state.activeItemLaiki.perioxiA = "";
    // this.state.activeItem = item;
    this.setState({ modalL: true });
  };

  handleNew = () => {
    // console.log(item);
    this.state.activeItem.nameP = "";
    this.state.activeItem.anagnwristikoP = "";
    this.state.activeItem.adeioP = false;
    this.state.activeItem.afmP = "";
    this.state.activeItem.siteP = "";
    this.state.activeItem.userIdp = "";
    this.state.activeItem.posoP = "";
    this.state.activeItem.plirwse = false;
    this.state.activeItem.isinteam = false;
    this.state.activeItem.anastoli = false;
    this.state.activeItem.patronymo = "";
    this.state.activeItem.dieuthinsi = "";
    this.state.activeItem.tilefwno = "";
    this.state.activeItem.kinito = "";
    this.state.activeItem.paratiriseis = "";
    this.state.activeItem.typos = "";
    this.state.activeItem.kathgoria = "";
    this.state.activeItem.adeiaAllouDimou = false;
    this.state.activeItem.adeiaDimou = false;
    this.state.activeItem.adeiaApokentrwmenis = false;
    this.state.activeItem.laikiAgoraId = "";
    this.state.activeItem.categoryP = "";
    // this.state.activeItem = item;
    this.setState({ modalP: true });
    // console.log(this.state.modalP);
    // console.log(this.state.activeItem);
    // this.render();
  };

  handleEdit = (item) => {
    // console.log(item);
    this.state.activeItem = item;

    if (item.arfak == null) {
      item.arfak = "";
    }
    this.refreshListDocs();

    this.state.selectedDocs = this.state.docsList.filter(
      (itemS) => itemS.pagkos === item.anagnwristikoP
    );

    // this.state.activeItem = item;
    this.setState({ modalP: true });
    // console.log(this.state.modalP);
    // console.log(this.state.activeItem);
    // this.render();
  };

  handleEditLaiki = (item) => {
    this.state.activeItemLaiki = item;

    this.state.laikiselected = item.nameLA;
    this.state.laikiselectedcenter = item.latlng;

    console.log(this.state.activeItemLaiki);
    // this.state.activeItem = item;
    this.setState({ modalL: true });
    // console.log(this.state.modalP);
    // console.log(this.state.activeItemLaiki);
    // this.render();
  };

  handleEditXrewsi = (item) => {
    this.state.activeItemXrewsi = item;
    console.log(this.state.activeItemXrewsi);
    this.setState({ modalPX: true });
  };

  handleDelete = (item) => {
    // axios
    //   .delete(`https://epixirin-volos.govcloud.gr/be/api/Pagkoi/${item.id}`)
    session.delete(`api/Pagkoi/${item.id}`)
      .then(this.refreshList());
    this.refreshList();
    this.setState({ state: this.state });
  };
  componentDidMount() {
    this.refreshList();
    this.refreshListDocs();
    this.refreshListCategory();
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
  }

  exagwgi = () => {
    let exagwgitable = [];

    for (var i = 0; i < UdataTable.length; i++) {
      exagwgitable[i] = UdataTable[i];
      exagwgitable[i].e = "";
    }
    console.log(exagwgitable);

    return (
      <ExcelFile element={<button>Download Data</button>}>
        <ExcelSheet data={exagwgitable} name="All">
          <ExcelColumn label="eidos" value="eidos" />
          <ExcelColumn label="name" value="name" />
          <ExcelColumn label="laikis" value="laikis" />
          <ExcelColumn label="category" value="category" />
        </ExcelSheet>
      </ExcelFile>
    );
  };

  refreshList() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/Pagkoi/")
    //   .then((res) => this.setState({ PagkoiList: res.data }))
    //   .catch((err) => console.log(err));
    session.get("api/Pagkoi/")
      .then((res) => this.setState({ PagkoiList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/")
    //   .then((res) => this.setState({ PagkoiXrewsiList: res.data }))
    //   .catch((err) => console.log(err));
    session.get("api/PagkoiXrewsi/")
      .then((res) => this.setState({ PagkoiXrewsiList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/LaikiAgoraMini/")
    //   .then((res) => this.setState({ LaikesSelectList: res.data }))
    //   .catch((err) => console.log(err));
    session.get("api/LaikiAgoraMini/")
    .then((res) => this.setState({ LaikesSelectList: res.data }))
    .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/LaikiAgora/")
    //   .then((res) => this.setState({ LaikesList: res.data }))
    //   .catch((err) => console.log(err));
    session.get("api/LaikiAgora/")
    .then((res) => this.setState({ LaikesList: res.data }))
    .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/")
    //   .then((res) => this.setState({ EpixeiriseisList: res.data }))
    //   .catch((err) => console.log(err));

    session.get("api/Epixeiriseis/")
    .then((res) => this.setState({ EpixeiriseisList: res.data }))
    .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/ParousiaPagkou/")
    //   .then((res) => this.setState({ ParousiaList: res.data }))
    //   .catch((err) => console.log(err));
    session.get("api/ParousiaPagkou/")
    .then((res) => this.setState({ ParousiaList: res.data }))
    .catch((err) => console.log(err));


    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/ParousiaPagkou/")
    //   .then((res) => this.setState({ ParousiesList: res.data }))
    //   .catch((err) => console.log(err));
    session.get("api/ParousiaPagkou/")
    .then((res) => this.setState({ ParousiaList: res.data }))
    .catch((err) => console.log(err));

  }

  refreshListDocs() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/FileFieldFormPagkos/")
    //   .then((res) => this.setState({ docsList: res.data }))
    //   .catch((err) => console.log(err));
    session.get("api/FileFieldFormPagkos/")
    .then((res) => this.setState({ docsList: res.data }))
    .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/FileFieldFormEpixeirisi/")
    //   .then((res) => this.setState({ docsListE: res.data }))
    //   .catch((err) => console.log(err));
    session.get("api/FileFieldFormEpixeirisi/")
    .then((res) => this.setState({ docsListE: res.data }))
    .catch((err) => console.log(err));
  }

  refreshListCategory() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/PagkosCategory/")
    //   .then((res) => this.setState({ PagkosCategoryList: res.data }))
    //   .catch((err) => console.log(err));
    session.get("api/PagkosCategory/")
    .then((res) => this.setState({ PagkosCategoryList: res.data }))
    .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/EpixeirisiCategory/")
    //   .then((res) => this.setState({ EpixeiriseisCategoryList: res.data }))
    //   .catch((err) => console.log(err));
    session.get("api/EpixeirisiCategory/")
    .then((res) => this.setState({ EpixeiriseisCategoryList: res.data }))
    .catch((err) => console.log(err));
  }
  handleLaikiSelect(e) {
    const value = e.target.value;

    console.log(value);

    this.state.laikiselected = value;

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }
  handleChangeBools = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });
  };

  handleChangeArxDocE = (e) => {
    var files = e.target.files[0];
    console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // console.log(name)
    // const arxdocitemE = { ...this.state.arxdocitemE, [name]: files };

    // this.setState({ arxdocitemE });

    console.log(this.state.activeItemE);

    this.state.arxdocitemE.file_field = e.target.files[0];
    this.state.arxdocitemE.epixeirisi = this.state.activeItemE.nameE;
    this.state.arxdocitemE.description = fname[0];
  };

  render() {
    console.log(this.state.laikiselected);

    let tmpItems = this.state.PagkoiList;

    if (this.state.searchItem.all == false) {
      console.log(this.state.searchItem);

      tmpItems = this.state.PagkoiList.filter(
        (item) =>
          item.anastoli === this.state.searchItem.isAnastoli &&
          item.plirwse === this.state.searchItem.exeiplirwsei
      );
    }

    // .filter(
    //   (item) => String(item.laikiAgoraId) === String(this.state.laikiselected)
    // );
    const tmpItemsLaikes = this.state.LaikesList;

    const tmpItemsParousies = this.state.ParousiaList;
    const tmpItemsLaikesSelect = this.state.LaikesSelectList;
    const { markers } = this.state;
    const classes = this.state.useStyles;
    const theme = this.state.useTheme;
    let EpixeiriseisItems = this.state.EpixeiriseisList;
    const Epcategories = this.state.EpixeiriseisCategoryList;

    let categories = [];

    if (this.state.PagkosCategoryList != undefined) {
      categories = this.state.PagkosCategoryList;
    }

    if (categories != undefined) {
      for (var i = 0; i < tmpItems.length; i++) {
        // console.log(tmpItems[i]);
        let categorytmp = categories.filter(
          (item) => String(item.namePC) === String(tmpItems[i].categoryP)
        );
        // console.log(categorytmp);
        if (categorytmp[0] != undefined) {
          tmpItems[i].color = categorytmp[0].colorPC;
          tmpItems[i].Bcolor = categorytmp[0].description;
        }
      }
    } else {
      this.setState({ state: this.state });
    }

    const positionC = [39.365, 22.951];
    const open = this.state.open;

    // }
    if (this.state.activeItem.coordsP1 == undefined) {
      this.state.activeItem.coordsP1 = [0, 0];
      this.state.activeItem.coordsP2 = [0, 0];
      this.state.activeItem.coordsP3 = [0, 0];
      this.state.activeItem.coordsP4 = [0, 0];
    }

    console.log(this.state.activeItem.laikiAgoraId);
    console.log(this.state.laikiselected);

    if (this.state.activeItem.laikiAgoraId == undefined) {
      this.state.activeItem.laikiAgoraId = this.state.laikiselected;
    }

    if (Epcategories != undefined) {
      for (var i = 0; i < EpixeiriseisItems.length; i++) {
        // console.log(EpixeiriseisItems[0].iconeE);
        // console.log(Epcategories);
        let categorytmp = Epcategories.filter(
          (item) => String(item.nameEC) === String(EpixeiriseisItems[i].iconeE)
        );

        if (categorytmp[0] != undefined) {
          // console.log(categorytmp);

          EpixeiriseisItems[i].logo = categorytmp[0].logo;

          const myIcon = L.icon({
            iconUrl: categorytmp[0].logo,
            iconSize: [25, 25],
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
          });

          EpixeiriseisItems[i].logo2 = myIcon;
        }
      }
    } else {
      Epcategories = undefined;
      this.setState({ state: this.state });
    }

    if (this.state.activeItemE.coordsE == undefined) {
      this.state.activeItemE.coordsE = [0, 0];
    }

    if (this.state.laikiselectedcenter == "") {
      this.state.laikiselectedcenter = center;
    }

    if (
      this.state.searchItem.isAnastoli == true ||
      this.state.searchItem.exeiplirwsei == true
    ) {
      this.state.searchItem.all = false;
    } else if (
      this.state.searchItem.isAnastoli == false &&
      this.state.searchItem.exeiplirwsei == false
    ) {
      this.state.searchItem.all = true;
    }

    UdataTable = [];

    UdataTableL = [];

    UdataTableP = [];

    UdataTablePP = [];

    UdataTableE = [];

    let UdataTablePX = [];
    let UdataTablePX2 = [];
    let UdataTableUsers = [];

    let tmpItemsXrewsi = this.state.PagkoiXrewsiList;

    for (var i = 0; i < tmpItemsXrewsi.length; i++) {
      const dataRow = {};

      const item = tmpItemsXrewsi[i];

      // dataRow.enarxiP = item.enarxiP;
      // dataRow.lixiP = item.lixiP;

      let tmp3 = item.enarxiP;
      let tmp4 = tmp3.split("-");
      dataRow.enarxiP = tmp4[2] + "-" + tmp4[1] + "-" + tmp4[0];

      tmp3 = item.lixiP;
      tmp4 = tmp3.split("-");
      dataRow.lixiP = tmp4[2] + "-" + tmp4[1] + "-" + tmp4[0];

      tmp3 = item.date;
      tmp4 = tmp3.split("-");
      dataRow.date = tmp4[2] + "-" + tmp4[1] + "-" + tmp4[0];

      dataRow.poso = item.poso;
      dataRow.pagkos = item.pagkos;

      UdataTableUsers.push(item.pagkos);

      dataRow.date = item.date;

      const tmppagkoi = this.state.PagkoiList.filter(
        (itemP) => String(item.pagkos) == String(itemP.anagnwristikoP)
      );

      console.log(item.pagkos);
      console.log(tmppagkoi);

      if (tmppagkoi[0] != undefined) {
        dataRow.epwn = tmppagkoi[0].userIdp;
        dataRow.afmP = tmppagkoi[0].afmP;
      }
      dataRow.time = item.enarxiP + " εώς " + item.lixiP;

      dataRow.timimonadas = item.timimonadas;

      if (item.pragmtetragwnika == "") {
        dataRow.tetragwnika = item.tetragwnika;
      } else {
        dataRow.tetragwnika = item.pragmtetragwnika;
      }

      dataRow.poso = item.poso;

      UdataTablePX.push(dataRow);
    }

    // if (this.state.datefrom != "" && this.state.dateto != "") {
    //   let date1 = new Date(this.state.datefrom);
    //   let date2 = new Date(this.state.dateto);

    //   let date = new Date(item.date);

    //   if (date > date1 && date < date2) {

    //     UdataTablePX.push(dataRow);

    //   }
    // }

    var unique = UdataTableUsers.filter((v, i, a) => a.indexOf(v) === i);

    console.log(unique);

    if (this.state.datefrom != "" && this.state.dateto != "") {
      for (var i = 0; i < unique.length; i++) {
        let tmpItemsXrewsi = this.state.PagkoiXrewsiList.filter(
          (itemXr) => itemXr.pagkos === unique[i]
        );

        console.log(tmpItemsXrewsi);

        const dataRow = {};
        var posof = 0;

        for (var j = 0; j < tmpItemsXrewsi.length; j++) {
          let date1 = new Date(this.state.datefrom);
          let date2 = new Date(this.state.dateto);

          let date = new Date(tmpItemsXrewsi[j].date);

          if (date1 != date2) {
            if (date >= date1 && date <= date2) {
              posof += parseInt(tmpItemsXrewsi[j].poso);
            }
          } else if (date1 == date2) {
            if (date == date1) {
              posof = parseInt(tmpItemsXrewsi[j].poso);
            }
          }

          dataRow.posof = posof;
          dataRow.pagkos = tmpItemsXrewsi[j].pagkos;
          dataRow.date1 = this.state.datefrom;
          dataRow.date2 = this.state.dateto;

          var tmp3 = this.state.datefrom;
          var tmp4 = tmp3.split("-");
          dataRow.date1 = tmp4[2] + "-" + tmp4[1] + "-" + tmp4[0];

          tmp3 = this.state.dateto;
          tmp4 = tmp3.split("-");
          dataRow.date2 = tmp4[2] + "-" + tmp4[1] + "-" + tmp4[0];

          const tmppagkoi = this.state.PagkoiList.filter(
            (itemP) =>
              String(tmpItemsXrewsi[j].pagkos) == String(itemP.anagnwristikoP)
          );

          if (tmppagkoi[0] != undefined) {
            dataRow.epwn = tmppagkoi[0].userIdp;
            dataRow.afmP = tmppagkoi[0].afmP;
          }
        }
        // if (posof != 0) {
        UdataTablePX2.push(dataRow);
        // }
      }
    }

    return (
      <>
        <GridContainer>
          <GridItem>
            <Card>
              <CardHeader color="success">Χρεώσεις</CardHeader>
              <CardBody>
                <Row style={{ display: "flex" }}>
                  <Col xs="3" style={{ display: "grid" }}>
                    <label>Έναρξη</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="datefrom"
                        name="datefrom"
                        showTodayButton
                        required
                        value={this.state.datefrom}
                        onChange={(e) => this.handleChangeFrom(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Col>
                  <Col xs="3" style={{ display: "grid" }}>
                    <label>Λήξη</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="dateto"
                        name="dateto"
                        showTodayButton
                        required
                        value={this.state.dateto}
                        onChange={(e) => this.handleChangeTo(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Col>
                  <Col xs="3">
                    <Button
                      round
                      color="danger"
                      size="sm"
                      onClick={() => this.handledateclear()}
                    >
                      <i className="fa fa-times"></i>
                    </Button>
                    {this.state.datefrom != "" && this.state.dateto != "" && (
                      <ExcelFile
                        element={
                          <button
                            style={{
                              color: "white",
                              backgroundColor: "#4CAF50",
                              borderColor: "#4CAF50",
                              fontSize: "1em",
                              borderRadius: "100px",
                            }}
                          >
                            Κατέβασμα excel χρεώσεων
                          </button>
                        }
                      >
                        <ExcelSheet data={UdataTablePX2} name="All">
                          <ExcelColumn label="Επωνυμία" value="pagkos" />
                          <ExcelColumn label="Ονοματεπώνυμο" value="epwn" />
                          <ExcelColumn label="ΑΦΜ" value="afmP" />
                          <ExcelColumn
                            label="Ημερομηνία Έναρξης"
                            value="date1"
                          />
                          <ExcelColumn label="Ημερομηνία Λήξης" value="date2" />
                          <ExcelColumn label="Ποσό" value="posof" />
                        </ExcelSheet>
                      </ExcelFile>
                    )}
                  </Col>
                </Row>

                {this.state.datefrom == "" && this.state.dateto == "" && (
                  <ReactTable
                    data={UdataTablePX}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Επωνυμία",
                        accessor: "pagkos",
                        disableFilters: true,
                        disableSortBy: true,
                      },
                      {
                        Header: "Ονοματεπώνυμο",
                        accessor: "epwn",
                        disableFilters: true,
                        disableSortBy: true,
                      },
                      {
                        Header: "ΑΦΜ",
                        accessor: "afmP",
                        disableFilters: true,
                        disableSortBy: true,
                      },

                      {
                        Header: "Ημερομηνία",
                        accessor: "date",
                        disableFilters: true,
                        disableSortBy: true,
                      },

                      {
                        Header: "Μήκος",
                        accessor: "tetragwnika",
                        disableFilters: true,
                        disableSortBy: true,
                      },

                      {
                        Header: "Τιμή μονάδας",
                        accessor: "timimonadas",
                        disableFilters: true,
                        disableSortBy: true,
                      },

                      // {
                      //   Header: "Έναρξη",
                      //   accessor: "",
                      //   Filter: SelectColumnFilter,
                      //   filter: filterGreaterThan,
                      // },
                      // {
                      //   Header: "Λήξη",
                      //   accessor: "lixiP",
                      //   Filter: SelectColumnFilter,
                      //   filter: filterLessThan,
                      // },
                      {
                        Header: "Ποσό",
                        accessor: "poso",
                        Filter: NumberRangeColumnFilter,
                        filter: "between",
                        disableFilters: true,
                        disableSortBy: true,
                      },
                      // {
                      //   Header: "Παρουσίες",
                      //   accessor: "par",
                      //   Filter: NumberRangeColumnFilter,
                      //   filter: "between",
                      // },
                      // {
                      //   Header: "Πλήρωσε",
                      //   accessor: "plirwse",
                      //   Filter: SelectColumnFilter,
                      //   filter: "includes",
                      // },

                      {
                        Header: "",
                        accessor: "e",
                        disableFilters: true,
                        disableSortBy: true,
                      },
                    ]}
                    defaultPageSize={5}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                )}
                {this.state.datefrom != "" && this.state.dateto != "" && (
                  <ReactTable
                    data={UdataTablePX2}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Επωνυμία",
                        accessor: "pagkos",
                        disableFilters: true,
                        disableSortBy: true,
                      },
                      {
                        Header: "Ονοματεπώνυμο",
                        accessor: "epwn",
                        disableFilters: true,
                        disableSortBy: true,
                      },
                      {
                        Header: "ΑΦΜ",
                        accessor: "afmP",
                        disableFilters: true,
                        disableSortBy: true,
                      },
                      {
                        Header: "Αρχή",
                        accessor: "date1",
                        disableFilters: true,
                        disableSortBy: true,
                      },
                      {
                        Header: "Τέλος",
                        accessor: "date2",
                        disableFilters: true,
                        disableSortBy: true,
                      },

                      {
                        Header: "Ποσό",
                        accessor: "posof",
                        Filter: NumberRangeColumnFilter,
                        filter: "between",
                        disableFilters: true,
                        disableSortBy: true,
                      },

                      {
                        Header: "",
                        accessor: "e",
                        disableFilters: true,
                        disableSortBy: true,
                      },
                    ]}
                    defaultPageSize={5}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { green, red } from '@mui/material/colors';
import Icon from '@mui/material/Icon';
import session from '../api/session'
import { SuperVisorModal } from './modal-supervisor.jsx'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { RouteSharp } from '@mui/icons-material';


const currentDateName = new Date().toLocaleString('en-us', { weekday: 'long' })

const getObjectDays = {
    'Monday': 'deytera',
    'Tuesday': 'triti',
    'Wednesday': 'tetarti',
    'Thursday': 'pempti',
    'Friday': 'paraskeui',
    'Saturday': 'savvato',
    'Sunday': 'kyriaki'
}

export default class CollapsibleTable extends React.Component {
    constructor(props) {
        super(props)
        // this.BasicModal = React.createRef()
        this.state = {
            laikes: [],
            selected: false,
            pagkoi: [],
            laikiId: '',
            page: 1,
            benchViolations: [],
            pagkoiCol1: [],
            pagkoiCol2: [],


        }
    }


    componentDidMount() {
        console.log(getObjectDays[currentDateName])
        session.get(`/api/laikes/?${getObjectDays[currentDateName]}=true&is_active=true`).then((resp) => {
            this.setState({ laikes: resp.data })
        }).catch(e => {

            if (e.response.data && e.response.data.detail === 'Signature has expired.') {
                window.location.href = "/yp";
            }
        })
        this.fetchBenchViolations()
    }



    fetchPagkousByLaikiId(id, page) {
        const col1 = [], col2 = []
        session.get(`api/pagkoi-all/?barcode=&qr_code=&laikiAgoraId=${id}&page=${page}&positions=1`).then(resp => {
            this.setState({ pagkoi: resp.data })

            if (resp.data.results.length > 0) {


                resp.data.results.forEach((item, i) => {
                    if (i % 2 == 0) {
                        col1.push(item)
                    } else {
                        col2.push(item)
                    }
                })

                console.log(col1)
                this.setState({
                    pagkoiCol1: col2,
                    pagkoiCol2: col1
                })
            }

        }).catch(e => {
            console.log(e.response.data)
        })

    }

    pageClick(_, v) {
        this.setState({ page: v })
        this.fetchPagkousByLaikiId(this.state.id, v)
    }

    fetchBenchViolations() {
        session.get('api/bench-violations/').then(resp => {
            this.setState({ benchViolations: resp.data })
        })
    }


    handleChange(e, row) {

        if (e.target.checked) {
            this.setState({ id: row.id, selected: e.target.checked, laikiId: row.id })
            const page = 1
            this.setState({ page: page })
            this.fetchPagkousByLaikiId(row.id, page)
        } else {
            this.setState({ id: null, selected: e.target.checked })
        }

    }
    endBenchInspectionModal(e) {
        this.fetchPagkousByLaikiId(this.state.laikiId, this.state.page)
    }



    render() {

        return (
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Όνομα λαϊκής- {this.props.id}</TableCell>
                                <TableCell align="center">Περιοχή</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.laikes && this.state.laikes.length > 0 && this.state.laikes.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.nameLA}
                                    </TableCell>
                                    <TableCell align="center">{row.perioxiA}</TableCell>
                                    <TableCell>
                                        <Checkbox
                                            value={row.id}
                                            checked={this.state.id === row.id}
                                            id={row.nameP}
                                            onChange={(e) => this.handleChange(e, row)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />

                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* START PAGKOI LISTVIEW */}

                {this.state.id != null ? (<div style={{ marginTop: "50px" }} className="some-div"  >
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Θέσεις Πάγκου</TableCell>

                                            <TableCell align="center">Κάτοχος</TableCell>
                                            <TableCell align="center">Κατηγορία</TableCell>
                                            <TableCell align="center">Κατηγορία Πάγκου</TableCell>
                                            <TableCell align="center">Ελέγχθηκε</TableCell>
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>

                                        {this.state.pagkoiCol1 && this.state.pagkoiCol1.length > 0 &&
                                            this.state.pagkoiCol1.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.bench_position ? row.bench_position : "-"}
                                                    </TableCell>

                                                    <TableCell align="center">{row.userIdp}</TableCell>
                                                    <TableCell align="center">{row.kathgoria}</TableCell>
                                                    <TableCell align="center">{row.category_name}</TableCell>
                                                    <TableCell align="center">{row.elegxos.length > 0 ? <Icon sx={{ color: green[500] }}>check-bold</Icon> : <Icon sx={{ color: red[500] }}>close-circle</Icon>} ({row.elegxos.length})</TableCell>
                                                    <TableCell>
                                                        <SuperVisorModal
                                                            benchAfm={row.afmP}
                                                            name={row.userIdp}
                                                            numberP={row.nameP}
                                                            licenseStart={row.enarxiAP}
                                                            licenceEnd={row.lixiAP}
                                                            squares={row.tetragwnika}
                                                            barcode={row.barcode}
                                                            qrcode={row.qr_code}
                                                            anastoli={row.anastoli}
                                                            adeiaAllouDimou={row.adeiaAllouDimou}
                                                            diakopi={row.diakopi}
                                                            pagkosID={row.id}
                                                            anagnwristikoPagkou={row.anagnwristikoP}
                                                            benchPosition={row.bench_position}
                                                            tetragwnika={row.tetragwnika}
                                                            endBenchInspection={(e) => this.endBenchInspectionModal(e)}
                                                            // benchViolations={this.state.benchViolations}
                                                            benchViolations={this.state.benchViolations}
                                                            selectedBenchViolations={row.violations}
                                                            laikiAgora={this.state.laikiId}

                                                        ></SuperVisorModal>
                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                    </TableBody>

                                    {/* {this.state.pagkoi && this.state.pagkoi.results && this.state.pagkoi.results.length > 0 &&
                                            this.state.pagkoi.results.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.bench_position ? row.bench_position : "-"}
                                                    </TableCell>

                                                    <TableCell align="center">{row.userIdp}</TableCell>
                                                    <TableCell align="center">{row.kathgoria}</TableCell>
                                                    <TableCell align="center">{row.category_name}</TableCell>
                                                    <TableCell align="center">{row.elegxos.length > 0 ? <Icon sx={{ color: green[500] }}>check-bold</Icon> : <Icon sx={{ color: red[500] }}>close-circle</Icon>} ({row.elegxos.length})</TableCell>
                                                    <TableCell>
                                                        <SuperVisorModal
                                                            benchAfm={row.afmP}
                                                            name={row.userIdp}
                                                            numberP={row.nameP}
                                                            licenseStart={row.enarxiAP}
                                                            licenceEnd={row.lixiAP}
                                                            squares={row.tetragwnika}
                                                            barcode={row.barcode}
                                                            qrcode={row.qr_code}
                                                            anastoli={row.anastoli}
                                                            adeiaAllouDimou={row.adeiaAllouDimou}
                                                            diakopi={row.diakopi}
                                                            pagkosID={row.id}
                                                            anagnwristikoPagkou={row.anagnwristikoP}
                                                            benchPosition={row.bench_position}
                                                            tetragwnika={row.tetragwnika}
                                                            endBenchInspection={(e) => this.endBenchInspectionModal(e)}
                                                            // benchViolations={this.state.benchViolations}
                                                            benchViolations={this.state.benchViolations}
                                                            selectedBenchViolations={row.violations}

                                                        ></SuperVisorModal>
                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                    </TableBody> */}


                                </Table>



                            </TableContainer>
                        </Grid>
                        <Grid item sm={6}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Θέσεις Πάγκου</TableCell>

                                            <TableCell align="center">Κάτοχος</TableCell>
                                            <TableCell align="center">Κατηγορία</TableCell>
                                            <TableCell align="center">Κατηγορία Πάγκου</TableCell>
                                            <TableCell align="center">Ελέγχθηκε</TableCell>
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>

                                        {this.state.pagkoiCol2 && this.state.pagkoiCol2.length > 0 &&
                                            this.state.pagkoiCol2.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.bench_position ? row.bench_position : "-"}
                                                    </TableCell>

                                                    <TableCell align="center">{row.userIdp}</TableCell>
                                                    <TableCell align="center">{row.kathgoria}</TableCell>
                                                    <TableCell align="center">{row.category_name}</TableCell>
                                                    <TableCell align="center">{row.elegxos.length > 0 ? <Icon sx={{ color: green[500] }}>check-bold</Icon> : <Icon sx={{ color: red[500] }}>close-circle</Icon>} ({row.elegxos.length})</TableCell>
                                                    <TableCell>
                                                        <SuperVisorModal
                                                            benchAfm={row.afmP}
                                                            name={row.userIdp}
                                                            numberP={row.nameP}
                                                            licenseStart={row.enarxiAP}
                                                            licenceEnd={row.lixiAP}
                                                            squares={row.tetragwnika}
                                                            barcode={row.barcode}
                                                            qrcode={row.qr_code}
                                                            anastoli={row.anastoli}
                                                            adeiaAllouDimou={row.adeiaAllouDimou}
                                                            diakopi={row.diakopi}
                                                            pagkosID={row.id}
                                                            anagnwristikoPagkou={row.anagnwristikoP}
                                                            benchPosition={row.bench_position}
                                                            tetragwnika={row.tetragwnika}
                                                            endBenchInspection={(e) => this.endBenchInspectionModal(e)}
                                                            // benchViolations={this.state.benchViolations}
                                                            benchViolations={this.state.benchViolations}
                                                            selectedBenchViolations={row.violations}

                                                        ></SuperVisorModal>
                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                    </TableBody>

                                    {/* {this.state.pagkoi && this.state.pagkoi.results && this.state.pagkoi.results.length > 0 &&
                                            this.state.pagkoi.results.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.bench_position ? row.bench_position : "-"}
                                                    </TableCell>

                                                    <TableCell align="center">{row.userIdp}</TableCell>
                                                    <TableCell align="center">{row.kathgoria}</TableCell>
                                                    <TableCell align="center">{row.category_name}</TableCell>
                                                    <TableCell align="center">{row.elegxos.length > 0 ? <Icon sx={{ color: green[500] }}>check-bold</Icon> : <Icon sx={{ color: red[500] }}>close-circle</Icon>} ({row.elegxos.length})</TableCell>
                                                    <TableCell>
                                                        <SuperVisorModal
                                                            benchAfm={row.afmP}
                                                            name={row.userIdp}
                                                            numberP={row.nameP}
                                                            licenseStart={row.enarxiAP}
                                                            licenceEnd={row.lixiAP}
                                                            squares={row.tetragwnika}
                                                            barcode={row.barcode}
                                                            qrcode={row.qr_code}
                                                            anastoli={row.anastoli}
                                                            adeiaAllouDimou={row.adeiaAllouDimou}
                                                            diakopi={row.diakopi}
                                                            pagkosID={row.id}
                                                            anagnwristikoPagkou={row.anagnwristikoP}
                                                            benchPosition={row.bench_position}
                                                            tetragwnika={row.tetragwnika}
                                                            endBenchInspection={(e) => this.endBenchInspectionModal(e)}
                                                            // benchViolations={this.state.benchViolations}
                                                            benchViolations={this.state.benchViolations}
                                                            selectedBenchViolations={row.violations}

                                                        ></SuperVisorModal>
                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                    </TableBody> */}


                                </Table>


                            </TableContainer>
                        </Grid>

                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} mt={5}>
                            {/* TODO : MAKING BUTTON BIGGER WILL MAKE EASY TO SUPERVISOR TO SEE / TOUCH EACH PAGE */}
                            <Pagination color="primary" count={this.state.pagkoi.total_pages} page={this.state.page}
                                onChange={(e, v) => this.pageClick(e, v)}
                            />
                        </Stack>
                    </Grid>


                </div>) : null
                }




                {/* END PAGKOI LISTVIEW */}

                {/* COL2 TABLE PAGKOI */}



            </div >

        );
    }
}




import React, { useEffect, useState } from "react";
import { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  RadialBarChart,
  RadialBar,
  PieChart,
  Pie,
  Sector,
  Cell,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";

import axios from "axios";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";
import classNames from "classnames";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import "leaflet/dist/leaflet.css";
// import { isConstructorDeclaration } from "typescript";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CustomInput from "components/CustomInput/CustomInput.js";
// import Date from "react-datetime";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import Close from "@material-ui/icons/Close";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import badgeStyle from "assets/jss/material-kit-react/components/badgeStyle.js";
import Badge from "components/Badge/Badge.js";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import MultiSelect from "react-multi-select-component";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// core components

import modalStyle from "assets/jss/material-kit-react/modalStyle.js";

import {
  CardFooter,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Progress,
  // Table,
  Container,
  Row,
  Input,
  Nav,
  TabPane,
  TabContent,
  // Button,
  NavLink,
  NavItem,
  Col,
  Modal,
  ModalHeader,
  // Button,
  ModalBody,
  // UncontrolledTooltip,
} from "reactstrap";
import Button from "components/CustomButtons/Button.js";
import { number } from "prop-types";
import ReactTable from "components/ReactTable/ReactTable.js";
import ReactExport from "react-data-export";
import Geocode from "react-geocode";
import lastDayOfISOWeek from "date-fns/fp/lastDayOfISOWeek/index";
import { TrendingFlatOutlined } from "@material-ui/icons";
import session from "../api/session";
import userParser from "components/userGroups.js";

Geocode.setApiKey("AIzaSyBSF6RaFqZGzFJgPZv1drw7PEjYZ8qZd_0");
Geocode.setLanguage("el");
Geocode.setRegion("gr");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
let UdataTable = [];
let UdataTableL = [];
let UdataTableP = [];
let UdataTablePP = [];
let UdataTablePX = [];
let UdataTableE = [];
let UdataTableEC = [];
let UdataTablePC = [];
let UdataTablePXC = [];

const style = {
  top: "50%",
  right: 0,
  transform: "translate(0, -50%)",
};

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

async function setDoc(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/docuploadPagkos";
  console.log(item);

  let formData = new FormData();
  formData.append("pagkos", item.pagkos);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/docuploadPagkos", formData);

  // let response = await axios.post(url, formData);
  console.log(response);
}

async function setPostDoc(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/docuploadPost";
  console.log(item);

  let formData = new FormData();
  formData.append("postId", item.postId);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  console.log(formData);

  // let response = await axios.post(url, formData);

  let response = await session.post("api/docuploadPost", formData);

  // let response = await axios.post(url, formData);
  console.log(response);
}

async function setDocE(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/docuploadEpixirisi";
  console.log(item);

  let formData = new FormData();
  formData.append("epixeirisi", item.epixeirisi);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/docuploadEpixirisi", formData);
  // let response = await axios.post(url, formData);
  console.log(response);
}

async function setlogo(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/logoupload";

  // console.log(formData);
  console.log(item);
  console.log(item.logo);
  console.log(item.nameEC);

  let formData = new FormData();

  formData.append("logo", item.logo);
  formData.append("nameEC", item.nameEC);
  formData.append("iconEC", "-");
  console.log(formData);

  // let response = await axios.post(url, formData);
  let response = await session.post("api/logoupload", formData);

  // let response = await axios.post(url, formData);
  console.log(response);
  // console.log(response.data.logo);
  // var logoname = response.data.logo;
  // window.location.reload();
}
// import leaflet from 'https://unpkg.com/leaflet@1.5.1/dist/leaflet.css';

// styles = {
//   label: {
//     cursor: "pointer",
//     paddingLeft: "0",
//     color: "rgba(0, 0, 0, 0.26)",
//     fontSize: "14px",
//     lineHeight: "1.428571429",
//     fontWeight: "400",
//     display: "inline-flex",
//   },
// };

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let posseleted = [];
let adressel = "";
let newE = false;
let editE = false;
let center = [39.365, 22.951];
let zoom = 14;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function MyComponent() {
//   const map = useMapEvents({
//     click: () => {
//       map.locate();
//     },
//     locationfound: (location) => {
//       console.log("location found:", location);
//     },
//   });
//   return null;
// }

// function LocationMarker() {
//   const [position, setPosition] = useState(null);
// const newItem = useState([]);

//   const map = useMapEvents({
//     click(e) {
//       var coord = e.latlng;
//       var lat = coord.lat;
//       var lng = coord.lng;
//       // posseleted = lat;
//       setPosition(e.latlng);
//       var tmpPos = [];
//       tmpPos.push(lat);
//       tmpPos.push(lng);
//       posseleted.push(tmpPos);
//       console.log(
//         "You clicked the map at latitude: " + lat + " and longitude: " + lng
//       );
//       Geocode.fromLatLng(lat, lng).then(
//         (response) => {
//           const address = response.results[0].formatted_address;
//           console.log(address);
//           adressel = address;
//         },
//         (error) => {
//           console.error(error);
//         }
//       );

//       // map.locate();
//     },
//     // locationfound(e) {
//     //   setPosition(e.latlng);
//     //   map.flyTo(e.latlng, map.getZoom());
//     // },
//   });

//   return position === null ? null : (
//     <Marker position={position}>
//       {" "}
//       <Tooltip>{adressel}</Tooltip>{" "}
//     </Marker>
//   );
// }

function LocationMarkerLaiki() {
  const [position, setPosition] = useState(null);
  // const newItem = useState([]);

  //   const map = useMapEvents({
  //     click(e) {
  //       var coord = e.latlng;
  //       var lat = coord.lat;
  //       var lng = coord.lng;
  //       // posseleted = lat;
  //       setPosition(e.latlng);
  //       var tmpPos = [];
  //       tmpPos.push(lat);
  //       tmpPos.push(lng);
  //       posseleted.push(tmpPos);
  //       console.log(
  //         "You clicked the map at latitude: " + lat + " and longitude: " + lng
  //       );

  //       // map.locate();
  //     },
  //     // locationfound(e) {
  //     //   setPosition(e.latlng);
  //     //   map.flyTo(e.latlng, map.getZoom());
  //     // },
  //   });

  //   return position === null ? null : (
  //     <Marker position={position}>
  //       <Popup>You are here</Popup>
  //     </Marker>
  //   );
}

// function handleChange(e) {
//   console.log(e.target);
//   console.log(e.target.value);
//   console.log(e.target.id);

// const { name, value } = e.target;
// this.state.newItem = { ...this.state.newItem, [name]:  value };

// console.log(this.state.newItem);
// }

export default function SuperuserAdmin() {
  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  let newItem = React.useState();
  let newItemC = React.useState();

  React.useEffect(() => {
    console.log("e");
    console.log(editE);
  }, [editE]);

  React.useEffect(() => {
    // console.log("e");
    // console.log(editE);
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <MapPanel></MapPanel>

            {/* <NavPills
              color="info"
              size="sm"
              tabs={[
                {
                  tabButton: "Λαϊκές - Πάγκοι - Επιχειρήσεις",
                  tabContent: <MapPanel></MapPanel>,
                },
                {
                  tabButton: "Κατηγορίες",
                  tabContent: <CategoryPanel></CategoryPanel>,
                },
                {
                  tabButton: "Χρήστες",
                  tabContent: <UsersPanel></UsersPanel>,
                },
                {
                  tabButton: "Posts",
                  tabContent: <PostPanel></PostPanel>,
                },
              ]}
            /> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

class MapPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      // useTheme: useTheme(),
      PagkoiList: [],
      PagkoiXrewsiList: [],
      LaikesList: [],
      docsList: [],
      LaikesSelectList: [],
      markers: [[0, 0]],
      activeItem: {},
      activeItemXrewsi: {},
      activeItemLaiki: {},
      modalP: false,
      modalPX: false,
      modalL: false,
      laikiselected: "-",
      pagkosselected: "",
      laikiselectedcenter: "",
      EpixeiriseisList: [],
      EpixeiriseisCategoryList: [],
      activeItemEC: {},
      activeItemE: {},
      modalE: false,
      modalEC: false,
      arxdocitem: {},
      selectedDocs: [],
      arxdocitemE: {},
      selectedDocsE: [],
      ParousiesList: [],
      dateprint: [],
      searchItem: {
        all: true,
        isAnastoli: false,
        exeiplirwsei: false,
      },
    };
  }
  handleCloseX = () => {
    this.state.activeItemXrewsi = [{}];
    this.setState({ activeItemXrewsi: [{}] });

    this.setState({ modalPX: false });
    this.setState({ state: this.state });
  };

  handleChangeX = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemXrewsi = { ...this.state.activeItemXrewsi, [name]: value };

    this.setState({ activeItemXrewsi });

    this.setState({ state: this.state });
  };

  handleChangeEnarxiXrewsi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItemXrewsi.enarxiP = fulldate;

    console.log(this.state.activeItemXrewsi);
    // this.setState({ activeItem });
  };

  handleChangeLixiXrewsi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItemXrewsi.lixiP = fulldate;

    console.log(this.state.activeItemXrewsi);

    this.setState({ state: this.state });
    // this.setState({ activeItem });
  };

  handleChangePagkosBoolsXrewsi = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    const activeItemXrewsi = { ...this.state.activeItemXrewsi, [name]: value };

    console.log(activeItemXrewsi);
    this.setState({ activeItemXrewsi });
  };

  handlePostXrewsi = () => {
    const newItem = this.state.activeItemXrewsi;

    if (newItem.tetragwnika != "" && newItem.timimonadas != "") {
      if (newItem.kathgoria === "Επαγγελματίες") {
        newItem.poso =
          parseInt(newItem.tetragwnika) * parseInt(newItem.timimonadas);
      } else {
        const parousies = this.state.ParousiesList.filter(
          (itemP) => itemP.pagkos === newItem.pagkos && itemP.parousia == true
        );

        let num = 0;

        var dateE = new Date(newItem.enarxiP);
        var dateL = new Date(newItem.lixiP);

        for (var i = 0; i < parousies.length; i++) {
          var date = new Date(parousies[i].date);

          console.log(parousies[i]);

          if (date >= dateE && date <= dateL) {
            num = num + 1;
          }
        }
        newItem.poso =
          parseInt(newItem.tetragwnika) *
          parseInt(newItem.timimonadas) *
          parseInt(num);
      }

      if (newItem.id) {
        // formData.append("id", newItem.id);
        // axios
        //   .put(
        //     `https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/${newItem.id}/`,
        //     newItem
        //   )
        session
          .put(`api/PagkoiXrewsi/${newItem.id}/`, newItem)
          .then(this.refreshList())
          .catch((error) => {
            alert(error.response.data);
          });
      } else {
        // axios
        //   .post("https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/", newItem)
        session.post("api/PagkoiXrewsi/", newItem).then(this.refreshList());
      }

      // this.state.activeItemXrewsi = [];
      this.refreshList();
      this.handleCloseX();
      this.setState({ state: this.state });
    } else {
      alert("προσθέστε τα απαραίτητα στοιχεία");
    }
  };
  handleEditXrewsi = (item) => {
    this.state.activeItemXrewsi = item;

    console.log(this.state.activeItemXrewsi);
    this.setState({ modalPX: true });
  };

  handleNewXrewsi = () => {
    this.state.activeItemXrewsi = {};

    this.state.activeItemXrewsi.id = "";
    this.state.activeItemXrewsi.poso = "";
    this.state.activeItemXrewsi.plirwse = false;

    this.state.activeItemXrewsi.tetragwnika = this.state.activeItem.tetragwnika;
    this.state.activeItemXrewsi.timimonadas = this.state.activeItem.timimonadas;
    this.state.activeItemXrewsi.kathgoria = this.state.activeItem.kathgoria;
    this.state.activeItemXrewsi.pagkos = this.state.activeItem.nameP;

    console.log(this.state.activeItemXrewsi);
    this.setState({ modalPX: true });
  };

  //ΚΛΕΙΝΕΙ MODAL
  handleClose = () => {
    this.state.activeItem = [];
    this.setState({ activeItem: [] });
    this.setState({ modalP: false });
  };

  handleCloseX = () => {
    this.state.activeItemXrewsi = [{}];
    this.setState({ activeItemXrewsi: [{}] });

    this.setState({ modalPX: false });
    this.setState({ state: this.state });
  };
  handleCloseE = () => {
    this.state.activeItemE = [];
    this.setState({ activeItemE: [] });
    this.setState({ modalE: false });
  };

  handleCloseEC = () => {
    this.state.activeItemEC = [];
    this.setState({ activeItemEC: [] });
    this.setState({ modalEC: false });
  };

  handleCloseLaiki = () => {
    this.state.activeItemLaiki = [];
    this.setState({ activeItemLaiki: [] });
    this.setState({ modalL: false });
  };
  handleChangeEC = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemEC = { ...this.state.activeItemEC, [name]: value };
    // formData.append([name], value);
    console.log("ok");
    this.setState({ activeItemEC });
  };
  ClearLaikiSelect() {
    this.state.laikiselected = "-";
    this.state.laikiselectedcenter = center;
    this.setState({ state: this.state });
    this.refreshList();
  }

  handleLaikiSelect(e, latlng) {
    const value = e.target.value;

    console.log(value);

    this.state.laikiselected = value;
    this.state.laikiselectedcenter = latlng;

    const { map } = this.state;
    if (map) map.flyTo(latlng, 18);

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  handleChangeSelectIcon = (e) => {
    const name = "iconeE";
    const value = e.target.value;
    const activeItemE = { ...this.state.activeItemE, [name]: value };

    console.log(activeItemE);
    this.setState({ activeItemE });

    this.setState({ state: this.state });
  };
  handleChangeLogo = (e) => {
    console.log("ok");
    var logo = e.target.files[0];
    // formData.append("logo", logo);

    this.state.activeItemEC.logo = e.target.files[0];
  };

  handleMultiSelectChange = (e) => {
    const name = "laikiAgoraId";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleMultiSelectChangeΚ = (e) => {
    const name = "kathgoria";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleChangeEp = (e) => {
    console.log(e);
    console.log(e.target.id);
    console.log(e.target.name);
    console.log(e.target.value);

    const name = e.target.id;
    const value = e.target.value;
    const activeItemE = { ...this.state.activeItemE, [name]: value };

    // console.log(activeItemE);
    this.setState({ activeItemE });
  };

  handleChangeEnarxiE = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItemE.enarxiE = fulldate;

    console.log(this.state.activeItemE);
    this.setState({ state: this.state });
    // this.setState({ activeItemE });
  };

  handleChangeLixiE = (e) => {
    // console.log(e);

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    this.state.activeItemE.lixiE = fulldate;

    this.setState({ state: this.state });
  };

  handleChange = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleChangeArxDoc = (e) => {
    var files = e.target.files[0];
    console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // console.log(name)
    // const arxdocitem = { ...this.state.arxdocitem, [name]: files };

    // this.setState({ arxdocitem });

    this.state.arxdocitem.file_field = e.target.files[0];
    this.state.arxdocitem.pagkos = this.state.activeItem.anagnwristikoP;
    this.state.arxdocitem.description = fname[0];
  };

  handleChangePagkosBools = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });
  };

  handleChangePagkosBoolsPrint = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    let searchItem = { ...this.state.searchItem, [name]: value };

    if (name == "all" && value == true) {
      searchItem.isAnastoli = false;
      searchItem.exeiplirwsei = false;
    }

    console.log(searchItem);

    this.setState({ searchItem });

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  };

  handleChangeSelect = (e) => {
    const name = "categoryP";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleChangeLaikiMeres = (e) => {
    console.log(e);
    console.log(e.target.id);
    console.log(e.target.checked);

    const name = e.target.id;
    const value = e.target.checked;

    const activeItemLaiki = { ...this.state.activeItemLaiki, [name]: value };

    console.log(activeItemLaiki);
    this.setState({ activeItemLaiki });
  };

  handleChangeLaiki = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemLaiki = { ...this.state.activeItemLaiki, [name]: value };

    console.log(activeItemLaiki);
    this.setState({ activeItemLaiki });

    this.setState({ state: this.state });
  };

  checkaddress = () => {
    let address = this.state.activeItemE.dieuthinsi;

    let latS = "";
    let lngS = "";

    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        latS = lat;
        lngS = lng;
        console.log(lat, lng);
        var tmpPos = [];
        tmpPos.push(latS);
        tmpPos.push(lngS);
        posseleted.push(tmpPos);

        if (this.state.mapModal) {
          this.state.mapModal.flyTo(tmpPos, 18);
          var marker = L.marker([latS, lngS]);

          this.state.mapModal.addLayer(marker);
        }

        // if (this.state.mapModal != null) {
        //   var marker = L.marker([latS, lngS]);
        //   var markers = L.markerClusterGroup();
        //   markers.addLayer(marker);
        //   this.state.mapModal.addLayer(markers);

        //   const { map } = this.state;
        //   if (map) map.flyTo(latlng, 18);

        // }
      },
      (error) => {
        console.error(error);
      }
    );

    // Geocode.fromAddress("Eiffel Tower").then(
    //   (response) => {
    //     const { lat, lng } = response.results[0].geometry.location;
    //     console.log(lat, lng);
    //   },
    //   (error) => {
    //     console.error(error);
    //   }
    // );
  };

  handlePostEp = () => {
    // console.log(newItem);
    // console.log(posseleted);
    const newItem = this.state.activeItemE;

    let lenghtps = posseleted.length;

    if (lenghtps != 0) {
      newItem.coordsE = posseleted[lenghtps - 1];
    }

    if (adressel != "") {
      newItem.dieuthinsi = adressel;
    }
    newItem.centerE = newItem.coordsE;

    // newItem.enarxiE = "2021-04-21";
    // newItem.lixiE = "2021-04-26";
    // newItem.iconeE = "test";

    if (newItem.tetragwnika != "" && newItem.timimonadas != "") {
      newItem.poso =
        parseInt(newItem.tetragwnika) * parseInt(newItem.timimonadas);
    }

    console.log(newItem);
    // console.log(posseleted);

    if (newItem.centerE[0] == 0) {
      alert("Παρακαλούμε επιλέξτε σημείο στον χάρτη");
    } else {
      if (newItem.id) {
        // axios
        //   .put(
        //     `https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/${newItem.id}/`,
        //     newItem
        //   )
        session
          .put(`api/Epixeiriseis/${newItem.id}/`, newItem)
          .then(this.refreshList());
        if (this.state.arxdocitemE.file_field != undefined) {
          setDocE(this.state.arxdocitemE);
          this.refreshListDocs();
        }
      } else {
        // axios
        //   .post("https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/", newItem)
        session.post("api/Epixeiriseis/", newItem).then(this.refreshList());
        if (this.state.arxdocitemE.file_field != undefined) {
          setDocE(this.state.arxdocitemE);
          this.refreshListDocs();
        }
      }

      this.state.activeItemE = [];
      this.refreshList();
      this.handleCloseE();
      document.getElementById("file_field").value = null;
    }
    // newE = true;
  };

  handleNewE = () => {
    // console.log(item);
    this.state.activeItemE.userIdE = "";
    this.state.activeItemE.nameE = "";
    this.state.activeItemE.afmE = "";

    this.state.activeItemE.arfak = "";
    this.state.activeItemE.antikatastasi = false;
    this.state.activeItemE.paratiriseis = "";
    this.state.activeItemE.dieuthinsi = "";

    this.state.activeItemE.siteE = "";
    this.state.activeItemE.posoE = "";
    this.state.activeItemE.covidStatus = "";
    this.state.activeItemE.wrarioE = "";
    this.state.activeItemE.wrarioL = "";
    this.state.activeItemE.iconeE = "";

    // this.state.activeItemE = item;
    this.setState({ modalE: true });
    // console.log(this.state.modalE);
    console.log(this.state.activeItemE);
    // this.render();
  };
  handleNewEC = () => {
    // console.log(item);
    this.state.activeItemEC.nameEC = "";
    this.state.activeItemEC.logo = "";
    // this.state.activeItemE = item;
    this.setState({ modalEC: true });
    // console.log(this.state.modalE);
    console.log(this.state.activeItemEC);
    // this.render();
  };

  handleEditE = (item) => {
    console.log(item);
    this.state.activeItemE = item;

    this.refreshListDocs();

    this.state.selectedDocsE = this.state.docsListE.filter(
      (itemS) => String(itemS.epixeirisi) === String(item.nameE)
    );

    console.log(this.state.selectedDocsE);

    // this.state.activeItemE = item;
    this.setState({ modalE: true });
    // console.log(this.state.modalE);
    console.log(this.state.activeItemE);
    // this.render();
  };

  handleDeleteE = (item) => {
    // axios
    //   .delete(`https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/${item.id}`)
    session.delete(`api/Epixeiriseis/${item.id}`).then(this.refreshList());
    this.refreshList();
  };

  handleChangeArxDocE = (e) => {
    var files = e.target.files[0];
    console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // console.log(name)
    // const arxdocitemE = { ...this.state.arxdocitemE, [name]: files };

    // this.setState({ arxdocitemE });

    console.log(this.state.activeItemE);

    this.state.arxdocitemE.file_field = e.target.files[0];
    this.state.arxdocitemE.epixeirisi = this.state.activeItemE.nameE;
    this.state.arxdocitemE.description = fname[0];
  };

  handleChangeLixi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItem.lixiP = fulldate;

    console.log(this.state.activeItem);

    this.setState({ state: this.state });
    // this.setState({ activeItem });
  };

  handleChangeLixiAdeias = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItem.lixiAP = fulldate;

    console.log(this.state.activeItem);

    this.setState({ state: this.state });
    // this.setState({ activeItem });
  };

  handleChangeEnarxi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItem.enarxiP = fulldate;

    console.log(this.state.activeItem);
    // this.setState({ activeItem });
  };
  handleChangeEnarxiAdeias = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // console.log(fulldate);

    this.state.activeItem.enarxiAP = fulldate;

    console.log(this.state.activeItem);
    // this.setState({ activeItem });
  };

  handlePostLaiki = (e) => {
    console.log(posseleted);
    const newItem = this.state.activeItemLaiki;

    var length = posseleted.length;

    if (posseleted[length - 1] != undefined) {
      newItem.centerLA = posseleted[length - 1];
      newItem.coordsLA = posseleted[length - 1];
    }

    console.log(newItem);
    // console.log(posseleted);

    if (newItem.id) {
      // axios
      //   .put(
      //     `https://epixirin-volos.govcloud.gr/be/api/LaikiAgora/${newItem.id}/`,
      //     newItem
      //   )
      session
        .put(`api/LaikiAgora/${newItem.id}/`, newItem)
        .then(this.refreshList());
    } else {
      // axios
      //   .post("https://epixirin-volos.govcloud.gr/be/api/LaikiAgora/", newItem)

      session.post("api/LaikiAgora/", newItem).then(this.refreshList());
    }

    this.state.activeItem = [];
    this.refreshList();
    this.handleCloseLaiki();
  };

  handlePost = () => {
    // console.log(newItem);
    // console.log(posseleted);
    const newItem = this.state.activeItem;

    var length = posseleted.length;

    if (posseleted[length - 1] != undefined) {
      newItem.coordsP1 = posseleted[length - 1];
    }
    if (posseleted[length - 2] != undefined) {
      newItem.coordsP2 = posseleted[length - 2];
    }
    if (posseleted[length - 3] != undefined) {
      newItem.coordsP3 = posseleted[length - 3];
    }
    if (posseleted[length - 4] != undefined) {
      newItem.coordsP4 = posseleted[length - 4];
    }
    newItem.centerP = newItem.coordsP1;
    // newItem.userIdp = 2;

    console.log(newItem);
    // console.log(posseleted);

    if (newItem.id) {
      // formData.append("id", newItem.id);
      // axios
      //   .put(
      //     `https://epixirin-volos.govcloud.gr/be/api/Pagkoi/${newItem.id}/`,
      //     newItem
      //   )
      session
        .put(`api/Pagkoi/${newItem.id}/`, newItem)
        .then(this.refreshList());

      if (this.state.arxdocitem.file_field != undefined) {
        setDoc(this.state.arxdocitem);
        this.refreshListDocs();
      }
    } else {
      this.state.arxdocitem.pagkos = newItem.anagnwristikoP;

      // axios
      //   .post("https://epixirin-volos.govcloud.gr/be/api/Pagkoi/", newItem)
      session.post("api/Pagkoi/", newItem).then(this.refreshList());

      if (this.state.arxdocitem.file_field != undefined) {
        setDoc(this.state.arxdocitem);
        this.refreshListDocs();
      }
    }

    this.state.activeItem = [];
    this.refreshList();
    this.refreshListDocs();
    this.handleClose();
    this.setState({ state: this.state });
  };
  // c

  handleNewL = () => {
    // console.log(item);
    // this.state.activeItemLaiki = [];

    this.state.activeItemLaiki.nameLA = "";
    this.state.activeItemLaiki.perioxiA = "";
    // this.state.activeItem = item;
    this.setState({ modalL: true });
  };

  handleNew = () => {
    // console.log(item);
    this.state.activeItem.nameP = "";
    this.state.activeItem.anagnwristikoP = "";
    this.state.activeItem.adeioP = false;
    this.state.activeItem.afmP = "";
    this.state.activeItem.siteP = "";
    this.state.activeItem.userIdp = "";
    this.state.activeItem.posoP = "";
    this.state.activeItem.plirwse = false;
    this.state.activeItem.isinteam = false;
    this.state.activeItem.anastoli = false;
    this.state.activeItem.patronymo = "";
    this.state.activeItem.dieuthinsi = "";
    this.state.activeItem.tilefwno = "";
    this.state.activeItem.kinito = "";
    this.state.activeItem.typos = "";
    this.state.activeItem.kathgoria = "";
    this.state.activeItem.adeiaAllouDimou = false;
    this.state.activeItem.adeiaDimou = false;
    this.state.activeItem.adeiaApokentrwmenis = false;
    this.state.activeItem.laikiAgoraId = "";
    this.state.activeItem.categoryP = "";
    // this.state.activeItem = item;
    this.setState({ modalP: true });
    // console.log(this.state.modalP);
    // console.log(this.state.activeItem);
    // this.render();
  };

  handleEdit = (item) => {
    if (item.arfak == null) {
      item.arfak = "";
    }
    // console.log(item);
    this.state.activeItem = item;

    this.state.selectedDocs = this.state.docsList.filter(
      (itemS) => itemS.pagkos === item.anagnwristikoP
    );

    // this.state.activeItem = item;
    this.setState({ modalP: true });
    // console.log(this.state.modalP);
    // console.log(this.state.activeItem);
    // this.render();
  };

  handleEditLaiki = (item) => {
    this.state.activeItemLaiki = item;

    this.state.laikiselected = item.nameLA;
    this.state.laikiselectedcenter = item.latlng;

    console.log(this.state.activeItemLaiki);
    // this.state.activeItem = item;
    this.setState({ modalL: true });
    // console.log(this.state.modalP);
    // console.log(this.state.activeItemLaiki);
    // this.render();
  };

  handleEditXrewsi = (item) => {
    this.state.activeItemXrewsi = item;
    console.log(this.state.activeItemXrewsi);
    this.setState({ modalPX: true });
  };

  handleDelete = (item) => {
    // axios
    //   .delete(`https://epixirin-volos.govcloud.gr/be/api/Pagkoi/${item.id}`)
    session.delete(`api/Pagkoi/${item.id}`).then(this.refreshList());
    this.refreshList();
    this.setState({ state: this.state });
  };
  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
    if (!userParser("Admin") && !userParser("SuperUsers")) {
      window.location.href = "/";
    }
    this.refreshList();
    this.refreshListDocs();
    this.refreshListCategory();
  }

  refreshList() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/Pagkoi/")
    session
      .get("api/Pagkoi/")
      .then((res) => this.setState({ PagkoiList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/")
    session
      .get("api/PagkoiXrewsi/")
      .then((res) => this.setState({ PagkoiXrewsiList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/LaikiAgoraMini/")
    session
      .get("api/LaikiAgoraMini/")
      .then((res) => this.setState({ LaikesSelectList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/LaikiAgora/")
    session
      .get("api/LaikiAgora/")
      .then((res) => this.setState({ LaikesList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/Epixeiriseis/")
    session
      .get("api/Epixeiriseis/")
      .then((res) => this.setState({ EpixeiriseisList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/ParousiaPagkou/")
    session
      .get("api/ParousiaPagkou/")
      .then((res) => this.setState({ ParousiesList: res.data }))
      .catch((err) => console.log(err));
  }

  refreshListDocs() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/FileFieldFormPagkos/")
    session
      .get("api/FileFieldFormPagkos/")
      .then((res) => this.setState({ docsList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/FileFieldFormEpixeirisi/")
    session
      .get("api/FileFieldFormEpixeirisi/")
      .then((res) => this.setState({ docsListE: res.data }))
      .catch((err) => console.log(err));
  }

  refreshListCategory() {
    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/PagkosCategory/")
    session
      .get("api/PagkosCategory/")
      .then((res) => this.setState({ PagkosCategoryList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get("https://epixirin-volos.govcloud.gr/be/api/EpixeirisiCategory/")
    session
      .get("api/EpixeirisiCategory/")
      .then((res) => this.setState({ EpixeiriseisCategoryList: res.data }))
      .catch((err) => console.log(err));
  }
  handleLaikiSelect(e) {
    const value = e.target.value;

    console.log(value);

    this.state.laikiselected = value;

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }
  handleChangeBools = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    const activeItem = { ...this.state.activeItem, [name]: value };

    console.log(activeItem);
    this.setState({ activeItem });
  };

  handleChangeArxDocE = (e) => {
    var files = e.target.files[0];
    console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // console.log(name)
    // const arxdocitemE = { ...this.state.arxdocitemE, [name]: files };

    // this.setState({ arxdocitemE });

    console.log(this.state.activeItemE);

    this.state.arxdocitemE.file_field = e.target.files[0];
    this.state.arxdocitemE.epixeirisi = this.state.activeItemE.nameE;
    this.state.arxdocitemE.description = fname[0];
  };

  render() {
    console.log(this.state.laikiselected);

    let tmpItems = this.state.PagkoiList;

    if (this.state.searchItem.all == false) {
      console.log(this.state.searchItem);

      tmpItems = this.state.PagkoiList.filter(
        (item) =>
          item.anastoli === this.state.searchItem.isAnastoli &&
          item.plirwse === this.state.searchItem.exeiplirwsei
      );
    }

    // .filter(
    //   (item) => String(item.laikiAgoraId) === String(this.state.laikiselected)
    // );
    const tmpItemsLaikes = this.state.LaikesList;

    const tmpItemsParousies = this.state.ParousiesList;
    const tmpItemsLaikesSelect = this.state.LaikesSelectList;
    const { markers } = this.state;
    const classes = this.state.useStyles;
    const theme = this.state.useTheme;
    let EpixeiriseisItems = this.state.EpixeiriseisList;
    const Epcategories = this.state.EpixeiriseisCategoryList;

    let categories = [];

    if (this.state.PagkosCategoryList != undefined) {
      categories = this.state.PagkosCategoryList;
    }

    if (categories != undefined) {
      for (var i = 0; i < tmpItems.length; i++) {
        // console.log(tmpItems[i]);
        let categorytmp = categories.filter(
          (item) => String(item.namePC) === String(tmpItems[i].categoryP)
        );
        // console.log(categorytmp);
        if (categorytmp[0] != undefined) {
          tmpItems[i].color = categorytmp[0].colorPC;
          tmpItems[i].Bcolor = categorytmp[0].description;
        }
      }
    } else {
      this.setState({ state: this.state });
    }

    const positionC = [39.365, 22.951];
    const open = this.state.open;

    // }
    if (this.state.activeItem.coordsP1 == undefined) {
      this.state.activeItem.coordsP1 = [0, 0];
      this.state.activeItem.coordsP2 = [0, 0];
      this.state.activeItem.coordsP3 = [0, 0];
      this.state.activeItem.coordsP4 = [0, 0];
    }

    console.log(this.state.activeItem.laikiAgoraId);
    console.log(this.state.laikiselected);

    if (this.state.activeItem.laikiAgoraId == undefined) {
      this.state.activeItem.laikiAgoraId = this.state.laikiselected;
    }

    if (Epcategories != undefined) {
      for (var i = 0; i < EpixeiriseisItems.length; i++) {
        // console.log(EpixeiriseisItems[0].iconeE);
        // console.log(Epcategories);
        let categorytmp = Epcategories.filter(
          (item) => String(item.nameEC) === String(EpixeiriseisItems[i].iconeE)
        );

        if (categorytmp[0] != undefined) {
          // console.log(categorytmp);

          EpixeiriseisItems[i].logo = categorytmp[0].logo;

          const myIcon = L.icon({
            iconUrl: categorytmp[0].logo,
            iconSize: [25, 25],
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
          });

          EpixeiriseisItems[i].logo2 = myIcon;

          // const iconPerson = new L.Icon({
          //   iconUrl: require(categorytmp[0].logo),
          //   iconRetinaUrl: require(categorytmp[0].logo),
          //   iconAnchor: null,
          //   popupAnchor: null,
          //   shadowUrl: null,
          //   shadowSize: null,
          //   shadowAnchor: null,
          //   iconSize: new L.Point(60, 75),
          //   className: "leaflet-div-icon",
          // });

          // EpixeiriseisItems[i].logo = iconPerson;
        }
      }
    } else {
      Epcategories = undefined;
      this.setState({ state: this.state });
    }

    if (this.state.activeItemE.coordsE == undefined) {
      this.state.activeItemE.coordsE = [0, 0];
    }

    if (this.state.laikiselectedcenter == "") {
      this.state.laikiselectedcenter = center;
    }

    if (
      this.state.searchItem.isAnastoli == true ||
      this.state.searchItem.exeiplirwsei == true
    ) {
      this.state.searchItem.all = false;
    } else if (
      this.state.searchItem.isAnastoli == false &&
      this.state.searchItem.exeiplirwsei == false
    ) {
      this.state.searchItem.all = true;
    }

    UdataTable = [];

    UdataTableL = [];

    UdataTableP = [];

    UdataTablePP = [];

    UdataTableE = [];

    UdataTableEC = [];

    UdataTablePC = [];

    UdataTablePX = [];

    UdataTablePXC = [];

    for (var i = 0; i < tmpItemsLaikes.length; i++) {
      const dataRow = {};

      const item = tmpItemsLaikes[i];
      dataRow.eidos = "Λαϊκή";
      dataRow.name = tmpItemsLaikes[i].nameLA;
      dataRow.perioxiA = tmpItemsLaikes[i].perioxiA;

      const days = "";

      if (item.deytera) {
        days += "Δευτέρα ";
      }
      if (item.triti) {
        days += "Τρίτη ";
      }
      if (item.tetarti) {
        days += "Τετάρτη ";
      }
      if (item.pempti) {
        days += "Πέμπτη ";
      }
      if (item.paraskeui) {
        days += "Παρασκευή ";
      }
      if (item.savvato) {
        days += "Σάββατο ";
      }
      if (item.kyriaki) {
        days += "Κυριακή ";
      }

      dataRow.days = days;

      dataRow.e = (
        <Button
          round
          color="info"
          size="sm"
          onClick={() => this.handleEditLaiki(item)}
        >
          <i className="fa fa-edit"></i>
        </Button>
      );

      UdataTable.push(dataRow);

      UdataTableL.push(dataRow);
    }

    for (var i = 0; i < tmpItems.length; i++) {
      const dataRow = {};

      const item = tmpItems[i];

      dataRow.eidos = "Πάγκος";
      dataRow.name = tmpItems[i].userIdp;
      dataRow.userIdp = tmpItems[i].nameP;
      dataRow.timeE = String(tmpItems[i].enarxiP);

      var date1 = new Date();
      var date2 = new Date(tmpItems[i].lixiP);

      if (date1 > date2) {
        dataRow.timeL2 = (
          <Button round color="danger" size="sm">
            <i className="fa fa-exclamation"></i>
          </Button>
        );
      } else if (
        date2.getFullYear() == date1.getFullYear() &&
        date2.getMonth() - date1.getMonth() <= 1
      ) {
        dataRow.timeL2 = (
          <Button round color="warning" size="sm">
            <i className="fa fa-question"></i>
          </Button>
        );
      } else {
        dataRow.timeL2 = (
          <Button round color="success" size="sm">
            <i className="fa fa-check"></i>
          </Button>
        );
      }
      dataRow.timeL = String(tmpItems[i].lixiP);

      dataRow.catmedia = <Badge color={tmpItems[i].Bcolor}> </Badge>;
      dataRow.category = tmpItems[i].categoryP;

      dataRow.afmP = tmpItems[i].afmP;
      dataRow.tetragwnika = tmpItems[i].tetragwnika;
      dataRow.timimonadas = tmpItems[i].timimonadas;
      dataRow.poso = tmpItems[i].poso;

      dataRow.laikis = tmpItems[i].laikiAgoraId;
      dataRow.e = (
        <Button
          round
          color="info"
          size="sm"
          onClick={() => this.handleEdit(item)}
        >
          <i className="fa fa-edit"></i>
        </Button>
      );

      UdataTable.push(dataRow);

      UdataTableP.push(dataRow);
    }

    let UdataTablePX = [];

    let tmpItemsXrewsi = this.state.PagkoiXrewsiList;

    for (var i = 0; i < tmpItemsXrewsi.length; i++) {
      const dataRow = {};

      const item = tmpItemsXrewsi[i];

      dataRow.enarxiP = item.enarxiP;
      dataRow.lixiP = item.lixiP;
      dataRow.poso = item.poso;
      dataRow.pagkos = item.pagkos;

      const tmppagkoi = this.state.PagkoiList.filter(
        (itemP) => itemP.nameP === item.pagkos
      );

      if (tmppagkoi[0] != undefined) {
        dataRow.epwn = tmppagkoi[0].userIdp;
        dataRow.afm = tmppagkoi[0].afmP;
      }
      dataRow.time = item.enarxiP + " εώς " + item.lixiP;
      const tmpparous = this.state.ParousiesList.filter(
        (itemPp) => itemPp.pagkos === item.pagkos && itemPp.parousia == true
      );

      let num = 0;

      var dateE = new Date(item.enarxiP);
      var dateL = new Date(item.lixiP);

      for (var j = 0; j < tmpparous.length; j++) {
        var date = new Date(tmpparous[j].date);

        if (date >= dateE && date <= dateL) {
          num = num + 1;
        }
      }

      if (item.plirwse == true) {
        dataRow.plirwse = "Ναί";
      } else {
        dataRow.plirwse = "Όχι";
      }

      UdataTablePX.push(dataRow);
    }

    for (var i = 0; i < tmpItemsParousies.length; i++) {
      const dataRow = {};
      const item = tmpItemsParousies[i];
      const ppa = tmpItemsParousies[i].pagkos;

      dataRow.pagkos = ppa;
      dataRow.date = item.date;

      if (item.parousia == true) {
        dataRow.parousia = "παρών";
      } else {
        dataRow.parousia = "απών";
      }

      console.log(this.state.PagkoiList);

      let tmpPagkos = this.state.PagkoiList.filter(
        (itemP) => String(itemP.nameP) === String(ppa)
      );

      console.log(tmpPagkos);

      if (tmpPagkos[0] != undefined) {
        dataRow.fname = tmpPagkos[0].userIdp;
        dataRow.laiki = tmpPagkos[0].laikiAgoraId;
      }

      UdataTablePP.push(dataRow);
    }

    for (var i = 0; i < EpixeiriseisItems.length; i++) {
      const dataRow = {};
      const item = EpixeiriseisItems[i];

      dataRow.eidos = "Επιχείρηση";
      dataRow.name = EpixeiriseisItems[i].nameE;
      dataRow.timeE = String(EpixeiriseisItems[i].enarxiE);
      dataRow.timeL = String(EpixeiriseisItems[i].lixiE);

      var date1 = new Date();
      var date2 = new Date(EpixeiriseisItems[i].lixiE);

      if (date1 > date2) {
        dataRow.timeL2 = (
          <Button round color="danger" size="sm">
            <i className="fa fa-exclamation"></i>
          </Button>
        );
      } else if (
        date2.getFullYear() == date1.getFullYear() &&
        date2.getMonth() - date1.getMonth() <= 1
      ) {
        dataRow.timeL2 = (
          <Button round color="warning" size="sm">
            <i className="fa fa-question"></i>
          </Button>
        );
      } else {
        dataRow.timeL2 = (
          <Button round color="success" size="sm">
            <i className="fa fa-check"></i>
          </Button>
        );
      }

      dataRow.userIdE = String(EpixeiriseisItems[i].userIdE);
      dataRow.afmE = String(EpixeiriseisItems[i].afmE);
      dataRow.wrarioE = String(EpixeiriseisItems[i].wrarioE);
      dataRow.wrarioL = String(EpixeiriseisItems[i].wrarioL);

      dataRow.catmedia = (
        <img style={{ width: "25px" }} src={EpixeiriseisItems[i].logo} />
      );
      dataRow.category = EpixeiriseisItems[i].iconeE;
      dataRow.laikis = "-";

      dataRow.e = (
        <Button
          round
          color="info"
          size="sm"
          onClick={() => this.handleEditE(item)}
        >
          <i className="fa fa-edit"></i>
        </Button>
      );

      UdataTable.push(dataRow);

      UdataTableE.push(dataRow);
    }

    console.log(Epcategories);

    for (var i = 0; i < Epcategories.length; i++) {
      const dataRow = {};
      const item = Epcategories[i];
      const name = Epcategories[i].nameEC;

      const tmpepix = EpixeiriseisItems.filter((item) => item.iconeE === name);

      dataRow.name = name;
      dataRow.plithos = tmpepix.length;

      var randomColor = require("randomcolor");
      var color = randomColor();

      dataRow.fill = color;
      // dataRow.fill = "#4CAF50";

      UdataTableEC.push(dataRow);
    }

    for (var i = 0; i < categories.length; i++) {
      const dataRow = {};
      const item = categories[i];
      const name = categories[i].namePC;
      const color = categories[i].colorPC;

      const tmpepix = tmpItems.filter((item) => item.categoryP === name);

      dataRow.name = name;
      dataRow.plithos = tmpepix.length;

      dataRow.fill = color;

      UdataTablePC.push(dataRow);
    }

    console.log(UdataTablePC);

    return (
      <>
        <GridContainer>
          <GridItem>
            <Card>
              <CardHeader color="success">
                Λαϊκές - Πάγκοι - Επιχειρήσεις
              </CardHeader>
              <NavPills
                color="success"
                tabs={[
                  //   {
                  //     tabButton: "Συγκεντρωτικά",
                  //     tabContent: (
                  //       <CardBody>
                  //         <ReactTable
                  //           data={UdataTable}
                  //           filterable
                  //           resizable={false}
                  //           columns={[
                  //             {
                  //               Header: "Είδος",
                  //               accessor: "eidos",
                  //             },
                  //             {
                  //               Header: "Επωνυμία",
                  //               accessor: "name",
                  //             },
                  //             {
                  //               Header: "Λαϊκή",
                  //               accessor: "laikis",
                  //             },

                  //             {
                  //               Header: "Ιδιότητα",
                  //               accessor: "category",
                  //             },
                  //             {
                  //               Header: "",
                  //               accessor: "catmedia",
                  //               disableFilters: true,
                  //               disableSortBy: true,
                  //             },
                  //             {
                  //               Header: "Έναρξη",
                  //               accessor: "timeE",
                  //             },
                  //             {
                  //               Header: "Λήξη",
                  //               accessor: "timeL",
                  //             },
                  //             {
                  //               Header: " ",
                  //               accessor: "timeL2",
                  //               disableFilters: true,
                  //               disableSortBy: true,
                  //             },
                  //             {
                  //               Header: "Ενέργειες",
                  //               accessor: "e",
                  //               disableFilters: true,
                  //               disableSortBy: true,
                  //             },

                  //             // {
                  //             //   Header: " ",
                  //             //   accessor: "",
                  //             // },
                  //           ]}
                  //           defaultPageSize={5}
                  //           showPaginationTop
                  //           showPaginationBottom={false}
                  //           className="-striped -highlight"
                  //         />
                  //       </CardBody>
                  //     ),
                  //   },
                  //   {
                  //     tabButton: "Λαικές",
                  //     tabContent: (
                  //       <CardBody>
                  //         <ReactTable
                  //           data={UdataTableL}
                  //           filterable
                  //           resizable={false}
                  //           columns={[
                  //             {
                  //               Header: "Επωνυμία",
                  //               accessor: "name",
                  //             },
                  //             {
                  //               Header: "Περιοχή",
                  //               accessor: "perioxiA",
                  //             },
                  //             {
                  //               Header: "Ημέρες",
                  //               accessor: "days",
                  //               disableFilters: true,
                  //               disableSortBy: true,
                  //             },
                  //             {
                  //               Header: "Ενέργειες",
                  //               accessor: "e",
                  //               disableFilters: true,
                  //               disableSortBy: true,
                  //             },
                  //           ]}
                  //           defaultPageSize={5}
                  //           showPaginationTop
                  //           showPaginationBottom={false}
                  //           className="-striped -highlight"
                  //         />
                  //       </CardBody>
                  //     ),
                  //   },
                  {
                    tabButton: "Πάγκοι",
                    tabContent: (
                      <CardBody>
                        {/* <ReactTable
                            data={UdataTableP}
                            filterable
                            resizable={false}
                            columns={[
                              {
                                Header: "Επωνυμία",
                                accessor: "userIdp",
                              },
                              {
                                Header: "Ονοματεπώνυμο",
                                accessor: "name",
                              },

                              {
                                Header: "ΑΦΜ",
                                accessor: "afmP",
                              },
                              {
                                Header: "Λαϊκή",
                                accessor: "laikis",
                              },

                              {
                                Header: "Ιδιότητα",
                                accessor: "category",
                              },
                              {
                                Header: "",
                                accessor: "catmedia",
                                disableFilters: true,
                                disableSortBy: true,
                              },
                              {
                                Header: "Μήκος πάγκου",
                                accessor: "tetragwnika",
                              },
                              {
                                Header: "Ποσό",
                                accessor: "poso",
                              },

                              {
                                Header: "Έναρξη",
                                accessor: "timeE",
                              },
                              {
                                Header: "Λήξη",
                                accessor: "timeL",
                              },
                              {
                                Header: " ",
                                accessor: "timeL2",
                                disableFilters: true,
                                disableSortBy: true,
                              },
                              {
                                Header: "Ενέργειες",
                                accessor: "e",
                                disableFilters: true,
                                disableSortBy: true,
                              },

                              // {
                              //   Header: " ",
                              //   accessor: "",
                              // },
                            ]}
                            defaultPageSize={5}
                            showPaginationTop
                            showPaginationBottom={false}
                            className="-striped -highlight"
                          /> */}
                        {/* <ResponsiveContainer width="100%" height="100%"> */}

                        <NavPills
                          color="success"
                          tabs={[
                            {
                              tabButton: "Bar",
                              tabContent: (
                                <BarChart
                                  width={1500}
                                  height={500}
                                  data={UdataTablePC}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend />
                                  <Bar
                                    dataKey="plithos"
                                    barSize={20}
                                    fill="#4CAF50"
                                    wrapperStyle={style}
                                  />
                                </BarChart>
                              ),
                            },
                            {
                              tabButton: "RadialBar",
                              tabContent: (
                                <RadialBarChart
                                  width={1500}
                                  height={500}
                                  cx="50%"
                                  cy="50%"
                                  innerRadius="10%"
                                  outerRadius="80%"
                                  barSize={10}
                                  data={UdataTablePC}
                                >
                                  <RadialBar
                                    minAngle={15}
                                    label={{
                                      position: "insideStart",
                                      fill: "#fff",
                                    }}
                                    background
                                    clockWise
                                    dataKey="plithos"
                                  />
                                  <Legend
                                    iconSize={10}
                                    layout="vertical"
                                    verticalAlign="middle"
                                    wrapperStyle={style}
                                  />
                                </RadialBarChart>
                              ),
                            },
                            {
                              tabButton: "Pie",
                              tabContent: (
                                <PieChart width={1500} height={500}>
                                  <Pie
                                    width={1500}
                                    height={500}
                                    dataKey="plithos"
                                    isAnimationActive={false}
                                    data={UdataTablePC}
                                    outerRadius={80}
                                    fill="#4CAF50"
                                    label
                                  />

                                  <Tooltip />
                                </PieChart>
                              ),
                            },
                            {
                              tabButton: "Spyder",
                              tabContent: (
                                <RadarChart
                                  width={1500}
                                  height={500}
                                  cx="50%"
                                  cy="50%"
                                  outerRadius="80%"
                                  data={UdataTablePC}
                                >
                                  <PolarGrid />
                                  <PolarAngleAxis dataKey="name" />
                                  <PolarRadiusAxis />
                                  <Radar
                                    name="Mike"
                                    dataKey="plithos"
                                    stroke="#4CAF50"
                                    fill="#4CAF50"
                                    fillOpacity={0.6}
                                  />
                                </RadarChart>
                              ),
                            },
                          ]}
                        />

                        {/* </ResponsiveContainer> */}
                      </CardBody>
                    ),
                  },
                  {
                    tabButton: "Επιχειρήσεις",
                    tabContent: (
                      <>
                        <CardBody>
                          {/*   <ReactTable
                            data={UdataTableE}
                            filterable
                            resizable={false}
                            columns={[
                              {
                                Header: "Επωνυμία",
                                accessor: "name",
                              },
                              {
                                Header: "Ονοματεπώνυο",
                                accessor: "userIdE",
                              },
                              {
                                Header: "ΑΦΜ",
                                accessor: "afmE",
                              },
                              {
                                Header: "Κατηγορία",
                                accessor: "category",
                              },
                              {
                                Header: "",
                                accessor: "catmedia",
                                disableFilters: true,
                                disableSortBy: true,
                              },
                              {
                                Header: "Ωράριο Έναρξη",
                                accessor: "wrarioE",
                              },
                              {
                                Header: "Ωράριο Λήξη",
                                accessor: "wrarioL",
                              },
                              {
                                Header: "Έναρξη",
                                accessor: "timeE",
                              },
                              {
                                Header: "Λήξη",
                                accessor: "timeL",
                              },
                              {
                                Header: " ",
                                accessor: "timeL2",
                                disableFilters: true,
                                disableSortBy: true,
                              },
                              {
                                Header: "Ενέργειες",
                                accessor: "e",
                                disableFilters: true,
                                disableSortBy: true,
                              },

                              // {
                              //   Header: " ",
                              //   accessor: "",
                              // },
                            ]}
                            defaultPageSize={5}
                            showPaginationTop
                            showPaginationBottom={false}
                            className="-striped -highlight"
                          /> */}

                          <NavPills
                            color="success"
                            tabs={[
                              {
                                tabButton: "Bar",
                                tabContent: (
                                  <BarChart
                                    width={1500}
                                    height={500}
                                    data={UdataTableEC}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar
                                      dataKey="plithos"
                                      barSize={20}
                                      fill="#4CAF50"
                                      wrapperStyle={style}
                                    />
                                  </BarChart>
                                ),
                              },
                              {
                                tabButton: "RadialBar",
                                tabContent: (
                                  <RadialBarChart
                                    width={1500}
                                    height={500}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius="10%"
                                    outerRadius="80%"
                                    barSize={10}
                                    data={UdataTableEC}
                                  >
                                    <RadialBar
                                      minAngle={15}
                                      label={{
                                        position: "insideStart",
                                        fill: "#fff",
                                      }}
                                      background
                                      clockWise
                                      dataKey="plithos"
                                    />
                                    <Legend
                                      iconSize={10}
                                      layout="vertical"
                                      verticalAlign="middle"
                                      wrapperStyle={style}
                                    />
                                  </RadialBarChart>
                                ),
                              },
                              {
                                tabButton: "Pie",
                                tabContent: (
                                  <PieChart width={1500} height={500}>
                                    <Pie
                                      width={1500}
                                      height={500}
                                      dataKey="plithos"
                                      isAnimationActive={false}
                                      data={UdataTableEC}
                                      outerRadius={80}
                                      fill="#4CAF50"
                                      label
                                    />

                                    <Tooltip />
                                  </PieChart>
                                ),
                              },
                              {
                                tabButton: "Spyder",
                                tabContent: (
                                  <RadarChart
                                    width={1500}
                                    height={500}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius="80%"
                                    data={UdataTableEC}
                                  >
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="name" />
                                    <PolarRadiusAxis />
                                    <Radar
                                      name="Mike"
                                      dataKey="plithos"
                                      stroke="#4CAF50"
                                      fill="#4CAF50"
                                      fillOpacity={0.6}
                                    />
                                  </RadarChart>
                                ),
                              },
                            ]}
                          />
                        </CardBody>
                      </>
                    ),
                  },
                ]}
              />
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

import React, { Component } from "react";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Header from "components/Header/Header.js";
import session from "../../api/session";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
  Input,
} from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { Visibility } from "@mui/icons-material";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";

class AlertDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      progress: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDeleteFile = (fileId) => {
    this.setState({ progress: true });
    session
      .delete(`api/business-violation-details-file/${fileId}/delete/`)
      .then(() => {
        setTimeout(() => {
          // this.props.handleViolationUpdateFiles();
          this.setState({ progress: false });
          this.props.SuccessDeletedFile();
        }, 800);
      })
      .catch((e) => {
        this.setState({ progress: false });
        console.log(e);
      });
  };

  render() {
    const { file } = this.props;
    const { open } = this.state;

    return (
      <div>
        <button onClick={this.handleClickOpen} style={{ marginLeft: "10px" }}>
          Διαγραφή {file.file.split("/").pop()}
        </button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Διαγραφή αρχείου;"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Είστε σίγουροι ότι θέλετε να διαγράψετε το αρχείο{" "}
              <b>{file.file.split("/").pop()}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.state.progress ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={() => this.handleDeleteFile(file.id)}
                variant="contained"
                color="primary"
              >
                Ναι
              </Button>
            )}

            <Button variant="contained" onClick={this.handleClose}>
              Οχι
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

class ViolationDetailsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propositionProtocolNumber: "",
      propositionDate: null,
      noticeProtocolNumber: "",
      noticeDate: null,
      boardDecisionProtocolNumber: "",
      boardDecisionDate: null,
      mayorVerdictProtocolNumber: "",
      mayorVerdictDate: null,
      decisionPublicNoticeProtocolNumber: "",
      decisionPublicNoticeDate: null,
      policeVerdictProtocolNumber: "",
      policeVerdictDate: null,
      sealingEssayProtocolNumber: "",
      sealingEssayDate: null,
      unsealingEssayProtocolNumber: "",
      unsealingEssayDate: null,
      violationDetailsData: {},
      files: [],
      selectedFiles: [],
      alertMsg: "",
      alertSeverity: "success",
      alertShow: false,
    };
  }

  handleFileChange = (event) => {
    const selected_files = Array.from(event.target.files);
    this.setState({ selectedFiles: selected_files });
  };

  handleAlert = (severity, msg) => {
    this.setState({
      alertShow: true,
      alertMsg: msg,
      alertSeverity: severity,
    });
    setTimeout(() => {
      this.setState({
        alertShow: false,
      });
    }, 2000);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const pathname = window.location.pathname;
    const businessId = pathname.split("/")[2];
    const violationId = pathname.split("/")[4];

    if (this.props.mode === "update") {
      session
        .put(`api/violation-details/update/${violationId}/`, {
          proposition_protocol_number: this.state.propositionProtocolNumber,
          notice_protocol_number: this.state.noticeProtocolNumber,
          board_decision_protocol_number: this.state
            .boardDecisionProtocolNumber,
          mayor_verdict_protocol_number: this.state.mayorVerdictProtocolNumber,
          decision_public_notice_protocol_number: this.state
            .decisionPublicNoticeProtocolNumber,
          police_verdict_protocol_number: this.state
            .policeVerdictProtocolNumber,
          sealing_essay_protocol_number: this.state.sealingEssayProtocolNumber,
          unsealing_essay_protocol_number: this.state
            .unsealingEssayProtocolNumber,
          propositon_date: this.state.propositionDate,
          notice_date: this.state.noticeDate,
          board_decision_date: this.state.boardDecisionDate,
          mayor_verdict_date: this.state.mayorVerdictDate,
          decision_public_notice_date: this.state.decisionPublicNoticeDate,
          police_verdict_date: this.state.policeVerdictDate,
          sealing_essay_date: this.state.sealingEssayDate,
          unsealing_essay_date: this.state.unsealingEssayDate,
        })
        .then((resp) => {
          if (this.state.selectedFiles.length > 0) {
            this.createFiles(resp.data.id);
          } else {
            this.handleAlert("success", "Η λεπτομέρειες παράβασης ανανεώθηκαν");
          }
          // window.location.href = `/business-details/${businessId}`
          this.props.handleViolationDetailUpdate();
        })
        .catch((e) => {
          this.handleAlert(
            "error",
            "Πηγε κάτι στραβά, παρακαλώ επικοινωνήστε με τους διαχειριστές"
          );
          console.log(e);
          return;
        });
    } else {
      session
        .post(`api/violation-details/create/${violationId}/`, {
          proposition_protocol_number: this.state.propositionProtocolNumber,
          notice_protocol_number: this.state.noticeProtocolNumber,
          board_decision_protocol_number: this.state
            .boardDecisionProtocolNumber,
          mayor_verdict_protocol_number: this.state.mayorVerdictProtocolNumber,
          decision_public_notice_protocol_number: this.state
            .decisionPublicNoticeProtocolNumber,
          police_verdict_protocol_number: this.state
            .policeVerdictProtocolNumber,
          sealing_essay_protocol_number: this.state.sealingEssayProtocolNumber,
          unsealing_essay_protocol_number: this.state
            .unsealingEssayProtocolNumber,
          propositon_date: this.state.propositionDate,
          notice_date: this.state.noticeDate,
          board_decision_date: this.state.boardDecisionDate,
          mayor_verdict_date: this.state.mayorVerdictDate,
          decision_public_notice_date: this.state.decisionPublicNoticeDate,
          police_verdict_date: this.state.policeVerdictDate,
          sealing_essay_date: this.state.sealingEssayDate,
          unsealing_essay_date: this.state.unsealingEssayDate,
        })
        .then((resp) => {
          if (this.state.selectedFiles.length > 0) {
            this.createFiles(resp.data.id);
            this.props.history.push({
              pathname: `/business-details/${businessId}`,

              state: {
                businessId: businessId,
                newValue: "3",
              },
            });
          } else {
            this.handleAlert("success", "Η λεπτομέρειες παράβασης ανανεώθηκαν");
            // console.log(this.props.match.params.businessId);

            this.props.history.push({
              pathname: `/business-details/${businessId}`,

              state: {
                businessId: businessId,
                newValue: "3",
              },
            });
          }

          // window.location.href = `/business-details/${businessId}`
        })
        .catch((e) => {
          this.handleAlert(
            "error",
            "Πηγε κάτι στραβά, παρακαλώ επικοινωνήστε με τους διαχειριστές"
          );
          console.log(e);
          return;
        });
    }
  };

  createFiles = (violationId) => {
    const formData = new FormData();
    this.state.selectedFiles.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("business_violation_details", violationId);
    session
      .post(`api/violation-details-files/${violationId}/create/`, formData)
      .then(() => {
        this.setState({ selectedFiles: [] });
        this.handleAlert("success", "Η λεπτομέρειες παράβασης ανανεώθηκαν");
        this.props.handleViolationDetailUpdate();
      })
      .catch((e) => {
        console.log(e);
        return;
      });
  };

  handleDeleteFile = (fileId) => {
    session
      .delete(`api/business-violation-details-file/${fileId}/delete/`)
      .then(() => {
        this.props.handleViolationDetailUpdate();
      })
      .catch((e) => {
        console.log(e);
        return;
      });

    this.setState((prevState) => ({
      files: prevState.files.filter((file) => file.id !== fileId),
    }));
  };

  isFieldDisable = (fieldName) => {
    const violationParse = this.props.violationMappingFields;

    const violationFields = violationParse.filter(
      (e) => e.eidos_choice == this.props.eidosParabasis
    );

    // console.log(
    //   violationFields.some((e) =>
    //     JSON.parse(e.fields_to_exclude).some((field) => field.name == fieldName)
    //   )
    // );

    return violationFields.some((e) =>
      JSON.parse(e.fields_to_exclude).some((field) => field.name == fieldName)
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.mode === "update") {
      if (this.props.violationDetailsData != prevProps.violationDetailsData) {
        this.setState({
          violationDetailsData: this.props.violationDetailsData,
          propositionProtocolNumber: this.props.violationDetailsData
            .proposition_protocol_number,
          propositionDate: this.props.violationDetailsData.propositon_date,
          noticeProtocolNumber: this.props.violationDetailsData
            .notice_protocol_number,
          noticeDate: this.props.violationDetailsData.notice_date,
          boardDecisionProtocolNumber: this.props.violationDetailsData
            .board_decision_protocol_number,
          boardDecisionDate: this.props.violationDetailsData
            .board_decision_date,
          mayorVerdictProtocolNumber: this.props.violationDetailsData
            .mayor_verdict_protocol_number,
          mayorVerdictDate: this.props.violationDetailsData.mayor_verdict_date,
          decisionPublicNoticeProtocolNumber: this.props.violationDetailsData
            .decision_public_notice_protocol_number,
          decisionPublicNoticeDate: this.props.violationDetailsData
            .decision_public_notice_date,
          policeVerdictProtocolNumber: this.props.violationDetailsData
            .police_verdict_protocol_number,
          policeVerdictDate: this.props.violationDetailsData
            .police_verdict_date,
          sealingEssayProtocolNumber: this.props.violationDetailsData
            .sealing_essay_protocol_number,
          sealingEssayDate: this.props.violationDetailsData.sealing_essay_date,
          unsealingEssayProtocolNumber: this.props.violationDetailsData
            .unsealing_essay_protocol_number,
          unsealingEssayDate: this.props.violationDetailsData
            .unsealing_essay_date,
        });
      }
      if (this.props.files != prevProps.files) {
        this.setState({
          files: this.props.files,
          // selectedFiles: this.props.files,
        });
      }
    }
  }

  render() {
    return (
      <Box p={4} m={2} bgcolor="white">
        <Card raised>
          <CardContent>
            <form onSubmit={this.handleSubmit}>
              {/* {this.state.violation_description.id} Violation date */}
              {this.state.alertShow ? (
                <Alert severity={this.state.alertSeverity}>
                  {this.state.alertMsg}
                </Alert>
              ) : null}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom align="center">
                    {this.props.mode === "update" ? "Ενημέρωση" : "Δημιουργία"}{" "}
                    Λεπτομερειών Παράβασης
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable(
                        "proposition_protocol_number"
                      )
                        ? "0.5"
                        : "1",
                    }}
                    name="proposition_protocol_number"
                    label="Αρ. πρωτ. εισήγησης"
                    margin="normal"
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.propositionProtocolNumber}
                    onChange={(event) =>
                      this.setState({
                        propositionProtocolNumber: event.target.value,
                      })
                    }
                    disabled={this.isFieldDisable(
                      "proposition_protocol_number"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable("propositon_date")
                        ? "0.5"
                        : "1",
                    }}
                    name="propositon_date"
                    label="Ημερομηνία εισήγησης"
                    type="date"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.propositionDate}
                    onChange={(event) =>
                      this.setState({ propositionDate: event.target.value })
                    }
                    disabled={this.isFieldDisable("propositon_date")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable("notice_protocol_number")
                        ? "0.5"
                        : "1",
                    }}
                    name="notice_protocol_number"
                    label="Αρ. πρωτ. ειδοποίησης"
                    margin="normal"
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.noticeProtocolNumber}
                    onChange={(event) =>
                      this.setState({
                        noticeProtocolNumber: event.target.value,
                      })
                    }
                    disabled={this.isFieldDisable("notice_protocol_number")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable("notice_date") ? "0.5" : "1",
                    }}
                    name="notice_date"
                    label="Ημερομηνία ειδοποίησης"
                    type="date"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.noticeDate}
                    onChange={(event) =>
                      this.setState({ noticeDate: event.target.value })
                    }
                    disabled={this.isFieldDisable("notice_date")}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable(
                        "board_decision_protocol_number"
                      )
                        ? "0.5"
                        : "1",
                    }}
                    name="board_decision_protocol_number"
                    label="Αρ. πρωτ. απόφασης επιτροπής"
                    margin="normal"
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.boardDecisionProtocolNumber}
                    onChange={(event) =>
                      this.setState({
                        boardDecisionProtocolNumber: event.target.value,
                      })
                    }
                    disabled={this.isFieldDisable(
                      "board_decision_protocol_number"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable("board_decision_date")
                        ? "0.5"
                        : "1",
                    }}
                    name="board_decision_date"
                    label="Ημερομηνία απόφασης επιτροπής"
                    type="date"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.boardDecisionDate}
                    onChange={(event) =>
                      this.setState({ boardDecisionDate: event.target.value })
                    }
                    disabled={this.isFieldDisable("board_decision_date")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable(
                        "mayor_verdict_protocol_number"
                      )
                        ? "0.5"
                        : "1",
                    }}
                    name="mayor_verdict_protocol_number"
                    label="Αρ. πρωτ. απόφασης δημάρχου"
                    margin="normal"
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.mayorVerdictProtocolNumber}
                    onChange={(event) =>
                      this.setState({
                        mayorVerdictProtocolNumber: event.target.value,
                      })
                    }
                    disabled={this.isFieldDisable(
                      "mayor_verdict_protocol_number"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable("mayor_verdict_date")
                        ? "0.5"
                        : "1",
                    }}
                    name="mayor_verdict_date"
                    label="Ημερομηνία απόφασης δημάρχου"
                    type="date"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.mayorVerdictDate}
                    onChange={(event) =>
                      this.setState({ mayorVerdictDate: event.target.value })
                    }
                    disabled={this.isFieldDisable("mayor_verdict_date")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable(
                        "decision_public_notice_protocol_number"
                      )
                        ? "0.5"
                        : "1",
                    }}
                    name="decision_public_notice_protocol_number"
                    label="Αρ. πρωτ. κοινοποίησης απόφασης"
                    margin="normal"
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.decisionPublicNoticeProtocolNumber}
                    onChange={(event) =>
                      this.setState({
                        decisionPublicNoticeProtocolNumber: event.target.value,
                      })
                    }
                    disabled={this.isFieldDisable(
                      "decision_public_notice_protocol_number"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable(
                        "decision_public_notice_date"
                      )
                        ? "0.5"
                        : "1",
                    }}
                    name="decision_public_notice_date"
                    label="Ημερομηνία κοινοποίησης απόφασης"
                    type="date"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.decisionPublicNoticeDate}
                    onChange={(event) =>
                      this.setState({
                        decisionPublicNoticeDate: event.target.value,
                      })
                    }
                    disabled={this.isFieldDisable(
                      "decision_public_notice_date"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable(
                        "police_verdict_protocol_number"
                      )
                        ? "0.5"
                        : "1",
                    }}
                    name="police_verdict_protocol_number"
                    label="Αρ. πρωτ. απόφασης Α.Τ."
                    margin="normal"
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.policeVerdictProtocolNumber}
                    onChange={(event) =>
                      this.setState({
                        policeVerdictProtocolNumber: event.target.value,
                      })
                    }
                    disabled={this.isFieldDisable(
                      "police_verdict_protocol_number"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable("police_verdict_date")
                        ? "0.5"
                        : "1",
                    }}
                    name="police_verdict_date"
                    label="Ημερομηνία απόφασης Α.Τ."
                    type="date"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.policeVerdictDate}
                    onChange={(event) =>
                      this.setState({ policeVerdictDate: event.target.value })
                    }
                    disabled={this.isFieldDisable("police_verdict_date")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable(
                        "sealing_essay_protocol_number"
                      )
                        ? "0.5"
                        : "1",
                    }}
                    name="sealing_essay_protocol_number"
                    label="Αρ. πρωτ. έκθεσης σφράγισης"
                    margin="normal"
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.sealingEssayProtocolNumber}
                    onChange={(event) =>
                      this.setState({
                        sealingEssayProtocolNumber: event.target.value,
                      })
                    }
                    disabled={this.isFieldDisable(
                      "sealing_essay_protocol_number"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable("sealing_essay_date")
                        ? "0.5"
                        : "1",
                    }}
                    name="sealing_essay_date"
                    label="Ημερομηνία έκθεσης σφράγισης"
                    type="date"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.sealingEssayDate}
                    onChange={(event) =>
                      this.setState({ sealingEssayDate: event.target.value })
                    }
                    disabled={this.isFieldDisable("sealing_essay_date")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable(
                        "unsealing_essay_protocol_number"
                      )
                        ? "0.5"
                        : "1",
                    }}
                    name="unsealing_essay_protocol_number"
                    label="Αρ. πρωτ. έκθεσης αποσφράγισης"
                    margin="normal"
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.unsealingEssayProtocolNumber}
                    onChange={(event) =>
                      this.setState({
                        unsealingEssayProtocolNumber: event.target.value,
                      })
                    }
                    disabled={this.isFieldDisable(
                      "unsealing_essay_protocol_number"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    style={{
                      opacity: this.isFieldDisable("unsealing_essay_date")
                        ? "0.5"
                        : "1",
                    }}
                    name="unsealing_essay_date"
                    label="Ημερομηνία έκθεσης αποσφράγισης"
                    type="date"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="standard"
                    fullWidth
                    value={this.state.unsealingEssayDate}
                    onChange={(event) =>
                      this.setState({ unsealingEssayDate: event.target.value })
                    }
                    disabled={this.isFieldDisable("unsealing_essay_date")}
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <FormControl fullWidth margin="normal">
                    <p>Λοιπή αλληλογραφία</p>
                    <Input
                      id="file-input"
                      type="file"
                      //   multiple
                      inputProps={{ multiple: true }}
                      onChange={this.handleFileChange}
                    />
                  </FormControl>
                  {this.state.files.length > 0 && (
                    <ul>
                      <p>Επιλεγμένα αρχεία</p>
                      {this.state.files.map((file) => (
                        <li key={file.id}>
                          <a
                            href={file.file}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {file.file.split("/").pop()}
                          </a>
                          <AlertDialogComponent
                            openDialog={this.openDialog}
                            file={file}
                            SuccessDeletedFile={() =>
                              this.props.handleViolationDetailUpdateFiles()
                            }
                          />
                          {/* <button
                            onClick={() => this.handleDeleteFile(file.id)}
                            style={{ marginLeft: "10px" }}
                          >
                            Διαγραφή
                          </button> */}
                        </li>
                      ))}
                    </ul>
                  )}
                </Grid>
              </Grid>
              <Button variant="contained" color="primary" type="submit">
                Αποθηκευση
              </Button>
            </form>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

class ViolationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdate: props.match.path.includes("update"),
      detailData: {},
      violationDetailsData: {},
      files: [],
      businessParabaseis: {},
      mappingViolationFields: [],
    };
  }
  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }

    this.fetchBusiness();
    this.fetchViolationMappingFields();
    this.fetchBusinessParabaseis();

    if (this.state.isUpdate) {
      this.fetchViolationDetails();
    }
  }
  async fetchViolationDetails() {
    session
      .get(
        //   `api/business-parabaseis/${this.props.match.params.violationId}/update/`
        `api/violation-details/update/${this.props.match.params.violationId}/`
      )
      .then((resp) => {
        this.setState({
          violationDetailsData: resp.data,
        });

        const detailsId = resp.data.id;

        session
          .get(`api/violation-details-files/${detailsId}/`)
          .then((resp) => {
            this.setState({
              files: resp.data,
            });
          })
          .catch((e) => {
            console.log(e);
            return;
          });
      })
      .catch((e) => {
        console.log(e);
        return;
      });
  }

  async fetchBusinessParabaseis() {
    session
      .get(
        `api/business-parabaseis/${this.props.match.params.violationId}/update/`
      )
      .then((resp) => {
        this.setState({ businessParabaseis: resp.data });
      })
      .catch((e) => {
        console.log(e);
        return;
      });
  }
  async fetchBusiness() {
    session
      .get(`api/Epixeiriseis/${this.props.match.params.bussinessId}/`)
      .then((resp) => {
        this.setState({
          detailData: resp.data,
        });
      })
      .catch((e) => {
        console.log(e);
        return;
      });
  }
  async fetchViolationMappingFields() {
    await session
      .get(`api/eidos-mapping-violation-list/`)
      .then((resp) => {
        this.setState({ mappingViolationFields: resp.data });
      })
      .catch((e) => {
        console.log(e);
        return;
      });
  }
  updateViolationDetaildsData() {
    this.fetchBusiness();
    this.fetchBusinessParabaseis();
    this.fetchViolationDetails();
    this.fetchViolationMappingFields();
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax small filter bgImage={require("assets/img/profile-bg2.jpg")}>
          <div style={{ height: "200px" }} />
        </Parallax>

        <Box
          sx={{
            width: "fit-content",
            borderBottom: 1,
            borderColor: "divider",
            mx: "auto",
            textAlign: "center",
          }}
        >
          <h3>
            Λεπτομέρειες παράβασης επιχείρησης - {this.state.detailData.nameE}
          </h3>
        </Box>
        {this.state.isUpdate ? (
          <ViolationDetailsForm
            propositionProtocolNumber={
              this.state.violationDetailsData.proposition_protocol_number
            }
            propositionDate={this.state.violationDetailsData.propositon_date}
            noticeProtocolNumber={
              this.state.violationDetailsData.notice_protocol_number
            }
            noticeDate={this.state.violationDetailsData.notice_date}
            boardDecisionProtocolNumber={
              this.state.violationDetailsData.board_decision_protocol_number
            }
            boardDecisionDate={
              this.state.violationDetailsData.board_decision_date
            }
            mayorVerdictProtocolNumber={
              this.state.violationDetailsData.mayor_verdict_protocol_number
            }
            mayorVerdictDate={
              this.state.violationDetailsData.mayor_verdict_date
            }
            decisionPublicNoticeProtocolNumber={
              this.state.violationDetailsData
                .decision_public_notice_protocol_number
            }
            decisionPublicNoticeDate={
              this.state.violationDetailsData.decision_public_notice_date
            }
            policeVerdictProtocolNumber={
              this.state.violationDetailsData.police_verdict_protocol_number
            }
            policeVerdictDate={
              this.state.violationDetailsData.police_verdict_date
            }
            sealingEssayProtocolNumber={
              this.state.violationDetailsData.sealing_essay_protocol_number
            }
            sealingEssayDate={
              this.state.violationDetailsData.sealing_essay_date
            }
            unsealingEssayProtocolNumber={
              this.state.violationDetailsData.unsealing_essay_protocol_number
            }
            unsealingEssayDate={
              this.state.violationDetailsData.unsealing_essay_date
            }
            files={this.state.files}
            violationDetailsData={this.state.violationDetailsData}
            mode="update"
            eidosParabasis={this.state.businessParabaseis.eidos}
            handleViolationDetailUpdate={() =>
              this.updateViolationDetaildsData()
            }
            handleViolationDetailUpdateFiles={(e) =>
              this.fetchViolationDetails(e)
            }
            violationDetailId={this.props.match.params.violationId}
            violationMappingFields={this.state.mappingViolationFields}
          />
        ) : (
          <ViolationDetailsForm
            mode="create"
            violationDetailId={this.props.match.params.violationId}
            violationMappingFields={this.state.mappingViolationFields}
            eidosParabasis={this.state.businessParabaseis.eidos}
            history={this.props.history}
          />
        )}
      </div>
    );
  }
}
export default ViolationDetails;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { el } from "date-fns/locale";
import Input from "@mui/material/Input";

import grLocale from "date-fns/locale/el";
import session from "../../api/session";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import EventSpy from "components/eventSpy";
import OutlinedInput from "@mui/material/OutlinedInput";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Menu } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Document, Page } from "react-pdf";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function BusinessViolationFile(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [eggrafo, setEggrafo] = React.useState("");
  const [fileBlob, setFileBlob] = React.useState("");
  const [fileFields, setFileFields] = React.useState([]);
  const [fieldValue, setFieldValue] = React.useState([]);
  const [isError, setIsError] = React.useState(false);
  const [modalSpinner, setModalSpinner] = React.useState(false);
  const [pdfURL, setPDFUrl] = React.useState("");

  const handleClose = () => {
    setEggrafo("");
    setFieldValue([]);
    setFileFields([]);
    setFileBlob("");
    setOpen(false);
  };

  const handleChange = (e) => {
    setEggrafo(e.target.value);
  };
  const fetchPdfFileEmbed = () => {
    if (!eggrafo) {
      return;
    }

    session
      .get(`api/eggrafo/?filename=${eggrafo}`, { responseType: "blob" })
      .then((resp) => {
        setPDFUrl(resp.request.responseURL);
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        setFileBlob(url);
      });
    session.get(`api/eggrafo/detail/?filename=${eggrafo}`).then((resp) => {
      const fieldArray = [];
      for (var i = 1; i <= resp.data.fields; i++) {
        fieldArray.push(i);
      }
      setFileFields(fieldArray);
    });
  };

  const fieldChange = (e) => {
    console.log(e.target.value, e.target.name);
    setFieldValue({
      ...fieldValue,
      [e.target.name]: e.target.value,
    });
  };

  const onSave = () => {
    console.log(fieldValue);
    setModalSpinner(true);

    session
      .post("api/eggrafo/post/", {
        eggrafo: eggrafo,
        parabasi_id: props.id,
        values: fieldValue,
      })
      .then((resp) => {
        setIsError(false);
        setModalSpinner(false);
        setOpen(false);
        setEggrafo("");
        setFieldValue([]);
        setFileFields([]);
        setFileBlob("");
        props.handleChange();
        props.handleSpinner("parabasiSpinner");
      })
      .catch((e) => {
        console.log(e.response.data);
        setModalSpinner(false);
        setIsError(true);

        return;
      });
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="contained"
        sx={{
          width: "fit-content",
          height: "78%",
          textTransform: "none",
          bgcolor: "#3b388e",
        }}
      >
        <AttachFileIcon></AttachFileIcon>Εγγραφο
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xl"}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Δημιουργία Αρχείου Παράβασης
        </DialogTitle>
        {isError ? (
          <Alert severity="error">
            Κάτι πήγε στραβά με το αρχείο! Παρακαλώ επικοινωνήστε με τους
            διαχειριστές
          </Alert>
        ) : (
          ""
        )}

        <DialogContent>
          {/* start modal spinner */}
          <Modal
            open={modalSpinner}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Παρακαλώ περιμένετε...
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <CircularProgress />
              </Typography>
            </Box>
          </Modal>
          {/* modalspinner */}

          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={2} lg={12}>
              <FormControl sx={{ m: 1, width: 200 }}>
                <TextField
                  label="Επιλογή Εγγράφου"
                  sx={{ width: 800 }}
                  select
                  value={eggrafo}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>-------------------</em>
                  </MenuItem>
                  {props.eggrafa.map((e) => (
                    <MenuItem key={e.name} value={e.name}>
                      {e.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Button variant="contained" onClick={fetchPdfFileEmbed}>
                  {" "}
                  Επιλογη
                </Button>
              </FormControl>
            </Grid>

            {fileFields.length > 0 &&
              fileFields.map((e, idx) => (
                <Grid item lg={2} key={idx}>
                  <TextField
                    label={`object_${idx + 1}`}
                    sx={{ width: 200 }}
                    name={`object_${idx + 1}`}
                    onChange={fieldChange}
                    value={fieldValue[`object_${idx + 1}`]}
                  ></TextField>
                </Grid>
              ))}

            <Grid item lg={12} xs={12}>
              {fileBlob ? (
                <iframe
                  src={pdfURL}
                  frameborder="0"
                  height="1200px"
                  width="80%"
                ></iframe>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="success" onClick={onSave}>
            Αποθηκευση
          </Button>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Κλεισιμο
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export { BusinessViolationFile };

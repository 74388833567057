import React from "react";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import profile from "assets/img/faces/user.png";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";
import {
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Progress,
  Container,
  Row,
  Input,
  Nav,
  TabPane,
  TabContent,
  // Button,
  NavLink,
  NavItem,
  Col,
  // UncontrolledTooltip,
} from "reactstrap";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { ImportsNotUsedAsValues } from "typescript";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        // {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "250px" }}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <UsersPanel></UsersPanel>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

class UsersPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      logged_in: localStorage.getItem("token") ? true : false,
      id: "",
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      // activeItem: { username: "", first_name: "", last_name: "", email: "" },
    };
  }

  //   handleChange = (e) => {
  //     const { name, value } = e.target;

  //     // const activeItem = { ...this.state, [name]: value };
  //     // console.log(this.state.activeItem);
  //     // this.setState({ activeItem });

  //     this.setState({ [name]: value });
  //   };

  //   handle_signup = (e, data) => {
  //     const newItem = [];

  //     newItem.id = this.state.id;
  //     newItem.username = this.state.username;
  //     newItem.first_name = this.state.first_name;
  //     newItem.last_name = this.state.last_name;
  //     newItem.email = this.state.email;

  //     console.log(newItem);

  //     axios
  //       .put(`https://epixirin-volos.govcloud.gr/be/api/users/${newItem.id}/`, newItem)
  //       .then(this.refreshListUsers());
  //   };

  //   componentDidMount() {
  //     if (this.state.logged_in) {
  //       fetch("https://epixirin-volos.govcloud.gr/be/tests/current_user/", {
  //         headers: {
  //           Authorization: `JWT ${localStorage.getItem("token")}`,
  //         },
  //       })
  //         .then((res) => res.json())
  //         .then((json) => {
  //           this.setState({ id: json.id });
  //           this.setState({ username: json.username });
  //           this.setState({ first_name: json.first_name });
  //           this.setState({ last_name: json.last_name });
  //           this.setState({ email: json.email });
  //         });
  //     }

  //   }

  render() {
    const classes = this.state.useStyles;

    return (
      <>
        <GridContainer style={{marginTop:'10vh'}}>
          <GridItem xs={6}>
            <Card>
              <CardHeader color="success">Φόρμα Επικοινωνίας</CardHeader>
              <CardBody>
                <Row>
                  <CustomInput
                    id="username"
                    labelText="Όνομα..."
                    inputProps={{
                      // value: this.state.username,
                      label: "Όνομα",
                      id: "username",
                      name: "username",
                      onChange: (e) => this.handleChange(e),
                      type: "text",
                    }}
                  />
                </Row>
                <Row>
                  {/* <label>email </label> */}
                  <CustomInput
                    id="email"
                    labelText="email..."
                    inputProps={{
                      // value: this.state.email,
                      label: "email",
                      id: "email",
                      name: "email",
                      onChange: (e) => this.handleChange(e),
                      type: "text",
                    }}
                  />
                </Row>
                <Row>
                  {/* <label>Όνομα </label> */}
                  <CustomInput
                    id="first_name"
                    labelText="Μήνυμα..."
                    inputProps={{
                      // value: this.state.first_name,
                      label: "Μήνυμα ",
                      id: "first_name",
                      name: "first_name",
                      onChange: (e) => this.handleChange(e),
                      type: "text",
                    }}
                  />{" "}
                </Row>
                <Row>
                  <Button
                    size="sm"
                    round
                    onClick={() => this.handle_signup(this.state)}
                    color="success"
                  >
                    Αποστολή
                  </Button>
                </Row>
              </CardBody>{" "}
            </Card>
          </GridItem>
          <GridItem xs={6}>
            <Card>
              <CardHeader color="success">Φόρμα Επικοινωνίας</CardHeader>
              <CardBody>
                <h2>Υπαίθριο Εμπόριο</h2>

                <h3></h3>
                <h3></h3>
                <h3></h3>
                <Card>
                  <CardBody>
                  
                  </CardBody>{" "}
                </Card>
              </CardBody>{" "}
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

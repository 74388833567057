import * as React from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import Box from "@mui/material/Box";
import ListView from "./listView";
import session from "../api/session";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class ContactManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msgData: [],
    };
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
    session.get("api/contact-list/").then((res) => {
      this.setState({ msgData: res.data });
    });
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Header
          brand="e-Pixeirin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <Parallax small filter bgImage={require("assets/img/profile-bg2.jpg")}>
          <div style={{ height: "200px" }} />
        </Parallax>
        <Box
          sx={{
            width: "fit-content",
            borderBottom: 1,
            borderColor: "divider",
            mx: "auto",
            textAlign: "center",
          }}
        >
          <h3>Πίνακας Μηνυμάτων</h3>
        </Box>
        <ListView data={this.state.msgData}></ListView>
        <div style={{ marginTop: "200px" }}>
          <Footer />
        </div>
      </div>
    );
  }
}
export default ContactManagement;

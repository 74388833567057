import React, { useEffect, useState } from "react";
import axios from "axios";
import emailjs from "emailjs-com";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  useMapEvents,
  FeatureGroup,
  Tooltip,
  LayersControl,
  // Circle,
} from "react-leaflet";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";
import classNames from "classnames";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import "leaflet/dist/leaflet.css";
// import { isConstructorDeclaration } from "typescript";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CustomInput from "components/CustomInput/CustomInput.js";
// import Date from "react-datetime";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import Close from "@material-ui/icons/Close";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import badgeStyle from "assets/jss/material-kit-react/components/badgeStyle.js";
import Badge from "components/Badge/Badge.js";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import MultiSelect from "react-multi-select-component";

import redmarker from "assets/img/download.png";
import session from '../api/session'

import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// core components

import modalStyle from "assets/jss/material-kit-react/modalStyle.js";

import {
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Progress,
  Container,
  Row,
  Input,
  Nav,
  TabPane,
  TabContent,
  // Button,
  NavLink,
  NavItem,
  Col,
  // UncontrolledTooltip,
} from "reactstrap";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import { number } from "prop-types";
import { LaptopWindows } from "@material-ui/icons";
// import leaflet from 'https://unpkg.com/leaflet@1.5.1/dist/leaflet.css';
import ReactTable from "components/ReactTable/ReactTable.js";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
let UdataTableLa = [];
let UdataTablePids = [];
let UdataTableP = [];
let UdataTablePX = [];
// styles = {
//   label: {
//     cursor: "pointer",
//     paddingLeft: "0",
//     color: "rgba(0, 0, 0, 0.26)",
//     fontSize: "14px",
//     lineHeight: "1.428571429",
//     fontWeight: "400",
//     display: "inline-flex",
//   },
// };

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let posseleted = [];
let newE = false;
let editE = false;
let center = [39.365, 22.951];
let zoom = 18;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      style={{ borderRadius: "15px" }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Όλα</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
async function setDoc(item) {
  var url = "https://epixirin-volos.govcloud.gr/be/api/docuploadPagkos";
  //console.log(item);

  let formData = new FormData();
  formData.append("pagkos", item.pagkos);
  formData.append("description", item.description);
  formData.append("file_field", item.file_field);

  //console.log(formData);

  // let response = await axios.post(url, formData);

  let response = session.post('api/docuploadPagkos', formData)

  // let response = await axios.post(url, formData);
  //console.log(response);
}

// function MyComponent() {
//   const map = useMapEvents({
//     click: () => {
//       map.locate();
//     },
//     locationfound: (location) => {
//       //console.log("location found:", location);
//     },
//   });
//   return null;
// }

// function ContactUs() {
function sendEmail(item) {
  console.log(item);

  var messageE =
    "Πάγκος: " +
    item.pagkos +
    " (αρ. θέσης-Ονοματεπώνυμο), ήταν παράνομος και ελέγχτηκε απο τόν " +
    item.user +
    " με παρατηρήσεις: " +
    item.paratiriseis;

  var templateparams = {
    recipient: "devproset.gr@gmail.com",
    to_name: "Παρανομία πάγκου",
    from_name: "Epixirein",
    message: messageE,
  };
  emailjs.send(
    "service_7tpw2hx",
    "template_o3w1p69",
    templateparams,
    "user_3F32QndRSmIjunzp4yvqg"
  );
  console.log("email sent");
}

//   return (
//     <form className="contact-form" onSubmit={sendEmail}>
//       <input type="hidden" name="contact_number" />
//       <label>Name</label>
//       <input type="text" name="user_name" />
//       <label>Email</label>
//       <input type="email" name="user_email" />
//       <label>Message</label>
//       <textarea name="message" />
//       <input type="submit" value="Send" />
//     </form>
//   );
// }

function LocationMarker() {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";

  const [position, setPosition] = useState(null);
  // const newItem = useState([]);

  const map = useMapEvents({
    click(e) {
      var coord = e.latlng;
      var lat = coord.lat;
      var lng = coord.lng;
      // posseleted = lat;
      setPosition(e.latlng);
      var tmpPos = [];
      tmpPos.push(lat);
      tmpPos.push(lng);
      posseleted.push(tmpPos);
      console.log(
        "You clicked the map at latitude: " + lat + " and longitude: " + lng
      );

      // map.locate();
    },
    // locationfound(e) {
    //   setPosition(e.latlng);
    //   map.flyTo(e.latlng, map.getZoom());
    // },
  });

  return position === null ? null : <></>;
}

function LocationMarkerLaiki() {
  const [position, setPosition] = useState(null);
  // const newItem = useState([]);

  const map = useMapEvents({
    click(e) {
      var coord = e.latlng;
      var lat = coord.lat;
      var lng = coord.lng;
      // posseleted = lat;
      setPosition(e.latlng);
      var tmpPos = [];
      tmpPos.push(lat);
      tmpPos.push(lng);
      posseleted.push(tmpPos);
      console.log(
        "You clicked the map at latitude: " + lat + " and longitude: " + lng
      );

      // map.locate();
    },
    // locationfound(e) {
    //   setPosition(e.latlng);
    //   map.flyTo(e.latlng, map.getZoom());
    // },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Tooltip>You are here</Tooltip>
    </Marker>
  );
}

// function handleChange(e) {
//   //console.log(e.target);
//   //console.log(e.target.value);
//   //console.log(e.target.id);

// const { name, value } = e.target;
// this.state.newItem = { ...this.state.newItem, [name]:  value };

// //console.log(this.state.newItem);
// }

export default function MapLaAdmin() {
  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  let newItem = React.useState();
  let newItemC = React.useState();

  React.useEffect(() => {
    //console.log("e");
    //console.log(editE);
  }, [editE]);

  React.useEffect(() => {
    // //console.log("e");
    // //console.log(editE);
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  return (
    <div>
      <Header
        //color="transparent"
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg2.jpg")}
        style={{ height: "200px" }}
      />
      <br />
      <br />
      <br />
      <br />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <MapPanel />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

class MapPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      // useTheme: useTheme(),
      PagkoiList: [],
      PagkoiXrewsiList: [],
      LaikesList: [],
      docsList: [],
      map: null,
      LaikesSelectList: [],
      markers: [[0, 0]],
      activeItem: {},
      activeItemXrewsi: {},
      activeItemLaiki: {},
      modalP: false,
      modalPX: false,
      modalL: false,
      activeItemPar: [],
      modalPS: false,
      activeItemPP2: [],
      modalPP2: false,
      ParousiesList: [],
      laikiselected: "-",
      pagkosselected: "",
      laikiselectedcenter: "",
      perioxiselected: "",
      arxdocitem: {},
      selectedDocs: [],
      ElegxoiList: [],
      activeItemElegxos: {},
      ParanomoiList: [],
      XrewseisList: [],
      xrewsiepagk: "",
      xrewsiparag: "",
      modalPrint: false,
      activeItemPrint: [],
    };
  }

  handleCloseX = () => {
    this.state.activeItemXrewsi = [{}];
    this.setState({ activeItemXrewsi: [{}] });

    this.setState({ modalPX: false });
    this.setState({ state: this.state });
  };

  //ΚΛΕΙΝΕΙ MODAL
  handleClose = () => {
    this.state.activeItem = [{}];
    this.setState({ activeItem: [{}] });

    this.setState({ modalP: false });
    this.setState({ state: this.state });
  };

  handleCloseLaiki = () => {
    this.state.activeItemLaiki = [];
    this.setState({ activeItemLaiki: [] });
    this.setState({ modalL: false });
    this.setState({ state: this.state });
  };

  handleClosePS = () => {
    this.setState({ activeItemPar: [] });
    this.setState({ modalPS: false });
    this.setState({ state: this.state });
  };

  handleClosePP2 = () => {
    this.setState({ activeItemPP2: [] });
    this.setState({ modalPP2: false });
    this.setState({ state: this.state });
  };

  handleClosePrint = () => {
    this.setState({ activeItemPrint: [] });
    this.setState({ modalPrint: false });
    this.setState({ state: this.state });
  };

  ClearLaikiSelect() {
    this.state.laikiselected = "-";
    this.state.laikiselectedcenter = center;
    this.setState({ state: this.state });
    this.refreshList();
  }

  handleMultiSelectChange = (e) => {
    const name = "laikiAgoraId";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    //console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });

    // //console.log(e);
    // const selectedvalue = e;

    // for (var i = 0; i < selectedvalue.length; i++) {
    //   //console.log(selectedvalue[i].value);
    //   const ids = [];
    //   ids.push(selectedvalue[i].value);
    //   this.state.activeItem.laikiAgoraId = ids;
    // }
  };

  handleMultiSelectChangeΚ = (e) => {
    const name = "kathgoria";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    //console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleChangeP1 = (e) => {
    const value = e.target.value;

    const activeItem = { ...this.state.activeItem, tetragwnika: value };

    this.setState({ activeItem });

    // this.checkposo();
  };

  handleChangeP2 = (e) => {
    const value = e.target.value;

    const activeItem = { ...this.state.activeItem, timimonadas: value };

    this.setState({ activeItem });

    // this.checkposo();
  };

  // checkposo = () => {
  //   if (
  //     this.state.activeItem.tetragwnika != "" &&
  //     this.state.xrewsiepagk != ""
  //   ) {
  //     this.state.activeItem.poso =
  //       parseInt(this.state.activeItem.tetragwnika) *
  //       parseInt(this.state.activeItem.timimonadas);
  //     // const activeItem = {
  //     //   ...this.state.activeItem,
  //     //   poso:
  //     //     parseInt(this.state.activeItem.tetragwnika) *
  //     //     parseInt(this.state.activeItem.timimonadas),
  //     // };

  //     // this.setState({ this.state.activeItem });
  //   }
  // };

  handleElegxo(e, pagkosname) {
    this.state.activeItemElegxos.pagkos = pagkosname;
    this.state.activeItemElegxos.user = localStorage.getItem(
      "userselectedname"
    );

    const newItem = this.state.activeItemElegxos;

    // axios
    //   .post("https://epixirin-volos.govcloud.gr/be/api/ElegxosPagkou/", newItem)
    session.post('api/ElegxosPagkou/', newItem)
      .then(this.refreshList())
      .then(alert("Έγινε έλεγχος"));
  }

  handleChangeXrewsi = (e, item) => {
    //console.log(e);
    //console.log(e.target.id);
    // console.log(e.target.checked);
    console.log(item);

    item.plirwse = e.target.checked;
    item.user = localStorage.getItem("userselectedname");

    if (item.id) {
      // axios
      //   .put(
      //     `https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/${item.id}/`,
      // item
      // )
      session.put(`api/PagkoiXrewsi/${item.id}/`, item)
        .then(this.refreshList());
    } else {
      // axios
      //   .post("https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/", item)
      session.post("api/PagkoiXrewsi/", item)
        .then(this.refreshList());
    }

    this.refreshList();
    this.setState({ state: this.state });
    this.render();
  };

  handleChangeParousia = (e, item) => {
    //console.log(e);
    //console.log(e.target.id);
    // console.log(e.target.checked);
    console.log(item);

    item.parousia = e.target.checked;
    item.user = localStorage.getItem("userselectedname");

    if (item.id) {
      // axios
      //   .put(
      //     `https://epixirin-volos.govcloud.gr/be/api/ParousiaPagkou/${item.id}/`,
      //     item
      //   )
      session.put(`api/ParousiaPagkou/${item.id}/`)
        .then(this.refreshList());
    } else {
      // axios
      //   .post("https://epixirin-volos.govcloud.gr/be/api/ParousiaPagkou/", item)
      session.post("api/ParousiaPagkou/", item)
        .then(this.refreshList());
    }

    this.handleParousiaXrewsi(item);

    // this.refreshXrewseisAll(item.pagkos);
  };

  handleParousiaXrewsi = (itemA) => {
    let namePP = itemA.pagkos;
    let datePP = itemA.date;
    let par = itemA.parousia;

    const newItemPX = this.state.PagkoiXrewsiList.filter(
      (itemF) => itemF.pagkos === namePP && itemF.date === datePP
    );

    let item = {};

    if (newItemPX[0] !== undefined) {
      item = newItemPX[0];
    }

    console.log(itemA);
    console.log(item);

    item.date = itemA.datePP;
    item.kathgoria = itemA.kathgoria;
    item.pagkos = itemA.pagkos;
    item.tetragwnika = itemA.tetragwnika;
    item.poso = "";

    if (itemA.kathgoria === "επαγγελματίες") {
      item.timimonadas = this.state.xrewsiepagk;
    } else {
      item.timimonadas = this.state.xrewsiparag;
    }

    // item.timimonadas = itemA.timimonadas;

    console.log(item);

    let tet = 0;
    let timi = 0;

    tet = parseInt(itemA.tetragwnika);

    if (item.pragmtetragwnika !== "" && item.pragmtetragwnika !== undefined) {
      tet = parseInt(itemA.pragmtetragwnika);
    }

    timi = parseInt(item.timimonadas);

    console.log(tet);
    console.log(timi);
    console.log(tet * timi);

    // if (item.pragmtetragwnika != "") {
    //   item.poso = parseInt(item.pragmtetragwnika) * parseInt(item.timimonadas);
    // } else {
    //   item.poso = parseInt(item.tetragwnika) * parseInt(item.timimonadas);
    // }

    item.poso = String(tet * timi);

    if (itemA.parousia === false) {
      item.poso = "0";
    }

    console.log(item);

    if (item.id) {
      // formData.append("id", newItem.id);
      // axios
      //   .put(
      //     `https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/${item.id}/`,
      //     item
      //   )
      session.put(`api/PagkoiXrewsi/${item.id}/`, item)
        .then(this.refreshList())
        .catch((error) => {
          alert(error.response.data);
        });
    } else {
      // axios
      //   .post("https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/", item)

      session.post("api/PagkoiXrewsi/", item)
        .then(this.refreshList());
    }
  };

  handleParousiaXrewsiChange = () => {
    const item = this.state.activeItemPP2;

    if (item.pragmtetragwnika !== "") {
      item.poso = parseInt(item.pragmtetragwnika) * parseInt(item.timimonadas);
    } else {
      item.poso = parseInt(item.tetragwnika) * parseInt(item.timimonadas);
    }

    // axios
    //   .put(`https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/${item.id}/`, item)
    session.put(`api/PagkoiXrewsi/${item.id}/`, item)
      .then(this.refreshList())
      .catch((error) => {
        alert(error.response.data);
      });

    this.refreshList();
    this.handleClosePP2();
  };

  handleChange = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleChangePar = (e) => {
    const value = e.target.value;
    const activeItemPar = { ...this.state.activeItemPar, paratiriseis: value };

    this.setState({ activeItemPar });
  };

  handleChangePP2 = (e) => {
    const value = e.target.value;
    const activeItemPP2 = {
      ...this.state.activeItemPP2,
      pragmtetragwnika: value,
    };

    this.setState({ activeItemPP2 });
  };

  handlePostPar = () => {
    const item = this.state.activeItemPar;

    // axios
    //   .post("https://epixirin-volos.govcloud.gr/be/api/ParanomosPagkou/", item)
    session.post("api/ParanomosPagkou/", item)
      .then(this.refreshList());

    sendEmail(this.state.activeItemPar);

    this.handleClosePS();
  };

  handleChangeX = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    const activeItemXrewsi = { ...this.state.activeItemXrewsi, [name]: value };

    this.setState({ activeItemXrewsi });

    this.setState({ state: this.state });
  };

  handleChangeSelect = (e) => {
    const name = "categoryP";
    const value = e.target.value;
    const activeItem = { ...this.state.activeItem, [name]: value };

    //console.log(activeItem);
    this.setState({ activeItem });

    this.setState({ state: this.state });
  };

  handleChangeLaikiMeres = (e) => {
    //console.log(e);
    //console.log(e.target.id);
    //console.log(e.target.checked);

    const name = e.target.id;
    const value = e.target.checked;

    const activeItemLaiki = { ...this.state.activeItemLaiki, [name]: value };

    //console.log(activeItemLaiki);
    this.setState({ activeItemLaiki });
  };

  handlePagkosSelect(e, latlng) {
    const value = e.target.value;

    //console.log(value);

    this.state.pagkosselected = value;

    const { map } = this.state;
    if (map) map.flyTo(latlng, 21);

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  handleChangeLaiki = (e) => {
    // //console.log(e);
    // //console.log(e.target.id);

    // //console.log(e.target.value);

    const name = e.target.id;
    const value = e.target.value;
    const activeItemLaiki = { ...this.state.activeItemLaiki, [name]: value };

    //console.log(activeItemLaiki);
    this.setState({ activeItemLaiki });

    this.setState({ state: this.state });
  };
  handleChangeEnarxiXrewsi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // //console.log(fulldate);

    this.state.activeItemXrewsi.enarxiP = fulldate;

    //console.log(this.state.activeItemXrewsi);
    // this.setState({ activeItem });
  };

  handleChangeLixiXrewsi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // //console.log(fulldate);

    this.state.activeItemXrewsi.lixiP = fulldate;

    //console.log(this.state.activeItemXrewsi);

    this.setState({ state: this.state });
    // this.setState({ activeItem });
  };

  handleChangePagkosBoolsXrewsi = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    const activeItemXrewsi = { ...this.state.activeItemXrewsi, [name]: value };

    //console.log(activeItemXrewsi);
    this.setState({ activeItemXrewsi });
  };

  handleChangeLixi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // //console.log(fulldate);

    this.state.activeItem.lixiP = fulldate;

    //console.log(this.state.activeItem);

    this.setState({ state: this.state });
    // this.setState({ activeItem });
  };

  handleChangeLixiAdeias = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // //console.log(fulldate);

    this.state.activeItem.lixiAP = fulldate;

    //console.log(this.state.activeItem);

    this.setState({ state: this.state });
    // this.setState({ activeItem });
  };

  handleChangeArxDoc = (e) => {
    var files = e.target.files[0];
    //console.log(files);
    var fname = files.name.split(".");

    let { name } = e.target;
    // //console.log(name)
    // const arxdocitem = { ...this.state.arxdocitem, [name]: files };

    // this.setState({ arxdocitem });

    this.state.arxdocitem.file_field = e.target.files[0];
    this.state.arxdocitem.pagkos = this.state.activeItem.anagnwristikoP;
    this.state.arxdocitem.description = fname[0];
    // e.target.value = null;
  };

  handleChangeEnarxi = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // //console.log(fulldate);

    this.state.activeItem.enarxiP = fulldate;

    //console.log(this.state.activeItem);
    // this.setState({ activeItem });
  };
  handleChangeEnarxiAdeias = (e) => {
    // var dateE = e;

    var day = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var fulldate = year + "-" + month + "-" + day;

    // //console.log(fulldate);

    this.state.activeItem.enarxiAP = fulldate;

    //console.log(this.state.activeItem);
    // this.setState({ activeItem });
  };

  handleChangePagkosBools = (e) => {
    const name = e.target.id;
    const value = e.target.checked;

    const activeItem = { ...this.state.activeItem, [name]: value };

    //console.log(activeItem);
    this.setState({ activeItem });
  };

  handlePostLaiki = (e) => {
    // //console.log(posseleted);
    const newItem = this.state.activeItemLaiki;

    var length = posseleted.length;

    if (posseleted[length - 1] !== undefined) {
      newItem.centerLA = posseleted[length - 1];
      newItem.coordsLA = posseleted[length - 1];
    }

    //console.log(newItem);

    if (newItem.coordsLA === undefined || newItem.coordsLA === "") {
      alert("Παρακαλούμε επιλέξτε σημείο στον χάρτη");
    } else if (newItem.nameLA === "" || newItem.perioxiA === "") {
      alert("Παρακαλούμε προσθέστε τις πληροφορίες της Λαϊκής");
    } else {
      if (newItem.id) {
        // axios
        //   .put(
        //     `https://epixirin-volos.govcloud.gr/be/api/LaikiAgora/${newItem.id}/`,
        //     newItem
        //   )
        session.put(`api/LaikiAgora/${newItem.id}/`, newItem)
          .then(this.refreshList());

        window.location.reload(false);
      } else {
        // axios
        //   .post("https://epixirin-volos.govcloud.gr/be/api/LaikiAgora/", newItem)
        session.post("api/LaikiAgora/", newItem)
          .then(this.refreshList());
      }

      this.state.activeItem = [];
      this.refreshList();
      this.handleCloseLaiki();
      posseleted = [];
      posseleted.splice();
    }
  };

  refreshXrewseisAll = (namePP) => {
    // const allpagkoi = this.state.
    const newItemPX = this.state.PagkoiXrewsiList.filter(
      (itemF) => itemF.pagkos === namePP
    );

    // if (newItem != undefined) {
    for (var x = 0; x < newItemPX.length; x++) {
      const newItem = newItemPX[x];

      console.log(newItem);
      let num = 0;
      const parousies = this.state.ParousiesList.filter(
        (itemP) => itemP.pagkos === newItem.pagkos && itemP.parousia === true
      );

      console.log(parousies);

      var dateE = new Date(newItem.enarxiP);
      var dateL = new Date(newItem.lixiP);

      for (var i = 0; i < parousies.length; i++) {
        var date = new Date(parousies[i].date);

        console.log(parousies[i]);

        if (date >= dateE && date <= dateL) {
          num = num + 1;
        }
      }
      newItem.poso =
        parseInt(newItem.tetragwnika) *
        parseInt(newItem.timimonadas) *
        parseInt(num);

      if (newItem.id) {
        // formData.append("id", newItem.id);
        // axios
        //   .put(
        //     `https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/${newItem.id}/`,
        //     newItem
        //   )
        session.put(`api/PagkoiXrewsi/${newItem.id}/`, newItem)
          .then(this.refreshList())
          .catch((error) => {
            alert(error.response.data);
          });

        this.setState({ state: this.state });
        this.refreshList();
      }
      // }
    }
  };

  refreshXrewseis = (namePP) => {
    // const allpagkoi = this.state.
    const newItemPX = this.state.PagkoiXrewsiList.filter(
      (itemF) => itemF.pagkos === namePP
    );

    const newItem = newItemPX[0];

    if (newItem.tetragwnika === undefined) {
      newItem.tetragwnika = "";
    }

    if (newItem.timimonadas === undefined) {
      newItem.timimonadas = "";
    }

    if (newItem.tetragwnika !== "" && newItem.timimonadas !== "") {
      if (newItem.kathgoria === "Επαγγελματίες") {
        newItem.poso =
          parseInt(newItem.tetragwnika) * parseInt(newItem.timimonadas);

        if (newItem.id) {
          // formData.append("id", newItem.id);
          // axios
          //   .put(
          //     `https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/${newItem.id}/`,
          //     newItem
          //   )

          session.put(`api/PagkoiXrewsi/${newItem.id}/`, newItem)
            .then(this.refreshList())
            .catch((error) => {
              alert(error.response.data);
            });

          this.setState({ state: this.state });
          this.refreshList();
        }
      } else {
        const parousies = this.state.ParousiesList.filter(
          (itemP) => itemP.pagkos === newItem.pagkos && itemP.parousia === true
        );

        console.log(parousies);

        let num = 0;

        var dateE = new Date(newItem.enarxiP);
        var dateL = new Date(newItem.lixiP);

        for (var i = 0; i < parousies.length; i++) {
          var date = new Date(parousies[i].date);

          // //console.log(parousies[i]);

          if (date >= dateE && date <= dateL) {
            num = num + 1;
          }

          newItem.poso =
            parseInt(newItem.tetragwnika) *
            parseInt(newItem.timimonadas) *
            parseInt(num);

          if (newItem.id) {
            // formData.append("id", newItem.id);
            // axios
            //   .put(
            //     `https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/${newItem.id}/`,
            //     newItem
            //   )
            session.put(`api/PagkoiXrewsi/${newItem.id}/`, newItem)
              .then(this.refreshList())
              .catch((error) => {
                alert(error.response.data);
              });

            this.setState({ state: this.state });
            this.refreshList();
          }
        }
      }
    }

    this.setState({ state: this.state });
    this.refreshList();
  };

  handlePostXrewsi = () => {
    const newItem = this.state.activeItemXrewsi;

    if (newItem.tetragwnika !== "" && newItem.timimonadas !== "") {
      if (newItem.kathgoria === "Επαγγελματίες") {
        newItem.poso =
          parseInt(newItem.tetragwnika) * parseInt(newItem.timimonadas);
      } else {
        const parousies = this.state.ParousiesList.filter(
          (itemP) => itemP.pagkos === newItem.pagkos && itemP.parousia === true
        );

        let num = 0;

        var dateE = new Date(newItem.enarxiP);
        var dateL = new Date(newItem.lixiP);

        for (let i = 0; i < parousies.length; i++) {
          var date = new Date(parousies[i].date);

          //console.log(parousies[i]);

          if (date >= dateE && date <= dateL) {
            num = num + 1;
          }
        }
        newItem.poso =
          parseInt(newItem.tetragwnika) *
          parseInt(newItem.timimonadas) *
          parseInt(num);
      }

      if (newItem.id) {
        // formData.append("id", newItem.id);
        // axios
        //   .put(
        //     `https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/${newItem.id}/`,
        //     newItem
        //   )

        session.put(`api/PagkoiXrewsi/${newItem.id}/`, newItem)
          .then(this.refreshList())
          .catch((error) => {
            alert(error.response.data);
          });
      } else {
        // axios
        //   .post("https://epixirin-volos.govcloud.gr/be/api/PagkoiXrewsi/", newItem)
        session.post("api/PagkoiXrewsi/", newItem)
          .then(this.refreshList());
      }

      // this.state.activeItemXrewsi = [];
      this.refreshList();
      this.handleCloseX();
      this.setState({ state: this.state });
    } else {
      alert("προσθέστε τα απαραίτητα στοιχεία");
    }
  };

  handlePost = () => {
    //console.log(newItem);
    // //console.log(posseleted);
    const newItem = this.state.activeItem;

    var length = posseleted.length;

    if (posseleted[length - 1] !== undefined || posseleted[length - 1] !== 0) {
      newItem.coordsP1 = posseleted[length - 1];
    }
    if (posseleted[length - 2] !== undefined || posseleted[length - 2] !== 0) {
      newItem.coordsP2 = posseleted[length - 2];
    }
    if (posseleted[length - 3] !== undefined || posseleted[length - 3] !== 0) {
      newItem.coordsP3 = posseleted[length - 3];
    }
    if (posseleted[length - 4] !== undefined || posseleted[length - 4] !== 0) {
      newItem.coordsP4 = posseleted[length - 4];
    }

    console.log(newItem);

    if (
      newItem.coordsP1 !== undefined &&
      newItem.coordsP2 !== undefined &&
      newItem.coordsP3 !== undefined &&
      newItem.coordsP4 !== undefined
    ) {
      newItem.centerP = [
        (newItem.coordsP1[0] + newItem.coordsP3[0]) / 2,
        (newItem.coordsP1[1] + newItem.coordsP3[1]) / 2,
      ];

      if (
        newItem.coordsP2[0] === 0 &&
        newItem.coordsP3[0] === 0 &&
        newItem.coordsP4[0] === 0
      ) {
        newItem.coordsP1 = undefined;
        newItem.coordsP2 = undefined;
        newItem.coordsP3 = undefined;
        newItem.coordsP4 = undefined;
      }

      if (newItem.coordsP3[0] === 0 && newItem.coordsP4[0] === 0) {
        newItem.coordsP1 = undefined;
        newItem.coordsP2 = undefined;
        newItem.coordsP3 = undefined;
        newItem.coordsP4 = undefined;
      }
    }

    if (newItem.coordsP1 === 0 && newItem.coordsP2 === 0) {
      newItem.coordsP1 = undefined;
      newItem.coordsP2 = undefined;
      newItem.coordsP3 = undefined;
      newItem.coordsP4 = undefined;
    }

    if (newItem.kathgoria === "επαγγελματίες") {
      newItem.timimonadas = this.state.xrewsiepagk;
    } else {
      newItem.timimonadas = this.state.xrewsiparag;
    }

    newItem.anagnwristikoP = newItem.nameP + "-" + newItem.userIdp;

    if (newItem.tetragwnika !== "" && newItem.timimonadas !== "") {
      newItem.poso =
        parseInt(newItem.tetragwnika) * parseInt(newItem.timimonadas);
    }

    //console.log(newItem);

    //console.log(UdataTablePids);
    //console.log(newItem.nameP);

    if (
      newItem.nameP === "" ||
      newItem.userIdp === "" ||
      newItem.categoryP === "" ||
      newItem.afmP === "" ||
      newItem.patronymo === "" ||
      newItem.kathgoria === ""
    ) {
      alert("Παρακαλούμε προσθέστε τα υποχρεωτικά πεδία");
      // }
      // else if (
      //   newItem.centerP[0] === 0 ||
      //   newItem.coordsP1[0] === 0 ||
      //   newItem.coordsP2[0] === 0 ||
      //   newItem.coordsP3[0] === 0 ||
      //   newItem.coordsP4[0] === 0
      // ) {
      //   alert("Παρακαλούμε σχεδιάστε τον πάγκο στον χάρτη");
    } else {
      if (newItem.id) {
        // formData.append("id", newItem.id);
        // axios
        //   .put(
        //     `https://epixirin-volos.govcloud.gr/be/api/Pagkoi/${newItem.id}/`,
        //     newItem
        //   )
        session.put(`api/Pagkoi/${newItem.id}/`, newItem)
          .then(this.refreshList())
          .catch((error) => {
            alert(error.response.data);
          });

        // this.state.arxdocitem.pagkos = newItem.nameP;
        // this.state.arxdocitem.description = "-";

        if (this.state.arxdocitem.file_field !== undefined) {
          setDoc(this.state.arxdocitem);
          this.refreshListDocs();
        }

        // var url = "https://epixirin-volos.govcloud.gr/be/api/docuploadPagkos";

        // axios
        //   .post(
        //     "https://epixirin-volos.govcloud.gr/be/api/docuploadPagkos",
        //     formData
        //     // this.state.arxdocitem
        //   )
        //   .then(this.refreshList);
      } else {
        if (UdataTablePids.includes(newItem.nameP)) {
          alert("Υπάρχει ήδη πάγκος με το ίδιο Αριθμό θέσης");
        } else {
          this.state.arxdocitem.pagkos = newItem.anagnwristikoP;

          // axios
          //   .post("https://epixirin-volos.govcloud.gr/be/api/Pagkoi/", newItem)
          session.post("api/Pagkoi/", newItem)
            .then(this.refreshList());

          if (this.state.arxdocitem.file_field !== undefined) {
            setDoc(this.state.arxdocitem);
            this.refreshListDocs();
          }
        }
      }

      this.state.activeItem = [];
      this.refreshList();
      this.refreshListDocs();
      this.handleClose();
      this.setState({ state: this.state });
      document.getElementById("file_field").value = null;
      posseleted = [];
      posseleted.splice();
    }
    // newE = true;
  };
  // c

  handleNewL = () => {
    // //console.log(item);
    this.state.activeItemLaiki.nameLA = "";
    this.state.activeItemLaiki.perioxiA = "";

    // this.state.activeItem = item;
    this.setState({ modalL: true });

    // //console.log(item);
    // //console.log(this.state.modalP);
    // //console.log(this.state.activeItemLaiki);
    // this.render();
  };

  handleNewPS = (pagkosname) => {
    this.state.activeItemPar.user = localStorage.getItem("userselectedname");
    this.state.activeItemPar.pagkos = pagkosname;
    this.state.activeItemPar.paratiriseis = "";
    this.setState({ modalPS: true });
  };

  handleNewPP2 = (xrewsi) => {
    this.state.activeItemPP2 = xrewsi;
    this.setState({ modalPP2: true });
  };

  handlePrinttable = (xrewsi) => {
    console.log(xrewsi);
    this.state.activeItemPrint = xrewsi;
    this.setState({ modalPrint: true });
  };

  handleprint = (xrewsi) => {
    const content = document.getElementById("toprint");

    console.log(content);
    let pri;

    const iframe = document.createElement("iframe");
    iframe.setAttribute("title", "print");
    iframe.setAttribute("id", "print");
    iframe.setAttribute(
      "style",
      "height: 0px; width: 0px; position: absolute;"
    );
    document.body.appendChild(iframe);
    pri = iframe.contentWindow;

    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  handleNew = () => {
    // //console.log(item);
    // this.state.activeItem = [{}];
    // // this.state.activeItem = [];
    // this.setState({ activeItem: [{}] });

    this.state.activeItem.nameP = "";
    this.state.activeItem.anagnwristikoP = "";
    this.state.activeItem.adeioP = false;
    this.state.activeItem.afmP = "";
    this.state.activeItem.siteP = "";
    this.state.activeItem.tetragwnika = "";
    this.state.activeItem.timimonadas = "";
    this.state.activeItem.poso = "";
    this.state.activeItem.userIdp = "";
    this.state.activeItem.posoP = "";
    this.state.activeItem.plirwse = false;
    this.state.activeItem.isinteam = false;
    this.state.activeItem.anastoli = false;
    this.state.activeItem.adeiaAllouDimou = false;
    this.state.activeItem.patronymo = "";
    this.state.activeItem.paratiriseis = "";

    this.state.activeItem.dieuthinsi = "";
    this.state.activeItem.tilefwno = "";
    this.state.activeItem.kinito = "";
    this.state.activeItem.typos = "";

    this.state.activeItem.kathgoria = "";
    this.state.activeItem.adeiaDimou = false;
    this.state.activeItem.adeiaApokentrwmenis = false;
    this.state.activeItem.diakopi = false;
    this.state.activeItem.arfak = "";
    this.state.activeItem.laikiAgoraId = this.state.laikiselected;
    this.state.activeItem.categoryP = "";
    this.state.activeItem.centerP = [0, 0];
    document.getElementById("file_field").value = null;

    // //console.log(this.state.modalP);
    //console.log(this.state.activeItem);

    this.setState({ state: this.state });
    // this.state.activeItem = item;
    this.setState({ modalP: true });
    // this.render();
  };

  handleEdit = (item) => {
    //console.log(item);
    //console.log(this.state.docsList);

    this.refreshListDocs();

    this.state.selectedDocs = this.state.docsList.filter(
      (itemS) => itemS.pagkos === item.anagnwristikoP
    );

    if (item.arfak == null) {
      item.arfak = "";
    }

    //console.log(this.state.selectedDocs);
    // //console.log(item);
    this.state.activeItem = item;
    // this.state.activeItem = item;
    this.setState({ modalP: true });
    // //console.log(this.state.modalP);
    // //console.log(this.state.activeItem);
    // this.render();
  };

  handleEditLaiki = (item) => {
    this.state.activeItemLaiki = item;

    //console.log(this.state.activeItemLaiki);
    // this.state.activeItem = item;
    this.setState({ modalL: true });
    // //console.log(this.state.modalP);
    // //console.log(this.state.activeItemLaiki);
    // this.render();
  };

  handleEditXrewsi = (item) => {
    this.state.activeItemXrewsi = item;

    //console.log(this.state.activeItemXrewsi);
    this.setState({ modalPX: true });
  };

  handleNewXrewsi = () => {
    this.state.activeItemXrewsi = {};

    this.state.activeItemXrewsi.id = "";
    this.state.activeItemXrewsi.poso = "";
    this.state.activeItemXrewsi.plirwse = false;

    this.state.activeItemXrewsi.tetragwnika = this.state.activeItem.tetragwnika;
    this.state.activeItemXrewsi.timimonadas = this.state.activeItem.timimonadas;
    this.state.activeItemXrewsi.kathgoria = this.state.activeItem.kathgoria;
    this.state.activeItemXrewsi.pagkos = this.state.activeItem.nameP;

    //console.log(this.state.activeItemXrewsi);
    this.setState({ modalPX: true });
  };

  handleDelete = (item) => {
    session
      .delete(`api/Pagkoi/${item.id}`)
      .then(this.refreshList());
    this.refreshList();
    this.setState({ state: this.state });
    this.render();
    // window.location.reload(false);
  };

  handleDeleteL = (item) => {
    session
      .delete(`api/LaikiAgora/${item.id}`)
      .then(this.refreshList());
    this.refreshList();
    this.setState({ state: this.state });
    this.render();
    //   window.location.reload(false);
  };

  handleDeleteDocP = (id) => {
    session
      .delete(`api/FileFieldFormPagkos/${id}`)
      .then(this.refreshListDocs());
    this.refreshList();
    this.refreshListDocs();
    this.setState({ state: this.state });
    this.setState({ modalP: false });
    this.render();
    document.getElementById("file_field").value = null;
    // window.location.reload(false);
    // this.setState({ modalP: true });

    this.refreshListDocs();
    this.setState({ modalP: false });
    this.yourFunction();

    // window.location.reload(false);
    // this.setState({ modalP: true });
  };

  async yourFunction() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    await delay(1000);
    console.log("Waited 5s");
    this.handleEdit(this.state.activeItem);
    this.setState({ modalP: false });
    await delay(1000);
    console.log("Waited 5s");
    this.handleEdit(this.state.activeItem);
  }
  componentDidMount() {
    this.refreshList();
    this.refreshListDocs();
    this.refreshListCategory();
    if (!localStorage.getItem("token")) {
      window.location.href = "../yp";
    }
  }

  refreshList() {
    session
      .get("api/Pagkoi/")
      .then((res) => this.setState({ PagkoiList: res.data }))
      .catch((err) => console.log(err));

    session
      .get("api/PagkoiXrewsi/")
      .then((res) => this.setState({ PagkoiXrewsiList: res.data }))
      .catch((err) => console.log(err));

    session
      .get("api/LaikiAgoraMini/")
      .then((res) => this.setState({ LaikesSelectList: res.data }))
      .catch((err) => console.log(err));

    session
      .get("api/LaikiAgora/")
      .then((res) => this.setState({ LaikesList: res.data }))
      .catch((err) => console.log(err));

    session
      .get("api/ParousiaPagkou/")
      .then((res) => this.setState({ ParousiesList: res.data }))
      .catch((err) => console.log(err));

    session
      .get("api/ElegxosPagkou/")
      .then((res) => this.setState({ ElegxoiList: res.data }))
      .catch((err) => console.log(err));
    session
      .get("api/ParanomosPagkou/")
      .then((res) => this.setState({ ParanomoiList: res.data }))
      .catch((err) => console.log(err));
    session
      .get("api/Xrewseis/")
      .then((res) => this.setState({ XrewseisList: res.data }))
      .catch((err) => console.log(err));
  }

  refreshListDocs() {
    session
      .get("api/FileFieldFormPagkos/")
      .then((res) => this.setState({ docsList: res.data }))
      .catch((err) => console.log(err));
  }

  refreshListCategory() {
    session
      .get("api/PagkosCategory/")
      .then((res) => this.setState({ PagkosCategoryList: res.data }))
      .catch((err) => console.log(err));
  }

  handleLaikiSelect(e, latlng, perioxi) {
    const value = e.target.value;

    //console.log(value);

    this.state.laikiselected = value;
    this.state.laikiselectedcenter = latlng;
    this.state.perioxiselected = perioxi;

    const { map } = this.state;
    if (map) map.flyTo(latlng, 18);

    this.setState({ state: this.state });
    this.refreshList();
    this.render();
  }

  render() {
    var today = Date().toLocaleString("el-GR");

    let daytoday = "";
    let mon = false;
    let tue = false;
    let wed = false;
    let thu = false;
    let fri = false;
    let sat = false;
    let sun = false;

    if (today.includes("Mon")) {
      daytoday = "Δευτέρα";
      mon = true;
    } else if (today.includes("Tue")) {
      daytoday = "Τρίτη";
      tue = true;
    } else if (today.includes("Wed")) {
      daytoday = "Τετάρτη";
      thu = true;
    } else if (today.includes("Thu")) {
      daytoday = "Πέμπτη";
      wed = true;
    } else if (today.includes("Fri")) {
      daytoday = "Παρασκευή";
      fri = true;
    } else if (today.includes("Sat")) {
      daytoday = "Σάββατο";
      sat = true;
    } else if (today.includes("Sun")) {
      daytoday = "Κυριακή";
      sun = true;
    }

    // //console.log(mon);
    // //console.log(tue);
    // //console.log(wed);
    // //console.log(thu);
    // //console.log(fri);
    // //console.log(sat);
    // //console.log(sun);

    // //console.log(this.state.LaikesSelectList);

    let tmpItems = this.state.PagkoiList.filter(
      (item) => String(item.laikiAgoraId) === String(this.state.laikiselected)
    );

    //console.log(this.state.PagkoiXrewsiList);

    let tmpItemsXrewsi = this.state.PagkoiXrewsiList.filter(
      (item) => String(item.pagkos) === String(this.state.activeItem.nameP)
    );

    //console.log(tmpItemsXrewsi);

    let tmpItemsLaikes = this.state.LaikesList;

    let tmpItemsLaikesSelected = [];

    for (let i = 0; i < tmpItemsLaikes.length; i++) {
      // //console.log("-------");

      var found = false;
      // //console.log(tmpItemsLaikes[i]);

      if (tmpItemsLaikes[i].deytera === true) {
        if (mon) {
          found = true;
          tmpItemsLaikesSelected = tmpItemsLaikes[i];
          // break;
        }
      }
      if (tmpItemsLaikes[i].triti === true) {
        if (tue) {
          //   //console.log("T");
          found = true;
          tmpItemsLaikesSelected = tmpItemsLaikes[i];
          // break;
        }
      }
      if (tmpItemsLaikes[i].tetarti === true) {
        if (thu) {
          //   //console.log("TR");
          found = true;
          tmpItemsLaikesSelected = tmpItemsLaikes[i];
          // break;
        }
      }
      if (tmpItemsLaikes[i].pempti === true) {
        if (wed) {
          //   //console.log("P");
          found = true;
          tmpItemsLaikesSelected = tmpItemsLaikes[i];
          // break;
        }
      }
      if (tmpItemsLaikes[i].paraskeui === true) {
        if (fri) {
          //   //console.log("PA");
          found = true;
          tmpItemsLaikesSelected = tmpItemsLaikes[i];
          // break;
        }
      }
      if (tmpItemsLaikes[i].savvato === true) {
        if (sat) {
          //   //console.log("S");
          found = true;
          tmpItemsLaikesSelected = tmpItemsLaikes[i];
          // break;
        }
      }
      if (tmpItemsLaikes[i].kyriaki === true) {
        if (sun) {
          //   //console.log("K");
          found = true;
          tmpItemsLaikesSelected = tmpItemsLaikes[i];
          // break;
        }
      }
    }

    // //console.log(tmpItemsLaikesSelected);

    tmpItemsLaikes = [tmpItemsLaikesSelected];

    // //console.log(tmpItemsLaikes);

    if (this.state.laikiselected !== "-") {
      tmpItemsLaikes = tmpItemsLaikes.filter(
        (item) => String(item.nameLA) === String(this.state.laikiselected)
      );
    }
    let tmpItemsLaikesMap = tmpItemsLaikes;

    if (this.state.laikiselected !== "-") {
      tmpItemsLaikesMap = tmpItemsLaikes.filter(
        (item) => String(item.nameLA) === String(this.state.laikiselected)
      );
    }

    const tmpItemsLaikesSelect = this.state.LaikesSelectList;
    const { markers } = this.state;
    const classes = this.state.useStyles;
    const theme = this.state.useTheme;
    let categories = [];

    if (this.state.PagkosCategoryList !== undefined) {
      categories = this.state.PagkosCategoryList;
    }

    if (categories !== undefined) {
      for (let i = 0; i < tmpItems.length; i++) {
        // //console.log(tmpItems[i]);
        let categorytmp = categories.filter(
          (item) => String(item.namePC) === String(tmpItems[i].categoryP)
        );
        // //console.log(categorytmp);
        if (categorytmp[0] != undefined) {
          tmpItems[i].color = categorytmp[0].colorPC;
          tmpItems[i].Bcolor = categorytmp[0].description;
        }
      }
    } else {
      this.setState({ state: this.state });
    }

    let xrewseislist = this.state.XrewseisList;

    let sortedProducts2 = [...xrewseislist];
    sortedProducts2.sort((a, b) => {
      if (parseInt(a.id) < parseInt(b.id)) {
        return 1;
      }
      if (parseInt(a.id) > parseInt(b.id)) {
        return -1;
      }
      return 0;
    });

    xrewseislist = sortedProducts2;

    if (xrewseislist[0] !== undefined) {
      this.state.xrewsiepagk = xrewseislist[0].timeEp;
      this.state.xrewsiparag = xrewseislist[0].timePar;
    }
    // //console.log(tmpItems);

    // if (newE == true) {
    //   this.refreshList();
    //   this.refreshList();
    //   //console.log(tmpItems.length);
    //   //console.log(this.state.PagkoiList);
    //   newE = false;
    // }

    // for (var i = 0; i < tmpItems.length; i++) {
    //   var tmpr = [];

    //   var tmpstr = "";
    //   tmpstr = tmpItems[i].coordsP1.split(",");
    //   var tmpstrlat = parseFloat(tmpstr[0]);
    //   var tmpstrlong = parseFloat(tmpstr[1]);
    //   tmpr.push(tmpstrlat);
    //   tmpr.push(tmpstrlong);
    //   // markers.push(tmpr);

    //   tmpItems.coordsP1 = tmpr;
    // }

    // const { markers } = this.state;
    // markers.push(e.latlng)
    // this.setState({markers})
    const positionC = [39.365, 22.951];
    const open = this.state.open;
    // //console.log(open);
    // //console.log(tmpItems);

    // //console.log(this.state.modalP);

    // const tmpItemsEdit = this.state.activeItem;

    // if (tmpItemsEdit == undefined) {
    //   tmpItemsEdit = [];
    // }
    if (this.state.activeItem.coordsP1 === undefined) {
      this.state.activeItem.coordsP1 = [0, 0];
      this.state.activeItem.coordsP2 = [0, 0];
      this.state.activeItem.coordsP3 = [0, 0];
      this.state.activeItem.coordsP4 = [0, 0];
    }

    // //console.log(this.state.activeItem.laikiAgoraId);
    // //console.log(this.state.laikiselected);
    // //console.log(this.state.laikiselectedcenter);

    // if (this.state.activeItem.laikiAgoraId == "") {
    //   this.state.activeItem.laikiAgoraId = this.state.laikiselected;
    // }

    if (this.state.laikiselectedcenter === "") {
      this.state.laikiselectedcenter = center;
    }

    if (tmpItems[0] !== undefined) {
      let sortedProducts = [...tmpItems];
      sortedProducts.sort((a, b) => {
        if (parseInt(a.userIdp) < parseInt(b.userIdp)) {
          return 1;
        }
        if (parseInt(a.userIdp) > parseInt(b.userIdp)) {
          return -1;
        }
        return 0;
      });

      tmpItems = sortedProducts;
    }

    UdataTableLa = [];

    for (var i = 0; i < tmpItemsLaikes.length; i++) {
      const dataRow = {};
      const coords = tmpItemsLaikes[i].coordsLA;
      const name = tmpItemsLaikes[i].nameLA;
      const item = tmpItemsLaikes[i];
      const perioxi = tmpItemsLaikes[i].perioxiA;

      dataRow.name = name;
      dataRow.selectLa = (
        <Radio
          checked={String(this.state.laikiselected) === String(name)}
          onChange={(e) => this.handleLaikiSelect(e, coords, perioxi)}
          value={name}
          name="radio-button-demo"
        />
      );
      // tmpItemsLaikes[i].nameLA;
      dataRow.perioxi = tmpItemsLaikes[i].perioxiA;
      // dataRow.days =
      //   (tmpItemsLaikes[i].deytera == true && "Δευτέρα ") +
      //   (tmpItemsLaikes[i].triti == true && "Τρίτη ") +
      //   (tmpItemsLaikes[i].tetarti == true && "Τετάρτη ") +
      //   (tmpItemsLaikes[i].pempti == true && "Πέμπτη ") +
      //   (tmpItemsLaikes[i].paraskeui == true && "Παρασκευή ") +
      //   (tmpItemsLaikes[i].savvato == true && "Σάββατο ") +
      //   (tmpItemsLaikes[i].kyriaki == true && "Κυριακή");
      //handleDeleteL

      const tmpItemsPagkoi = this.state.PagkoiList.filter(
        (item) => String(item.laikiAgoraId) === String(name)
      );

      //   if (tmpItemsPagkoi.length == 0) {
      //     dataRow.e = (
      //       <>
      //         {" "}
      //         <Button
      //           round
      //           color="info"
      //           size="sm"
      //           onClick={() => this.handleEditLaiki(item)}
      //         >
      //           <i className="fa fa-edit"></i>
      //         </Button>{" "}
      //         <Button
      //           round
      //           color="danger"
      //           size="sm"
      //           onClick={() => this.handleDeleteL(item)}
      //         >
      //           <i className="fa fa-times"></i>
      //         </Button>{" "}
      //       </>
      //     );
      //   } else {
      //     dataRow.e = (
      //       <>
      //         {" "}
      //         <Button
      //           round
      //           color="info"
      //           size="sm"
      //           onClick={() => this.handleEditLaiki(item)}
      //         >
      //           <i className="fa fa-edit"></i>
      //         </Button>{" "}
      //       </>
      //     );
      //   }

      UdataTableLa.push(dataRow);
    }

    UdataTablePids = [];
    UdataTableP = [];

    for (let i = 0; i < tmpItems.length; i++) {
      const dataRow = {};
      const coords = tmpItems[i].coordsP1;
      const nameP = tmpItems[i].nameP;
      const item = tmpItems[i];
      const anagnwristikoP = tmpItems[i].anagnwristikoP;

      dataRow.name = nameP;

      UdataTablePids.push(tmpItems[i].nameP);

      if (coords != null) {
        dataRow.selectLa = (
          <Radio
            checked={String(this.state.pagkosselected) === String(nameP)}
            onChange={(e) => this.handlePagkosSelect(e, coords)}
            value={nameP}
            name="radio-button-demo"
          />
        );
      }
      // tmpItemsLaikes[i].nameLA;
      dataRow.perioxi = tmpItems[i].categoryP;
      dataRow.onomatepwnymo = tmpItems[i].userIdp;
      dataRow.poso = tmpItems[i].poso;
      dataRow.tetragwnika = tmpItems[i].tetragwnika;

      //   //console.log(this.state.ParousiesList);

      let newDate = new Date();
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();

      if (String(month).length === 1) {
        month = "0" + String(month);
      }
      if (String(date).length === 1) {
        date = "0" + String(date);
      }
      let day = year + "-" + month + "-" + date;
      //   //console.log(day);.

      //console.log(this.state.ParousiesList);
      //console.log(nameP);
      // console.log(day);
      // console.log(this.state.ParousiesList);

      if (item.kathgoria !== "επαγγελματίες") {
        const parousiaL = this.state.ParousiesList.filter(
          (itemP) => itemP.pagkos === anagnwristikoP && itemP.date === day
        );

        // //console.log(parousiaL);
        // //console.log("------------------");

        let parousia = false;
        let parousiaitem = {};
        let plirwseX = false;

        if (parousiaL[0] !== undefined) {
          parousia = parousiaL[0].parousia;
          parousiaitem = parousiaL[0];
        } else {
          parousiaitem.date = day;
          parousiaitem.pagkos = anagnwristikoP;
        }

        parousiaitem.tetragwnika = item.tetragwnika;
        parousiaitem.timimonadas = item.timimonadas;
        parousiaitem.kathgoria = item.kathgoria;

        const tmpitem = this.state.PagkoiXrewsiList.filter(
          (itemXR) =>
            itemXR.pagkos === parousiaitem.pagkos &&
            itemXR.date === parousiaitem.date
        );

        const tmpitemParanomos = this.state.ParanomoiList.filter(
          (itemXR) =>
            itemXR.pagkos === parousiaitem.pagkos &&
            String(itemXR.date).includes(String(parousiaitem.date))
        );

        let xrewsiitem = [];
        if (tmpitem[0] !== undefined) {
          // console.log(tmpitem[0]);

          const tmppagkos = this.state.PagkoiList.filter(
            (itemP) => String(itemP.anagnwristikoP) === tmpitem[0].pagkos
          );

          // console.log(tmppagkos[0]);

          xrewsiitem = tmpitem[0];
          plirwseX = tmpitem[0].plirwse;

          xrewsiitem.fullname =
            tmppagkos[0].userIdp + " " + tmppagkos[0].patronymo;
          xrewsiitem.afm = tmppagkos[0].afmP;
        }

        if (parousia === true) {
          dataRow.parousia = (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    onChange={(e) => this.handleChangeParousia(e, parousiaitem)}
                    name="parousia"
                    id="parousia"
                    color="primary"
                  />
                }
                label="Παρών"
              />
              <Button
                round
                color="info"
                size="sm"
                onClick={() => this.handleNewPP2(xrewsiitem)}
              >
                <i className="fa fa-edit"></i>
              </Button>
              <Button
                round
                color="success"
                size="sm"
                onClick={(e) => this.handleElegxo(e, anagnwristikoP, 1)}
              >
                Έλεγχος{" "}
              </Button>

              {plirwseX === true && (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(e) => this.handleChangeXrewsi(e, xrewsiitem)}
                      name="parousia"
                      id="parousia"
                      color="primary"
                    />
                  }
                  label="Πληρωμή"
                />
              )}
              {plirwseX === false && (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => this.handleChangeXrewsi(e, xrewsiitem)}
                      name="parousia"
                      id="parousia"
                      color="primary"
                    />
                  }
                  label="Πληρωμή"
                />
              )}

              <Button
                round
                color="success"
                size="sm"
                onClick={() => this.handlePrinttable(xrewsiitem)}
              >
                <i className="fa fa-print"></i>
              </Button>
            </>
          );
        } else {
          if (tmpitemParanomos.length === 0) {
            dataRow.parousia = (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) =>
                        this.handleChangeParousia(e, parousiaitem)
                      }
                      name="parousia"
                      id="parousia"
                      color="primary"
                    />
                  }
                  label="Απών"
                />
                <Button
                  round
                  color="danger"
                  size="sm"
                  onClick={() => this.handleNewPS(anagnwristikoP)}
                >
                  Παράνομος
                </Button>
              </>
            );
          } else {
            dataRow.parousia = (
              <>
                ΠΑΡΑΝΟΜΟΣ{" "}
                <Button
                  round
                  color="danger"
                  size="sm"
                  onClick={() => this.handleNewPS(anagnwristikoP)}
                >
                  Έλεγχος{" "}
                </Button>
              </>
            );
          }
        }
      }

      let tmp = tmpItems[i].enarxiAP;
      let tmp2 = tmp.split("-");
      dataRow.timeE = tmp2[2] + "-" + tmp2[1] + "-" + tmp2[0];

      tmp = tmpItems[i].lixiAP;
      tmp2 = tmp.split("-");
      dataRow.timeL = tmp2[2] + "-" + tmp2[1] + "-" + tmp2[0];

      //     dataRow.timeE = String(tmpItems[i].enarxiAP);
      // dataRow.timeL = String(tmpItems[i].lixiAP);

      var date1 = new Date();
      var date2 = new Date(tmpItems[i].lixiP);

      if (date1 > date2) {
        dataRow.timeL2 = (
          <Button round color="danger" size="sm">
            <i className="fa fa-exclamation"></i>
          </Button>
        );
      } else if (
        date2.getFullYear() === date1.getFullYear() &&
        date2.getMonth() - date1.getMonth() <= 1
      ) {
        dataRow.timeL2 = (
          <Button round color="warning" size="sm">
            <i className="fa fa-question"></i>
          </Button>
        );
      } else {
        dataRow.timeL2 = (
          <Button round color="success" size="sm">
            <i className="fa fa-check"></i>
          </Button>
        );
      }

      dataRow.e = (
        <>
          {" "}
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEdit(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>{" "}
          {/* <Button
            round
            color="danger"
            size="sm"
            onClick={() => this.handleDelete(item)}
          >
            <i className="fa fa-times"></i>
          </Button>{" "} */}
        </>
      );

      UdataTableP.push(dataRow);
    }

    let UdataTablePX = [];

    //console.log(tmpItemsXrewsi);

    for (let i = 0; i < tmpItemsXrewsi.length; i++) {
      const dataRow = {};
      //console.log(tmpItemsXrewsi[i]);

      const item = tmpItemsXrewsi[i];

      dataRow.enarxiP = item.enarxiP;
      dataRow.lixiP = item.lixiP;
      dataRow.date = item.date;
      dataRow.poso = item.poso;

      if (item.pragmtetragwnika === "") {
        dataRow.tetragwnika = item.tetragwnika;
      } else {
        dataRow.tetragwnika = item.pragmtetragwnika;
      }

      dataRow.pagkos = item.pagkos;

      const tmppagkoi = this.state.PagkoiList.filter(
        (itemP) => itemP.nameP === item.pagkos
      );

      dataRow.epwn = tmppagkoi[0].userIdp;
      dataRow.afm = tmppagkoi[0].afmP;
      dataRow.time = item.enarxiP + " εώς " + item.lixiP;

      // const tmpparous = this.state.ParousiesList.filter(
      //   (itemPp) => itemPp.pagkos === item.pagkos && itemPp.parousia == true
      // );

      // let num = 0;

      // var dateE = new Date(item.enarxiP);
      // var dateL = new Date(item.lixiP);

      // for (var j = 0; j < tmpparous.length; j++) {
      //   var date = new Date(tmpparous[j].date);

      //   if (date >= dateE && date <= dateL) {
      //     num = num + 1;
      //   }
      // }

      // dataRow.par = num;

      // if (item.plirwse == true) {
      //   dataRow.plirwse = "Ναί";
      // } else {
      //   dataRow.plirwse = "Όχι";
      // }

      dataRow.e = (
        <>
          {" "}
          <Button
            round
            color="info"
            size="sm"
            onClick={() => this.handleEditXrewsi(item)}
          >
            <i className="fa fa-edit"></i>
          </Button>{" "}
        </>
      );

      //console.log(dataRow);

      UdataTablePX.push(dataRow);
    }

    //console.log(UdataTablePX);

    // let savedisabled = false;

    // if (
    //   this.state.activeItem.nameP === undefined &&
    //   this.state.activeItem.afmP === undefined &&
    //   this.state.activeItem.siteP === undefined &&
    //   this.state.activeItem.userIdp === undefined &&
    //   this.state.activeItem.enarxiP === undefined &&
    //   this.state.activeItem.lixiP === undefined &&
    //   this.state.activeItem.posoP === undefined &&
    //   this.state.activeItem.laikiAgoraId === undefined &&
    //   this.state.activeItem.categoryP === undefined
    // ) {
    //   savedisabled = true;
    // }

    // //console.log(savedisabled);

    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={7}>
            <Row>
              <Col>
                {categories.length === 0 && (
                  <Card>
                    <CardHeader color="success">Χάρτης</CardHeader>
                    <CardBody>
                      <MapContainer
                        center={center}
                        zoom={zoom}
                        scrollWheelZoom={false}
                        style={{ height: "80vh", width: "200wh" }}
                        whenCreated={(map) => this.setState({ map })}
                      >
                        <LayersControl position="topright">
                          <LayersControl.BaseLayer checked name="Street View">
                            <TileLayer
                              maxNativeZoom={19}
                              minZoom={0}
                              maxZoom={22}
                              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                          </LayersControl.BaseLayer>
                          <LayersControl.BaseLayer name="Earth view">
                            <TileLayer
                              maxNativeZoom={17}
                              minZoom={0}
                              maxZoom={17}
                              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                              attribution='&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
                            />
                          </LayersControl.BaseLayer>
                        </LayersControl>
                      </MapContainer>
                    </CardBody>
                  </Card>
                )}

                {categories.length !== 0 && (
                  <Card>
                    <CardHeader color="success">Χάρτης</CardHeader>
                    <CardBody>
                      <MapContainer
                        center={center}
                        zoom={zoom}
                        scrollWheelZoom={false}
                        style={{ height: "60vh", width: "200wh" }}
                        whenCreated={(map) => this.setState({ map })}
                      >
                        <LayersControl position="topright">
                          <LayersControl.BaseLayer checked name="Street View">
                            <TileLayer
                              maxNativeZoom={19}
                              minZoom={0}
                              maxZoom={22}
                              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                          </LayersControl.BaseLayer>
                          <LayersControl.BaseLayer name="Earth view">
                            <TileLayer
                              maxNativeZoom={17}
                              minZoom={0}
                              maxZoom={17}
                              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                              attribution='&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
                            />
                          </LayersControl.BaseLayer>
                        </LayersControl>

                        {tmpItems.map((item) => (
                          <>
                            {item.coordsP1 != null && (
                              <Polygon
                                positions={[
                                  item.coordsP1,
                                  item.coordsP2,
                                  item.coordsP3,
                                  item.coordsP4,
                                ]}
                                color={item.color}
                              >
                                <Tooltip>
                                  {item.nameP}
                                  <br></br>
                                  {item.categoryP}
                                </Tooltip>
                              </Polygon>
                            )}
                          </>
                        ))}
                        {/* {this.state.laikiselectedcenter != center && (
                          <Marker
                            position={this.state.laikiselectedcenter}
                            icon={L.icon({
                              iconUrl:
                                "uploads/logos/download.png",
                              iconSize: [26, 41],
                              iconAnchor: [
                                this.state.laikiselectedcenter[0] - 29,
                                this.state.laikiselectedcenter[1] + 18,
                              ],
                              popupAnchor: null,
                              shadowUrl: null,
                              shadowSize: null,
                              shadowAnchor: null,
                            })}
                          >
                            <Tooltip>{this.state.laikiselected}</Tooltip>
                          </Marker>
                        )} */}

                        {tmpItemsLaikesMap.map((item) => (
                          <Marker
                            key={`marker-${item.id}`}
                            position={item.coordsLA}
                          >
                            <Tooltip>{item.nameLA}</Tooltip>
                          </Marker>
                        ))}
                      </MapContainer>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={5}>
            <Card>
              <CardHeader color="success">
                Λαϊκές Αγορές{" "}
                {/* <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNewL()}
                >
                  <i className="fa fa-plus"></i>
                </Button> */}
              </CardHeader>
              <CardBody>
                {/* <GridContainer
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <h3>
                    {" "}
                    {"Λαϊκές Αγορές"}
                    <Button
                      round
                      color="info"
                      size="sm"
                      onClick={() => this.handleNewL()}
                    >
                      <i className="fa fa-plus"></i>
                    </Button>{" "}
                  </h3>
                </GridContainer> */}

                <ReactTable
                  data={UdataTableLa}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: (
                        <Button
                          round
                          color="danger"
                          size="sm"
                          onClick={() => this.ClearLaikiSelect()}
                        >
                          {" "}
                          <i className="fa fa-times"></i>{" "}
                        </Button>
                      ),
                      accessor: "selectLa",
                      disableFilters: true,
                      disableSortBy: true,
                    },
                    {
                      Header: "Όνομα",
                      accessor: "name",
                    },
                    {
                      Header: "Περιοχή",
                      accessor: "perioxi",
                    },
                    // {
                    //   Header: "Μέρες",
                    //   accessor: "days",
                    // },
                    {
                      Header: "",
                      accessor: "e",
                      disableFilters: true,
                      disableSortBy: true,
                    },

                    // {
                    //   Header: " ",
                    //   accessor: "",
                    // },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />

                {/* <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Τίτλος</TableCell>
                        <TableCell>Περιοχή</TableCell>
                        <TableCell>Ημέρες</TableCell>
                        <TableCell>Ενέργειες</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tmpItemsLaikes.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell component="th" scope="row">
                            <Radio
                              checked={
                                String(this.state.laikiselected) ===
                                String(item.nameLA)
                              }
                              onChange={(e) =>
                                this.handleLaikiSelect(e, item.coordsLA)
                              }
                              value={item.nameLA}
                              name="radio-button-demo"
                            />
                            {item.nameLA}
                            {/* <FormControl component="fieldset">
                          <FormControlLabel
                            value={item.id}
                            onChange={(e) => this.handleLaikiSelect(e)}
                            control={<Radio />}
                            label={item.nameLA}
                          />
                        </FormControl> 
                          </TableCell>
                          <TableCell>{item.perioxiA} </TableCell>
                          <TableCell>
                            {item.deytera && "Δευτέρα "}
                            {item.triti && "Τρίτη "}
                            {item.tetarti && "Τετάρτη "}
                            {item.pempti && "Πέμπτη "}
                            {item.paraskeui && "Παρασκευή "}
                            {item.savvato && "Σάββατο "}
                            {item.kyriaki && "Κυριακή"}
                          </TableCell>

                          <TableCell>
                            <Button
                              round
                              color="info"
                              size="sm"
                              onClick={() => this.handleEditLaiki(item)}
                            >
                              <i className="fa fa-edit"></i>
                            </Button>{" "}
                            {/* <Button
                          round
                          color="danger"
                          size="sm"
                          onClick={() => this.handleDelete(item)}
                        >
                          <i className="fa fa-times"></i>
                        </Button> 
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}
              </CardBody>
            </Card>

            <Card>
              <CardHeader color="success">
                Πάγκοι Λαϊκής{" "}
                <Button
                  round
                  color="info"
                  size="sm"
                  onClick={() => this.handleNew()}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </CardHeader>{" "}
              <CardBody>
                <span>
                  <ReactTable
                    data={UdataTableP}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: " ",
                        accessor: "selectLa",
                        disableFilters: true,
                        disableSortBy: true,
                      },
                      {
                        Header: "Αριθμός Θέσης",
                        accessor: "name",
                      },
                      {
                        Header: "Είδος",
                        accessor: "perioxi",
                        Filter: SelectColumnFilter,
                        filter: "includes",
                      },
                      {
                        Header: "Ονοματεπώνυμο",
                        accessor: "onomatepwnymo",
                      },
                      {
                        Header: "Μήκος ",
                        accessor: "tetragwnika",
                      },

                      // {
                      //   Header: "Έναρξη ",
                      //   accessor: "timeE",
                      // },
                      // {
                      //   Header: "Λήξη ",
                      //   accessor: "timeL",
                      // },
                      // {
                      //   Header: " ",
                      //   accessor: "timeL2",
                      //   disableFilters: true,
                      //   disableSortBy: true,
                      // },
                      {
                        Header: "Παρουσία - Έλεγχος",
                        accessor: "parousia",
                        disableFilters: true,
                        disableSortBy: true,
                      },
                      // {
                      //   Header: "Παράνομος",
                      //   accessor: "paranomos",
                      //   disableFilters: true,
                      //   disableSortBy: true,
                      // },
                      {
                        Header: "",
                        accessor: "e",
                        disableFilters: true,
                        disableSortBy: true,
                      },

                      // {
                      //   Header: " ",
                      //   accessor: "",
                      // },
                    ]}
                    defaultPageSize={5}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </span>

                {/* <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Αριθμός Θέσης </TableCell>
                        <TableCell>Είδος</TableCell>
                        <TableCell>Ονοματεπώνυμο</TableCell>
                        <TableCell>Ποσό</TableCell>

                        <TableCell>Ενέργειες</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tmpItems.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell component="th" scope="row">
                            <Radio
                              checked={
                                String(this.state.pagkosselected) ===
                                String(item.nameP)
                              }
                              onChange={(e) =>
                                this.handlePagkosSelect(e, item.coordsP1)
                              }
                              value={item.nameP}
                              name="radio-button-demo"
                            />
                            {item.nameP}
                          </TableCell>
                          <TableCell>
                            <Badge color={item.Bcolor}>{item.categoryP}</Badge>
                          </TableCell>
                          <TableCell>{item.userIdp}</TableCell>
                          <TableCell>{item.poso}</TableCell>
                          <TableCell>
                            <Button
                              round
                              color="info"
                              size="sm"
                              onClick={() => this.handleEdit(item)}
                            >
                              <i className="fa fa-edit"></i>
                            </Button>{" "}
                            <Button
                              round
                              color="danger"
                              size="sm"
                              onClick={() => this.handleDelete(item)}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalP}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="xlg"
          onClose={() => this.handleClose()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            Στοιχεία Πάγκου Λαϊκής
            <IconButton
              className={classes.modalLloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClose()}
            >
              <Close className={classes.modalLlose} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <Card>
                  <CardHeader color="success">Χάρτης</CardHeader>
                  <CardBody>
                    {" "}
                    <label>Παρακαλούμε σχεδιάστε τον πάγκο στον χάρτη</label>
                    <MapContainer
                      center={this.state.laikiselectedcenter}
                      zoom={zoom}
                      scrollWheelZoom={false}
                      style={{ height: "50vh", width: "150wh" }}
                      whenCreated={(map) => this.setState({ map })}
                    >
                      <FeatureGroup>
                        <EditControl
                          position="topright"
                          onEdited={this._onEditPath}
                          onCreated={this._onCreate}
                        />
                      </FeatureGroup>

                      <TileLayer
                        maxNativeZoom={19}
                        minZoom={0}
                        maxZoom={22}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />

                      {tmpItems.map((item) => (
                        <>
                          {item.coordsP1 != null && (
                            <Polygon
                              positions={[
                                item.coordsP1,
                                item.coordsP2,
                                item.coordsP3,
                                item.coordsP4,
                              ]}
                              color={item.color}
                            >
                              <Tooltip>{item.nameP}</Tooltip>
                            </Polygon>
                          )}
                        </>
                      ))}

                      <Polygon
                        positions={[
                          this.state.activeItem.coordsP1,
                          this.state.activeItem.coordsP2,
                          this.state.activeItem.coordsP3,
                          this.state.activeItem.coordsP4,
                        ]}
                        color="red"
                      >
                        <Tooltip>{this.state.activeItem.nameP}</Tooltip>
                      </Polygon>

                      <Marker position={this.state.activeItem.coordsP1}>
                        <Tooltip>{this.state.activeItem.nameP}</Tooltip>
                      </Marker>

                      {/* <MyComponent /> */}
                      <LocationMarker />
                    </MapContainer>
                  </CardBody>
                </Card>
              </GridItem>
              {/* <GridItem xs={1}></GridItem> */}

              <GridItem xs={6} sm={6} md={6} lg={3}>
                {/* <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-name-label">Name</InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="laikiAgoraId"
                    multiple
                    // value={personName}
                    // onChange={handleChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                  >
                    {tmpItemsLaikes.map((item) => (
                      <MenuItem value={item.id}>{item.nameLA}</MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                {/* <Input
                  type="select"
                  name="laikiAgoraId"
                  id="laikiAgoraId"
                  onChange={(e) => this.handleChange(e)}
                >
                  <option style={{ color: "black" }}>{"-"}</option>
                  {tmpItemsLaikes.map((item) => (
                    <option value={item.id}>{item.nameLA}</option>
                  ))}
                </Input> */}
                <Card>
                  <CardHeader color="success">Στοιχεία επιχείρησης</CardHeader>
                  <CardBody>
                    {" "}
                    <label>
                      {" "}
                      <b> Επιλογή Λαϊκής </b>
                    </label>{" "}
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="laikiAgoraId"
                      id="laikiAgoraId"
                      value={String(this.state.activeItem.laikiAgoraId)}
                      onChange={(e) => this.handleMultiSelectChange(e)}
                      style={{ fontSize: "14px" }}
                    >
                      {tmpItemsLaikes.map((item) => (
                        <MenuItem value={item.nameLA}>{item.nameLA}</MenuItem>
                      ))}
                    </Select>
                    <br />
                    <label style={{ color: "#47A34B" }}>
                      Επιλογή Κατηγορίας πωλητών*
                    </label>
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="kathgoria"
                      id="kathgoria"
                      value={String(this.state.activeItem.kathgoria)}
                      onChange={(e) => this.handleMultiSelectChangeΚ(e)}
                      style={{ fontSize: "14px" }}
                    >
                      <MenuItem value="παραγωγοί">Παραγωγοί </MenuItem>
                      <MenuItem value="επαγγελματίες">Επαγγελματίες </MenuItem>
                    </Select>
                    <br />
                    {/* <MultiSelect
                  options={tmpItemsLaikesSelect}
                  // value={selected}
                  onChange={(e) => this.handleMultiSelectChange(e)}
                  labelledBy="Select"
                /> */}
                    <label style={{ color: "#47A34B" }}>Αριθμός Θέσης*</label>{" "}
                    <br />
                    <CustomInput
                      id="nameP"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.nameP,
                        placeholder: "Αριθμός Θέσης*",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <label style={{ color: "#47A34B" }}> ΑΦΜ * </label> <br />
                    <CustomInput
                      id="afmP"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.afmP,
                        placeholder: "ΑΦΜ",
                        onChange: (e) => this.handleChange(e),
                        type: "number",
                      }}
                    />{" "}
                    <br />
                    <label>
                      {" "}
                      <b> Αρ. Φακέλου </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="arfak"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.arfak,
                        placeholder: "Αρ. Φακέλου",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <label>
                      {" "}
                      <b> Site </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="siteP"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.siteP,
                        placeholder: "Site",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <label>
                      {" "}
                      <b> Διεύθυνση </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="dieuthinsi"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.dieuthinsi,
                        placeholder: "Διεύθυνση",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <label style={{ color: "#47A34B" }}>
                      Επιλογή Είδους Πάγκου*
                    </label>
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      name="categoryP"
                      required
                      id="categoryP"
                      value={String(this.state.activeItem.categoryP)}
                      onChange={(e) => this.handleChangeSelect(e)}
                      style={{ fontSize: "12px" }}
                    >
                      {categories.map((item) => (
                        <MenuItem value={item.namePC}>{item.namePC}</MenuItem>
                      ))}
                    </Select>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="success">Στοιχεία Κατόχου</CardHeader>
                  <CardBody>
                    {" "}
                    <br />
                    <label style={{ color: "#47A34B" }}>
                      {" "}
                      Ονοματεπώνυμο *{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="userIdp"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.userIdp,
                        placeholder: "Ονοματεπώνυμο",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <label style={{ color: "#47A34B" }}>
                      {" "}
                      Πατρώνυμο *{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="patronymo"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.patronymo,
                        placeholder: "Πατρώνυμο",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />{" "}
                    <br />
                    <label>
                      {" "}
                      <b> Τηλέφωνο </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="tilefwno"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.tilefwno,
                        placeholder: "Τηλέφωνο",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />
                    <br />
                    <label>
                      {" "}
                      <b> Κινητό </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="kinito"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.kinito,
                        placeholder: "Κινητό",
                        onChange: (e) => this.handleChange(e),
                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader color="success">Αρχεία</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="file_field"
                      >
                        <b> Αρχείο </b>
                      </label>
                      <Input
                        //  multiple
                        id="file_field"
                        onChange={(e) => this.handleChangeArxDoc(e)}
                        name="file_field"
                        type="file"
                        ref={(ref) => (this.fileInput = ref)}
                      />
                    </FormGroup>{" "}
                    <br />
                    {this.state.activeItem.id && (
                      <>
                        <label>
                          {" "}
                          <b> Διαθέσιμα αρχεία </b>
                        </label>
                        {this.state.selectedDocs.map(
                          ({ id, file_field, description }) => (
                            <Badge>
                              <Button
                                size="sm"
                                round
                                onClick={() =>
                                  window.open(file_field, "_blank")
                                }
                              >
                                {" "}
                                {description}{" "}
                              </Button>

                              <Button
                                round
                                color="danger"
                                size="sm"
                                onClick={() => this.handleDeleteDocP(id)}
                              >
                                <i className="fa fa-times"></i>
                              </Button>
                            </Badge>
                          )
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>

                {/* <Input
                  type="select"
                  name="categoryP"
                  id="categoryP"
                  onChange={(e) => this.handleChange(e)}
                >
                  <option style={{ color: "black" }}>{"-"}</option>
                  {categories.map((item) => (
                    <option value={item.namePC}>
                      {item.namePC} {" - "}
                      {item.colorPC}
                    </option>
                  ))}
                </Input> */}
              </GridItem>
              {/* <GridItem xs={1}></GridItem> */}
              <GridItem xs={6} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="success">Οικονομικά Στοιχεία</CardHeader>
                  <CardBody>
                    {" "}
                    {/* <label>
                      {" "}
                      Οικονομικά Στοιχεία{" "} */}
                    {/* </label> */}
                    <br />
                    <label>
                      {" "}
                      <b> Μήκος Πάγκου </b>{" "}
                    </label>{" "}
                    <br />
                    <CustomInput
                      id="tetragwnika"
                      inputProps={{
                        required: true,
                        value: this.state.activeItem.tetragwnika,
                        placeholder: "Μήκος πάγκου",
                        onChange: (e) => this.handleChangeP1(e),
                        type: "number",
                      }}
                    />{" "}
                    <br />
                    {this.state.activeItem.kathgoria === "επαγγελματίες" && (
                      <>
                        <label>
                          {" "}
                          <b> Τιμή μονάδας </b>{" "}
                        </label>{" "}
                        <br />
                        <CustomInput
                          id="timimonadas"
                          inputProps={{
                            required: true,

                            value: this.state.xrewsiepagk,
                            placeholder: "Τιμή μονάδας",
                            onChange: (e) => this.handleChangeP2(e),
                            type: "number",
                          }}
                        />{" "}
                      </>
                    )}{" "}
                    {this.state.activeItem.kathgoria === "παραγωγοί" && (
                      <>
                        <label>
                          {" "}
                          <b> Τιμή μονάδας </b>{" "}
                        </label>{" "}
                        <br />
                        <CustomInput
                          id="timimonadas"
                          inputProps={{
                            required: true,

                            value: this.state.xrewsiparag,
                            placeholder: "Τιμή μονάδας",
                            onChange: (e) => this.handleChangeP2(e),
                            type: "number",
                          }}
                        />{" "}
                      </>
                    )}{" "}
                    <br />
                    {this.state.activeItem.kathgoria === "επαγγελματίες" && (
                      <>
                        <label>
                          {" "}
                          <b> Κόστος </b>{" "}
                        </label>{" "}
                        <br />
                        <CustomInput
                          id="poso"
                          inputProps={{
                            disabled: true,
                            value: this.state.activeItem.poso,
                            placeholder: "Κόστος",
                            // onChange: (e) => this.handleChange(e),
                            type: "text",
                          }}
                        />
                      </>
                    )}{" "}
                    <br />
                    {this.state.activeItem.kathgoria === "παραγωγοί" && (
                      <>
                        {/* {this.state.activeItem.id && (
                          <>
                            <Button
                              round
                              color="info"
                              size="sm"
                              onClick={() => this.handleNewXrewsi()}
                            >
                              <i className="fa fa-plus"></i>
                            </Button>
                          </>
                        )} */}
                        {/* <ExcelFile
                          element={
                            <button
                              style={{
                                color: "white",
                                backgroundColor: "#4CAF50",
                                borderColor: "#4CAF50",
                                fontSize: "1em",
                                borderRadius: "100px",
                              }}
                            >
                              Κατέβασμα χρεώσεων
                            </button>
                          }
                        >
                          <ExcelSheet data={UdataTablePX} name="All">
                            <ExcelColumn label="Επωνυμία" value="epwn" />
                            <ExcelColumn label="ΑΦΜ" value="afm" />
                            <ExcelColumn
                              label="Διάστημα χρέωσης"
                              value="time"
                            />
                            <ExcelColumn label="Ποσό" value="poso" />
                            <ExcelColumn label="Πλήρωσε" value="plirwse" />
                            <ExcelColumn label="Παρουσίες" value="par" />
                          </ExcelSheet>
                        </ExcelFile> */}
                        <span>
                          <ReactTable
                            data={UdataTablePX}
                            filterable
                            resizable={false}
                            columns={[
                              // {
                              //   Header: "Έναρξη",
                              //   accessor: "enarxiP",
                              // },
                              {
                                Header: "Ημερομηνία",
                                accessor: "date",
                              },
                              {
                                Header: "Μήκος",
                                accessor: "tetragwnika",
                              },

                              // {
                              //   Header: "Λήξη",
                              //   accessor: "lixiP",
                              // },
                              {
                                Header: "Ποσό",
                                accessor: "poso",
                              },
                              // {
                              //   Header: "Παρουσίες",
                              //   accessor: "par",
                              // },
                              // {
                              //   Header: "Πλήρωσε",
                              //   accessor: "plirwse",
                              //   Filter: SelectColumnFilter,
                              //   filter: "includes",
                              // },

                              {
                                Header: "",
                                accessor: "e",
                                disableFilters: true,
                                disableSortBy: true,
                              },
                            ]}
                            defaultPageSize={5}
                            showPaginationTop
                            showPaginationBottom={false}
                            className="-striped -highlight"
                          />
                        </span>
                      </>
                    )}
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="enarxiP"
                    name="enarxiP"
                    showTodayButton
                    required
                    label="Έναρξη"
                    value={this.state.activeItem.enarxiP}
                    onChange={(e) => this.handleChangeEnarxi(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider> */}
                    {/* <InputLabel id="lixiP" className={classes.label}>
                  Λήξη
                </InputLabel> */}
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="lixeE"
                    name="lixeE"
                    required
                    label="Λήξη"
                    value={this.state.activeItem.lixiP}
                    onChange={(e) => this.handleChangeLixi(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider> */}
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="success">Επιπλέον Στοιχεία</CardHeader>
                  <CardBody>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="enarxiAP"
                        name="enarxiAP"
                        showTodayButton
                        required
                        label="Έναρξη άδειας πάγκου"
                        value={this.state.activeItem.enarxiAP}
                        onChange={(e) => this.handleChangeEnarxiAdeias(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <InputLabel id="lixiP" className={classes.label}>
                  Λήξη
                </InputLabel> */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="lixeAP"
                        name="lixeAP"
                        required
                        label="Λήξη άδειας πάγκου"
                        value={this.state.activeItem.lixiAP}
                        onChange={(e) => this.handleChangeLixiAdeias(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <CustomInput
                  id="posoP"
                  inputProps={{
                    value: this.state.activeItem.posoP,
                    placeholder: "Ποσό",
                    onChange: (e) => this.handleChange(e),
                    type: "text",
                  }}
                /> */}
                    {/* {this.state.activeItem.plirwse && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        onChange={(e) => this.handleChangePagkosBools(e)}
                        name="plirwse"
                        id="plirwse"
                        color="primary"
                      />
                    }
                    label="Πλήρωσε;"
                  />
                )}
                {!this.state.activeItem.plirwse && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => this.handleChangePagkosBools(e)}
                        name="plirwse"
                        id="plirwse"
                        color="primary"
                      />
                    }
                    label="Πλήρωσε;"
                  />
                )} */}
                    {this.state.activeItem.anastoli && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="anastoli"
                            id="anastoli"
                            color="primary"
                          />
                        }
                        label="Αναστολή άδειας λειτουργίας;"
                      />
                    )}
                    {!this.state.activeItem.anastoli && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="anastoli"
                            id="anastoli"
                            color="primary"
                          />
                        }
                        label="Αναστολή άδειας λειτουργίας;"
                      />
                    )}
                    {this.state.activeItem.adeiaAllouDimou && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaAllouDimou"
                            id="adeiaAllouDimou"
                            color="primary"
                          />
                        }
                        label="Άδεια άλλου Δήμου;"
                      />
                    )}
                    {!this.state.activeItem.adeiaAllouDimou && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaAllouDimou"
                            id="adeiaAllouDimou"
                            color="primary"
                          />
                        }
                        label="Άδεια άλλου Δήμου;"
                      />
                    )}
                    {this.state.activeItem.isinteam && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="isinteam"
                            id="isinteam"
                            color="primary"
                          />
                        }
                        label="Ανήκει στο σωματείο Λαϊκών Αγορών;"
                      />
                    )}
                    {!this.state.activeItem.isinteam && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="isinteam"
                            id="isinteam"
                            color="primary"
                          />
                        }
                        label="Ανήκει στο σωματείο Λαϊκών Αγορών;"
                      />
                    )}
                    {this.state.activeItem.adeiaDimou && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaDimou"
                            id="adeiaDimou"
                            color="primary"
                          />
                        }
                        label="Έχει άδεια του δήμου;"
                      />
                    )}
                    {!this.state.activeItem.adeiaDimou && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaDimou"
                            id="adeiaDimou"
                            color="primary"
                          />
                        }
                        label="Έχει άδεια του δήμου;"
                      />
                    )}
                    {this.state.activeItem.adeiaApokentrwmenis && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaApokentrwmenis"
                            id="adeiaApokentrwmenis"
                            color="primary"
                          />
                        }
                        label="Έχει άδεια της περιφέρειας;"
                      />
                    )}
                    {!this.state.activeItem.adeiaApokentrwmenis && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="adeiaApokentrwmenis"
                            id="adeiaApokentrwmenis"
                            color="primary"
                          />
                        }
                        label="Έχει άδεια της περιφέρειας;"
                      />
                    )}
                    {this.state.activeItem.diakopi && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            defaultChecked
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="diakopi"
                            id="diakopi"
                            color="primary"
                          />
                        }
                        label="Διακοπή αδειοδότησης;"
                      />
                    )}
                    {!this.state.activeItem.diakopi && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            onChange={(e) => this.handleChangePagkosBools(e)}
                            name="diakopi"
                            id="diakopi"
                            color="primary"
                          />
                        }
                        label="Διακοπή αδειοδότησης;"
                      />
                    )}
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="success">Παρατηρήσεις</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="paratiriseis"
                      inputProps={{
                        value: this.state.activeItem.paratiriseis,
                        placeholder: "Παρατηρήσεις",
                        onChange: (e) => this.handleChange(e),

                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleClose()}>
              Άκυρο
            </Button>
            {/* {savedisabled && ( */}
            <Button
              size="sm"
              round
              onClick={() => this.handlePost()}
              color="success"
            >
              Αποθήκευση
            </Button>
            {/* )} */}
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalPX}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleCloseX()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}> Στοιχεία Πάγκου Λαϊκής</h4>
            <IconButton
              className={classes.modalLloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseX()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs="6">
                <Card>
                  <CardHeader>Στοιχεία Πάγκου</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="pagkos"
                      inputProps={{
                        disabled: true,
                        value: this.state.activeItemXrewsi.pagkos,
                        placeholder: "Πάγκος",
                        onChange: (e) => this.handleChangeX(e),
                        type: "text",
                      }}
                    />

                    <CustomInput
                      id="kathgoria"
                      inputProps={{
                        disabled: true,
                        value: this.state.activeItemXrewsi.kathgoria,
                        placeholder: "Κατηγορία",
                        onChange: (e) => this.handleChangeX(e),
                        type: "text",
                      }}
                    />
                    <CustomInput
                      id="tetragwnika"
                      inputProps={{
                        disabled: true,
                        value: this.state.activeItemXrewsi.tetragwnika,
                        placeholder: "Μήκος Πάγκου",
                        onChange: (e) => this.handleChangeX(e),
                        type: "text",
                      }}
                    />
                    <CustomInput
                      id="timimonadas"
                      inputProps={{
                        disabled: true,
                        value: this.state.activeItemXrewsi.timimonadas,
                        placeholder: "Τιμή μονάδας",
                        onChange: (e) => this.handleChangeX(e),
                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs="6">
                <Card>
                  <CardHeader>Στοιχεία Χρέωσης</CardHeader>
                  <CardBody>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="enarxiP"
                        name="enarxiP"
                        showTodayButton
                        required
                        label="Έναρξη"
                        value={this.state.activeItemXrewsi.enarxiP}
                        onChange={(e) => this.handleChangeEnarxiXrewsi(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="lixeE"
                        name="lixeE"
                        required
                        label="Λήξη"
                        value={this.state.activeItemXrewsi.lixiP}
                        onChange={(e) => this.handleChangeLixiXrewsi(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    <CustomInput
                      id="posoP"
                      inputProps={{
                        disabled: true,
                        value: this.state.activeItemXrewsi.poso,
                        placeholder: "Ποσό",
                        onChange: (e) => this.handleChangeX(e),
                        type: "text",
                      }}
                    />
                    {this.state.activeItemXrewsi.plirwse && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={(e) =>
                              this.handleChangePagkosBoolsXrewsi(e)
                            }
                            name="plirwse"
                            id="plirwse"
                            color="primary"
                          />
                        }
                        label="Πλήρωσε;"
                      />
                    )}
                    {!this.state.activeItemXrewsi.plirwse && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              this.handleChangePagkosBoolsXrewsi(e)
                            }
                            name="plirwse"
                            id="plirwse"
                            color="primary"
                          />
                        }
                        label="Πλήρωσε;"
                      />
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseX()}>
              Άκυρο
            </Button>
            {/* {savedisabled && ( */}
            <Button
              size="sm"
              round
              onClick={() => this.handlePostXrewsi()}
              color="success"
            >
              Αποθήκευση
            </Button>
            {/* )} */}
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalL}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="md"
          onClose={() => this.handleCloseLaiki()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalLloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleCloseLaiki()}
            >
              <Close className={classes.modalLlose} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={6}>
                {" "}
                <label>
                  Παρακαλούμε επιλέξτε στον χάρτη το κέντρο της Λαϊκής
                </label>
                <MapContainer
                  center={this.state.laikiselectedcenter}
                  zoom={zoom}
                  scrollWheelZoom={false}
                  style={{ height: "50vh", width: "180wh" }}
                >
                  <TileLayer
                    maxNativeZoom={19}
                    minZoom={0}
                    maxZoom={22}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {this.state.activeItemLaiki.coordsLA !== undefined && (
                    <Marker position={this.state.activeItemLaiki.coordsLA}>
                      <Tooltip>{this.state.activeItemLaiki.nameLA}</Tooltip>
                    </Marker>
                  )}
                  {/* <MyComponent /> */}
                  <LocationMarkerLaiki />{" "}
                </MapContainer>{" "}
              </GridItem>
              <GridItem xs={6}>
                <CustomInput
                  id="nameLA"
                  name="nameLA"
                  inputProps={{
                    value: this.state.activeItemLaiki.nameLA,
                    placeholder: "Όνομα Λαϊκής",
                    onChange: (e) => this.handleChangeLaiki(e),
                    type: "text",
                  }}
                />
                <br />
                <CustomInput
                  id="perioxiA"
                  inputProps={{
                    value: this.state.activeItemLaiki.perioxiA,
                    placeholder: "Περιοχή",
                    onChange: (e) => this.handleChangeLaiki(e),
                    type: "text",
                  }}
                />
                <br />
                <FormGroup>
                  {this.state.activeItemLaiki.deytera && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="deytera"
                          id="deytera"
                          color="primary"
                        />
                      }
                      label="Δευτέρα"
                    />
                  )}
                  {!this.state.activeItemLaiki.deytera && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="deytera"
                          id="deytera"
                          color="primary"
                        />
                      }
                      label="Δευτέρα"
                    />
                  )}
                  {this.state.activeItemLaiki.triti && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="triti"
                          id="triti"
                          color="primary"
                        />
                      }
                      label="Τρίτη"
                    />
                  )}
                  {!this.state.activeItemLaiki.triti && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="triti"
                          id="triti"
                          color="primary"
                        />
                      }
                      label="Τρίτη"
                    />
                  )}{" "}
                  {this.state.activeItemLaiki.tetarti && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="tetarti"
                          id="tetarti"
                          color="primary"
                        />
                      }
                      label="Τετάρτη"
                    />
                  )}
                  {!this.state.activeItemLaiki.tetarti && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="tetarti"
                          id="tetarti"
                          color="primary"
                        />
                      }
                      label="Τετάρτη"
                    />
                  )}
                  {this.state.activeItemLaiki.pempti && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="pempti"
                          id="pempti"
                          color="primary"
                        />
                      }
                      label="Πέμπτη"
                    />
                  )}
                  {!this.state.activeItemLaiki.pempti && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="pempti"
                          id="pempti"
                          color="primary"
                        />
                      }
                      label="Πέμπτη"
                    />
                  )}{" "}
                  {this.state.activeItemLaiki.paraskeui && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="paraskeui"
                          id="paraskeui"
                          color="primary"
                        />
                      }
                      label="Παρασκευή"
                    />
                  )}
                  {!this.state.activeItemLaiki.paraskeui && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="paraskeui"
                          id="paraskeui"
                          color="primary"
                        />
                      }
                      label="Παρασκευή"
                    />
                  )}
                  {this.state.activeItemLaiki.savvato && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="savvato"
                          id="savvato"
                          color="primary"
                        />
                      }
                      label="Σάββατο"
                    />
                  )}
                  {!this.state.activeItemLaiki.savvato && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="savvato"
                          id="savvato"
                          color="primary"
                        />
                      }
                      label="Σάββατο"
                    />
                  )}
                  {this.state.activeItemLaiki.kyriaki && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="kyriaki"
                          id="kyriaki"
                          color="primary"
                        />
                      }
                      label="Κυριακή"
                    />
                  )}
                  {!this.state.activeItemLaiki.kyriaki && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => this.handleChangeLaikiMeres(e)}
                          name="kyriaki"
                          id="kyriaki"
                          color="primary"
                        />
                      }
                      label="Κυριακή"
                    />
                  )}
                </FormGroup>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleCloseLaiki()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handlePostLaiki()}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalPS}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleClosePS()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            Παράνομος πάγκος
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClosePS()}
            >
              <Close className={classes.modalCloseEC} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem>
                <Card>
                  <CardHeader color="success">Παρατηρήσεις</CardHeader>
                  <CardBody>
                    <CustomInput
                      id="paratiriseis"
                      inputProps={{
                        placeholder: "Παρατηρήσεις",
                        maxlength: "5",
                        onChange: (e) => this.handleChangePar(e),
                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleClosePS()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handlePostPar()}
              color="success"
            >
              Αποθήκευση ΚΑΙ ΑΠΟΣΤΟΛΗ
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalPP2}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleClosePP2()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            Επεξεργασία στοιχεία
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClosePP2()}
            >
              <Close className={classes.modalCloseEC} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem>
                <Card>
                  <CardHeader color="success">Πραγματικά μέτρα</CardHeader>
                  <CardBody>
                    <label>
                      Δηλωμένο μήκος: {this.state.activeItemPP2.tetragwnika}{" "}
                    </label>
                    <br />

                    <label>Πραγματικό μήκος</label>
                    <br />
                    <CustomInput
                      id="pragmtetragwnika"
                      inputProps={{
                        placeholder: "Πραγματικό μήκος",
                        value: this.state.activeItemPP2.pragmtetragwnika,
                        onChange: (e) => this.handleChangePP2(e),
                        type: "text",
                      }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleClosePP2()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handleParousiaXrewsiChange()}
              color="success"
            >
              Αποθήκευση
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={this.state.modalPrint}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleClosePrint()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalClose}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClosePrint()}
            >
              <Close className={classes.modalCloseEC} />
            </IconButton>
            {/* <h4 className={classes.modalTitle}>Modal title</h4> */}
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem>
                <Card id="toprint">
                  <CardHeader color="success">ΔΗΜΟΣ ΒΟΛΟΥ</CardHeader>
                  <CardBody>
                    ΤΜΗΜΑ ΕΜΠΟΡΙΟΥ
                    <br /> ΓΡΑΦΕΙΟ ΛΑΙΚΩΝ ΑΓΟΡΩΝ
                    <br />
                    <label>
                      ΑΡ. ΑΠΟΔΕΙΞΗΣ: {this.state.activeItemPrint.id}{" "}
                    </label>
                    <br />
                    <label>
                      Ημερομηνία: {this.state.activeItemPrint.date}{" "}
                    </label>
                    <br />
                    <label>ΠΕΡΙΟΧΗ: {this.state.perioxiselected} </label>
                    <br />
                    <br />
                    <label> {this.state.activeItemPrint.fullname} </label>
                    <br />
                    <label>ΑΦΜ: {this.state.activeItemPrint.afm} </label>
                    <br />
                    <label>
                      ΜΕΤΡΑ ΠΡΟΣ ΧΡΕΩΣΗ:
                      {this.state.activeItemPrint.pragmtetragwnika === "" && (
                        <>{this.state.activeItemPrint.tetragwnika} </>
                      )}
                      {this.state.activeItemPrint.pragmtetragwnika !== "" && (
                        <>{this.state.activeItemPrint.pragmtetragwnika} </>
                      )}
                    </label>
                    <br />
                    {/* <label>
                      Τιμή: {this.state.activeItemPrint.timimonadas}{" "}
                    </label> */}
                    {/* <br />
                    <label>Ποσό: {this.state.activeItemPrint.poso} </label>
                    <br /> */}
                    {this.state.activeItemPrint.plirwse && (
                      <label>ΠΛΗΡΩΜΗ</label>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" round onClick={() => this.handleClosePrint()}>
              Άκυρο
            </Button>
            <Button
              size="sm"
              round
              onClick={() => this.handleprint()}
              color="success"
            >
              Εκτύπωση
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

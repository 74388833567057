import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import session from "../api/session";
import EventSpy from "components/eventSpy";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import OutlinedInput from "@mui/material/OutlinedInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const violationMappingStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function EidosAdeiasDeleteSwal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDelete = () => {
    session
      .get(`api/eidos-adeias/${props.eidosAdeiasID}/actions/`)
      .then((resp) => {
        EventSpy(3, `Διαγραφή Είδος Άδειας ${JSON.stringify(resp.data)}`);
      });

    session
      .delete(`api/eidos-adeias/${props.eidosAdeiasID}/actions/`)
      .then(() => setOpen(false), props.eidosAdeiasEnd());
  };

  return (
    <div>
      <Button onClick={handleOpen} color="error">
        Διαγραφη
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε Σίγουροι; Διαγραφή Είδος Άδειας
          </Typography>

          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <Button onClick={onDelete} variant="outlined" color="error">
                  {" "}
                  Διαγραφη
                </Button>
                <Button onClick={handleClose}> Ακυρο</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function EidosAdeiasModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = React.useState(props.title ? props.title : "");

  const titleChange = (e) => {
    setTitle(e.target.value);
  };

  const onSave = () => {
    if (props.action === "Create") {
      session
        .post("api/eidos-adeias/create/", {
          title: title,
        })
        .then(
          (resp) =>
            EventSpy(1, `Δημιουργία Είδος Άδειας ${JSON.stringify(resp.data)}`),
          setOpen(false),
          props.eidosAdeiasEnd(),
          setTitle("")
        );
    } else {
      session
        .put(`api/eidos-adeias/${props.eidosAdeiasID}/actions/`, {
          title: title,
        })
        .then(
          (resp) =>
            EventSpy(1, `Ανανέωση Είδος Άδειας ${JSON.stringify(resp.data)}`),
          setOpen(false),
          props.eidosAdeiasEnd()
        );
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Είδος
            Άδειας
          </Typography>

          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <TextField
                  label="Τίτλος"
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={10}
                  value={title}
                  onChange={titleChange}
                />
                <Button onClick={onSave}> Αποθηκευση</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function StadioParabasisDeleteSwal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDelete = () => {
    session
      .get(`api/stadio-parabasis/${props.stadioID}/action/`)
      .then((resp) => {
        EventSpy(3, `Διαγραφή στάδιο παράβασης ${JSON.stringify(resp.data)}`);
      });
    session
      .delete(`api/stadio-parabasis/${props.stadioID}/action/`)
      .then(() => setOpen(false), props.handleStadioEnd());
  };

  return (
    <div>
      <Button onClick={handleOpen} color="error">
        Διαγραφη
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε Σίγουροι; Διαγραφή Στάδιο Παράβασης
          </Typography>

          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <Button onClick={onDelete} variant="outlined" color="error">
                  {" "}
                  Διαγραφη
                </Button>
                <Button onClick={handleClose}> Ακυρο</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function StadiaParabaseisModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = React.useState(props.title ? props.title : "");

  const titleChange = (e) => {
    setTitle(e.target.value);
  };
  const onSave = () => {
    if (props.action === "Create") {
      session
        .post("api/stadio-parabasis/create/", {
          title: title,
        })
        .then(
          (resp) =>
            EventSpy(
              1,
              `Δημιουργία Στάδιο παράβασης ${JSON.stringify(resp.data)}`
            ),
          setOpen(false),
          props.handleStadioEnd(),
          setTitle("")
        );
    } else {
      session
        .put(`api/stadio-parabasis/${props.stadioID}/action/`, {
          title: title,
        })
        .then(
          (resp) =>
            EventSpy(
              2,
              `Ανανέωση Στάδιο παράβασης ${JSON.stringify(resp.data)}`
            ),
          setOpen(false),
          props.handleStadioEnd()
        );
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Στάδιο
            Παράβασης
          </Typography>
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <TextField
                  label="Τίτλος"
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={10}
                  value={title}
                  onChange={titleChange}
                />
                <Button onClick={onSave}> Αποθηκευση</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function EidosParabasisDeleteSwal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDelete = () => {
    session.get(`api/eidos-parabasis/${props.EidosID}/action/`).then((resp) => {
      EventSpy(3, `Διαγραφή Είδος παράβασης ${JSON.stringify(resp.data)}`);
    });
    session
      .delete(`api/eidos-parabasis/${props.EidosID}/action/`)
      .then(() => setOpen(false), props.handleEnd());
  };

  return (
    <div>
      <Button onClick={handleOpen} color="error">
        Διαγραφη
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε Σίγουροι; Διαγραφή Είδος Παράβασης
          </Typography>

          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <Button onClick={onDelete} variant="outlined" color="error">
                  {" "}
                  Διαγραφη
                </Button>
                <Button onClick={handleClose}> Ακυρο</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function EidosParabasisModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = React.useState(props.title ? props.title : "");

  const titleChange = (e) => {
    setTitle(e.target.value);
  };

  const onSave = () => {
    if (props.action === "Create") {
      session
        .post("api/eidos-parabasis/create/", {
          title: title,
        })
        .then(
          (resp) =>
            EventSpy(
              1,
              `Δημιουργία Είδος παράβασης ${JSON.stringify(resp.data)}`
            ),
          setOpen(false),
          props.handleEnd(),
          setTitle("")
        );
    } else {
      session
        .put(`api/eidos-parabasis/${props.eidosId}/action/`, {
          title: title,
        })
        .then(
          (resp) =>
            EventSpy(
              2,
              `Ανανέωση Είδος παράβασης ${JSON.stringify(resp.data)}`
            ),
          setOpen(false),
          props.handleEnd()
        );
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Είδος
            Παράβασης
          </Typography>
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <TextField
                  label="Τίτλος"
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={10}
                  value={title}
                  onChange={titleChange}
                />
                <Button onClick={onSave}> Αποθηκευση</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function PenaltyModal(props) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(props.title ? props.title : "");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const titleChange = (e) => {
    setTitle(e.target.value);
  };

  const onSave = () => {
    if (props.action === "Create") {
      session
        .post("api/penalty/create/", {
          title: title,
        })
        .then(
          (resp) =>
            EventSpy(1, `Δημιουργία Ποινής ${JSON.stringify(resp.data)}`),
          setOpen(false),
          props.penaltyEnd(),
          setTitle("")
        );
    } else {
      session
        .put(`api/penalty/${props.penaltyId}/reup/`, {
          title: title,
        })
        .then(
          (resp) => EventSpy(2, `Ανανέωση Ποινής ${JSON.stringify(resp.data)}`),
          setOpen(false),
          props.penaltyEnd()
        );
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Ποινής
          </Typography>
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <TextField
                  label="Τίτλος"
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={10}
                  value={title}
                  onChange={titleChange}
                />
                <Button onClick={onSave}> Αποθηκευση</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function PenaltyDeleteSwal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDelete = () => {
    session.get(`api/penalty/${props.penaltyId}/reup/`).then((resp) => {
      EventSpy(3, `Διαγραφή Ποινής ${JSON.stringify(resp.data)}`);
    });
    session
      .delete(`api/penalty/${props.penaltyId}/delete/`)
      .then(() => setOpen(false), props.penaltyEnd());
  };

  return (
    <div>
      <Button onClick={handleOpen} color="error">
        Διαγραφη
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε Σίγουροι; Διαγραφή Ποινής
          </Typography>

          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <Button onClick={onDelete} variant="outlined" color="error">
                  {" "}
                  Διαγραφη
                </Button>
                <Button onClick={handleClose}> Ακυρο</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function CitySectionModal(props) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(props.title ? props.title : "");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const titleChange = (e) => {
    setTitle(e.target.value);
  };

  const onSave = () => {
    if (props.action === "Create") {
      session
        .post("api/city-section/create/", {
          title: title,
        })
        .then(
          (resp) =>
            EventSpy(1, `Δημιουργία Δημ Ενοτητας ${JSON.stringify(resp.data)}`),
          setOpen(false),
          props.citySectionEnd(),
          setTitle("")
        );
    } else {
      session
        .put(`api/city-section/${props.citySectionId}/reup/`, {
          title: title,
        })
        .then(
          (resp) =>
            EventSpy(2, `Ανανέωση Δημ Ενοτητας ${JSON.stringify(resp.data)}`),
          setOpen(false),
          props.citySectionEnd()
        );
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Δημοτικής
            Ενότητας
          </Typography>
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <TextField
                  label="Τίτλος"
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={10}
                  value={title}
                  onChange={titleChange}
                />
                <Button onClick={onSave}> Αποθηκευση</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function CitySectionDeleteSwal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDelete = () => {
    session
      .get(`api/city-section/${props.citySectionId}/reup/`)
      .then((resp) => {
        EventSpy(3, `Διαγραφή Δημ Ενοτητας ${JSON.stringify(resp.data)}`);
      });
    session
      .delete(`api/city-section/${props.citySectionId}/delete/`)
      .then(() => setOpen(false), props.citySectionEnd());
  };

  return (
    <div>
      <Button onClick={handleOpen} color="error">
        Διαγραφη
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε Σίγουροι; Διαγραφή Δημοτικής Ενότητας
          </Typography>

          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <Button onClick={onDelete} variant="outlined" color="error">
                  {" "}
                  Διαγραφη
                </Button>
                <Button onClick={handleClose}> Ακυρο</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function BusinessCategoryModal(props) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(props.nameEC ? props.nameEC : "");
  const [logo, setLogo] = React.useState(props.logo ? props.logo : "");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const titleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleBusinessLogo = (e) => {
    console.log(e.target.files);
    setLogo(e.target.files[0]);
  };

  const onSave = () => {
    if (props.action === "Create") {
      let data = new FormData();
      data.append("nameEC", title);
      data.append("logo", logo);
      data.append("iconEC", "-");
      session
        .post("api/EpixeirisiCategory/", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          (resp) =>
            EventSpy(1, `Δημιουργία Κατ επιχ ${JSON.stringify(resp.data)}`),
          setOpen(false),
          props.businessCategoryEnd(),
          setTitle("")
        );
    } else {
      let data = new FormData();
      data.append("nameEC", title);
      data.append("logo", logo);
      data.append("iconEC", "-");

      session
        .put(`api/EpixeirisiCategory/${props.businessCatetoryId}/`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          (resp) =>
            EventSpy(2, `Ανανέωση Κατ επιχ ${JSON.stringify(resp.data)}`),
          setOpen(false),
          props.businessCategoryEnd()
        );
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Κατηγορία
            Επιχείρησης
          </Typography>
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <TextField
                  label="Τίτλος"
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={10}
                  value={title}
                  onChange={titleChange}
                />
                <br />
                <br />
                <br />

                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Συνημμένο αρχείο
                </Typography>

                <input
                  id="file_field"
                  name="logo"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleBusinessLogo(e)}
                />
                <br />
                <br />
                <br />
                <Button onClick={onSave}> Αποθηκευση</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function BusinessCategoryDeleteSwal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDelete = () => {
    session
      .get(`api/EpixeirisiCategory/${props.businessCatetoryId}/`)
      .then((resp) => {
        EventSpy(3, `Διαγραφή Κατ επιχ ${JSON.stringify(resp.data)}`);
      });
    session
      .delete(`api/EpixeirisiCategory/${props.businessCatetoryId}/`)
      .then(() => setOpen(false), props.businessCategoryEnd());
  };

  return (
    <div>
      <Button onClick={handleOpen} color="error">
        Διαγραφη
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε Σίγουροι; Διαγραφή Κατηγορία Επιχείρησης
          </Typography>

          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <Button onClick={onDelete} variant="outlined" color="error">
                  {" "}
                  Διαγραφη
                </Button>
                <Button onClick={handleClose}> Ακυρο</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function BenchViolation(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = React.useState(props.title ? props.title : "");
  const [alertShow, setAlertShow] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");

  const changeTitle = (e) => {
    setTitle(e.target.value);
    setAlertShow(false);
  };

  const onSave = () => {
    if (props.action === "Create") {
      session
        .post("api/bench-violation/create/", {
          title: title
            .split(" ")
            .filter((e) => e)
            .join(" "),
        })
        .then((resp) => {
          setOpen(false);
          setTitle("");
          EventSpy(
            1,
            `Δημιουργία Παράβασης Πάγκου ${JSON.stringify(resp.data)}`
          );
          props.BenchViolationChange();
        })
        .catch((e) => {
          console.log(e.response.data);
          if (e.response.data.title) {
            setAlertShow(true);
            setErrorMsg("Ο τίτλος υπάρχει ήδη");
          }
          return;
        });
    } else {
      session
        .put(`api/bench-violation/${props.benchViolationId}/actions/`, {
          title: title
            .split(" ")
            .filter((e) => e)
            .join(" "),
        })
        .then((resp) => {
          setOpen(false);
          EventSpy(2, `Ανανέωση Παράβασης Πάγκου ${JSON.stringify(resp.data)}`);
          props.BenchViolationChange();
        })
        .catch((e) => {
          console.log(e.response.data);
          if (e.response.data.title) {
            setAlertShow(true);
            setErrorMsg("Ο τίτλος υπάρχει ήδη");
          }
          return;
        });
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Παράβαση
            Πάγκου
          </Typography>
          {alertShow && <Alert severity="error">{errorMsg}</Alert>}
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <TextField
                  label="Τίτλος"
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={10}
                  value={title}
                  onChange={changeTitle}
                />
                <br />
                <br />
                <br />

                <Button onClick={onSave}> Αποθηκευση</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
function AttestationCreateModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = React.useState(props.title ? props.title : "");

  const titleChange = (e) => {
    setTitle(e.target.value);
  };
  const onSave = () => {
    if (props.action === "Create") {
      session
        .post("api/violation-attestation/create/", {
          name: title,
        })
        .then(
          (resp) =>
            EventSpy(
              1,
              `Δημιουργία βεβαιωσης παραβασης ${JSON.stringify(resp.data)}`
            ),
          setOpen(false),
          props.handleAttestationEnd(),
          setTitle("")
        );
    } else {
      session
        .put(`api/violation-attestation/${props.attestationId}/option/`, {
          name: title,
        })
        .then(
          (resp) =>
            EventSpy(
              2,
              `Ανανέωση βεβαιωσης παραβασης ${JSON.stringify(resp.data)}`
            ),
          setOpen(false),
          props.handleAttestationEnd()
        );
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Αρχή
            Βεβαίωσης Παράβασης
          </Typography>
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <TextField
                  label="Τίτλος"
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={10}
                  value={title}
                  onChange={titleChange}
                />
                <Button onClick={onSave}> Αποθηκευση</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
function AttestationDeleteSwal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDelete = () => {
    session
      .get(`api/violation-attestation/${props.attestationId}/option/`)
      .then((resp) => {
        EventSpy(
          3,
          `Διαγραφή Αρχή βεβαίωσης παράβασης ${JSON.stringify(resp.data)}`
        );
      });
    session
      .delete(`api/violation-attestation/${props.attestationId}/option/`)
      .then(() => setOpen(false), props.handleAttestationEnd());
  };

  return (
    <div>
      <Button onClick={handleOpen} color="error">
        Διαγραφη
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε Σίγουροι; Διαγραφή Αρχή Βεβαίωσης
          </Typography>

          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <Button onClick={onDelete} variant="outlined" color="error">
                  {" "}
                  Διαγραφη
                </Button>
                <Button onClick={handleClose}> Ακυρο</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function ViolationStageCreateModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = React.useState(props.title ? props.title : "");

  const titleChange = (e) => {
    setTitle(e.target.value);
  };
  const onSave = () => {
    if (props.action === "Create") {
      session
        .post("api/bussiness-violations-status/create/", {
          name: title,
        })
        .then(
          (resp) =>
            EventSpy(
              1,
              `Δημιουργία κατασταση παραβασης ${JSON.stringify(resp.data)}`
            ),
          setOpen(false),
          props.handleViolationStageEnd(),
          setTitle("")
        );
    } else {
      session
        .put(`api/bussiness-violations-status/${props.violationId}/actions/`, {
          name: title,
        })
        .then(
          (resp) =>
            EventSpy(
              2,
              `Ανανέωση κατασταση παραβασης ${JSON.stringify(resp.data)}`
            ),
          setOpen(false),
          props.handleViolationStageEnd()
        );
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Κατάσταση
            Παράβασης
          </Typography>
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <TextField
                  label="Τίτλος"
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={10}
                  value={title}
                  onChange={titleChange}
                />
                <Button onClick={onSave}> Αποθηκευση</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
function ViolationStageDeleteSwal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDelete = () => {
    session
      .get(`api/bussiness-violations-status/${props.violationId}/actions/`)
      .then((resp) => {
        EventSpy(
          3,
          `Διαγραφή κατασταση παράβασης ${JSON.stringify(resp.data)}`
        );
      });
    session
      .delete(`api/bussiness-violations-status/${props.violationId}/actions/`)
      .then(() => setOpen(false), props.handleViolationStageEnd());
  };

  return (
    <div>
      <Button onClick={handleOpen} color="error">
        Διαγραφη
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε Σίγουροι; Διαγραφή Κατάσταση Παράβασης
          </Typography>

          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <Button onClick={onDelete} variant="outlined" color="error">
                  {" "}
                  Διαγραφη
                </Button>
                <Button onClick={handleClose}> Ακυρο</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function ViolationDescriptionCreateModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = React.useState(props.title ? props.title : "");

  const titleChange = (e) => {
    setTitle(e.target.value);
  };
  const onSave = () => {
    if (props.action === "Create") {
      session
        .post("api/violation-description/create/", {
          name: title,
        })
        .then(
          (resp) =>
            EventSpy(
              1,
              `Δημιουργία περιγραφή παραβασης ${JSON.stringify(resp.data)}`
            ),
          setOpen(false),
          props.handleViolationDescriptionEnd(),
          setTitle("")
        );
    } else {
      session
        .put(`api/violation-description/${props.violationId}/actions/`, {
          name: title,
        })
        .then(
          (resp) =>
            EventSpy(
              2,
              `Ανανέωση περιγραφή παραβασης ${JSON.stringify(resp.data)}`
            ),
          setOpen(false),
          props.handleViolationDescriptionEnd()
        );
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Κατάσταση
            Παράβασης
          </Typography>
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <TextField
                  label="Τίτλος"
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={10}
                  value={title}
                  onChange={titleChange}
                />
                <Button onClick={onSave}> Αποθηκευση</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
function ViolationDescriptionDeleteSwal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDelete = () => {
    session
      .get(`api/bussiness-violations-status/${props.violationId}/actions/`)
      .then((resp) => {
        EventSpy(
          3,
          `Διαγραφή περιγραφή παράβασης ${JSON.stringify(resp.data)}`
        );
      });
    session
      .delete(`api/bussiness-violations-status/${props.violationId}/actions/`)
      .then(() => setOpen(false), props.handleViolationDescriptionEnd());
  };

  return (
    <div>
      <Button onClick={handleOpen} color="error">
        Διαγραφη
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε Σίγουροι; Διαγραφή Κατάσταση Παράβασης
          </Typography>

          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <Button onClick={onDelete} variant="outlined" color="error">
                  {" "}
                  Διαγραφη
                </Button>
                <Button onClick={handleClose}> Ακυρο</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function ViolationMappingFields(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [alertShow, setAlertShow] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [violationCategory, setViolationCategory] = React.useState(
    props.eidosCategory || ""
  );
  const [violationFields, setViolationFields] = React.useState(
    props.violationFields || []
  );
  const [eidosParabasis, setEidosParabasis] = React.useState(
    props.eidosParabasis || []
  );
  const initialSelectedValues = props.violationFields.map(
    (field) => field.name
  );
  const [fieldName, setFieldName] = React.useState(props.fieldName || []);

  const handleChange = (event) => {
    setFieldName(event.target.value);
  };

  const handleSave = () => {
    const nameToVerboseName = fieldName.map((entry) => {
      const matchingDictionaryEntry = violationFields.find(
        (dictEntry) => dictEntry.verbose_name === entry
      );
      if (matchingDictionaryEntry) {
        return matchingDictionaryEntry;
      } else {
        return { name: entry, verbose_name: entry };
      }
    });

    if (props.action == "Create") {
      session
        .post("api/eidos-mapping-violation-create/", {
          eidos_choice: violationCategory,
          fields_to_exclude: JSON.stringify(nameToVerboseName),
        })
        .then(() => {
          setAlertShow(false);

          setOpen(false);
          props.handleViolationMapping();
        })
        .catch((e) => {
          setAlertShow(true);
          setErrorMsg("Κάτι πήγε στραβά!");
        });
    } else {
      // EidosMappingId
      session
        .put(`api/eidos-mapping-violation/${props.EidosMappingId}/update/`, {
          eidos_choice: violationCategory,
          fields_to_exclude: JSON.stringify(nameToVerboseName),
        })
        .then(() => {
          setAlertShow(false);
          setOpen(false);
          props.handleViolationMapping();
        })
        .catch((e) => {
          console.log(e);
          if (e.response.data.hasOwnProperty("eidos_choice")) {
            setErrorMsg(
              "Κάτι πήγε στραβά! Η κατηγορία παράβασης πρέπει να είναι μοναδική"
            );
          } else {
            setErrorMsg("Κάτι πήγε στραβά!");
          }

          setAlertShow(true);
        });
    }
  };

  const handleViolationCategory = (e) => {
    setViolationCategory(e.target.value);
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={violationMappingStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Κατάσταση
            Παράβασης
          </Typography>
          {alertShow && <Alert severity="error">{errorMsg}</Alert>}
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 5 }}>
              <CardContent>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Κατηγορία Παράβασης
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Κατηγορία Παράβασης"
                    onChange={handleViolationCategory}
                    value={violationCategory}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {eidosParabasis.map((e) => (
                      <MenuItem value={e.id}>{e.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
              <CardContent>
                <InputLabel id="demo-multiple-chip-label">
                  Πεδία παράβασης
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={fieldName}
                  onChange={handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Πεδία" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {violationFields.map((field) => (
                    <MenuItem key={field.name} value={field.verbose_name}>
                      {field.verbose_name}
                    </MenuItem>
                  ))}
                </Select>
              </CardContent>

              <Button onClick={handleSave}> Αποθηκευση</Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function ViolationMappingFieldDelete(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDelete = () => {
    session
      .get(`api/eidos-mapping-violation/${props.EidosMappingId}/delete/`)
      .then((resp) => {
        EventSpy(
          3,
          `Διαγραφή λεμπτομέρεια παράβασης ${JSON.stringify(resp.data)}`
        );
      });
    session
      .delete(`api/eidos-mapping-violation/${props.EidosMappingId}/delete/`)
      .then(() => setOpen(false), props.handleViolationMapping());
  };

  return (
    <div>
      <Button onClick={handleOpen} color="error">
        Διαγραφη
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε Σίγουροι; Διαγραφή Λεπτομέρειας Παράβασης
          </Typography>

          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <Button onClick={onDelete} variant="outlined" color="error">
                  {" "}
                  Διαγραφη
                </Button>
                <Button onClick={handleClose}> Ακυρο</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function ParabasiMappingModalCreateUpdate(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = React.useState(props.title ? props.title : "");
  const [alertShow, setAlertShow] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [violationCategory, setViolationCategory] = React.useState(
    props.eidosCategory || ""
  );

  const [stadiaParabasis, setStadiaParabasis] = React.useState(
    props.stadiaParabasis || []
  );

  const [fieldName, setFieldName] = React.useState(props.fieldName || []);

  const handleChange = (event) => {
    setFieldName(event.target.value);
  };

  const handleViolationCategory = (e) => {
    setViolationCategory(e.target.value);
  };

  const handleSave = () => {
    const stadiaIds = fieldName.map(
      (title) => stadiaParabasis.find((stadio) => stadio.title === title).id
    );
    if (props.action == "Create") {
      console.log(fieldName);

      session
        .post("api/parabasi-mapping/create/", {
          eidos_parabasis: violationCategory,
          stadio_parabasis: stadiaIds,
        })
        .then(() => {
          setAlertShow(false);

          setOpen(false);
          props.handleParabasiMappingModal();
        })
        .catch((e) => {
          setAlertShow(true);
          setErrorMsg("Κάτι πήγε στραβά!");
        });
    } else {
      session
        .put(`api/parabasi-mapping/${props.parabasiMappingId}/actions/`, {
          eidos_parabasis: violationCategory,
          stadio_parabasis: stadiaIds,
        })
        .then(() => {
          setAlertShow(false);

          setOpen(false);
          props.handleParabasiMappingModal();
        })
        .catch((e) => {
          setAlertShow(true);
          setErrorMsg("Κάτι πήγε στραβά!");
        });
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={violationMappingStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασία"} Συσχέτιση
            Παράβασης
          </Typography>
          {alertShow && <Alert severity="error">{errorMsg}</Alert>}
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 5 }}>
              <CardContent>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Είδος Παράβασης
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Κατηγορία Παράβασης"
                    onChange={handleViolationCategory}
                    value={violationCategory}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {props.eidosParabasis.map((e) => (
                      <MenuItem value={e.id}>{e.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
              <CardContent>
                <InputLabel id="demo-multiple-chip-label">
                  Στάδια παράβασης
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={fieldName}
                  onChange={handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Πεδία" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {stadiaParabasis.map((field) => (
                    <MenuItem key={field.title} value={field.title}>
                      {field.title}
                    </MenuItem>
                  ))}
                </Select>
              </CardContent>

              <Button onClick={handleSave}> Αποθηκευση</Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function ParabasiMappingDelete(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDelete = () => {
    session
      .get(`api/parabasi-mapping/${props.parabasiMappingId}/actions/`)
      .then((resp) => {
        EventSpy(
          3,
          `Διαγραφή συσχέτιση παράβασης ${JSON.stringify(resp.data)}`
        );
      });
    session
      .delete(`api/parabasi-mapping/${props.parabasiMappingId}/actions/`)
      .then(() => setOpen(false), props.handleParabasiMappingModal());
  };

  return (
    <div>
      <Button onClick={handleOpen} color="error">
        Διαγραφη
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε Σίγουροι; Διαγραφή Συσχέτιση Παράβασης
          </Typography>

          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <CardContent>
                <Button onClick={onDelete} variant="outlined" color="error">
                  {" "}
                  Διαγραφη
                </Button>
                <Button onClick={handleClose}> Ακυρο</Button>
              </CardContent>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

export {
  BenchViolation,
  BusinessCategoryDeleteSwal,
  BusinessCategoryModal,
  CitySectionDeleteSwal,
  CitySectionModal,
  PenaltyDeleteSwal,
  PenaltyModal,
  EidosParabasisModal,
  EidosParabasisDeleteSwal,
  StadiaParabaseisModal,
  StadioParabasisDeleteSwal,
  EidosAdeiasModal,
  EidosAdeiasDeleteSwal,
  AttestationCreateModal,
  AttestationDeleteSwal,
  ViolationStageCreateModal,
  ViolationStageDeleteSwal,
  ViolationDescriptionCreateModal,
  ViolationDescriptionDeleteSwal,
  ViolationMappingFields,
  ViolationMappingFieldDelete,
  ParabasiMappingModalCreateUpdate,
  ParabasiMappingDelete,
};

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import Input from "@mui/material/Input";
import session from "../../api/session";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from "@mui/material/Link";
import Autocomplete from "@mui/material/Autocomplete";
import grLocale from "date-fns/locale/el";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EventSpy from "components/eventSpy";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  width: "60%",
};

function ParabaseisModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState(
    props.date ? new Date(props.date) : null
  );
  const [eidos, setEidosParabasi] = React.useState(
    props.selectedEidos ? props.selectedEidos : ""
  );
  const [stadio, setStadioParabasi] = React.useState(
    props.selectedStadio ? props.selectedStadio : ""
  );
  // const [stadioUpdate, setStadioUpdate] = React.useState(props.updateStadia)
  const [textValue, setTextValue] = React.useState(
    props.comments ? props.comments : ""
  );
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [checked, setChecked] = React.useState(
    props.checkAnaklisi ? true : false
  );
  const [checkedFile, setCheckedFile] = React.useState(false);
  const [penalty, setPenalty] = React.useState(
    props.selectedPenalty ? props.selectedPenalty : ""
  );

  const handlePenalty = (e) => {
    console.log(e.target.value);

    setPenalty(e.target.value);
  };

  const handleSelectChange = (event) => {
    setStadioParabasi(event.target.value);
  };
  const handleEidosSelectChange = (event) => {
    setEidosParabasi(event.target.value);
  };

  const dateChange = (newValue) => {
    setValue(newValue);
  };

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };
  const handleFileChecked = (event) => {
    setCheckedFile(event.target.checked);
  };

  const handleChangeText = (event) => {
    setTextValue(event.target.value);
  };

  const SaveCard = () => {
    console.log(selectedFile, "eimai sto save card");
    if (value == null || value === "") {
      alert("Παρακαλώ συμπληρώστε την Ημερομηνία");
      return;
    }

    let data = new FormData();
    if (selectedFile) {
      data.append("file", selectedFile);
    }

    // data.append('date', value.toLocaleDateString().split('/').reverse().join('-'))
    data.append(
      "date",
      value
        .toLocaleDateString("el", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-")
    );
    data.append("stadio", stadio);
    data.append("eidos", eidos);
    data.append("anaklisi_adeias", checked);
    // data.append('comments', textValue)
    data.append("penalty", penalty);
    data.append("business", props.personID);
    data.append("created_by", props.created_by);

    if (props.action === "Create") {
      session
        .post(`api/business-parabaseis/create/`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          EventSpy(1, `Δημιουργία Παράβασης ${JSON.stringify(resp.data)}`);
          setOpen(false);
          props.handleChange();
          props.handleSpinner("parabasiSpinner");
        });
    }
    if (props.action === "Update") {
      if (checkedFile === true) {
        data.delete("file");
        data.append("file", "");
      }

      session
        .put(`api/business-parabaseis/${props.id}/update/`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          EventSpy(2, `Ενημέρωση Παράβασης ${JSON.stringify(resp.data)}`);
          setOpen(false);
          props.handleChange();
          props.handleSpinner("parabasiSpinner");
        });
      setCheckedFile(false);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="outlined">
        {" "}
        {props.buttonTitle ? props.buttonTitle : "Δημιουργια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.title ? props.title : "Δημιουργία"} Παράβασης
          </Typography>
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardContent>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={grLocale}
                    >
                      <Stack spacing={4}>
                        <DesktopDatePicker
                          label="Ημερομηνία"
                          // inputFormat="yyy/MM/dd"
                          inputFormat="dd/MM/yyyy"
                          value={value}
                          // onChange={(newValue) => setValue(newValue)}
                          onChange={(newValue) => dateChange(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </CardContent>
                </Grid>
                <Grid item md={12}>
                  <CardContent>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 250 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Σταδιο Παράβασης
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={stadio}
                        onChange={handleSelectChange}
                        label="Σταδιο Παράβασης"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>

                        {props.stadia &&
                          props.stadia.length > 0 &&
                          props.stadia.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </CardContent>
                </Grid>
                <Grid item xs={12}>
                  <CardContent>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 250 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Είδος Παράβασης
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={eidos}
                        onChange={handleEidosSelectChange}
                        label="Είδος Παράβασης"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {props.eidos &&
                          props.eidos.length > 0 &&
                          props.eidos.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </CardContent>
                </Grid>
                <Grid item xs={12}>
                  <CardContent>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 250 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Ποινή
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label1"
                        id="demo-simple-select-standard1"
                        value={penalty}
                        onChange={handlePenalty}
                        label="Ποινή"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {props.penalties &&
                          props.penalties.length > 0 &&
                          props.penalties.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {/* <TextField
                                            id="outlined-multiline-flexible"
                                            label="Ποινή"
                                            multiline
                                            rows="4"
                                            onChange={(e) => handleChangeText(e)}
                                            value={textValue}
                                        /> */}
                  </CardContent>
                </Grid>
                <Grid item xs={12}>
                  <CardContent>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleChangeCheck}
                          name="jason"
                        />
                      }
                      label="Ανάκληση Άδειας"
                    />
                  </CardContent>
                </Grid>
                <br />
                <Grid item xs={12}>
                  <Input
                    id="file_field"
                    name="file_field"
                    type="file"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                  <br />
                  <br />

                  {props.file && props.file.length > 0 && (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedFile}
                            onChange={handleFileChecked}
                          />
                        }
                        label={
                          <Typography style={{ color: "black" }}>
                            Διαγραφή <br />
                            {decodeURI(props.file.split("/").pop())}
                          </Typography>
                        }
                      />

                      {/* <Link href={props.file}>{decodeURI(props.file.split('/').pop())}</Link> */}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Stack>
          <br />
          <br />

          <Typography>
            <Button variant="contained" color="success" onClick={SaveCard}>
              Αποθηκευση
            </Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

function MetabibaseisModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState(
    props.date ? new Date(props.date) : null
  );
  const [textValueFrom, setTextValueFrom] = React.useState(
    props.fromPerson ? props.fromPerson : ""
  );
  const [textValueTo, setTextValueTo] = React.useState(
    props.toPerson ? props.toPerson : ""
  );
  const [textValue, setTextValue] = React.useState(
    props.comments ? props.comments : ""
  );
  const [checkedFile, setCheckedFile] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [valueOption, setValueOption] = React.useState(options[0]);
  const [valueToPerson, setValueToPerson] = React.useState("");

  const handleFromChange = (event) => {
    setTextValueFrom(event.target.value);
  };
  const handleFileChecked = (event) => {
    setCheckedFile(event.target.checked);
  };

  const handleToChange = (event) => {
    setTextValueTo(event.target.value);
  };
  const handleChangeText = (event) => {
    setTextValue(event.target.value);
  };
  const SaveCard = () => {
    let data = new FormData();
    if (selectedFile) {
      data.append("file", selectedFile);
    }

    data.append(
      "date",
      value
        .toLocaleDateString("el", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-")
    );
    data.append("from_person", textValueFrom);
    data.append("to_person", textValueTo);

    data.append("comments", textValue);
    data.append("business", props.personID);
    data.append("created_by", props.created_by);

    if (props.action === "Create") {
      let toPersonForm = new FormData();
      if (selectedFile) {
        toPersonForm.append("file", selectedFile);
      }
      toPersonForm.append(
        "date",
        value
          .toLocaleDateString("el", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-")
      );
      toPersonForm.append("from_person", textValueFrom);
      toPersonForm.append("to_person", textValueTo);
      toPersonForm.append("comments", textValue);
      toPersonForm.append("business", valueToPerson);
      toPersonForm.append("created_by", props.created_by);

      session
        .post(`api/business-transfer/create/`, toPersonForm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          EventSpy(1, `Δημιουργία Μεταβίβασης ${JSON.stringify(resp.data)}`);
          console.log("toPerson Done");
          props.handleSpinner("metabibasiSpinner");
          props.handleChange();
        });

      session
        .post(`api/business-transfer/create/`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          setOpen(false);
          props.handleSpinner("metabibasiSpinner");
          props.handleChange();
        });
    }
    if (props.action === "Update") {
      if (checkedFile) {
        data.delete("file");
        data.append("file", "");
      }

      session
        .get(
          `api/business-tranfer/list/?from_person=${textValueFrom}&to_person=${textValueTo}&comments=${props.comments}`
        )
        .then((resp) => {
          const transferData = resp.data;
          console.log(resp.data);
          for (const tradata of transferData) {
            data.append("business", tradata.business);
            session
              .put(`api/business-transfer/${tradata.id}/update/`, data, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((resp) => {
                EventSpy(
                  2,
                  `Ανανέωση Μεταβίβασης ${JSON.stringify(resp.data)}`
                );
                console.log("done for", transferData);
                props.handleSpinner("metabibasiSpinner");
                props.handleChange();
              });
          }
        });
      setOpen(false);
      props.handleChange();
      setCheckedFile(false);

      // session.put(`api/business-transfer/${props.id}/update/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      //     .then(() => setOpen(false), props.handleChange())
      // setCheckedFile(false)
    }
  };
  const onInputChange = (e) => {
    session
      .get(`api/business/?business_name=${e.target.value}`)
      .then((resp) => {
        console.log(resp.data);
        const results = resp.data.results;
        if (results && results.length > 0) {
          setOptions(results);
        }
      });
  };

  const like = (e, v) => {
    console.log(v);
    console.log(v.id);
    setValueToPerson(v.id);
    setTextValueTo(v.nameE);
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="outlined">
        {" "}
        {props.buttonTitle ? props.buttonTitle : "Δημιουργια"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.title ? props.title : "Δημιουργία"} Μεταβίβασης
          </Typography>
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardContent>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={grLocale}
                    >
                      <Stack spacing={4}>
                        <DesktopDatePicker
                          label="Ημερομηνία"
                          inputFormat="dd/MM/yyyy"
                          value={value}
                          onChange={(newValue) => setValue(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </CardContent>
                </Grid>
                <Grid item xs={12}>
                  <CardContent>
                    <TextField
                      label="Σχολια"
                      id="outlined-multiline-flexible"
                      multiline
                      maxRows={4}
                      value={textValue}
                      onChange={handleChangeText}
                    />
                  </CardContent>
                </Grid>
                <Grid item xs={12}>
                  <CardContent>
                    <TextField
                      label="Απο"
                      id="outlined-multiline-flexible"
                      disabled
                      multiline
                      maxRows={4}
                      value={textValueFrom}
                      onChange={handleFromChange}
                    />
                  </CardContent>
                </Grid>
                <Grid item xs={12}>
                  <CardContent>
                    <TextField
                      label="Προς"
                      id="outlined-multiline-flexible"
                      multiline
                      maxRows={4}
                      value={textValueTo}
                      disabled
                    />
                  </CardContent>

                  <CardContent>
                    <Autocomplete
                      value={valueOption}
                      onChange={like}
                      id="combo-box-demo"
                      loading
                      loadingText="Φόρτωση"
                      options={options}
                      noOptionsText="Δεν βρεθηκαν Αποτελέσματα"
                      onInputChange={onInputChange}
                      getOptionLabel={(option) => option.nameE}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                           {...params}
                           label="Αναζήτηση Επιχείρησης"
                           variant="outlined"
                        />
                       )}
                    />
                  </CardContent>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    //  multiple
                    id="file_field"
                    name="file_field"
                    type="file"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                  <br />
                  <br />
                </Grid>

                {props.file && props.file.length > 0 && (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedFile}
                          onChange={handleFileChecked}
                        />
                      }
                      label={
                        <Typography style={{ color: "black" }}>
                          Διαγραφή <br />
                          {decodeURI(props.file.split("/").pop())}
                        </Typography>
                      }
                    />

                    {/* <Link href={props.file}>{decodeURI(props.file.split('/').pop())}</Link> */}
                  </div>
                )}
              </Grid>
            </Box>
          </Stack>
          <br />
          <br />
          <Typography>
            <Button variant="contained" color="success" onClick={SaveCard}>
              Αποθηκευση
            </Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

const customStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function PoinesParabaseisModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [poines, setPoines] = React.useState(props.poines);

  return (
    <div style={{ display: "flex", justifyContent: "right" }}>
      <Button onClick={handleOpen} variant="contained">
        {" "}
        Συνολικες Ποινες
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Συνολικές Παραβάσεις ανά κατηγορία παράβασης -{" "}
            {new Date().getFullYear()}
          </Typography>
          <hr />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Έτος</TableCell>
                  {/* <TableCell align="right">Ώρα Δημιουργίας</TableCell> */}
                  <TableCell align="center">Κατηγορία Παράβασης</TableCell>
                  <TableCell align="right">Σύνολο</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.poines &&
                  props.poines.length > 0 &&
                  props.poines.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{row.date__year}</TableCell>
                      <TableCell align="center">{row.eidos__title}</TableCell>
                      <TableCell
                        align="right"
                        style={{ color: row.cnt_eidos >= 3 ? "red" : "" }}
                      >
                        {" "}
                        {row.cnt_eidos}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}

export { ParabaseisModal, MetabibaseisModal, PoinesParabaseisModal };

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { el } from "date-fns/locale";
import Input from "@mui/material/Input";
import enGB from "date-fns/locale/en-GB";

import grLocale from "date-fns/locale/el";
import session from "../api/session";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import EventSpy from "components/eventSpy";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const customStyleDiv = {
  display: "flex",
  justifyContent: "space-evenly",
};

function CreateBusinessModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [buisnessName, setBuisnessName] = React.useState("");
  const [owner, setOwner] = React.useState("");
  const [afm, setAfm] = React.useState("");
  const [site, setSite] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [mobilePhone, setMobilePhone] = React.useState("");
  const [homePhone, setHomePhone] = React.useState("");
  const [folderNumber, setFolderNumber] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [alertShow, setAlertShow] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [distinctiveTitle, setDistinctiveTitle] = React.useState("");
  const [licenseNumber, setLicenseNumber] = React.useState("");
  const [notificationDate, setNotificationDate] = React.useState(null);
  const [citySection, setCitySection] = React.useState("");
  const [citySectionId, setCitySectionId] = React.useState("");
  const [businessCategoryId, setBussinessCategoryId] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState("");

  const isFormValid = () => {
    if (
      [buisnessName, owner, address, category]
        .map((e) => e == "")
        .filter((o) => o == true).length > 0
    ) {
      setAlertShow(true);
      setErrorMsg("Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία!");
      return false;
    } else {
      setAlertShow(false);
      return true;
    }
  };

  const handleSelectedFiles = (e) => {
    setFiles(e.target.files);
  };

  const handleCitySection = (e) => {
    if (e.target.value != "") {
      session.get("api/city-section/list/").then((resp) => {
        const citySectionId = resp.data.find(
          (ct) => ct.title === e.target.value
        ).id;
        setCitySectionId(citySectionId);

        console.log(citySectionId);
      });
    }
    setCitySection(e.target.value);
  };

  const handleBusinessCategory = (e) => {
    if (e.target.value != "") {
      session.get("api/EpixeirisiCategory/").then((resp) => {
        const businessCatId = resp.data.find(
          (bt) => bt.nameEC === e.target.value
        ).id;
        setBussinessCategoryId(businessCatId);
      });
    }
    setCategory(e.target.value);
  };

  const saveDialog = () => {
    if (isFormValid()) {
      const today = new Date();
      session
        .post("api/Epixeiriseis/", {
          nameE: buisnessName
            .split(" ")
            .filter((e) => e)
            .join(" "),
          afmE: afm,
          iconeE: businessCategoryId,
          userIdE: owner,
          siteE: site,
          dieuthinsi: address,
          paratiriseis: notes,
          arfak: folderNumber,
          distinctive_title: distinctiveTitle,
          mobile_phone: mobilePhone,
          home_phone: homePhone,
          license_number: licenseNumber,
          notification_date:
            notificationDate != null
              ? notificationDate
                .toLocaleDateString("el", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
                .split("/")
                .reverse()
                .join("-")
              : null,
          city_section: citySectionId,
          wrarioE:
            startTime != null
              ? startTime.toLocaleTimeString("el", { hour12: false })
              : new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                8,
                0,
                0
              ).toLocaleTimeString("el", { hour12: false }),
          wrarioL:
            endTime != null
              ? endTime.toLocaleTimeString("el", { hour12: false })
              : new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                16,
                0,
                0
              ).toLocaleTimeString("el", { hour12: false }),
        })
        .then((resp) => {
          EventSpy(1, `Δημιουργία επιχείρησης ${JSON.stringify(resp.data)}`);
          props.createBusinessEnd();
          setTimeout(() => {
            if (files.length > 0) {
              for (const fileObject of files) {
                let data = new FormData();
                data.append("file_field", fileObject);
                data.append("epixeirisi", resp.data.id);
                session.post(`api/FileFieldFormEpixeirisi/`, data, {
                  headers: { "Content-Type": "multipart/form-data" },
                });
              }
            }
          }, 2000);
          setOpen(false);
        })
        .catch((e) => {
          console.log(e.response.data);
          if (e.response.data.hasOwnProperty("nameE")) {
            setAlertShow(true);
            setErrorMsg("Επιχείρηση με αυτό το όνομα υπάρχει ήδη");
            setTimeout(() => {
              setAlertShow(false);
            }, 2000);
            return;
          } else {
            setAlertShow(true);
            setErrorMsg(e.response.data);
            setTimeout(() => {
              setAlertShow(false);
            }, 2000);
          }
          return;
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAlertShow(false);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        startIcon={<AddBusinessIcon />}
        variant="contained"
        sx={{
          width: "fit-content",
          height: "78%",
          textTransform: "none",
          bgcolor: "#169ab0",
        }}
      >
        Δημιουργία Επιχείρησης
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"l"}>
        <DialogTitle style={{ textAlign: "center" }}>
          Δημιουργία Επιχείρησης
        </DialogTitle>
        {alertShow && <Alert severity="error">{errorMsg}</Alert>}

        <DialogContent style={{ textAlign: "center" }}>
          <hr />

          <Grid container spacing={8} alignItems="center" justify="center">
            <Grid item xs={6}>
              <Card
                sx={{ minWidth: 275 }}
                style={{
                  boxShadow: "0 0 20px aquamarine",
                  borderStyle: "groove",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Επωνυμία"
                      variant="standard"
                      required
                      onChange={(e) => setBuisnessName(e.target.value)}
                    />

                    <TextField
                      id="standard-basic"
                      label="Ιδιοκτήτης"
                      variant="standard"
                      required
                      onChange={(e) => setOwner(e.target.value)}
                    />
                  </div>

                  <br />
                  <br />

                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Αρ.Αδείας"
                      variant="standard"
                      onChange={(e) => setLicenseNumber(e.target.value)}
                    />
                    <TextField
                      id="standard-basic"
                      label="Διεύθυνση"
                      variant="standard"
                      required
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <br />
                  <br />
                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Διακριτικός Τίτλος"
                      variant="standard"
                      onChange={(e) => setDistinctiveTitle(e.target.value)}
                    />
                    <TextField
                      id="standard-basic"
                      label="ΑΦΜ"
                      variant="standard"
                      onChange={(e) => setAfm(e.target.value)}
                    />
                  </div>
                  <br />
                  <br />
                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Αριθμός Φακέλου"
                      variant="standard"
                      onChange={(e) => setFolderNumber(e.target.value)}
                    />
                    <TextField
                      id="standard-basic"
                      label="Site"
                      variant="standard"
                      onChange={(e) => setSite(e.target.value)}
                    />
                  </div>
                  <br />
                  <br />
                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Κινητό Τηλέφωνο"
                      variant="standard"
                      onChange={(e) => setMobilePhone(e.target.value)}
                    />

                    <TextField
                      id="standard-basic"
                      label="Σταθερό Τηλέφωνο"
                      variant="standard"
                      onChange={(e) => setHomePhone(e.target.value)}
                    />
                  </div>
                  <br />
                  <br />
                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Παρατηρήσεις"
                      variant="standard"
                      onChange={(e) => setNotes(e.target.value)}
                    />
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enGB}
                    >
                      <Stack spacing={2} style={{ width: "29%" }}>
                        <DesktopDatePicker
                          label="Ημ/νία Γνωστοποίησης"
                          inputFormat="dd/MM/yyyy"
                          value={notificationDate}
                          // onChange={(newValue) => setValue(newValue)}
                          onChange={(newValue) => setNotificationDate(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                  <br />
                  <br />
                  <div className="myclass">
                    <InputLabel id="demo-controlled-open-select-label">
                      Κατηγορία Επιχείρησης*
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={category}
                      onChange={(e) => handleBusinessCategory(e)}
                    >
                      <MenuItem value="">
                        <em>-------------------</em>
                      </MenuItem>
                      {props.categories.map((e) => (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <br />

                  <div className="myclass">
                    <InputLabel id="demo-controlled-open-select-label1">
                      Δημοτική Ενότητα
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label1"
                      id="demo-controlled-open-select1"
                      value={citySection}
                      onChange={(e) => handleCitySection(e)}
                    >
                      <MenuItem value="">
                        <em>-------------------</em>
                      </MenuItem>
                      {props.citySections.map((e) => (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                sx={{ minWidth: 275 }}
                style={{
                  boxShadow: "0 0 20px aquamarine",
                  borderStyle: "groove",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="Εναρξη Ωραρίου"
                      value={startTime}
                      ampm={false}
                      format="HH:mm:ss"
                      onChange={(newValue) => {
                        setStartTime(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <br />
                  <br />

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="Λήξη Ωραρίου"
                      value={endTime}
                      ampm={false}
                      format="HH:mm:ss"
                      onChange={(newValue) => {
                        setEndTime(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      
                    />
                  </LocalizationProvider>
                </CardContent>
              </Card>

              <br />
              <br />

              <Card
                sx={{ minWidth: 275 }}
                style={{
                  boxShadow: "0 0 20px aquamarine",
                  borderStyle: "groove",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Συνημμένο αρχείο
                  </Typography>

                  <input
                    multiple
                    id="file_field"
                    name="file_field"
                    type="file"
                    onChange={(e) => handleSelectedFiles(e)}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="success" onClick={saveDialog}>
            Αποθηκευση
          </Button>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Κλεισιμο
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function DeleteBussiness(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deleteBusiness = () => {
    session.get(`api/Epixeiriseis/${props.bussinessID}/`).then((resp) => {
      EventSpy(3, `Διαγράφηκε η επιχείρηση ${JSON.stringify(resp.data)}`);
    });
    session
      .delete(`api/Epixeiriseis/${props.bussinessID}/`)
      .then(() => props.handleBusinessDeleteEnd())
      .catch((e) => console.log(e.response.data))
      .finally(() => setOpen(false));
  };

  return (
    <div style={{ display: "inline" }}>
      <Tooltip title="Διαγραφή επιχείρησης">
        <Button
          onClick={handleOpen}
          startIcon={<DeleteIcon />}
          color="error"
        ></Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε σίγουροι οτι θέλετε να διαγράψετε την Επιχείρηση -{" "}
            {props.bussinessName} ;
          </Typography>

          <hr />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Button color="error" variant="outlined" onClick={deleteBusiness}>
            Διαγραφη
          </Button>
          <Button color="success" variant="outlined" onClick={handleClose}>
            Επιστροφη
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export { CreateBusinessModal, DeleteBussiness };

import session from "views/api/session";
// Action value is integer option with create=1 ,update=2, delete=3
export default function EventSpy(action, description) {
    session
        .post("api/event/create/", {
            action: action,
            description: description,
        })
        .then(() => console.log("spied"))
        .catch((e) => {
            console.log(e.response.data, "Spy");
        });
}
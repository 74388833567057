import React from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import CssBaseline from "@material-ui/core/CssBaseline";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// import Paginations from "components/Pagination/Pagination.js";
import { Pagination } from "@material-ui/lab";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// reactstrap components
import { FormGroup, Input, Row, Col } from "reactstrap";
// Define a default UI for filtering

// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
// }) {
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <span>
//       Search:{" "}
//       <input
//         value={value || ""}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         placeholder={`${count} records...`}
//         style={{
//           fontSize: "1.1rem",
//           border: "0",
//         }}
//       />
//     </span>
//   );
// }

// function DefaultColumnFilter({
//   column: { filterValue, preFilteredRows, setFilter },
// }) {
//   const count = preFilteredRows.length;
//   return (
//     <FormGroup style={{ zIndex: 1 }}>
//       <Input
//         style={{ width: "80px", borderRadius: "15px", zIndex: 1 }}
//         type="text"
//         value={filterValue || ""}
//         onChange={(e) => {
//           setFilter(e.target.value); // Set undefined to remove the filter entirely
//         }}
//         placeholder={`Αναζήτηση `}
//       />
//     </FormGroup>
//   );
// }

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      style={{ width: "80px", borderRadius: "15px", zIndex: 1 }}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Αναζήτηση`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
// Our table component
function Table({ columns, data }) {
  const [numberOfRows, setNumberOfRows] = React.useState(10);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn options
      filterTypes,
      initialState: { pageSize: 5, pageIndex: 0 },
    },

    useFilters,

    useSortBy,
    usePagination
    // useGlobalFilter!
  );
  let pageSelectData = Array.apply(
    null,
    Array(pageOptions.length)
  ).map(function () {});
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];
  return (
    <>
      <div className="ReactTable -striped -highlight">
        <MaUTable {...getTableProps()} className="rt-table">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, key) => (
                  <th>
                    <span
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      // className={classnames("rt-th rt-resizable-header", {
                      //   "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                      //   "-sort-asc": column.isSorted && !column.isSortedDesc,
                      //   "-sort-desc": column.isSorted && column.isSortedDesc,
                      // })}
                    >
                      {/* <div className="rt-resizable-header-content"> */}
                      {column.render("Header")}
                      {/* </div> */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                      {/* Render the columns filter UI */}

                      {/* <div>
                      {headerGroup.headers.length - 1 === key
                        ? null
                        : column.canFilter
                        ? column.render("Filter")
                        : null}
                    </div> */}
                    </span>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 }
                  )}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        className="rt-td ml-1"
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
        <div>
          <FormGroup row>
            <Row>
              {/* <Col className="-previous">
                <button
                  type="button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="-btn"
                  style={{ width: "80px", borderRadius: "15px" }}
                  color={localStorage.getItem("color")}
                >
                  <i className="fa fa-arrow-left" />
                </button>
              </Col> */}
              <Col>
                {/* <Paginations
                
                  pages={pageSelectData.map((prop, key) => {
                    return {
                      value: key,
                      label: "Σελίδα " + (key + 1),
                    };
                  })}
                  color="info"
                /> */}
                <Pagination
                  count={pageCount}
                  page={pageSelect + 1}
                  color="primary"
                  onChange={(event, value) => {
                    gotoPage(value - 1);
                    handlePageSelect(value - 1);
                  }}
                />

                {/* <Select
                  //color={localStorage.getItem('color')}
                  // className="react-select info  w-50"
                  // classNamePrefix="react-select"
                  style={{ width: "200px", borderRadius: "15px" }}
                  name="singleSelect"
                  value={pageSelect}
                  onChange={(value) => {
                    gotoPage(value);
                    handlePageSelect(value);
                  }}
                  options={pageSelectData.map((prop, key) => {
                    return {
                      value: key,
                      label: "Σελίδα " + (key + 1),
                    };
                  })}
                  placeholder="Επιλογή Σελίδων"
                /> */}
                {/* <Select
                  //color={localStorage.getItem('color')}
                  // className="react-select info  w-50"
                  // classNamePrefix="react-select"
                  name="singleSelect"
                  style={{ width: "200px", borderRadius: "15px" }}
                  value={numberOfRows}
                  onChange={(value) => {
                    console.log(value);
                    setPageSize(value.value);
                    setNumberOfRows(value);
                  }}
                  options={numberOfRowsData.map((prop) => {
                    return {
                      value: prop,
                      label: prop + " Σειρές",
                    };
                  })}
                  placeholder="Επιλογή Σειρών"
                /> */}
              </Col>
              {/* <Col className="-next">
                <button
                  type="button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="-btn"
                  style={{ width: "80px", borderRadius: "15px" }}
                  color={localStorage.getItem("color")}
                >
                  <i className="fa fa-arrow-right" />
                </button>
              </Col> */}
            </Row>
          </FormGroup>
        </div>
      </div>
      {/* <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div> */}
    </>
  );
}
// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}
// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
export default Table;

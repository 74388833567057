import React, { useEffect, useState } from "react";
import axios from "axios"; // nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";

import logoS from "assets/img/serresgr-logo-300x77.png";
import mapslide from "assets/img/map_slide.png";
import ReactTable from "components/ReactTable/ReactTable.js";
import ReactExport from "react-data-export";
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import session from "../api/session";
let UdataTable = [];

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div>
      <Header
        routes={dashboardRoutes}
        brand="e-Pixeirin"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <br></br>
      <br />
      <br />
      <br></br>
      <Parallax filter image={require("assets/img/profile-bg2.jpg")}>
        <div className={classes.container} style={{ textAlign: "center" }}>
          <br />
          {localStorage.getItem("token") && <LandingPanel></LandingPanel>}

          <GridContainer>
            <GridItem xs={6}>
              <h1 className={classes.title}>
                ΔΙΑΔΡΑΣΤΙΚΟΙ ΧΑΡΤΕΣ ΕΠΙΧΕΙΡΗΣΕΩΝ
              </h1>
              <h3>Δήμος Βόλου</h3>
              <br />

              <Button
                color="success"
                size="lg"
                href="/MapE"
                rel="noopener noreferrer"
                round
              >
                {/* <i className="fas fa-play" /> */}
                Χαρτης Επιχειρησεων
              </Button>
              <Button
                color="success"
                size="lg"
                href="/MapLA"
                rel="noopener noreferrer"
                round
              >
                {/* <i className="fas fa-play" /> */}
                Χαρτης Λαικης Αγορας
              </Button>
            </GridItem>

            <GridItem xs={6}>
              <img
                src={logoS}
                style={{ width: "300px" }}
                alt="..."
                className={imageClasses}
              />
              <img
                src={mapslide}
                style={{ width: "400px" }}
                alt="..."
                className={imageClasses}
              />
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          {/* <TeamSection /> */}
          {/* <WorkSection /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

class LandingPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useStyles: makeStyles(styles),
      // useTheme: useTheme(),
      PagkoiList: [],
      LaikesList: [],
      docsList: [],
      LaikesSelectList: [],
      markers: [[0, 0]],
      activeItem: {},
      activeItemLaiki: {},
      modalP: false,
      modalL: false,
      laikiselected: "",
      EpixeiriseisList: [],
      EpixeiriseisCategoryList: [],
      activeItemEC: {},
      activeItemE: {},
      modalE: false,
      modalEC: false,
      arxdocitem: {},
      selectedDocs: [],
    };
  }

  componentDidMount() {
    this.refreshList();
  }

  refreshList() {
    const api_url = "http://127.0.0.1:8000/api/";
    const epixirin_api = "https://epixirin-volos.govcloud.gr/be/api/";

    // session.get('api/Pagkoi/')
    // .then((res) => this.setState({ PagkoiList: res.data }))
    //   .catch((err) => console.log(err));

    // axios
    //   .get(`${api_url}Pagkoi/`)
    //   .then((res) => this.setState({ PagkoiList: res.data }))
    //   .catch((err) => console.log(err));

    session
      .get("api/LaikiAgoraMini/")
      .then((res) => this.setState({ LaikesSelectList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get(`${api_url}LaikiAgoraMini/`)
    //   .then((res) => this.setState({ LaikesSelectList: res.data }))
    //   .catch((err) => console.log(err));

    session
      .get("api/LaikiAgora/")
      .then((res) => this.setState({ LaikesList: res.data }))
      .catch((err) => console.log(err));

    // axios
    //   .get(`${api_url}LaikiAgora/`)
    //   .then((res) => this.setState({ LaikesList: res.data }))
    //   .catch((err) => console.log(err));

    // session.get('api/Epixeiriseis/')
    // .then((res) => this.setState({ EpixeiriseisList: res.data }))
    // .catch((err) => console.log(err));
    // axios
    //   .get(`${api_url}Epixeiriseis/`)
    //   .then((res) => this.setState({ EpixeiriseisList: res.data }))
    //   .catch((err) => console.log(err));
  }

  render() {
    console.log(this.state.laikiselected);

    const tmpItems = this.state.PagkoiList;

    const tmpItemsLaikes = this.state.LaikesList;
    const { markers } = this.state;
    const classes = this.state.useStyles;
    const theme = this.state.useTheme;
    let EpixeiriseisItems = this.state.EpixeiriseisList;

    var today = new Date(),
      date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();

    UdataTable = [];

    let TelixanE = [];
    let TelixanP = [];
    let TkonteyounE = [];
    let TkonteyounP = [];

    let elixanE = "";
    let elixanP = "";
    let konteyounE = "";
    let konteyounP = "";

    for (let i = 0; i < tmpItems.length; i++) {
      const dataRow = {};

      const item = tmpItems[i];

      dataRow.eidos = "Πάγκος";
      dataRow.name = tmpItems[i].nameP;
      dataRow.userIdp = tmpItems[i].userIdp;
      dataRow.timeE = String(tmpItems[i].enarxiP);
      dataRow.timeL = String(tmpItems[i].lixiP);

      UdataTable.push(dataRow);
    }
    for (let i = 0; i < EpixeiriseisItems.length; i++) {
      const dataRow = {};
      const item = EpixeiriseisItems[i];

      dataRow.eidos = "Επιχείρηση";
      dataRow.name = EpixeiriseisItems[i].nameE;
      dataRow.timeE = String(EpixeiriseisItems[i].enarxiE);
      dataRow.timeL = String(EpixeiriseisItems[i].lixiE);

      UdataTable.push(dataRow);
    }

    if (tmpItems.length !== 0 && EpixeiriseisItems.length !== 0) {
      for (let i = 0; i < UdataTable.length; i++) {
        var date1 = new Date();
        var date2 = new Date(UdataTable[i].timeL);

        if (date1 > date2) {
          if (UdataTable[i].eidos === "Επιχείρηση") {
            TelixanE.push(UdataTable[i].name);
          } else {
            TelixanP.push(UdataTable[i].name);
          }
          // console.log("E ->" + UdataTable[i].name + " - " + UdataTable[i].timeL);
        } else if (
          date2.getFullYear() === date1.getFullYear() &&
          date2.getMonth() - date1.getMonth() <= 1
        ) {
          if (UdataTable[i].eidos === "Επιχείρηση") {
            TkonteyounE.push(UdataTable[i].name);
          } else {
            TkonteyounP.push(UdataTable[i].name);
          }

          // console.log(
          //   "K  -> " + UdataTable[i].name + " - " + UdataTable[i].timeL
          // );
        }
      }
    }
    for (let i = 0; i < TelixanE.length; i++) {
      elixanE += TelixanE[i] + ",";
      if (i === 10) {
        elixanE += "και άλλα " + String(TelixanE.length - 10);
        break;
      }
    }
    for (let i = 0; i < TelixanP.length; i++) {
      elixanP += TelixanP[i] + ",";
      if (i === 10) {
        elixanP += "και άλλα " + String(TelixanP.length - 10);
        break;
      }
    }
    for (let i = 0; i < TkonteyounE.length; i++) {
      konteyounE += TkonteyounE[i] + ",";
      if (i === 10) {
        konteyounE += "και άλλα " + String(TkonteyounE.length - 10);
        break;
      }
    }
    for (let i = 0; i < TkonteyounP.length; i++) {
      konteyounP += TkonteyounP[i] + ",";
      if (i === 10) {
        konteyounP += "και άλλα " + String(TkonteyounP.length - 10);
        break;
      }
    }
    // let elixanM = "";
    // console.log(elixan);
    // if (elixan.length != 0) {
    //   elixanM =
    //     "Προσοχή : Οι συνδρομές των παρακάτω εταιριών έληξαν : " + elixan;
    // }
    // let konteyounM = "";
    // if (konteyoun.length != 0) {
    //   konteyounM =
    //     "Προσοχή : Οι συνδρομές των παρακάτω εταιριών λήγουν σύντομα: " +
    //     konteyoun;
    // }

    return (
      <>
        <div>
          <GridContainer>
            <GridItem xs={6}>
              {elixanE.length !== 0 && (
                <SnackbarContent
                  message={
                    <span>
                      <b>Προσοχή :</b> Οι συνδρομές των παρακάτω{" "}
                      <b>Επιχρειρήσεων </b> έληξαν: <br></br>
                      {elixanE}
                    </span>
                  }
                  close
                  color="danger"
                  icon={Warning}
                />
              )}

              {konteyounE.length !== 0 && (
                <SnackbarContent
                  message={
                    <span>
                      <b>Προσοχή :</b> Οι συνδρομές των παρακάτω{" "}
                      <b>Επιχρειρήσεων</b> λήγουν σύντομα:
                      <br></br>
                      {konteyounE}
                    </span>
                  }
                  close
                  color="warning"
                  icon="info_outline"
                />
              )}
            </GridItem>

            <GridItem xs={6}>
              {elixanP.length !== 0 && (
                <SnackbarContent
                  message={
                    <span>
                      <b>Προσοχή </b>: Οι συνδρομές των παρακάτω <b>πάγκων </b>{" "}
                      έληξαν: <br></br>
                      {elixanP}
                    </span>
                  }
                  close
                  color="danger"
                  icon={Warning}
                />
              )}
              {konteyounP.length !== 0 && (
                <SnackbarContent
                  message={
                    <span>
                      <b>Προσοχή :</b> Οι συνδρομές των παρακάτω <b>πάγκων </b>{" "}
                      λήγουν σύντομα:
                      <br></br>
                      {konteyounP}
                    </span>
                  }
                  close
                  color="warning"
                  icon="info_outline"
                />
              )}
            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  }
}

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckBox from '@mui/material/Checkbox';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { el } from "date-fns/locale";
import Input from "@mui/material/Input";
import grLocale from "date-fns/locale/el";
import enGB from "date-fns/locale/en-GB";
import session from "../../api/session";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import EventSpy from "components/eventSpy";
// import { CheckBox } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function UpdateBusinessLicenseFile(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [file, setFile] = React.useState(props.file ? props.file : "");
  const [businessLisence, setbusinessLisence] = React.useState("");

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const saveFile = () => {
    session.get(`api/business-file/${props.fileId}/actions/`).then((resp) => {
      console.log(resp.data.business_lisence);
      let data = new FormData();
      data.append("file", file);
      data.append("business_lisence", resp.data.business_lisence);
      session
        .put(`api/business-file/${props.fileId}/actions/`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          () => setOpen(false),
          props.handleBusinessFileEnd(),
          props.handleSpinner("licenseSpinner")
        );
    });
  };

  return (
    <div style={{ display: "inline" }}>
      <Button onClick={handleOpen} startIcon={<EditIcon />}></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Επεξεργασία Αρχείου
          </Typography>
          <hr />
          <input type="file" onChange={(e) => handleFile(e)} />

          <br />
          <br />
          <Grid item xs={12}>
            <CardContent>
              <Button onClick={saveFile}> Αποθηκευση</Button>
            </CardContent>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
const customStyleDiv = {
  display: "flex",
  justifyContent: "space-evenly",
};
function BusinessEdit(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [buisnessName, setBuisnessName] = React.useState(
    props.bussinessName ? props.bussinessName : ""
  );
  const [owner, setOwner] = React.useState(props.owner ? props.owner : "");
  const [afm, setAfm] = React.useState(props.afm ? props.afm : "");
  const [site, setSite] = React.useState(props.site ? props.site : "");
  const [address, setAddress] = React.useState(
    props.address ? props.address : ""
  );
  const [folderNumber, setFolderNumber] = React.useState(
    props.folderNumber ? props.folderNumber : ""
  );
  const [category, setCategory] = React.useState(props.categories);
  const [notes, setNotes] = React.useState(props.notes ? props.notes : "");
  const [startTime, setStartTime] = React.useState(
    props.startTime ? props.startTime : ""
  );
  const [endTime, setEndTime] = React.useState(
    props.endTime ? props.endTime : ""
  );
  const [alertShow, setAlertShow] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [distinctiveTitle, setDistinctiveTitle] = React.useState(
    props.distinctiveTitle ? props.distinctiveTitle : ""
  );

  const [bussinessCategory, setBussinessCategory] = React.useState(
    props.bussinessCategory ? props.bussinessCategory : ""
  );
  const [licenseNumber, setLicenseNumber] = React.useState(
    props.licenseNumber ? props.licenseNumber : ""
  );
  const [notificationDate, setNotificationDate] = React.useState(
    props.notificationDate != null ? moment(props.notificationDate)._d : ""
  );
  const [mobilePhone, setMobilePhone] = React.useState(
    props.mobilePhone ? props.mobilePhone : ""
  );
  const [homePhone, setHomePhone] = React.useState(
    props.homePhone ? props.homePhone : ""
  );
  const [isClosed, setIsClosed] = React.useState(
    props.isClosed ? props.isClosed : false
  );
  const [notdat, setNotDate] = React.useState();
  const [citySection, setCitySection] = React.useState(
    props.citySection ? props.citySection : ""
  );
  const [citySectionId, setCitySectionId] = React.useState(
    props.citySectionId ? props.citySectionId : null
  );
  const [businessCatId, setBussinessCategoryId] = React.useState(
    props.businessCatId ? props.businessCatId : null
  );
  const [errorMsg, setErrorMsg] = React.useState("");

  const today = new Date();
  const [startTimeDate, setStartTimeDate] = React.useState(
    new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      startTime.split(":")[0],
      startTime.split(":")[1],
      startTime.split(":")[2]
    )
  );
  const [endTimeDate, setEndTimeDate] = React.useState(
    new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      endTime.split(":")[0],
      endTime.split(":")[1],
      endTime.split(":")[2]
    )
  );
  // const [alertShow, setAlertShow] = React.useState(false)
  const isFormValid = () => {
    if (
      [buisnessName, owner, address, bussinessCategory]
        .map((e) => e == "")
        .filter((o) => o == true).length > 0
    ) {
      setAlertShow(true);
      setErrorMsg("Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία!");
      return false;
    } else {
      setAlertShow(false);
      return true;
    }
  };
  const handleMultipleFiles = (e) => {
    setFiles(e.target.files);
  };
  const handleFiles = () => {
    if (files.length > 0) {
      let data = new FormData();
      for (const fileObject of files) {
        data.append("file_field", fileObject);
        data.append("epixeirisi", props.bussinessId);
        session
          .post(`api/FileFieldFormEpixeirisi/`, data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((resp) => {
            EventSpy(1, `Δημιουργία Αρχείου ${JSON.stringify(resp.data)}`);
            console.log("Hrthe to resp data");
          });
      }
      console.log("teleiwsa ola ta resp");
    }
    console.log("eimai sto true");
    return true;
  };

  const handleCitySection = (e) => {
    if (e.target.value != "") {
      session.get("api/city-section/list/").then((resp) => {
        const citySectionId = resp.data.find(
          (ct) => ct.title === e.target.value
        ).id;
        setCitySectionId(citySectionId);
      });
    }
    setCitySection(e.target.value);
  };

  const handleBusinessCategory = (e) => {
    if (e.target.value != "") {
      session.get("api/EpixeirisiCategory/").then((resp) => {
        const businessCatId = resp.data.find(
          (bt) => bt.nameEC === e.target.value
        ).id;
        setBussinessCategoryId(businessCatId);
      });
    }
    setBussinessCategory(e.target.value);
  };

  const saveDialog = () => {
    if (isFormValid()) {
      const today = new Date();
      session
        .put(`api/Epixeiriseis/${props.bussinessId}/`, {
          nameE: buisnessName,
          afmE: afm,
          iconeE: businessCatId,
          userIdE: owner,
          siteE: site,
          dieuthinsi: address,
          mobile_phone: mobilePhone,
          home_phone: homePhone,
          is_closed: isClosed,
          paratiriseis: notes,
          arfak: folderNumber,
          distinctive_title: distinctiveTitle,
          license_number: licenseNumber,
          notification_date:
            notificationDate != null
              ? moment(notificationDate).format("YYYY-MM-DD")
              : null,
          city_section: citySectionId,
          wrarioE:
            startTimeDate != null
              ? startTimeDate.toLocaleTimeString("el", { hour12: false })
              : new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                8,
                0,
                0
              ).toLocaleTimeString("el", { hour12: false }),
          wrarioL:
            endTimeDate != null
              ? endTimeDate.toLocaleTimeString("el", { hour12: false })
              : new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                16,
                0,
                0
              ).toLocaleTimeString("el", { hour12: false }),
        })
        .then((resp) => {
          EventSpy(2, `Ανανέωση Επιχείρησης ${JSON.stringify(resp.data)}`);

          if (files.length > 0) {
            let data = new FormData();
            for (const fileObject of files) {
              data.append("file_field", fileObject);
              data.append("epixeirisi", props.bussinessId);
              session
                .post(`api/FileFieldFormEpixeirisi/`, data, {
                  headers: { "Content-Type": "multipart/form-data" },
                })
                .then((resp) => {
                  EventSpy(
                    1,
                    `Δημιουργία Αρχείου ${JSON.stringify(resp.data)}`
                  );
                  props.handleSpinner("businessSpinner");
                  props.handleChangeUpdate();
                });
            }
          } else {
            props.handleChangeUpdate();
          }
          // if (handleFiles()) {
          //     console.log('kanw update')
          //     props.handleChangeUpdate()
          // }
          setOpen(false);
        })
        .catch((e) => {

          if (e.response.data.hasOwnProperty("nameE")) {
            setAlertShow(true);
            setErrorMsg("Επιχείρηση με αυτό το όνομα υπάρχει ήδη");

            setTimeout(() => {
              setAlertShow(false);
            }, 2000);
            return;
          } else {

            setAlertShow(true);
            setErrorMsg(JSON.stringify(e.response.data));
            setTimeout(() => {
              setAlertShow(false);
            }, 2000);

          }

          return;
        });
    }
    setFiles([]);
  };

  const handleClose = () => {
    setOpen(false);
    setAlertShow(false);
  };
  const checkNotDate = (value) => {
    console.log(value);
    console.log(moment(value)._d);
    setNotDate(value);
  };

  return (
    <div>
      {/* <Button
                onClick={handleOpen}
                // startIcon={<AddBusinessIcon />}
                variant="contained"
                sx={{
                    width: "fit-content",
                    height: "10%",
                    textTransform: "none",
                    bgcolor: "#169ab0",
                }}
            >
                Επεξεργασία Επιχείρησης
            </Button> */}
      <Button onClick={handleOpen} variant="outlined">
        Επεξεργασια
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"l"}>
        <DialogTitle style={{ textAlign: "center" }}>
          Επεξεργασία Επιχείρησης - {owner} {bussinessCategory}
        </DialogTitle>
        {alertShow && <Alert severity="error">{errorMsg}</Alert>}

        <DialogContent style={{ textAlign: "center" }}>
          <hr />

          <Grid container spacing={8} alignItems="center" justify="center">
            <Grid item xs={6}>
              <Card
                sx={{ minWidth: 275 }}
                style={{
                  boxShadow: "0 0 20px aquamarine",
                  borderStyle: "groove",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Επωνυμία"
                      variant="standard"
                      value={buisnessName}
                      onChange={(e) => setBuisnessName(e.target.value)}
                    />
                    <TextField
                      id="standard-basic"
                      label="Ιδιοκτήτης"
                      variant="standard"
                      value={owner}
                      onChange={(e) => setOwner(e.target.value)}
                    />
                  </div>
                  <br />
                  <br />

                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Αρ.Αδείας"
                      variant="standard"
                      value={licenseNumber}
                      onChange={(e) => setLicenseNumber(e.target.value)}
                    />
                    <TextField
                      id="standard-basic"
                      label="Διεύθυνση"
                      variant="standard"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <br />
                  <br />
                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Διακριτικός Τίτλος"
                      variant="standard"
                      value={distinctiveTitle}
                      onChange={(e) => setDistinctiveTitle(e.target.value)}
                    />
                    <TextField
                      id="standard-basic"
                      label="ΑΦΜ"
                      variant="standard"
                      value={afm}
                      onChange={(e) => setAfm(e.target.value)}
                    />
                  </div>

                  <br />
                  <br />
                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Αριθμός Φακέλου"
                      variant="standard"
                      value={folderNumber}
                      onChange={(e) => setFolderNumber(e.target.value)}
                    />
                    <TextField
                      id="standard-basic"
                      label="Site"
                      variant="standard"
                      value={site}
                      onChange={(e) => setSite(e.target.value)}
                    />
                  </div>
                  <br />
                  <br />
                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Κινητό Τηλέφωνο"
                      variant="standard"
                      value={mobilePhone}
                      onChange={(e) => setMobilePhone(e.target.value)}
                    />
                    <TextField
                      id="standard-basic"
                      label="Σταθερό Τηλέφωνο"
                      variant="standard"
                      value={homePhone}
                      onChange={(e) => setHomePhone(e.target.value)}
                    />
                  </div>
                  <br />
                  <br />
                  <div className="first-section" style={customStyleDiv}>
                    <TextField
                      id="standard-basic"
                      label="Παρατηρήσεις"
                      variant="standard"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />

                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enGB}
                    >
                      <Stack spacing={2} style={{ width: "29%" }}>
                        <DesktopDatePicker
                          label="Ημ/νία Γνωστοποίησης"
                          // inputFormat="yyy/MM/dd"
                          inputFormat="dd/MM/yyyy"
                          value={notificationDate}
                          // onChange={(newValue) => setValue(newValue)}
                          onChange={(newValue) => setNotificationDate(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                  <br />
                  <br />
                  <div className="myclass">
                    <InputLabel id="demo-controlled-open-select-label">
                      Κατηγορία Επιχείρησης*
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={bussinessCategory}
                      onChange={(e) => handleBusinessCategory(e)}
                    >
                      <MenuItem value="">
                        <em>-------------------</em>
                      </MenuItem>
                      {props.categories.map((e) => (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <br />
                  <div className="myclass">
                    <InputLabel id="demo-controlled-open-select-label1">
                      Δημοτική Ενότητα
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label1"
                      id="demo-controlled-open-select1"
                      value={citySection}
                      onChange={(e) => handleCitySection(e)}
                    >
                      <MenuItem value="">
                        <em>-------------------</em>
                      </MenuItem>
                      {props.citySections.map((e) => (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                sx={{ minWidth: 275 }}
                style={{
                  boxShadow: "0 0 20px aquamarine",
                  borderStyle: "groove",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="Εναρξη Ωραρίου"
                      value={startTimeDate}
                      ampm={false}
                      format="HH:mm:ss"
                      onChange={(newValue) => {
                        setStartTimeDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <br />
                  <br />

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="Λήξη Ωραρίου"
                      value={endTimeDate}
                      ampm={false}
                      format="HH:mm:ss"
                      onChange={(newValue) => {
                        setEndTimeDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </CardContent>
              </Card>
              <br />
              <br />

              <Card
                sx={{ minWidth: 275 }}
                style={{
                  boxShadow: "0 0 20px aquamarine",
                  borderStyle: "groove",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Συνημμένο αρχείο
                  </Typography>

                  <input
                    multiple
                    id="file_field"
                    name="file_field"
                    type="file"
                    onChange={(e) => handleMultipleFiles(e)}
                  />
                </CardContent>
              </Card>
              <br />
              <br />
              <Card
                sx={{ minWidth: 275 }}
                style={{
                  boxShadow: "0 0 20px aquamarine",
                  borderStyle: "groove",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Η Επιχείρηση έχει κλείσει
                  </Typography>
                  <div className="myclass">
                    <CheckBox
                      id="standard-basic"
                      label="Έχει κλείσει"
                      variant="standard"
                      checked={isClosed}
                      onChange={(e) => setIsClosed(e.target.checked)}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="success" onClick={saveDialog}>
            Αποθηκευση
          </Button>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Κλεισιμο
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function BusinessLisenceModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [startDate, setStartDate] = React.useState(
    props.start_date ? new Date(props.start_date) : null
  );
  const [endDate, setEndDate] = React.useState(
    props.end_date ? new Date(props.end_date) : null
  );
  const [comment, setComment] = React.useState(
    props.comment ? props.comment : ""
  );
  const [files, setFiles] = React.useState([]);
  const [licenseParameter, setLicenseParameter] = React.useState(
    props.licenseParam ? props.licenseParam : ""
  );

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const handleFiles = (e) => {
    setFiles(e.target.files);
  };
  const handleSelectChange = (e) => {
    setLicenseParameter(e.target.value);
  };

  const saveCard = () => {
    var end_date = "";
    if (endDate != null) {
      end_date = new Date(endDate)
        .toLocaleDateString("el", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");
    }
    if (props.action === "Create") {
      session
        .post("api/bussiness-license/create/", {
          start_date: new Date(startDate)
            .toLocaleDateString("el", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-"),
          end_date: end_date == "" ? null : end_date,
          comments: comment,
          bussiness: props.businessID,
          license_parameter: licenseParameter,
          created_by: props.created_by,
        })
        .then((resp) => {
          EventSpy(1, `Δημιουργία Άδειας ${JSON.stringify(resp.data)}`);

          if (files.length > 0) {
            for (const file of files) {
              let data = new FormData();

              data.append("file", file);
              data.append("business_lisence", resp.data.id);
              setTimeout(() => {
                session
                  .post("api/business-files/create/", data, {
                    headers: { "Content-Type": "multipart/form-data" },
                  })
                  .then((resp) => {
                    EventSpy(
                      1,
                      `Δημιουργία Αρχείου Αδείας ${JSON.stringify(resp.data)}`
                    );
                    props.handleBusinessChange();
                    props.handleSpinner("licenseSpinner");
                  });
              }, 1000);
            }
          }
        })
        .finally(
          () => setOpen(false),
          setStartDate(null),
          setEndDate(null),
          setComment(null),
          setLicenseParameter(""),
          setFiles([])
        );
      props.handleBusinessChange();
    } else {
      let updateData = new FormData();
      updateData.append(
        "start_date",
        new Date(startDate)
          .toLocaleDateString("el", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-")
      );
      updateData.append("end_date", end_date);
      updateData.append("comments", comment);
      updateData.append("bussiness", props.businessID);
      updateData.append("license_parameter", licenseParameter);
      updateData.append("created_by", props.created_by);

      // session.put(`api/business-license/${props.actionID}/actions/`, updateData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => setOpen(false), props.handleBusinessChange())
      if (files.length > 0) {
        for (const file of files) {
          let form = new FormData();
          form.append("file", file);
          form.append("business_lisence", props.actionID);
          setTimeout(() => {
            session
              .post("api/business-files/create/", form, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((resp) => {
                EventSpy(
                  1,
                  `Δημιουργία Αρχείου Άδειας ${JSON.stringify(resp.data)}`
                );
                props.handleBusinessChange();
                props.handleSpinner("licenseSpinner");
              });
          }, 1000);
        }
      }
      console.log(end_date);
      session
        .put(`api/business-license/${props.actionID}/actions/`, updateData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          (resp) => EventSpy(2, `Ανανέωση Άδειας ${JSON.stringify(resp.data)}`),
          setOpen(false)
        );
      props.handleBusinessChange();
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="outlined">
        {props.action === "Create" ? "Δημιουργια" : "Επεξεργασια"}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.action === "Create" ? "Δημιουργία" : "Επεξεργασια"} Αδειας
          </Typography>
          <hr />
          <Stack spacing={5}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardContent>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enGB}
                    >
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        label="Έναρξη Άδειας"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>

                    <br />
                    <br />
                  </CardContent>
                </Grid>
                <Grid item xs={12}>
                  <CardContent>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enGB}
                    >
                      <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Λήξη Άδειας"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </CardContent>
                </Grid>

                <br />
                <br />
                <Grid item xs={12}>
                  <CardContent>
                    <TextField
                      label="Παρατηρήσεις"
                      id="standard-basic"
                      variant="standard"
                      multiline
                      maxRows={10}
                      value={comment}
                      onChange={(e) => handleComment(e)}
                    />
                  </CardContent>
                </Grid>
                <Grid item xs={12}>
                  <CardContent>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 250 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Είδος Άδειας
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={licenseParameter}
                        onChange={handleSelectChange}
                        label="Σταδιο Παράβασης"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>

                        {props.licenseParameters &&
                          props.licenseParameters.length > 0 &&
                          props.licenseParameters.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </CardContent>
                </Grid>

                <br />
                <br />

                <Grid item xs={12}>
                  <CardContent>
                    <input
                      multiple
                      type="file"
                      onChange={(e) => handleFiles(e)}
                    />

                    {/* {files && files.length > 0 && files.map((e, index) => (
                                            <ul key={index}>
                                                <li>{e.name}</li>
                                            </ul>
                                        ))} */}
                  </CardContent>
                </Grid>

                <br />
                <br />
                <br />
                <Grid item xs={12}>
                  <CardContent>
                    <Button onClick={saveCard}> Αποθηκευση</Button>
                  </CardContent>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function DeleteBusinessFile(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [filename, setFilename] = React.useState(props.filename);
  const [fileId, setFileId] = React.useState(props.file);
  const [action, setAction] = React.useState(props.action);

  const actionMap = () => {
    var dict = {
      stoixeia: `api/FileFieldFormEpixeirisi/${props.fileId}/`,
      license: `api/business-file/${props.fileId}/actions/`,
      parabasi: `api/business-parabaseis/${props.id}/update/`,
    };
    return dict[action];
  };

  const deleteFile = () => {
    let actionLink = actionMap();
    session
      .delete(actionLink)
      .then(
        () => EventSpy(3, `Διαγραφή Αρχείου ${actionLink}`),
        setOpen(false),
        props.handleChangeUpdate()
      );
  };

  return (
    <div style={{ display: "inline" }}>
      <Button
        onClick={handleOpen}
        startIcon={<DeleteIcon />}
        color="error"
        style={{ padding: "0px" }}
      ></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε σίγουροι οτι θέλετε να διαγράψετε τo αρχείο με όνομα :
          </Typography>

          <hr />
          <br />
          <br />
          <Typography variant="h6" component="h2">
            <a href={props.filenameUrl} target="_blank" rel="noopener noreferrer">
              {props.filename}
            </a>{" "}
            ;
          </Typography>
          <br />
          <br />
          <br />
          <Button color="error" variant="outlined" onClick={deleteFile}>
            Διαγραφη
          </Button>
          <Button color="success" variant="outlined" onClick={handleClose}>
            Επιστροφη
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export {
  BusinessLisenceModal,
  UpdateBusinessLicenseFile,
  BusinessEdit,
  DeleteBusinessFile,
};
